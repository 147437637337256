import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Input,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  ORGANIZATION_SETTINGS_LIST,
  INVENTORY_SETTING,
  AUTO_CLOSING_TYPE_CHOICES,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class InventorySettings extends Component {
  state = {
    org_setting_id: "",
    auto_closing_type: "",
    auto_closing_types: [],
    auto_closing: false,
    low_stock_quantity: 0,
    alert_time_for_product_low_stock: Date.now(),
    alert_time_for_product_low_stock_view: moment(),
  };

  componentDidMount() {
    this.getOrganizationSettingsList();
    this.getAutoClosingTypesOptions();
  }
  getOrganizationSettingsList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORGANIZATION_SETTINGS_LIST}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState(
            {
              org_setting_id: response.data?.data[0]?._id.$oid ?? "",
            },
            () => {
              this.getInventorySettings();
            }
          );
          console.log("response from organization settings list", response);
        })
        .catch((error) => {
          console.log("error from organization settings list api", error);
        });
    });
  };

  getInventorySettings = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${INVENTORY_SETTING}${this.state.org_setting_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            auto_closing: response.data?.data?.auto_closing ?? false,
            auto_closing_type: response.data?.data?.auto_closing_type ?? "",
            low_stock_quantity: response.data?.data?.low_stock_quantity ?? 0,
            alert_time_for_product_low_stock:
              response.data?.data?.low_stock_alert_datetime?.$date ??
              Date.now(),
            alert_time_for_product_low_stock_view:
              response.data?.data?.low_stock_alert_datetime?.$date ?? moment(),
          });
          console.log("response from inventory settings", response);
        })
        .catch((error) => {
          console.log("error from inventory settings api", error);
        });
    });
  };

  getAutoClosingTypesOptions = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${AUTO_CLOSING_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            auto_closing_types: response.data.auto_closing_type_choices,
          });
          console.log("response from auto closing type options", response);
        })
        .catch((error) => {
          console.log("error from auto closing type options api", error);
        });
    });
  };

  setAutoClosingType = (value) => {
    this.setState({ auto_closing_type: value }, () => {
      console.log(this.state.auto_closing_type);
    });
  };
  setAutoClosing = (value) => {
    this.setState({ auto_closing: CHECK_BOOL(value) }, () => {
      console.log(this.state.auto_closing);
    });
  };

  setLowStockValue = (evt) => {
    evt.preventDefault();
    this.setState({ low_stock_quantity: evt.target.value }, () => {
      console.log(this.state.low_stock_quantity);
    });
  };

  updateInventorySettings = () => {
    const data = {
      org_setting_id: this.state.org_setting_id,
      auto_closing: this.state.auto_closing,
      auto_closing_type: this.state.auto_closing_type,
      low_stock_quantity: this.state.low_stock_quantity,
      low_stock_alert_datetime: this.state.alert_time_for_product_low_stock,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${INVENTORY_SETTING}${this.state.org_setting_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from inventory Settings", response);
          this.getOrganizationSettingsList();
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from inventory Settings", error);
        });
    });
  };

  render() {
    return (
      <Card style={{ marginTop: 5 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Product Stock Settings"
        />
        <Form>
          <Form.Item>
            <Text>Select Auto Closing Type</Text>
            <Select
              value={this.state.auto_closing_type}
              onChange={this.setAutoClosingType}
            >
              {this.state.auto_closing_types.map((r) => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Enable Auto Closing</Text>
            <Select
              value={this.state.auto_closing.toString()}
              onChange={this.setAutoClosing}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Low Stock Alert</Text>
            <Input
              placeholder="Low Stock Alert"
              type="number"
              value={this.state.low_stock_quantity}
              onChange={this.setLowStockValue}
            />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Alert Time for Low Stock</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  inputVariant="outlined"
                  value={this.state.alert_time_for_product_low_stock_view}
                  onChange={(value, dateString) => {
                    this.setState({
                      alert_time_for_product_low_stock:
                        moment(value).format("x"),
                      alert_time_for_product_low_stock_view: value,
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.updateInventorySettings}
              style={{ marginTop: 24, marginBottom: 15 }}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
