import React, { Component } from "react";
import { Card, Typography, PageHeader, Button } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import {
  EDIT_ORDER,
  ORDER_TAG_PRINT,
  ORDER_INVOICE_PRINT
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";

import { Spinner } from "../loader";

import "../login.css";
import ReactGA from "react-ga4";

const { Text } = Typography;

export default class OrderLink extends Component {
  state = {
    order_number: "",
    name: "",
    details: "",
    items: [],
    itemObj: {},
    item: "",
    price: null,
    qty: 0,
    unit: "",
    drop_contact_name: "",
    drop_contact_no: "",
    drop_contact_email: "",
    start_entity_name: "",
    start_entity_key: "",
    start_entity_address: "",
    end_entity_name: "",
    end_entity_key: "",
    end_loc: "",
    end_loc_name: "",
    delivery: true,
    delivered: false,
    rescheduled: false,
    startObj: {},
    endObj: {},
    pl: null,
    pw: null,
    ph: null,
    assigned_org_name: "",
    assigned_org_id: "",
    parcel_wght: null,
    total_price: null,
    delivery_charge: null,
    other_charges: null,
    bill_amount: null,
    total_discount_on_product: null,
    other_discount: null,
    other_discount_type: "amount",
    total_discount: 0,
    received_amount: null,
    due_amount: null,
    // received: false,
    delivery_date_time: moment(),
    delivery_date_time_view: moment(),
    modify_on: "",
    data: [],
    dataS: [],
    orgList: [],
    loading: false,
    visible: false,
    showMap: false,
    showEntity: false,
    showProduct: false,
    delivery_time_flexible: false,
    delivery_charge_included: false,
    paid: false,
    payment_method: "",
    settled: false,
    cancelled: false,

    query: "",
    start_location: "",
    value: [],
    dataMap: [],
    fetch: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    editEnd: false,
    zoom: 15,
    get_connected_orgs: true,
    products: [],
    edit: false,
    assigned_to: "",
    task_id: "",
    org_id: "",
    created_by_name: "",
    total_refund: 0,
    total_refunded_amount: 0,
    total_received_amount: 0
  };

  componentDidMount() {
    this.setState({ org_id: GET_USER_LOCAL().organization.$oid });
    this.getOrderDetails();
    window.location.replace(
      `conveyence://app/root/home/orders/details/${this.props.match.params.order_id}`
    );
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getOrderDetails = () => {
    this.setState({ order_id: this.props.match.params.order_id });

    GET_ACCESS_TOKEN().then(token => {
      let order_id = this.props.match.params.order_id;
      let url = `${EDIT_ORDER}${order_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro view order", response.data.data);
        this.setState({
          name: response.data.data.name,
          details: response.data.data.details,
          assigned_org_id: response.data.data.assigned_org.$oid,
          assigned_org_name: response.data.data.assigned_org_name,
          bill_amount: response.data.data.bill_amount,
          delivered: response.data.data.delivered,
          delivery: response.data.data.delivery,
          delivery_charge: response.data.data.delivery_charge,
          delivery_date_time: response.data.data.delivery_date_time.$date,
          delivery_date_time_view: response.data.data.delivery_date_time.$date,
          drop_contact_email: response.data.data.drop_contact_email,
          drop_contact_name: response.data.data.drop_contact_name,
          drop_contact_no: response.data.data.drop_contact_no,
          due_amount: response.data.data.due_amount,
          end_loc: response.data.data.end_loc,
          end_location_lon: response.data.data.end_loc.coordinates[0],
          end_location_lat: response.data.data.end_loc.coordinates[1],
          center_lon: response.data.data.end_loc.coordinates[0],
          center_lat: response.data.data.end_loc.coordinates[1],
          end_loc_name: response.data.data.end_loc_name,
          order_number: response.data.data.order_number,
          other_charges: response.data.data.other_charges,
          pl: response.data.data.parcel_dim.pl,
          pw: response.data.data.parcel_dim.pw,
          ph: response.data.data.parcel_dim.ph,
          parcel_wght: response.data.data.parcel_wght,
          // received: response.data.data.received,
          total_received_amount:
            response.data?.data?.total_received_amount ?? 0,
          rescheduled: response.data.data.rescheduled,
          created_by_name: response.data.data.created_by_name,
          delivery_time_flexible:
            response.data.data.delivery_time_flexible || false,
          total_price: response.data.data.total_price,
          other_discount: response.data?.data?.other_discount ?? 0,
          other_discount_type:
            response.data?.data?.other_discount_type ?? "amount",
          total_refund: response.data?.data?.total_refund ?? 0,
          total_refunded_amount: response.data?.data?.total_refunded_amount ?? 0,
          total_discount_on_product:
            response.data?.data?.discount_on_products ?? 0,
          total_discount: response.data?.data?.total_discount ?? 0,
          start_entity_name: response.data.data.start_entity.name,
          end_entity_name: response.data.data.end_entity.name,
          products: response.data.data.items,
          modify_on: response.data.data.modified_on.$date,
          start_entity_address: response.data.data.start_entity.address,
          delivery_charge_included: response.data.data.delivery_charge_included,
          paid: response.data.data.paid,
          payment_method: response.data.data.payment_method
            ? response.data.data.payment_method
            : "",
          settled: response.data.data.settled,
          cancelled: response.data.data.cancelled,
          task_id: response.data.data.task_ids[0] || ""
        });
      });
    });
  };

  orderPrintTag = (item_id, item) => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ORDER_TAG_PRINT}?order_id=${this.state.order_id}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then(response => {
          console.log("response from only order tag print list fmt", response);
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.order_tag_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch(error => {
          console.log("error from only order tag print list fmt", error);
        });
    });
  };

  orderInvoiceTag = (item_id, item) => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ORDER_INVOICE_PRINT}?order_id=${this.state.order_id}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then(response => {
          console.log(
            "response from only order invoice print list fmt",
            response
          );
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.order_tag_invoice_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch(error => {
          console.log("error from only order invoice print list fmt", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title={`Order Number : ${this.state.order_number}`}
        />

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Card className="view">
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Order Name</Text>:{" "}
                {this.state.name}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Details</Text>:{" "}
                {this.state.details}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Created By</Text>:{" "}
                {this.state.created_by_name}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Pickup Place Name</Text>:{" "}
                {this.state.start_entity_name}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Pickup Place Address</Text>
                : {this.state.start_entity_address}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Delivery Place Name</Text>:{" "}
                {this.state.end_entity_name}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Delivery Place Address
                </Text>
                : {this.state.end_loc_name}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Drop Contact Name</Text>:{" "}
                {this.state.drop_contact_name}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Drop Contact Mobile</Text>:{" "}
                {this.state.drop_contact_no}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Drop Contact Email</Text>:{" "}
                {this.state.drop_contact_email}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Delivery date</Text>:{" "}
                {timeConverter(this.state.delivery_date_time)}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Assigned Organization
                </Text>
                : {this.state.assigned_org_name}
              </p>
            </Card>
          </div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* <Card className="view">
              
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Parcel Weight</Text>:{" "}
                {this.state.parcel_wght} KG
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Parcel Width</Text>:{" "}
                {this.state.pw} Feet
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Parcel Height</Text>:{" "}
                {this.state.ph} Feet
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Parcel Length</Text>:{" "}
                {this.state.pl} Feet
              </p>
            </Card> */}
            <Card className="view">
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Total</Text>: ৳{" "}
                {this.state.total_price}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Delivery Charge</Text>: ৳{" "}
                {this.state.delivery_charge}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Other Charges</Text>: ৳{" "}
                {this.state.other_charges}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Other Discount Type</Text>:{" "}
                {this.state.other_discount_type}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Other Discount</Text>:{" "}
                {this.state.other_discount_type === "amount"
                  ? this.state.other_discount
                  : this.state.other_discount_type === "percentage"
                  ? `${this.state.other_discount}%`
                  : 0}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Discount on Products</Text>
                : {this.state.total_discount_on_product}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Total Discount</Text>:{" "}
                {this.state.total_discount}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Bill Amount</Text>: ৳{" "}
                {this.state.bill_amount}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Total Received Amount</Text>: {" "}
                {this.state.total_received_amount}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Due</Text>: ৳{" "}
                {this.state.due_amount}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Total Refund</Text>:{" "}
                {this.state.total_refund}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Total Refunded Amount</Text>: {" "}
                {this.state.total_refunded_amount}
              </p>

              
            </Card>
            <Card className="view">
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Delivery</Text>:{" "}
                {this.state.delivery.toString()}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Delivered</Text>:{" "}
                {this.state.delivered.toString()}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Paid</Text>:{" "}
                {this.state.paid.toString()}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Payment Method</Text>:{" "}
                {this.state.payment_method}
              </p>
              {/* <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Received</Text>:{" "}
                {this.state.received.toString()}
              </p> */}
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Rescheduled</Text>:{" "}
                {this.state.rescheduled.toString()}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Delivery Time Flexible
                </Text>
                : {this.state.delivery_time_flexible.toString()}
              </p>
            </Card>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            onClick={this.orderPrintTag}
            className="login-form-button"
            style={{ marginTop: 10 }}
          >
            Print Order
          </Button>
          <Button
            onClick={this.orderInvoiceTag}
            className="login-form-button"
            style={{ marginTop: 10 }}
          >
            Print Invoice
          </Button>
        </div>
      </Card>
    );
  }
}
