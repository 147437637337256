import React, { Component } from 'react';
import { Form, Icon, Input, Button, Card } from "antd";
import { Redirect } from "react-router-dom";
import swal from "sweetalert2";
import axios from "axios";

import { RESET_PASSWORD } from "../api/constants";
// import { LOCAL_USER_INFO, validateEmail } from "../utilities/utilities";
import { Spinner } from "./loader";
import "./login.css";
import Logo from "../assets/logo.png";

export default class NewPasswordEmail extends Component {
    state = {
        password: "",
        confirmPassword: "",
        email:"",
        access_id:"",
        loading: false,
        toLogin: false,
        width: window.innerWidth,
        height: window.innerHeight,
        show_confirm_password:false,
        show_password:false
      };
    
      componentDidMount() {
        this.setState({ email: this.props.location.state.email,access_id: this.props.location.state.access_id });
        const handleResize = () => {
          this.setState({ height: window.innerHeight, width: window.innerWidth });
        };
        window.addEventListener("resize", handleResize);
      }
    
      setPassword = evt => {
        evt.preventDefault();
        this.setState({ password: evt.target.value });
        console.log(this.state.password);
      };
      setConfirmPassword = evt => {
        evt.preventDefault();
        this.setState({ confirmPassword: evt.target.value });
        console.log(this.state.confirmPassword);
      };
    
      changePassword = () => {
        if (this.state.password !== this.state.confirmPassword) {
            swal.fire("Error", "Password does not match", "error");
          } else {
            this.setState({ loading: true });
            const url = `${RESET_PASSWORD}`;
            let data = {
                user: this.state.email,
                new_password: this.state.password,
                access_id:this.state.access_id
              };
    
              console.log(data);
    
              axios({
                method: "put",
                url: url,
                data: data,
                headers: { 
                  'content-type': 'application/x-www-form-urlencoded' 
                }
              })
              .then(response => {
                this.setState({ loading: false });
                console.log("response from change password api", response);
                if (response.data.hasOwnProperty("status")) {
                  swal.fire("Information", response.data.status, "info");
                  if(response.data.status === "password reset successful"){
                    this.setState({ toLogin: true });
                  }
                 
                }
              })
              .catch(error => {
                this.setState({ loading: false });
                console.log("error in change password", error);
              });
          }
       
      };
      render() {
        if (this.state.loading === true) {
          return <Spinner />;
        } else if (this.state.toLogin === true) {
          return (
            <Redirect to="/" />
          );
        }
        return (
          <div className="container" style={{ width: this.state.width }}>
            <div style={{ textAlign: "center", paddingBottom: 10 }}>
              <img src={Logo} alt="" style={{width:300,height:70}}/>
            </div>
            <Card title="Please Enter New Password" className="card" style={{width:450,margin:"auto"}}>
              <Form className="login-form">
                <Form.Item>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Input
                    type={this.state.show_password ? "text" : "password"}
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="New Password"
                    value={this.state.password}
                    onChange={this.setPassword}
                  />
                  <Button
                      onClick={() =>
                        this.setState({
                          show_password: !this.state.show_password,
                        })
                      }
                    >
                      <Icon
                        type={
                          this.state.show_password ? "eye" : "eye-invisible"
                        }
                        style={{
                          marginTop: 3,
                          fontSize: 18,
                          color: "#7B8FA1",
                        }}
                      />
                    </Button>
                  </div>
                </Form.Item>
                <Form.Item>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Input
                    type={this.state.show_confirm_password ? "text" : "password"}
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Confirm Password"
                    value={this.state.confirmPassword}
                    onChange={this.setConfirmPassword}
                  />
                  <Button
                      onClick={() =>
                        this.setState({
                          show_confirm_password: !this.state.show_confirm_password,
                        })
                      }
                    >
                      <Icon
                        type={
                          this.state.show_confirm_password ? "eye" : "eye-invisible"
                        }
                        style={{
                          marginTop: 3,
                          fontSize: 18,
                          color: "#7B8FA1",
                        }}
                      />
                    </Button>
                  </div>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="login-form-button"
                    htmlType="submit"
                    onClick={this.changePassword}
                  >
                    Confirm
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        );
      }
}
