import React, { Component } from "react";
import { Card, PageHeader, Table, Typography, Select, Pagination, Button } from "antd";
import axios from "axios";
import {
  INVENTORY_PRODUCT_STOCK_HISTORY_LIST,
  INVENTORY_STOCK_HISTORY_REPORT
} from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FileSaver from "file-saver";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";

import "../login.css";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

class ViewInventoryStockHistory extends Component {
  state = {
    data: [],
    inventory_id: "",
    page: 0,
    total: 0,
    limit: 10,
    start_time:moment().startOf('month').format('x'),
    start_time_view:moment().startOf('month'),
    end_time: Date.now(),
    end_time_view: moment()
  };

  componentDidMount() {
    this.setState({ inventory_id: this.props.location.state.inventory_id ,start_time:moment().startOf('month').format("x")});
    this.getInventoryStockHistory();
  }

  getInventoryStockHistory = () => {
    GET_ACCESS_TOKEN().then(token => {
      let inventory_id = this.props.location.state.inventory_id;
      let url = `${INVENTORY_PRODUCT_STOCK_HISTORY_LIST}${inventory_id}?page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro  inventory product stock history", response);
        this.setState({
          data: response.data?.data ?? [],
          total: response.data.total
        });
      });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getInventoryStockHistory();
    });
  };

  setLimit = value => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getInventoryStockHistory();
    });
  };

  downloadStockHistory=()=>{
    GET_ACCESS_TOKEN().then(token => {
      const url = `${INVENTORY_STOCK_HISTORY_REPORT}${this.state.inventory_id}?start_date=${this.state.start_time}&end_date=${this.state.end_time}&access_token=${token}`;

      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          console.log("response from stock history fmt", blob);

          FileSaver.saveAs(
            blob,
            `conveyance_stock_history_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch(error => {
          console.log("error from order detailed invoice api", error);
        });
    });
  }

  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          onBack={() => this.props.history.goBack()}
          title="Inventory Product Stock History"
        />
        <Card>
          <Card>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.start_time_view}
                    onChange={(value, dateString) => {
                      this.setState({
                        start_time: moment(value).format("x"),
                        start_time_view: value
                      });
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.end_time_view}
                    onChange={(value, dateString) => {
                      this.setState({
                        end_time: moment(value)
                          .add(59, "seconds")
                          .format("x"),
                        end_time_view: value
                      });
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {this.props.userProfileData.report.inventory_report.inventory_stock_history_report?(
                <Button
                style={{
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 15
                }}
                onClick={this.downloadStockHistory}
              >
                Download Stock History
              </Button>
              ):null}
              
            </div>
          </Card>
          <div
            style={{
              display: "flex",
              flexDirection: "row"
            }}
          >
            <div style={{ margin: 10 }}>
              <Text>{`Displaying : `}</Text>
              <Select value={this.state.limit} onChange={this.setLimit}>
                <Option key={10}>10</Option>
                <Option key={25}>25</Option>
                <Option key={50}>50</Option>
                <Option key={100}>100</Option>
              </Select>
              <Text>{`  Entries `}</Text>
            </div>
          </div>
          <Table
            dataSource={this.state.data}
            pagination={false}
            rowKey={record => record._id.$oid}
            size="small"
            bordered
          >
            <Column
              title="Product Name"
              dataIndex="product_name"
              key="product_name"
            />
            <Column
              title="Current Stock Quantity"
              dataIndex="current_stock_quantity"
              key="current_stock_quantity"
            />

            <Column
              title="Current Unit Purchase Price"
              dataIndex="current_unit_purchase_price"
              key="current_unit_purchase_price"
            />
            <Column
              title="Previous Stock Quantity"
              dataIndex="previous_stock_quantity"
              key="previous_stock_quantity"
            />
            <Column
              title="Previous Unit Purchase Price"
              dataIndex="previous_unit_purchase_price"
              key="previous_unit_purchase_price"
            />
            <Column
              title="Discount Per Unit"
              dataIndex="discount_per_unit"
              key="discount_per_unit"
            />

            <Column
              title="Added Stock"
              dataIndex="added_stock"
              key="added_stock"
            />

            <Column title="Unit Cost" dataIndex="unit_cost" key="unit_cost" />
            <Column
              title="Cost Amount"
              dataIndex="cost_amount"
              key="cost_amount"
            />

            <Column
              title="Subtracted Stock"
              dataIndex="subtracted_stock"
              key="subtracted_stock"
            />
            <Column
              title="Add or Subtract Reason"
              dataIndex="add_or_subtract_reason"
              key="add_or_subtract_reason"
            />
            <Column
              title="Created On"
              key="created_by"
              render={(text, record) => (
                <span>{timeConverter(record.created_on.$date)}</span>
              )}
            />
          </Table>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.limit)}
              current={this.state.page + 1}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {  getUserData })(
  ViewInventoryStockHistory
);