import React, { Component } from "react";
import { Button, Card, Typography, PageHeader, Select } from "antd";
import { debounce } from "lodash";
// import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import { CREATE_DRIVER, GENERATE_TASK_FROM_ORDER } from "../../api/constants";
import { GET_ACCESS_TOKEN, randomId } from "../../utilities/utilities";
// import { Spinner } from "../loader";

const { Text } = Typography;
const { Option } = Select;

export default class TaskFromOrder extends Component {
  state = {
    order_id: "",
    name: "",
    details: "",
    start_loc: "",
    start_time: "",
    start_loc_name: "",
    end_loc: "",
    end_time: "",
    end_loc_name: "",
    task_code: "",
    price: null,
    assigned_to: "",
    assigned_to_label: "",
    userData: [],
  };
  componentDidMount() {
    this.getOrderDetails();
    this.getUserList();
  }

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  }, 500);

  setAssigned = (value) => {
    this.setState(
      { assigned_to_label: value.label, assigned_to: value.key },
      () => {
        console.log(this.state.assigned_to);
      }
    );
  };

  getOrderDetails = () => {
    this.setState({ order_id: this.props.location.state.order_id });

    GET_ACCESS_TOKEN().then((token) => {
      let order_id = this.props.location.state.order_id;
      let url = `${GENERATE_TASK_FROM_ORDER}${order_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response from generate task from order get", response);
        this.setState({
          name: response.data.data.name,
          details: response.data.data.details,
          end_loc: response.data.data.end_loc,
          end_loc_name: response.data.data.end_loc_name,
          task_code: response.data.data.order_number,
          price: response.data.data.bill_amount,
          start_loc:
            response.data?.data?.start_entity?.loc ??
            response.data.data.end_loc,
          start_loc_name:
            response.data?.data?.start_entity?.address ??
            response.data.data.end_loc_name,
          end_time: response.data.data.delivery_date_time.$date,
          drop_contact_name: response.data.data.drop_contact_name,
          drop_contact_no: response.data.data.drop_contact_no,
        });
      });
    });
  };

  generateTask = () => {
    //   this.setState({ loading: true });
    let data = {
      name: this.state.name,
      details: this.state.details,
      end_loc: this.state.end_loc,
      end_loc_name: this.state.end_loc_name,
      task_code: randomId(10),
      price: this.state.price,
      start_loc: this.state.start_loc,
      start_loc_name: this.state.start_loc_name,
      end_time: this.state.end_time,
      start_time: this.state.end_time,
      assigned_to: this.state.assigned_to,
      assigned_to_name: this.state.assigned_to_label,
      start_dist: 1000,
      end_dist: 1000,
      trip_duration: 0,
      delay_mins: null,
      assigned_asset: "",
      prev_task_id: "",
      m_task_id: "",
      contact_no: "",
      contact_name: "",
      drop_contact_name: this.state.drop_contact_name,
      drop_contact_no: this.state.drop_contact_no,
      colors: [],
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${GENERATE_TASK_FROM_ORDER}${this.state.order_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          // this.setState({ loading: false });
          swal.fire("Info", response.data.message, "info");
          console.log("response from generate task from order put", response);
        })
        .catch((error) => {
          // this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from generate task from order put", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => this.props.history.goBack()}
          title="Generate Task"
        />
        <Card style={{ margin: 5 }}>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Task Code </Text>:{" "}
            {this.state.task_code}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Task Name </Text>:{" "}
            {this.state.name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Task Details </Text>:{" "}
            {this.state.details}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Task Price </Text>:{" "}
            {this.state.price}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>From</Text>{" "}
            {this.state.start_loc_name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>To</Text>:{" "}
            {this.state.end_loc_name}
          </p>
          <div style={{ marginBottom: 5 }}>
            <Text>Search Assigned User</Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              style={{ width: "100%" }}
              value={{
                key: this.state.assigned_to,
                label: this.state.assigned_to_label,
              }}
              onChange={this.setAssigned}
              placeholder="Select User"
            >
              {this.state.userData.map((r) => {
                return (
                  <Option
                    key={r._id.$oid}
                  >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                );
              })}
            </Select>
          </div>
        </Card>
        <Button className="login-form-button" onClick={this.generateTask}>
          Generate task
        </Button>
      </Card>
    );
  }
}
