import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Input } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_BILL_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { TextArea } = Input;

export default class CreateBillType extends Component {
  state = { name: "", details: "" };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setDetails = evt => {
    evt.preventDefault();
    this.setState({ details: evt.target.value }, () => {
      console.log(this.state.details);
    });
  };

  createBillType = () => {
    if (this.state.name === "" || this.state.details === "") {
      swal.fire("info", "Please enter all informations", "info");
    } else {
      let data = {
        name: this.state.name,
        details: this.state.details
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_BILL_TYPE}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            console.log("response from create Bill Type", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from create Bill Type", error);
          });
      });
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Create Bill Type"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Details</Text>
            <TextArea
              rows={4}
              placeholder="Details"
              type="text"
              value={this.state.details}
              onChange={this.setDetails}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createBillType}
            >
              Create Bill Type
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
