import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { MANDATORY_UPDATE } from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";

const { Text } = Typography;
const { Option } = Select;

export default class UpdateSettings extends Component {
  state = {
    version_number: "",
    mandatory_update: false,
    redirect_url: "",
    message:""
  };

  setVersion = evt => {
    evt.preventDefault();
    this.setState({ version_number: evt.target.value }, () => {
      console.log(this.state.version_number);
    });
  };
  setUrl = evt => {
    evt.preventDefault();
    this.setState({ redirect_url: evt.target.value }, () => {
      console.log(this.state.redirect_url);
    });
  };
  setMessage = evt => {
    evt.preventDefault();
    this.setState({ message: evt.target.value }, () => {
      console.log(this.state.message);
    });
  };
  setMandatory = value => {
    this.setState({ mandatory_update: CHECK_BOOL(value) }, () => {
      console.log(this.state.mandatory_update);
    });
  };
  createUpdateSettings = () => {
    if (this.state.version_number === "") {
      swal.fire("Info", "Please enter version number", "info");
    } else {
      let data = {
        version_number: this.state.version_number,
        mandatory_update: this.state.mandatory_update,
        redirect_url: this.state.redirect_url,
        message: this.state.message
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${MANDATORY_UPDATE}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            console.log("response from create Update settings", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from create Update settings", error);
          });
      });
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Create Update Settings"
        />
        <Form>
          <Form.Item>
            <Text>Vesrion Number</Text>
            <Input
              placeholder="Vesrion Number"
              type="text"
              value={this.state.version_number}
              onChange={this.setVersion}
            />
          </Form.Item>
          <Form.Item>
            <Text>Mandatory Update</Text>
            <Select defaultValue="False" onChange={this.setMandatory}>
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Redirect URL</Text>
            <Input
              placeholder="Redirect URL"
              type="text"
              value={this.state.redirect_url}
              onChange={this.setUrl}
            />
          </Form.Item>
          <Form.Item>
            <Text>Message</Text>
            <Input
              placeholder="Message"
              type="text"
              value={this.state.message}
              onChange={this.setMessage}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createUpdateSettings}
            >
              Create Settings
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
