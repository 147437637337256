import React, { Component } from "react";
import {
  Card,
  PageHeader,
  Table,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Pagination,
  Icon,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import FileSaver from "file-saver";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import {
  EDIT_INVENTORY,
  PRODUCT_STOCK_CHANGE,
  PRODUCT_ADD_REASON,
  PRODUCT_SUBTRACT_REASON,
  CREATE_PRODUCT,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { debounce } from "lodash";

import "../login.css";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

class ViewInventory extends Component {
  state = {
    data: [],
    inventory_id: "",
    contact_person_mobile: "",
    contact_person_name: "",
    created_on: "",
    current_value: 0,
    last_closing_time: "",
    last_closing_value: 0,
    place_address: "",
    place_name: "",
    starting_time: "",
    starting_value: 0,
    total_product: "",
    show_stock_modal: false,
    added_stock: 0,
    subtracted_stock: 0,
    cost_amount: 0,
    remarks: "",
    product_id: "",
    stock_update_type: "add",
    disbale_update_stock_button: false,
    modify_inventory: false,
    add_reason_options: [],
    remove_reason_options: [],
    unit_purchase_price: 0,
    sku: "",
    product_name: "",
    category_name: "",
    page: 0,
    limit: 10,
    total: 0,
    sort_by: "created_on",
    sort_type: "dsc",
  };

  componentDidMount() {
    this.setState(
      { inventory_id: this.props.location.state.inventory_id },
      () => {
        this.getProductList();
      }
    );
    this.getAddReasonOptions();
    this.getSubtractReasonOptions();
    this.getInventoryDetails();
  }

  getProductList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT}?sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&enable_order_for_availabl_stock_or_stock_quantity_zero=true&is_parent_product=false&sku=${this.state.sku}&name=${this.state.product_name}&category_name=${this.state.category_name}&inventory_id=${this.state.inventory_id}&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from product list api", error);
        });
    });
  }, 500);

  getAddReasonOptions = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PRODUCT_ADD_REASON}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product add reason list", response);
          this.setState(
            {
              add_reason_options:
                response?.data?.add_reason_choices?.filter(
                  (reason) => reason.name !== "internal_transfer"
                ) ?? [],
            },
            () => {
              this.setState({ remarks: this.state.add_reason_options[0].name });
            }
          );
        })
        .catch((error) => {
          console.log("error from product add reason list api", error);
        });
    });
  };
  getSubtractReasonOptions = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PRODUCT_SUBTRACT_REASON}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product subtract reason list", response);
          this.setState({
            remove_reason_options:
              response?.data?.subtract_reason_choices?.filter(
                (reason) => reason.name !== "internal_transfer"
              ) ?? [],
          });
        })
        .catch((error) => {
          console.log("error from product subtract reason list api", error);
        });
    });
  };

  getInventoryDetails = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let inventory_id = this.props.location.state.inventory_id;
      let url = `${EDIT_INVENTORY}${inventory_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro view inventory", response);
        this.setState({
          // data: response.data?.data?.inventory_items ?? [],
          contact_person_mobile:
            response.data?.data?.inventory_data?.entity_contact_person_mobile ??
            "",
          contact_person_name:
            response.data?.data?.inventory_data?.entity_contact_person_name ??
            "",
          created_on:
            response.data?.data?.inventory_data?.created_on?.$date ?? "",
          current_value:
            response.data?.data?.inventory_data?.current_value ?? 0,
          last_closing_time:
            response.data?.data?.inventory_data?.last_closing_time?.$date ?? "",
          last_closing_value:
            response.data?.data?.inventory_data?.last_closing_value ?? 0,
          place_address:
            response.data?.data?.inventory_data?.entity_address ?? "",
          place_name: response.data?.data?.inventory_data?.entity_name ?? "",
          starting_time:
            response.data?.data?.inventory_data?.starting_time?.$date ?? "",
          starting_value:
            response.data?.data?.inventory_data?.starting_value ?? 0,
          total_product:
            response.data?.data?.inventory_data?.total_product ?? "",
        });
      });
    });
  };

  setStockUpdateType = (value) => {
    this.setState({ stock_update_type: value }, () => {
      console.log(this.state.stock_update_type);
      if (this.state.stock_update_type === "add") {
        this.setState({
          subtracted_stock: 0,
          remarks: "",
        });
      } else {
        this.setState({
          added_stock: 0,
          cost_amount: 0,
          remarks: "",
        });
      }
    });
  };

  updateStock = () => {
    if (
      this.state.stock_update_type === "add" &&
      Number(this.state.added_stock) < 1
    ) {
      swal.fire("Info", "Added quantity can not be less than 1", "info");
    } else if (
      this.state.stock_update_type === "remove" &&
      Number(this.state.subtracted_stock) < 1
    ) {
      swal.fire("Info", "Removed quantity can not be less than 1", "info");
    } else if (this.state.remarks === "") {
      swal.fire("Info", "Please select remarks", "info");
    } else if (
      this.state.remarks === "wrong_added_stock_adjustment" &&
      Number(this.state.cost_amount) < 1
    ) {
      swal.fire("Info", "Please enter cost amount", "info");
    } else {
      this.setState({ disbale_update_stock_button: true });
      const data = {
        added_stock: Number(this.state.added_stock),
        subtracted_stock: Number(this.state.subtracted_stock),
        cost_amount: Number(this.state.cost_amount),
        add_or_subtract_reason: this.state.remarks,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${PRODUCT_STOCK_CHANGE}${this.state.product_id}?access_token=${token}`;

        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            this.getInventoryDetails();
            this.setState({
              show_stock_modal: false,
              added_stock: 0,
              subtracted_stock: 0,
              cost_amount: 0,
              remarks: "",
              disbale_update_stock_button: false,
              unit_purchase_price: 0,
            });
            console.log("response from product status change", response);
          })
          .catch((error) => {
            this.setState({ disbale_update_stock_button: false });
            swal.fire("Error", error.message, "error");
            console.log("error from product status change", error);
          });
      });
    }
  };

  downloadDetailInventorySummary = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_INVENTORY}${this.state.inventory_id}?report_type=excel&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log(
            "response from detail inventory summary excel report fmt",
            blob
          );
          FileSaver.saveAs(
            blob,
            `conveyance_detail_inventory_summary_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from detail inventory summary api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getProductList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getProductList();
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => this.props.history.goBack()}
          title="Inventory Details"
        />
        <Card
          style={{
            width: "100%",
          }}
        >
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Place Name</Text>:{" "}
            {this.state.place_name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Place Address</Text>:{" "}
            {this.state.place_address}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Contact Person Name</Text>:{" "}
            {this.state.contact_person_name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Contact Person Mobile</Text>:{" "}
            {this.state.contact_person_mobile}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Product</Text>:{" "}
            {this.state.total_product}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Inventory Starting Date</Text>:{" "}
            {this.state.starting_time
              ? timeConverter(this.state.starting_time)
              : ""}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Inventory Starting value</Text>
            : {this.state.starting_value}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Inventory Current Value</Text>:{" "}
            {this.state.current_value}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>
              Inventory Last Closing Value
            </Text>
            : {this.state.last_closing_value}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>
              Inventory Last Closing Date
            </Text>
            :{" "}
            {this.state.last_closing_time
              ? timeConverter(this.state.last_closing_time)
              : ""}
          </p>
        </Card>

        <Link
          to={{
            pathname: "/viewInventoryStockHistory",
            state: { inventory_id: this.state.inventory_id },
          }}
        >
          <Button type="link">View Inventory Stock History</Button>
        </Link>
        {this.props.userProfileData.report.inventory_report.inventory_detail_report?(
          <Button type="link" onClick={this.downloadDetailInventorySummary}>
          Download Details Report
        </Button>
        ):null}
        
        {/* <div style={{ margin: 10 }}>
          <Text>{`Displaying : `}</Text>
          <Select value={this.state.limit} onChange={this.setLimit}>
            <Option key={10}>10</Option>
            <Option key={25}>25</Option>
            <Option key={50}>50</Option>
            <Option key={100}>100</Option>
          </Select>
        </div> */}
        <Card>
          <Table
            dataSource={this.state.data}
            pagination={false}
            rowKey={(record) => record._id.$oid}
            size="small"
            bordered
          >
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Category Name</div>
                  </div>

                  <Input
                    value={this.state.category_name}
                    onChange={(evt) => {
                      this.setState(
                        { category_name: evt.target.value, page: 0 },
                        () => {
                          this.getProductList();
                        }
                      );
                    }}
                  />
                </>
              }
              dataIndex="category_name"
              key="category_name"
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Product Name</div>
                  </div>

                  <Input
                    value={this.state.product_name}
                    onChange={(evt) => {
                      this.setState(
                        { product_name: evt.target.value, page: 0 },
                        () => {
                          this.getProductList();
                        }
                      );
                    }}
                  />
                </>
              }
              dataIndex="name"
              key="name"
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>SKU</div>
                  </div>

                  <Input
                    value={this.state.sku}
                    onChange={(evt) => {
                      this.setState({ sku: evt.target.value, page: 0 }, () => {
                        this.getProductList();
                      });
                    }}
                  />
                </>
              }
              dataIndex="sku"
              key="sku"
            />
            <Column title="Unit" dataIndex="unit" key="unit" />

            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Unit Purchase Price</div>
                    <div>
                      <Icon
                        type="caret-up"
                        onClick={() => {
                          this.setState(
                            {
                              sort_by: "unit_purchase_price",
                              sort_type: "asc",
                            },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                      <Icon
                        type="caret-down"
                        onClick={() => {
                          this.setState(
                            {
                              sort_by: "unit_purchase_price",
                              sort_type: "dsc",
                            },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              dataIndex="unit_purchase_price"
              key="unit_purchase_price"
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Unit Selling Price</div>
                    <div>
                      <Icon
                        type="caret-up"
                        onClick={() => {
                          this.setState(
                            { sort_by: "unit_selling_price", sort_type: "asc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                      <Icon
                        type="caret-down"
                        onClick={() => {
                          this.setState(
                            { sort_by: "unit_selling_price", sort_type: "dsc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              dataIndex="unit_selling_price"
              key="unit_selling_price"
            />
            <Column title="MRP" dataIndex="mrp" key="mrp" />

            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Stock Quantity</div>
                    <div>
                      <Icon
                        type="caret-up"
                        onClick={() => {
                          this.setState(
                            { sort_by: "stock_quantity", sort_type: "asc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                      <Icon
                        type="caret-down"
                        onClick={() => {
                          this.setState(
                            { sort_by: "stock_quantity", sort_type: "dsc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              dataIndex="stock_quantity"
              key="stock_quantity"
            />
            <Column
              title="Ordered Quantity"
              dataIndex="ordered_quantity"
              key="ordered_quantity"
            />
            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Available Stock</div>
                    <div>
                      <Icon
                        type="caret-up"
                        onClick={() => {
                          this.setState(
                            { sort_by: "available_stock", sort_type: "asc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                      <Icon
                        type="caret-down"
                        onClick={() => {
                          this.setState(
                            { sort_by: "available_stock", sort_type: "dsc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              dataIndex="available_stock"
              key="available_stock"
            />

            <Column
              title={
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div>Stock Value</div>
                    <div>
                      <Icon
                        type="caret-up"
                        onClick={() => {
                          this.setState(
                            { sort_by: "stock_value", sort_type: "asc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                      <Icon
                        type="caret-down"
                        onClick={() => {
                          this.setState(
                            { sort_by: "stock_value", sort_type: "dsc" },
                            () => {
                              this.getProductList();
                            }
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              dataIndex="stock_value"
              key="stock_value"
            />
            <Column
              title="Status"
              dataIndex="product_status"
              key="product_status"
            />
            <Column
              title="Created On"
              key="created_by"
              render={(text, record) => (
                <span>{timeConverter(record.created_on.$date)}</span>
              )}
            />
            <Column
              title="Action"
              key="action"
              render={(text, record) => (
                <span>
                  {this.props.userProfileData.inventory
                    .orders_by_inventory_product ? (
                    <Link
                      to={{
                        pathname: "/promotionOrderList",
                        state: {
                          item_id: record._id.$oid,
                        },
                      }}
                    >
                      <Button style={{ color: "green" }} type="link">
                        View Orders
                      </Button>
                    </Link>
                  ) : null}

                  {this.props.userProfileData.inventory
                    .inventory_single_product_stock_history ? (
                    <Link
                      to={{
                        pathname: "/viewProductStockHistory",
                        state: {
                          product_id: record._id.$oid,
                          inventory_id: record.inventory_id,
                        },
                      }}
                    >
                      <Button style={{ color: "green" }} type="link">
                        View Product Stock History
                      </Button>
                    </Link>
                  ) : null}
                  {this.props.userProfileData.inventory
                    .modify_inventory_stock ? (
                    <Button
                      type="link"
                      style={{ color: "green" }}
                      onClick={() =>
                        this.setState({
                          show_stock_modal: true,
                          product_id: record._id.$oid,
                          unit_purchase_price: record.unit_purchase_price,
                        })
                      }
                    >
                      Modify Inventory
                    </Button>
                  ) : null}
                </span>
              )}
            />
          </Table>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.limit)}
              current={this.state.page + 1}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>
        <Modal
          title="Modify Inventory"
          destroyOnClose={true}
          footer={null}
          visible={this.state.show_stock_modal}
          onCancel={() => {
            this.setState({
              show_stock_modal: false,
              product_id: "",
              added_stock: 0,
              subtracted_stock: 0,
              cost_amount: 0,
              remarks: "",
              unit_purchase_price: 0,
            });
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <Form>
            <Form.Item>
              <Text>Stock Update Type</Text>
              <Select
                value={this.state.stock_update_type}
                onChange={this.setStockUpdateType}
              >
                <Option key="add">Add</Option>
                <Option key="remove">Remove</Option>
              </Select>
            </Form.Item>
            {this.state.stock_update_type === "add" ? (
              <Form.Item>
                <Text>Added Stock Quantity</Text>
                <Input
                  placeholder="Added Stock Quantity"
                  type="text"
                  value={this.state.added_stock}
                  onChange={(evt) => {
                    // if (
                    //   this.state.stock_update_type === "add" &&
                    //   Number(evt.target.value) < 1
                    // ) {
                    //   swal.fire(
                    //     "Info",
                    //     "Added quantity can not be less than 1","info"
                    //   );
                    // }
                    this.setState({ added_stock: evt.target.value }, () => {
                      this.setState({
                        cost_amount:
                          Number(this.state.unit_purchase_price) *
                          Number(this.state.added_stock),
                      });
                    });
                  }}
                />
              </Form.Item>
            ) : null}

            {this.state.stock_update_type === "remove" ? (
              <Form.Item>
                <Text>Removed Stock Quantity</Text>
                <Input
                  placeholder="Removed Stock Quantity"
                  type="text"
                  value={this.state.subtracted_stock}
                  onChange={(evt) => {
                    // if (
                    //   this.state.stock_update_type === "remove" &&
                    //   Number(evt.target.value) < 1
                    // ) {
                    //   swal.fire(
                    //     "Info",
                    //     "Removed quantity can not be less than 1","info"
                    //   );
                    // }
                    this.setState({ subtracted_stock: evt.target.value });
                  }}
                />
              </Form.Item>
            ) : null}

            {this.state.stock_update_type === "add" ? (
              <Form.Item>
                <Text>Total Purchase Price</Text>
                <Input
                  placeholder="Total Purchase Price"
                  type="text"
                  value={this.state.cost_amount}
                  onChange={(evt) =>
                    this.setState({ cost_amount: evt.target.value })
                  }
                />
              </Form.Item>
            ) : this.state.stock_update_type === "remove" &&
              this.state.remarks === "wrong_added_stock_adjustment" ? (
              <Form.Item>
                <Text>Total Purchase Price</Text>
                <Input
                  placeholder="Total Purchase Price"
                  type="text"
                  value={this.state.cost_amount}
                  onChange={(evt) =>
                    this.setState({ cost_amount: evt.target.value })
                  }
                />
              </Form.Item>
            ) : null}

            {this.state.stock_update_type === "add" ? (
              <Form.Item>
                <Text>Remarks</Text>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.remarks}
                  onChange={(value) => this.setState({ remarks: value })}
                  placeholder="Remarks"
                >
                  {this.state.add_reason_options.map((r) => {
                    return <Option key={r.name}>{r.label}</Option>;
                  })}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item>
                <Text>Remarks</Text>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.remarks}
                  onChange={(value) => this.setState({ remarks: value })}
                  placeholder="Remarks"
                >
                  {this.state.remove_reason_options.map((r) => {
                    return <Option key={r.name}>{r.label}</Option>;
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item>
              <Button
                disabled={this.state.disbale_update_stock_button}
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateStock}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {  getUserData })(
  ViewInventory
);