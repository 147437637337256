import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import store,{persistor} from "../redux/store/store";
import { PersistGate } from 'redux-persist/integration/react'

import Login from "./login";
import SignIn from "./signIn";
import Register from "./register";
import Signup from "./signup";
import SignupEmail from "./signupEmail";
import Dashboard from "./dashboard";
import VerifyAccount from "./verifyAccount";
import VerifyAccountEmail from "./verifyAccountEmail";
import Successful from "./payment/successful";
import SuccessInt from "./payment/successInt";
// import Processing from "./payment/processing";
import Failed from "./payment/failed";
import FailInt from "./payment/failInt";
import Cancelled from "./payment/cancelled";
import CancelInt from "./payment/cancelInt";
import JoinInvitation from "./invitation/joinInvitation";
import JoinOrgInvitation from "./organization/joinOrgInvitation";
import PaymentPage from "./monthlyBill/paymentPage";
import PaymentPageInt from "./monthlyBill/paymentPageInt";
import ForgetPassword from "./forgetPassword";
import ResetCodeMobile from "./resetCodeMobile";
import NewPassword from "./newPassword";
import EmailVerifyPassword from "./emailVerifyPassword";
import NewPasswordEmail from "./newPasswordEmail";
import TaskLink from "../components/task/taskLink";
import OrderItemDetails from "../components/order/orderItemDetails";
import PrivacyPolicy from "./privacyPolicy";
import LiveDashboard from "./task/liveDashboard";
import TaskDetailsLink from "./task/taskDetailsLink";


import ReactGA from "react-ga4";

import { PrivateRoute } from "../utilities/utilities";

ReactGA.initialize("G-TDQG1KK5D1");

class App extends React.Component {
  // state={
  //   location:""
  // }
  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      console.error = console.warn = console.log = () => {};
    }
    // this.setState({location:window.location.pathname},()=>{
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
    console.log(window.location.pathname);
    // })
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
        <Router>
          <Route exact path="/" component={Login} />
          <Route exact path="/signin" component={SignIn} />
          <Route
            exact
            path="/register/:pre_selected_plan_id"
            component={Register}
          />
          <Route exact path="/register" component={Register} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/signupEmail" component={SignupEmail} />
          <Route exact path="/forgetPassword" component={ForgetPassword} />
          <Route exact path="/resetCodeMobile" component={ResetCodeMobile} />
          <Route exact path="/newPassword" component={NewPassword} />
          <Route exact path="/verifyAccount" component={VerifyAccount} />
          <Route
            exact
            path="/verifyAccountEmail"
            component={VerifyAccountEmail}
          />
          <Route
            exact
            path="/password/reset/:reset_password_id/:email"
            component={EmailVerifyPassword}
          />
          <Route exact path="/newPasswordEmail" component={NewPasswordEmail} />
          <Route
            exact
            path="/paymentPage/:mb_id/:user_id"
            component={PaymentPage}
          />
          <Route
            exact
            path="/paymentPageInt/:mb_id/:user_id"
            component={PaymentPageInt}
          />
          <Route
            exact
            path="/accept-invitation/:invitation_id"
            component={JoinInvitation}
          />
          <Route
            exact
            path="/successful/:mb_id/:tran_id"
            component={Successful}
          />
          <Route exact path="/successInt" component={SuccessInt} />
          <Route exact path="/failed" component={Failed} />
          <Route exact path="/failInt" component={FailInt} />
          <Route exact path="/cancelled" component={Cancelled} />
          <Route exact path="/cancelInt" component={CancelInt} />
          <Route exact path="/task-link/:task_link" component={TaskLink} />
          <PrivateRoute
            exact
            path="/accept-org-invitation/:invitation_id"
            component={JoinOrgInvitation}
          />
          <PrivateRoute
            exact
            path="/OrderItemDetails/:order_id/:item_id"
            component={OrderItemDetails}
          />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/home" component={Dashboard} />
          <PrivateRoute exact path="/insights" component={Dashboard} />
          <PrivateRoute exact path="/logout" component={Dashboard} />
          <PrivateRoute exact path="/about" component={Dashboard} />
          <PrivateRoute exact path="/createTask" component={Dashboard} />
          <PrivateRoute exact path="/taskList" component={Dashboard} />
          <PrivateRoute exact path="/liveTask" component={LiveDashboard} />
          <PrivateRoute exact path="/editTask" component={Dashboard} />
          <PrivateRoute exact path="/viewTask" component={Dashboard} />
          <PrivateRoute exact path="/createTaskType" component={Dashboard} />
          <PrivateRoute exact path="/taskTypeList" component={Dashboard} />
          <PrivateRoute exact path="/editTaskType" component={Dashboard} />
          <PrivateRoute exact path="/taskDetailsLink/:task_id" component={TaskDetailsLink} />
          <PrivateRoute
            exact
            path="/createOrganization"
            component={Dashboard}
          />
          <PrivateRoute exact path="/viewOrganization" component={Dashboard} />
          <PrivateRoute exact path="/editOrganization" component={Dashboard} />
          <PrivateRoute exact path="/organizationList" component={Dashboard} />
          <PrivateRoute
            exact
            path="/subscribeOrganizationPlan"
            component={Dashboard}
          />
          <PrivateRoute exact path="/createUser" component={Dashboard} />
          <PrivateRoute exact path="/createPlan" component={Dashboard} />
          <PrivateRoute exact path="/userList" component={Dashboard} />
          <PrivateRoute exact path="/viewUser" component={Dashboard} />
          <PrivateRoute exact path="/createBulkUser" component={Dashboard} />
          <PrivateRoute
            exact
            path="/mobileHeartbeatList"
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path="/connectToOrganization"
            component={Dashboard}
          />
          <PrivateRoute
            exact
            path="/connectAssetToUser"
            component={Dashboard}
          />
          <PrivateRoute exact path="/editUser" component={Dashboard} />
          <PrivateRoute exact path="/planList" component={Dashboard} />
          <PrivateRoute
            exact
            path="/createExtraPackage"
            component={Dashboard}
          />
          <PrivateRoute exact path="/createService" component={Dashboard} />
          <PrivateRoute exact path="/serviceList" component={Dashboard} />
          <PrivateRoute exact path="/editService" component={Dashboard} />
          <PrivateRoute exact path="/editPlan" component={Dashboard} />
          <PrivateRoute exact path="/createAsset" component={Dashboard} />
          <PrivateRoute exact path="/assetList" component={Dashboard} />
          <PrivateRoute exact path="/editAsset" component={Dashboard} />
          <PrivateRoute exact path="/viewAsset" component={Dashboard} />
          <PrivateRoute exact path="/assetLastLocation" component={Dashboard} />
          <PrivateRoute exact path="/routeHistory" component={Dashboard} />
          <PrivateRoute exact path="/allocatedDriverLog" component={Dashboard} />
          <PrivateRoute
            exact
            path="/viewAsset/:asset_id"
            component={Dashboard}
          />
          <PrivateRoute exact path="/createSupplier" component={Dashboard} />
          <PrivateRoute exact path="/supplierList" component={Dashboard} />
          <PrivateRoute exact path="/editSupplier" component={Dashboard} />
          <PrivateRoute exact path="/createEntity" component={Dashboard} />
          <PrivateRoute exact path="/entityList" component={Dashboard} />
          <PrivateRoute exact path="/editEntity" component={Dashboard} />
          <PrivateRoute exact path="/viewEntity" component={Dashboard} />
          {/* <PrivateRoute exact path="/ViewEntity/:shop_id" component={Dashboard} /> */}
          <PrivateRoute
            exact
            path="/EntityDetails/:shop_id"
            component={Dashboard}
          />
          <PrivateRoute exact path="/createProduct" component={Dashboard} />
          <PrivateRoute exact path="/productList" component={Dashboard} />
          <PrivateRoute exact path="/editProduct" component={Dashboard} />
          <PrivateRoute exact path="/viewProduct" component={Dashboard} />
          <PrivateRoute exact path="/createProductCategory" component={Dashboard} />
          <PrivateRoute exact path="/productCategoryList" component={Dashboard} />
          <PrivateRoute exact path="/editProductCategory" component={Dashboard} />
          <PrivateRoute exact path="/productBulkUpload" component={Dashboard} />
          <PrivateRoute exact path="/createMtype" component={Dashboard} />
          <PrivateRoute exact path="/mtypeList" component={Dashboard} />
          <PrivateRoute exact path="/editMtype" component={Dashboard} />
          <PrivateRoute exact path="/createMaterial" component={Dashboard} />
          <PrivateRoute exact path="/materialList" component={Dashboard} />
          <PrivateRoute exact path="/editMaterial" component={Dashboard} />
          <PrivateRoute exact path="/createOrder" component={Dashboard} />
          <PrivateRoute exact path="/createPurchaseOrder" component={Dashboard} />
          <PrivateRoute exact path="/createPosOrder" component={Dashboard} />
          <PrivateRoute exact path="/orderList" component={Dashboard} />
          <PrivateRoute exact path="/purchaseOrderList" component={Dashboard} />
          <PrivateRoute exact path="/advancedOrderList" component={Dashboard} />
          <PrivateRoute exact path="/promotionOrderList" component={Dashboard} />
          <PrivateRoute exact path="/editMultipleOrder" component={Dashboard} />
          <PrivateRoute exact path="/editOrder" component={Dashboard} />
          <PrivateRoute exact path="/viewOrder" component={Dashboard} />
          <PrivateRoute exact path="/orderfromCode" component={Dashboard} />
          <PrivateRoute
            exact
            path="/ViewOrder/:order_id"
            component={Dashboard}
          />
          <PrivateRoute exact path="/createProcedure" component={Dashboard} />
          <PrivateRoute exact path="/procedureList" component={Dashboard} />
          <PrivateRoute exact path="/editProcedure" component={Dashboard} />
          <PrivateRoute exact path="/createUnit" component={Dashboard} />
          <PrivateRoute exact path="/unitList" component={Dashboard} />
          <PrivateRoute exact path="/editUnit" component={Dashboard} />
          <PrivateRoute exact path="/createBill" component={Dashboard} />
          <PrivateRoute exact path="/createBillType" component={Dashboard} />
          <PrivateRoute exact path="/editBill" component={Dashboard} />
          <PrivateRoute exact path="/billList" component={Dashboard} />
          <PrivateRoute exact path="/viewBill" component={Dashboard} />
          <PrivateRoute exact path="/monthlyBillList" component={Dashboard} />
          <PrivateRoute exact path="/createMonthlyBill" component={Dashboard} />
          <PrivateRoute exact path="/staffBillPayment" component={Dashboard} />
          <PrivateRoute exact path="/viewMonthlyBill" component={Dashboard} />
          <PrivateRoute
            exact
            path="/changeOrganization"
            component={Dashboard}
          />
          <PrivateRoute exact path="/createRequisition" component={Dashboard} />
          <PrivateRoute exact path="/viewRequisition" component={Dashboard} />
          <PrivateRoute exact path="/editRequisition" component={Dashboard} />
          <PrivateRoute
            exact
            path="/requisitionDetails"
            component={Dashboard}
          />
          <PrivateRoute exact path="/createCountry" component={Dashboard} />
          <PrivateRoute exact path="/countryList" component={Dashboard} />
          <PrivateRoute exact path="/editCountry" component={Dashboard} />
          <PrivateRoute exact path="/createRoute" component={Dashboard} />
          <PrivateRoute exact path="/routeList" component={Dashboard} />
          <PrivateRoute exact path="/editRoute" component={Dashboard} />
          <PrivateRoute exact path="/sendInvitation" component={Dashboard} />
          <PrivateRoute exact path="/sendOrgInvitation" component={Dashboard} />
          <PrivateRoute exact path="/createConnection" component={Dashboard} />
          <PrivateRoute exact path="/connectionList" component={Dashboard} />
          <PrivateRoute exact path="/editConnection" component={Dashboard} />
          <PrivateRoute exact path="/genTask" component={Dashboard} />
          <PrivateRoute exact path="/attendanceList" component={Dashboard} />
          <PrivateRoute exact path="/createAttendance" component={Dashboard} />
          <PrivateRoute exact path="/editAttendance" component={Dashboard} />
          <PrivateRoute exact path="/viewAttendance" component={Dashboard} />
          <PrivateRoute
            exact
            path="/quickAttendanceList"
            component={Dashboard}
          />
          <PrivateRoute exact path="/profile" component={Dashboard} />
          <PrivateRoute exact path="/notificationList" component={Dashboard} />
          <PrivateRoute exact path="/createYoutubeLink" component={Dashboard} />
          <PrivateRoute exact path="/youtubeLinkList" component={Dashboard} />
          <PrivateRoute exact path="/editYoutubeLink" component={Dashboard} />
          <PrivateRoute exact path="/createContainer" component={Dashboard} />
          <PrivateRoute exact path="/containerList" component={Dashboard} />
          <PrivateRoute exact path="/editContainer" component={Dashboard} />
          <PrivateRoute exact path="/viewContainer" component={Dashboard} />
          <PrivateRoute
            exact
            path="/ViewContainer/:container_id"
            component={Dashboard}
          />
          <PrivateRoute exact path="/createParcel" component={Dashboard} />
          <PrivateRoute exact path="/parcelList" component={Dashboard} />
          <PrivateRoute exact path="/editParcel" component={Dashboard} />
          <PrivateRoute exact path="/viewParcel" component={Dashboard} />
          <PrivateRoute
            exact
            path="/ViewParcel/:parcel_id"
            component={Dashboard}
          />
          <PrivateRoute exact path="/containerforOrder" component={Dashboard} />
          <PrivateRoute exact path="/parcelFromOrder" component={Dashboard} />
          <PrivateRoute
            exact
            path="/multiParcelFromOrder"
            component={Dashboard}
          />
          <PrivateRoute exact path="/createTracking" component={Dashboard} />
          <PrivateRoute exact path="/viewTracking" component={Dashboard} />
          <PrivateRoute exact path="/editTracking" component={Dashboard} />
          <PrivateRoute exact path="/connecttoFinder" component={Dashboard} />
          <PrivateRoute exact path="/userRoute" component={Dashboard} />
          <PrivateRoute exact path="/fuelData" component={Dashboard} />
          <PrivateRoute exact path="/createVisitPlan" component={Dashboard} />
          <PrivateRoute exact path="/visitPlans" component={Dashboard} />
          <PrivateRoute exact path="/assetReport" component={Dashboard} />
          <PrivateRoute exact path="/viewPlan" component={Dashboard} />
          <PrivateRoute exact path="/createRate" component={Dashboard} />
          <PrivateRoute exact path="/rateList" component={Dashboard} />
          <PrivateRoute exact path="/editRate" component={Dashboard} />
          <PrivateRoute exact path="/createQrcode" component={Dashboard} />
          <PrivateRoute exact path="/qrcodeList" component={Dashboard} />
          <PrivateRoute exact path="/createTerritory" component={Dashboard} />
          <PrivateRoute exact path="/territoryList" component={Dashboard} />
          <PrivateRoute exact path="/editTerritory" component={Dashboard} />
          <PrivateRoute exact path="/viewTerritory" component={Dashboard} />
          <PrivateRoute exact path="/createRoasterPlan" component={Dashboard} />
          <PrivateRoute exact path="/roasterPlanList" component={Dashboard} />
          <PrivateRoute exact path="/editRoasterPlan" component={Dashboard} />
          <PrivateRoute exact path="/createRoaster" component={Dashboard} />
          <PrivateRoute exact path="/roasterList" component={Dashboard} />
          <PrivateRoute exact path="/editRoaster" component={Dashboard} />
          <PrivateRoute exact path="/createCustomField" component={Dashboard} />
          <PrivateRoute exact path="/startTask" component={Dashboard} />
          <PrivateRoute exact path="/startingFrom" component={Dashboard} />
          <PrivateRoute exact path="/startTime" component={Dashboard} />
          <PrivateRoute exact path="/chooseAsset" component={Dashboard} />
          <PrivateRoute exact path="/destination" component={Dashboard} />
          <PrivateRoute
            exact
            path="/attendanceSettings"
            component={Dashboard}
          />
          <PrivateRoute exact path="/connectInventory" component={Dashboard} />
          <PrivateRoute exact path="/createLeave" component={Dashboard} />
          <PrivateRoute exact path="/leaveList" component={Dashboard} />
          <PrivateRoute exact path="/viewLeave" component={Dashboard} />
          <PrivateRoute exact path="/updateSettings" component={Dashboard} />
          <PrivateRoute exact path="/createIncident" component={Dashboard} />
          <PrivateRoute exact path="/incidentList" component={Dashboard} />
          <PrivateRoute exact path="/editIncident" component={Dashboard} />
          <PrivateRoute exact path="/incidentBills" component={Dashboard} />
          <PrivateRoute exact path="/createReminder" component={Dashboard} />
          <PrivateRoute exact path="/reminderList" component={Dashboard} />
          <PrivateRoute exact path="/createRole" component={Dashboard} />
          <PrivateRoute exact path="/roleList" component={Dashboard} />
          <PrivateRoute exact path="/editRole" component={Dashboard} />
          <PrivateRoute exact path="/managePermission" component={Dashboard} />
          <PrivateRoute exact path="/createSubOrgType" component={Dashboard} />
          <PrivateRoute exact path="/subOrgTypeList" component={Dashboard} />
          <PrivateRoute exact path="/editSubOrgType" component={Dashboard} />
          <PrivateRoute exact path="/createSubOrg" component={Dashboard} />
          <PrivateRoute exact path="/subOrgList" component={Dashboard} />
          <PrivateRoute exact path="/editSubOrg" component={Dashboard} />
          <PrivateRoute exact path="/viewSubOrg" component={Dashboard} />
          <PrivateRoute exact path="/createSalesTarget" component={Dashboard} />
          <PrivateRoute exact path="/salesTargetList" component={Dashboard} />
          <PrivateRoute exact path="/editSalesTarget" component={Dashboard} />
          <PrivateRoute exact path="/createInventory" component={Dashboard} />
          <PrivateRoute exact path="/inventoryList" component={Dashboard} />
          <PrivateRoute exact path="/viewInventory" component={Dashboard} />
          <PrivateRoute exact path="/modifyInventory" component={Dashboard} />
          <PrivateRoute exact path="/internalTransfer" component={Dashboard} />
          <PrivateRoute exact path="/viewInventoryStockHistory" component={Dashboard} />
          <PrivateRoute exact path="/viewProductStockHistory" component={Dashboard} />
          <PrivateRoute exact path="/viewClosingHistory" component={Dashboard} />
          <PrivateRoute exact path="/orderSettings" component={Dashboard} />
          <PrivateRoute exact path="/smsSettings" component={Dashboard} />
          <PrivateRoute exact path="/emailSettings" component={Dashboard} />
          <PrivateRoute exact path="/createTarget" component={Dashboard} />
          <PrivateRoute exact path="/createAchievement" component={Dashboard} />
          <PrivateRoute exact path="/achievementList" component={Dashboard} />
          <PrivateRoute exact path="/achievementDetails" component={Dashboard} />
          <PrivateRoute exact path="/targetList" component={Dashboard} />
          <PrivateRoute exact path="/targetDetails" component={Dashboard} />
          <PrivateRoute exact path="/walletList" component={Dashboard} />
          <PrivateRoute exact path="/dueCollectionList" component={Dashboard} />
          <PrivateRoute exact path="/walletHistory" component={Dashboard} />
          <PrivateRoute exact path="/inventorySettings" component={Dashboard} />
          <PrivateRoute exact path="/financeReport" component={Dashboard} />
          <PrivateRoute exact path="/createCustomForm" component={Dashboard} />
          <PrivateRoute exact path="/editCustomForm" component={Dashboard} />
          <PrivateRoute exact path="/createPromotion" component={Dashboard} />
          <PrivateRoute exact path="/promotionList" component={Dashboard} />
          <PrivateRoute exact path="/editPromotion" component={Dashboard} />
          <PrivateRoute exact path="/createBillSettings" component={Dashboard} />
          <PrivateRoute exact path="/editBillSettings" component={Dashboard} />
          <PrivateRoute exact path="/billSettingsList" component={Dashboard} />
          <PrivateRoute exact path="/connectThirdParty" component={Dashboard} />
          <PrivateRoute exact path="/prescriptionSearchList" component={Dashboard} />
          <PrivateRoute exact path="/handoverList" component={Dashboard} />
          <PrivateRoute exact path="/createReturnOrder" component={Dashboard} />
        </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
