import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Typography,
  Select,
  Pagination
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import FileSaver from "file-saver";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  SIMPLE_ATTENDANCE,
  SIMPLE_ATTENDANCE_REPORT,
  SIMPLE_ATTENDANCE_REPORT_SINGLE,
  CREATE_DRIVER
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

export default class QuickAttendanceList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false,
    from: Date.now() / 1000,
    from_view: moment(),
    to: Date.now() / 1000,
    to_view: moment(),
    timezone_option: 1,
    report_for_option: 'quick-report',
    org_id: "",
    from_single: Date.now() / 1000,
    from_single_view: moment(),

    to_single: Date.now() / 1000,
    to_single_view: moment(),
    userData: [],
    filtered: [],
    user_id: "",
    limit: 10
    // search_start_time: false,
    // search_end_time: false,
    // startTimeStamp: Date.now(),
    // endTimeStamp: Date.now(),
    // userData: [],
    // assigned_to_label: "",
    // assigned_to: "",

    // timezone_name: "",
    // timezone_choice: "org_timezone",
    // start_date_view: moment(),
    // end_date_view: moment(),
    // org_absent_users: [],
    // attendanceChoice: "checkedin",

    // options: 1,
    // options_single: 1,

    // custom_fields: false,
    // custom_fields_single: false,
  };

  componentDidMount() {
    this.setState({
      org_id: GET_USER_LOCAL().organization.$oid
    });
    this.getQuickAttendanceList();
    this.getUserList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  setReportForOptions = value => {
    this.setState({ report_for_option: value }, () => {
      console.log(this.state.report_for_option);
    });
  };

  setTimezoneOptions = value => {
    this.setState({ timezone_option: value }, () => {
      console.log(this.state.timezone_option);
    });
  };

  setTo = (value, dateString) => {
    this.setState(
      { to: moment(value).format("x") / 1000, to_view: value },
      () => {
        console.log("Selected Time: ", this.state.to);
      }
    );
  };
  setFrom = (value, dateString) => {
    this.setState(
      { from: moment(value).format("x") / 1000, from_view: value },
      () => {
        console.log("Selected Time: ", this.state.from);
      }
    );
  };

  setToSingle = (value, dateString) => {
    this.setState(
      { to_single: moment(value).format("x") / 1000, to_single_view: value },
      () => {
        console.log("Selected Time: ", this.state.to_single);
      }
    );
  };
  setFromSingle = (value, dateString) => {
    this.setState(
      {
        from_single: moment(value).format("x") / 1000,
        from_single_view: value
      },
      () => {
        console.log("Selected Time: ", this.state.from_single);
      }
    );
  };

  handleSearch = value => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from search user", response);
          this.setState({
            userData: response.data.data
          });
        })
        .catch(error => {
          console.log("error from search user api", error);
        });
    });
  };

  setAssignedUser = value => {
    this.setState({ user_id: value, filtered: [] }, () => {
      console.log(this.state.user_id);
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 100;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ userData: response.data.data });
          console.log("response from user list", response);
        })
        .catch(error => {
          console.log("error from user list api", error);
        });
    });
  };

  getQuickAttendanceList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${SIMPLE_ATTENDANCE}?page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from quick Attendance list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from quick attendance list api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getQuickAttendanceList();
    });
  };

  setLimit = value => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getQuickAttendanceList();
    });
  };

  downloadQuickReport = () => {
    if (
      moment(this.state.to_view).diff(
        this.state.from_view,
        "days"
      ) > 30
    ) {
      swal.fire("Info", "Date range can not exceed 31 days", "info");
    }
    /*
    if (
      Number(this.state.from) > Date.now() / 1000 + 86400 ||
      Number(this.state.to) > Date.now() / 1000 + 86400
    ) {
      swal.fire("Info", "Future Date Not Allowed", "info");
    } 
    */
    else {
      GET_ACCESS_TOKEN().then(token => {
        const url = `${SIMPLE_ATTENDANCE_REPORT}?org_id=${this.state.org_id}
        &from=${Number(this.state.from)}
        &to=${Number(this.state.to)}
        &timezone_option=${Number(this.state.timezone_option)}
        &access_token=${token}`;
        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            console.log("response from Quick Attendance list fmt", blob);

            FileSaver.saveAs(
              blob,
              `conveyance_quick_attendance_report_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
          })
          .catch(error => {
            console.log("error from Attendance list api", error);
          });
      });
    }
  };

  downloadQuickSingleReport = () => {
    if (
      moment(this.state.to_view).diff(
        this.state.from_view,
        "days"
      ) > 30
    ) {
      swal.fire("Info", "Date range can not exceed 31 days", "info");
    }
    /*
    if (
      Number(this.state.from_single) > Date.now() / 1000 + 86400 ||
      Number(this.state.to_single) > Date.now() / 1000 + 86400
    ) {
      swal.fire("Info", "Future Date Not Allowed", "info");
    } 
    */
    else if (this.state.user_id === "") {
      swal.fire("Info", "Please select a user", "info");
    }
    else {
      GET_ACCESS_TOKEN().then(token => {
        const url = `${SIMPLE_ATTENDANCE_REPORT_SINGLE}?from=${Number(this.state.from)}
        &to=${Number(this.state.to)}
        &options=${Number(this.state.options)}
        &user_id=${this.state.user_id}
        &access_token=${token}`;
        fetch(url)
          .then(res => res.blob())
          .then(blob => {
            console.log("response from quick Attendance Single list fmt", blob);

            FileSaver.saveAs(
              blob,
              `conveyance_quick_attendance_report_single_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
          })
          .catch(error => {
            console.log("error from quick Attendance list api", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }

    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Quick Attendance List"
        />

        <Card style={{ marginTop: 5 }}>
          <Text style={{ fontWeight: "bolder", fontSize: 20 }}>
            Report Options {" "}
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 10
            }}
          >
            <div style={{ margin: 5, display: "flex", flexDirection: "column", width: "220px" }}>
              <Text> Report For: {""}</Text>
              <Select
                value={this.state.report_for_option.toString()}
                onChange={this.setReportForOptions}
                placeholder="Report For"
              >
                <Option key={1} value="quick-report">Quick Report</Option>
                <Option key={2} value="single-report">Single Report</Option>
              </Select>
            </div>

            {/* Show time select for any option */}
            {this.state.report_for_option === 'quick-report' ||
              this.state.report_for_option === 'single-report' ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: 5,
                      minWidth: 200
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.from_view}
                        onChange={this.setFrom}
                        disableFuture={true}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <Text style={{ paddingTop: 20 }}>---</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: 5
                    }}
                  >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.to_view}
                        onChange={this.setTo}
                        disableFuture={true}
                        minDate={this.state.from_view}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </>
              ) : null}

            {/* Show Timezone option for quick report */}
            {this.state.report_for_option === 'quick-report' && (
              <>
                <div style={{ margin: 5, display: "flex", flexDirection: "column", width: "200px" }}>
                  <Text> Timezone: {""}</Text>
                  <Select
                    value={this.state.timezone_option.toString()}
                    onChange={this.setTimezoneOptions}
                    placeholder="Timezone"
                  >
                    <Option key={1}>Organization</Option>
                    <Option key={2}>User</Option>
                  </Select>
                </div>

                <Button
                  onClick={this.downloadQuickReport}
                  style={{
                    width: "auto",
                    backgroundColor: "#1A3337",
                    color: "white",
                    marginTop: 25,
                  }}
                >
                  Download Report
                </Button>
              </>
            )}

            {/* Show Search User and Download single Report button for single report */}
            {GET_USER_LOCAL().org_admin &&
              this.state.report_for_option === 'single-report' && (
                <>
                  <div style={{ margin: 5, display: "flex", flexDirection: "column", width: "200px" }}>
                    <Text>Search User:</Text>
                    <Select
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      onSearch={this.handleSearch}
                      style={{ width: "100%" }}
                      value={this.state.user_id}
                      onChange={this.setAssignedUser}
                      placeholder="Select User"
                    >
                      {this.state.userData.map(r => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`${r.name}>${r.mobile}`}</Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                  }}>
                    <Button
                      onClick={this.downloadQuickSingleReport}
                      style={{
                        width: "auto",
                        backgroundColor: "#1A3337",
                        color: "white",
                        marginTop: 25,
                      }}
                    >
                      Download Single Report
                    </Button>
                  </div>
                </>
              )}
          </div>
        </Card>


        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
        </div>

        <Table
          style={{ marginTop: 5 }}
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          {/* <Column
        title="Attendance"
        key="attendance"
        render={(text, record) => (
          <span>
            {record.attendance !== null ? record.attendance.toString() : ""}
          </span>
        )}
      /> */}

          <Column
            title={<><Text ellipsis={true}>Checkin Time</Text></>}
            key="checkin_time.$date"
            render={(text, record) => (
              <span>
                {record.checkin_time !== null
                  ? timeConverter(record.checkin_time.$date)
                  : ""}
              </span>
            )}
          />
          <Column
            title={<><Text ellipsis={true}>Checkin Location</Text></>}
            key="Checkin Location"
            render={(text, record) => <span>{record.checkin_loc_name}</span>}
          />

          <Column
            title={<><Text ellipsis={true}>Checkout Time</Text></>}
            key="checkout_time.$date"
            render={(text, record) => (
              <span>
                {record.checkout_time !== null
                  ? timeConverter(record.checkout_time.$date)
                  : ""}
              </span>
            )}
          />

          <Column
            title={<><Text ellipsis={true}>Checkout Location</Text></>}
            key="Checkout Location"
            render={(text, record) => <span>{record.checkout_loc_name}</span>}
          />

          <Column
            title={<><Text ellipsis={true}>Created By</Text></>}
            key="created_by_name"
            render={(text, record) => (
              <span>
                {record.created_by_name !== null ? record.created_by_name : ""}
              </span>
            )}
          />
          <Column
            title={<><Text ellipsis={true}>Created For</Text></>}
            key="created_for"
            render={(text, record) => (
              <span>
                {record.created_for.name !== null
                  ? record.created_for.name
                  : ""}
              </span>
            )}
          />

          <Column
            title={<><Text ellipsis={true}>Created On</Text></>}
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />

          <Column
            title={<><Text ellipsis={true}>Modified By</Text></>}
            key="modified_by_name"
            render={(text, record) => (
              <span>
                {record.modified_by_name !== null
                  ? record.modified_by_name
                  : ""}
              </span>
            )}
          />

          <Column
            title={<><Text ellipsis={true}>Modified On</Text></>}
            key="modified_by"
            render={(text, record) => (
              <span>{timeConverter(record.modified_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span style={{ display: "flex", flexDirection: "column" }}>
                {GET_USER_LOCAL().org_admin ? (
                  <Link
                    to={{
                      pathname: "/userRoute",
                      state: {
                        search_user:
                          record?.created_for?.id ?? record?.created_by,
                        start_time: record?.checkin_time?.$date,
                        end_time: record?.checkout_time?.$date,
                        user_name:
                          record?.created_for?.name ?? record?.created_by_name
                      }
                    }}
                  >
                    <Button type="link" style={{ color: "blue" }}>
                      View Route
                    </Button>
                  </Link>
                ) : null}
              </span>
            )}
          />
        </Table>

        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}
