import React, { Component } from "react";
import { Card, PageHeader, Typography } from "antd";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L from "leaflet";
import axios from "axios";
// import swal from "sweetalert2";

import { FINDER_ASSET_LAST_LOCATION } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40],
});

const { Text } = Typography;

export default class AssetLastLocation extends Component {
  state = {
    asset_id: "",
    asset_provider_id: "",
    created_on: "",
    modified_on: "",
    time: "",
    name: "",
    deleted: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    zoom: 13,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAssetLastLocation();
  }

  getAssetLastLocation = () => {
    this.setState(
      {
        asset_id: this.props.location.state.asset_id,
        asset_provider_id: this.props?.location?.state?.asset_provider_id,
      },
      () => {
        const data = {
          asset_id: this.state.asset_id,
          asset_provider_id: this.state.asset_provider_id,
        };
        console.log(data);
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${FINDER_ASSET_LAST_LOCATION}?access_token=${token}`;

          axios({
            method: "post",
            url: url,
            data: data,
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          })
            .then((response) => {
              console.log("response from finder asset last location", response);
              this.setState({
                created_on: response.data.data.created_on.$date ?? "",
                modified_on: response.data.data.modified_on.$date ?? "",
                time: response.data.data.time ?? "",
                name: response.data.data.name ?? "",
                deleted: response.data.data.deleted ?? false,
                center_lat:
                  response.data.data.location.coordinates[1] ?? 23.685,
                center_lon:
                  response.data.data.location.coordinates[0] ?? 90.3563,
              });
            })
            .catch((error) => {
              console.log("error from finder asset last location", error);
            });
        });
      }
    );
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title={`Last Location for ${this.state.name}`}
          onBack={() => this.props.history.goBack()}
        />

        <Card style={{ margin: 10 }}>
          <Map
            center={[this.state.center_lat, this.state.center_lon]}
            zoom={this.state.zoom}
            style={{ height: 800 }}
            onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
          >
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.49.2",
              }}
              type={"roadmap"}
            />

            <Marker
              position={[this.state.center_lat, this.state.center_lon]}
              icon={red}
            >
              <Popup>
                <span>{`Name:${this.state.name}, Time:${timeConverter(
                  this.state.time * 1000
                )}`}</span>
              </Popup>
            </Marker>
          </Map>
        </Card>
        <Card className="view">
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}> Name</Text>: {this.state.name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}> Time</Text>:{" "}
            {timeConverter(this.state.time * 1000)}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}> Created On</Text>:{" "}
            {timeConverter(this.state.created_on)}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}> Modified On</Text>:{" "}
            {timeConverter(this.state.modified_on)}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}> Deleted</Text>:{" "}
            {this.state.deleted.toString()}
          </p>
        </Card>
      </Card>
    );
  }
}
