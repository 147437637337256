import React, { Component } from "react";
import {
  //   Table,
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Modal,
  Icon,
} from "antd";
import { Redirect } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import { debounce } from "lodash";
// import moment from "moment";
import {
  CREATE_BILL_TYPE,
  BILL_FOR_LIST,
  CREATE_ROLE,
  CREATE_ENTITY,
  EDIT_ENTITY,
  EDIT_BILL_SETTINGS,
  BILL_DETAILS
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  //   CHECK_BOOL,
  //   GET_USER_LOCAL,
} from "../../utilities/utilities";

import CreateShop from "../shop/createShop";

import ReactGA from "react-ga4";

import "../login.css";

// const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
// const { Column } = Table;

export default class EditBillSettings extends Component {
  state = {
    bill_settings_id:"",
    name: "",
    miscellaneous_bill_type_choices: [],
    bill_for_choices: [],
    role_choices: [],
    misc_bill_type_id: "",
    misc_bill_type_name: "",
    bill_for_id: "",
    bill_for_name: "",
    role_ids: [],
    bill_calculation_type: "hourly",
    rate: "",
    start_percentage: "",
    end_percentage: "",
    radius_start_loc: "",
    radius_end_loc: "",
    radius_rate: "",
    dataS: [],
    redirect: false,
    start_entity_name: "",
    start_entity_key: "",
    end_entity_name: "",
    end_entity_key: "",
    start_loc: "",
    end_loc: "",
    start_loc_name: "",
    end_loc_name: "",
    showEntity: false,
  };

  componentDidMount() {
    this.setState({ bill_settings_id: this.props.location.state.bill_settings_id });
    this.getBillSettings();
    this.getBillTypeChoices();
    this.getBillForChoices();
    this.getRoleChoices();
    this.getShopList();

     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getBillSettings = () => {
    GET_ACCESS_TOKEN().then(token => {
      let bill_settings_id = this.props.location.state.bill_settings_id;
      let url = `${BILL_DETAILS}${bill_settings_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit bill settings get", response.data.data);
        this.setState(
          {
            name: response?.data?.data?.name??"",
            bill_for_id: response?.data?.data?.bill_for?.id??"",
            bill_for_name: response?.data?.data?.bill_for?.name??"",
            bill_calculation_type: response?.data?.data?.bill_calculation_type??"",
            misc_bill_type_id: response?.data?.data?.bill_type?.id??"",
            misc_bill_type_name: response?.data?.data?.bill_type?.name??"",
            rate: response?.data?.data?.rate??"",
            role_ids: response?.data?.data?.role?.map(id=>id.id)??[],
            start_percentage: response?.data?.data?.start_percentage??"",
            end_percentage: response?.data?.data?.end_percentage??"",
            start_loc: response?.data?.data?.radius_spec?.radius_start_loc?.coordinates??"",
            end_loc: response?.data?.data?.radius_spec?.radius_end_loc?.coordinates??"",
            radius_rate: response?.data?.data?.radius_spec?.rate??"",
          }
        );
      });
    });
  };

  getShopList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=10&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("shop list api", response);
          this.setState({ dataS: response.data.data });
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  };

  searchPlace = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from place list", response);
          this.setState({
            dataS: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from place list api", error);
        });
    });
  }, 500);

  getBillTypeChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_BILL_TYPE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            miscellaneous_bill_type_choices: response.data.data,
          });
          console.log("response from bill type choices", response);
        })
        .catch((error) => {
          console.log("error from bill type choices api", error);
        });
    });
  };
  getBillForChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${BILL_FOR_LIST}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            bill_for_choices: response.data.data,
          });
          console.log("response from bill for choices", response);
        })
        .catch((error) => {
          console.log("error from bill for choices api", error);
        });
    });
  };
  getRoleChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ROLE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            role_choices: response.data.data,
          });
          console.log("response from role choices", response);
        })
        .catch((error) => {
          console.log("error from role choices api", error);
        });
    });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setRate = (evt) => {
    evt.preventDefault();
    this.setState({ rate: evt.target.value }, () => {
      console.log(this.state.rate);
    });
  };
  setRadiusRate = (evt) => {
    evt.preventDefault();
    this.setState({ radius_rate: evt.target.value }, () => {
      console.log(this.state.radius_rate);
    });
  };
  setStartPercentage = (evt) => {
    evt.preventDefault();
    this.setState({ start_percentage: evt.target.value }, () => {
      console.log(this.state.start_percentage);
    });
  };
  setEndPercentage = (evt) => {
    evt.preventDefault();
    this.setState({ end_percentage: evt.target.value }, () => {
      console.log(this.state.end_percentage);
    });
  };

  setBillType = (value) => {
    this.setState(
      { misc_bill_type_id: value.key, misc_bill_type_name: value.label },
      () => {
        console.log(this.state.misc_bill_type_name);
      }
    );
  };
  setBillFor = (value) => {
    this.setState({ bill_for_id: value.key,bill_for_name:value.label }, () => {
      console.log(this.state.bill_for_id);
    });
  };
  setBillCalculationType = (value) => {
    this.setState({ bill_calculation_type: value }, () => {
      console.log(this.state.bill_calculation_type);
    });
  };
  setRoles = (value) => {
    this.setState({ role_ids: value }, () => {
      console.log(this.state.role_ids);
    });
  };

  setStart = (value) => {
    this.setState(
      { start_entity_name: value.label, start_entity_key: value.key },
      () => {
        this.getShopList();
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_ENTITY}${this.state.start_entity_key}?access_token=${token}`;
          axios({
            method: "get",
            url: url,
          }).then((response) => {
            console.log("response fro edit entity", response);
            this.setState({
              start_loc: response.data.data.loc,
              start_loc_name: response.data.data.address,
            });
          });
        });
      }
    );
  };
  setEnd = (value) => {
    this.setState(
      { end_entity_name: value.label, end_entity_key: value.key },
      () => {
        this.getShopList();
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_ENTITY}${this.state.end_entity_key}?access_token=${token}`;
          axios({
            method: "get",
            url: url,
          }).then((response) => {
            console.log("response fro edit entity", response);

            this.setState({
              end_loc: response.data.data.loc,
              end_loc_name: response.data.data.address,
            });
          });
        });
      }
    );
  };

  editBillSettings = () => {
    this.setState({ loading: true });
    let data = {
        name: this.state.name,
        bill_for: this.state.bill_for_id,
        bill_calculation_type: this.state.bill_calculation_type,
        bill_type_id: this.state.misc_bill_type_id,
        rate: this.state.rate,
        role_ids: this.state.role_ids,
        start_percentage: this.state.start_percentage,
        end_percentage: this.state.end_percentage,
        radius_start_loc: this.state.start_loc,
        radius_end_loc: this.state.end_loc,
        radius_rate: this.state.radius_rate,
    };
    console.log("edit settings", data);

    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_BILL_SETTINGS}${this.state.bill_settings_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          console.log("response from edit bill settings", response);
          this.getBillSettings();
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from edit bill settings", error);
        });
    });
  };

  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/billList" />;
    }

    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={()=>this.props.history.goBack()}
          title="Edit Bill Settings"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Form.Item>
                <Text>Name</Text>
                <Input
                  placeholder="Name"
                  type="text"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </Form.Item>

              <Form.Item>
                <Text>Bill For</Text>
                <Select
                labelInValue
                  value={{key:this.state.bill_for_id,label:this.state.bill_for_name}}
                  onChange={this.setBillFor}
                >
                  {this.state.bill_for_choices.map((r) => {
                    return <Option key={r._id.$oid}>{r.name.charAt(0).toUpperCase() + r.name.slice(1)}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Bill Calculation Type</Text>
                <Select
                  value={this.state.bill_calculation_type}
                  onChange={this.setBillCalculationType}
                >
                  <Option key="hourly">Hourly</Option>
                  <Option key="per km">Per Kilometer</Option>
                  <Option key="count">Count</Option>
                  <Option key="fixed">Fixed</Option>
                  <Option key="percentage">Percentage</Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Text>Bill Type</Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.misc_bill_type_id,
                    label: this.state.misc_bill_type_name,
                  }}
                  onChange={this.setBillType}
                  placeholder="Bill Type"
                >
                  {this.state.miscellaneous_bill_type_choices.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item>
                <Text>Rate</Text>
                <Input
                  placeholder="Rate"
                  type="number"
                  value={this.state.rate}
                  onChange={this.setRate}
                />
              </Form.Item>
              <Form.Item>
                <Text>User Roles</Text>
                <Select
                  mode="multiple"
                  value={this.state.role_ids}
                  onChange={this.setRoles}
                >
                  {this.state.role_choices.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              {this.state.misc_bill_type_name === "Commission" ? (
                <Form.Item>
                  <Text>Start Percentage</Text>
                  <Input
                    placeholder="Start Percentage"
                    type="number"
                    value={this.state.start_percentage}
                    onChange={this.setStartPercentage}
                  />
                </Form.Item>
              ) : null}
              {this.state.misc_bill_type_name === "Commission" ? (
                <Form.Item>
                  <Text>End Percentage</Text>
                  <Input
                    placeholder="End Percentage"
                    type="number"
                    value={this.state.end_percentage}
                    onChange={this.setEndPercentage}
                  />
                </Form.Item>
              ) : null}
              {this.state.bill_for_name === "Attendance" ||
              this.state.bill_for_name === "Task" ? (
                <Form.Item>
                  <Text>
                    Search Radius Start Location{" "}
                    <Button
                      type="link"
                      onClick={() => this.setState({ showEntity: true })}
                    >
                      <Icon type="plus" style={{ fontSize: 20 }} />
                    </Button>
                  </Text>
                  <Select
                    labelInValue
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue={true}
                    onSearch={this.searchPlace}
                    value={{
                      key: this.state.start_entity_key,
                      label: this.state.start_entity_name,
                    }}
                    onChange={this.setStart}
                    placeholder="Select Start Place"
                  >
                    {this.state.dataS.map((r) => {
                      return (
                        <Option
                          key={r._id.$oid}
                        >{`Name : ${r.name} >>> Address : ${r.address} >>> Type : ${r.entity_type}`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
              {this.state.bill_for_name === "Attendance" ||
              this.state.bill_for_name === "Task" ? (
                <Form.Item>
                  <Text>
                    Search Radius End Location{" "}
                    <Button
                      type="link"
                      onClick={() => this.setState({ showEntity: true })}
                    >
                      <Icon type="plus" style={{ fontSize: 20 }} />
                    </Button>
                  </Text>
                  <Select
                    labelInValue
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue={true}
                    onSearch={this.searchPlace}
                    value={{
                      key: this.state.end_entity_key,
                      label: this.state.end_entity_name,
                    }}
                    onChange={this.setEnd}
                    placeholder="Select End Place"
                  >
                    {this.state.dataS.map((r) => {
                      return (
                        <Option
                          key={r._id.$oid}
                        >{`Name : ${r.name} >>> Address : ${r.address} >>> Type : ${r.entity_type}`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
              {this.state.bill_for_name === "Attendance" ||
              this.state.bill_for_name === "Task" ? (
                <Form.Item>
                  <Text>Rate Within Radius</Text>
                  <Input
                    placeholder="Rate Within Radius"
                    type="number"
                    value={this.state.radius_rate}
                    onChange={this.setRadiusRate}
                  />
                </Form.Item>
              ) : null}
            </div>
          </div>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={() => this.editBillSettings()}
            >
              Update Settings
            </Button>
          </Form.Item>
        </Form>
        <Modal
          title="Create New Place"
          visible={this.state.showEntity}
          onOk={() => {
            this.setState({ showEntity: false });
            this.getShopList();
          }}
          onCancel={() => {
            this.setState({ showEntity: false });
            this.getShopList();
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <CreateShop />
        </Modal>
      </Card>
    );
  }
}
