import React, { Component } from "react";
import { Card, Tabs } from "antd";

import "../login.css";

import Order from "./order";
import ReactGA from "react-ga4";


const { TabPane } = Tabs;

export default class MainInsights extends Component {
  state = {
    activeKeyInsights: "1"
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    let key = JSON.parse(localStorage.getItem("activeKeyInsights"));
    if (key) {
      this.setState({ activeKeyInsights: key });
    }
  }

  onChange = activeKeyInsights => {
    console.log(`onChange ${activeKeyInsights}`);
    this.setState(
      {
        activeKeyInsights
      },
      () => {
        localStorage.setItem(
          "activeKeyInsights",
          JSON.stringify(this.state.activeKeyInsights)
        );
      }
    );
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>

        <Tabs
          destroyInactiveTabPane={true}
          activeKey={this.state.activeKeyInsights}
          onChange={this.onChange}
        >
          <TabPane tab="Order" key="1">
            <Order />
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}
