import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_TASK_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;

export default class EditTaskType extends Component {
  state = {
    name: "",
    taskType_id: ""
  };
  componentDidMount() {
    this.setState({
        taskType_id: this.props.location.state.taskType_id
    });
    this.getTaskType();
  }

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

 

  getTaskType = () => {
    GET_ACCESS_TOKEN().then(token => {
      let taskType_id = this.props.location.state.taskType_id;
      let url = `${EDIT_TASK_TYPE}${taskType_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit unit", response);
        this.setState({
          name: response.data.data.name
        });
      });
    });
  };



  editTaskType = () => {
    const data = {
      name: this.state.name,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_TASK_TYPE}${this.state.taskType_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit taa type", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from edit taa type", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Edit Task Type"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>

         

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editTaskType}
            >
              Update Task Type
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
