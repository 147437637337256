import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Spin, Select } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_ASSET,
  CREATE_PARCEL,
  CREATE_CONTAINER,
  CREATE_ORDER,
  CREATE_QRCODE,
  CREATE_ENTITY
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateQrcode extends Component {
  state = {
    for_name: "asset",
    id: "",
    name: "",
    assetData: [],
    containerData: [],
    orderData: [],
    parcelData: [],
    entityData: [],
    filtered: []
  };

  componentDidMount() {
    this.getAssetList();
    this.getParcelList();
    this.getContainerList();
    this.getOrderList();
    this.getEntityList();
  }

  getAssetList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ASSET}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from asset list", response);
          this.setState({
            assetData: response.data.data
          });
        })
        .catch(error => {
          console.log("error from asset list api", error);
        });
    });
  };
  getEntityList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ENTITY}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from entity list", response);
          this.setState({
            entityData: response.data.data
          });
        })
        .catch(error => {
          console.log("error from entity list api", error);
        });
    });
  };

  getParcelList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_PARCEL}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from parcel list", response);
          this.setState({
            parcelData: response.data.data
          });
        })
        .catch(error => {
          console.log("error from parcel list api", error);
        });
    });
  };

  getContainerList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_CONTAINER}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from container list", response);
          this.setState({
            containerData: response.data.data
          });
        })
        .catch(error => {
          console.log("error from container list api", error);
        });
    });
  };

  getOrderList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ORDER}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from order list", response);
          this.setState({
            orderData: response.data.data
          });
        })
        .catch(error => {
          console.log("error from order list api", error);
        });
    });
  };

  setAsset = value => {
    this.setState({ name: value.label, id: value.key, filtered: [] }, () => {
      console.log(this.state.name);
    });
  };
  handleSearchA = value => {
    const result = this.state.assetData.filter(asset => {
      return asset.name.toLowerCase() === value.toLowerCase();
    });
    console.log(result);
    this.setState({ filtered: result });
  };

  setEntity = value => {
    this.setState({ name: value.label, id: value.key, filtered: [] }, () => {
      console.log(this.state.name);
    });
  };
  handleSearchE = value => {
    const result = this.state.entityData.filter(entity => {
      return entity.name.toLowerCase() === value.toLowerCase();
    });
    console.log(result);
    this.setState({ filtered: result });
  };

  setOrder = value => {
    this.setState({ name: value.label, id: value.key, filtered: [] }, () => {
      console.log(this.state.name);
    });
  };
  handleSearchO = value => {
    const result = this.state.orderData.filter(order => {
      return order.name.toLowerCase() === value.toLowerCase();
    });
    console.log(result);
    this.setState({ filtered: result });
  };

  setContainer = value => {
    this.setState({ name: value.label, id: value.key, filtered: [] }, () => {
      console.log(this.state.name);
    });
  };
  handleSearchC = value => {
    const result = this.state.containerData.filter(container => {
      return container.name.toLowerCase() === value.toLowerCase();
    });
    console.log(result);
    this.setState({ filtered: result });
  };

  setParcel = value => {
    this.setState({ name: value.label, id: value.key, filtered: [] }, () => {
      console.log(this.state.name);
    });
  };
  handleSearchP = value => {
    const result = this.state.parcelData.filter(parcel => {
      return parcel.name.toLowerCase() === value.toLowerCase();
    });
    console.log(result);
    this.setState({ filtered: result });
  };

  setName = value => {
    this.setState({ for_name: value, filtered: [] }, () => {
      console.log(this.state.for_name);
    });
  };
  createCode = () => {
    if (this.state.id === "") {
      swal.fire(
        "info",
        "Please Select an asset,order,container or parcel",
        "info"
      );
    } else {
      let data = {
        id: this.state.id,
        for_name: this.state.for_name
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_QRCODE}?access_token=${token}`;

        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            console.log("response from gen qr code", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from gen qr code", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const orderList = this.state.orderData.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });

    const assetList = this.state.assetData.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });

    const entityList = this.state.entityData.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });

    const parcelList = this.state.parcelData.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });

    const containerList = this.state.containerData.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });

    const FliteredList = this.state.filtered.map(r => {
      return <Option key={r._id.$oid}>{r.name}</Option>;
    });
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Create QR Code"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Generate Code For</Text>
            <Select
              value={this.state.for_name.toString()}
              onChange={this.setName}
            >
              <Option key="asset">Asset</Option>
              <Option key="entity">Place</Option>
              <Option key="order">Order</Option>
              <Option key="container">Container</Option>
              <Option key="parcel">Parcel</Option>
            </Select>
          </Form.Item>
          {this.state.for_name === "asset" ? (
            <Form.Item>
              <Text>Search Asset</Text>
              <Select
                showSearch={true}
                labelInValue
                notFoundContent={fetch ? <Spin size="small" /> : null}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleSearchA}
                value={{ key: this.state.id, label: this.state.name }}
                onChange={this.setAsset}
                placeholder="Search Asset"
              >
                {this.state.filtered.length === 0 ? assetList : FliteredList}
              </Select>
            </Form.Item>
          ) : null}
          {this.state.for_name === "entity" ? (
            <Form.Item>
              <Text>Search Place</Text>
              <Select
                showSearch={true}
                labelInValue
                notFoundContent={fetch ? <Spin size="small" /> : null}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleSearchE}
                value={{ key: this.state.id, label: this.state.name }}
                onChange={this.setEntity}
                placeholder="Search Place"
              >
                {this.state.filtered.length === 0 ? entityList : FliteredList}
              </Select>
            </Form.Item>
          ) : null}
          {this.state.for_name === "order" ? (
            <Form.Item>
              <Text>Search Order</Text>
              <Select
                showSearch={true}
                labelInValue
                notFoundContent={fetch ? <Spin size="small" /> : null}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleSearchO}
                value={{ key: this.state.id, label: this.state.name }}
                onChange={this.setOrder}
                placeholder="Search Order"
              >
                {this.state.filtered.length === 0 ? orderList : FliteredList}
              </Select>
            </Form.Item>
          ) : null}
          {this.state.for_name === "container" ? (
            <Form.Item>
              <Text>Search Container</Text>
              <Select
                showSearch={true}
                labelInValue
                notFoundContent={fetch ? <Spin size="small" /> : null}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleSearchC}
                value={{ key: this.state.id, label: this.state.name }}
                onChange={this.setContainer}
                placeholder="Search Container"
              >
                {this.state.filtered.length === 0
                  ? containerList
                  : FliteredList}
              </Select>
            </Form.Item>
          ) : null}
          {this.state.for_name === "parcel" ? (
            <Form.Item>
              <Text>Search Parcel</Text>
              <Select
                showSearch={true}
                labelInValue
                notFoundContent={fetch ? <Spin size="small" /> : null}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                onSearch={this.handleSearchP}
                value={{ key: this.state.id, label: this.state.name }}
                onChange={this.setParcel}
                placeholder="Search Parcel"
              >
                {this.state.filtered.length === 0 ? parcelList : FliteredList}
              </Select>
            </Form.Item>
          ) : null}

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createCode}
            >
              Create Code
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
