import React, { Component } from "react";
import { Card, Avatar, Typography, List } from "antd";

import { GET_USER_LOCAL, timeConverter } from "../utilities/utilities";

const { Text } = Typography;

export default class UserProfile extends Component {
  state = {
    data: null
  };

  render() {
    const primary = GET_USER_LOCAL().organizations.filter(
      org => org._id.$oid === GET_USER_LOCAL().organization.$oid
    );
    return (
      <Card style={{ margin: 10 }}>
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: 10 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <Avatar style={{ marginRight: 5 }} size={100}>
                  <Text style={{ fontSize: 50 }}>
                    {GET_USER_LOCAL().name[0].toUpperCase()}
                  </Text>
                </Avatar>
                <Text style={{ fontSize: 25 }}>
                  {GET_USER_LOCAL().name}
                </Text>
              </div>
            </Card>
            <Card style={{ margin: 10 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ fontSize: 15 }}>
                  Mobile: <Text style={{fontWeight:"500"}}>{GET_USER_LOCAL().mobile}</Text>
                </Text>
                <Text style={{ fontSize: 15 }}>
                  Email: <Text style={{fontWeight:"500"}}>{GET_USER_LOCAL().email}</Text>
                </Text>
                <Text style={{ fontSize: 15 }}>
                  PIN: <Text style={{fontWeight:"500"}}>{GET_USER_LOCAL().pin}</Text>
                </Text>
                <Text style={{ fontSize: 15 }}>
                  Ref Id: <Text style={{fontWeight:"500"}}>{GET_USER_LOCAL().ref_id}</Text>
                </Text>
                <Text style={{ fontSize: 15 }}>
                  Mobile Verified: <Text style={{fontWeight:"500"}}>{GET_USER_LOCAL().mobile_verified.toString()}</Text>
                </Text>
                <Text style={{ fontSize: 15 }}>
                  Email Verified: <Text style={{fontWeight:"500"}}>{GET_USER_LOCAL().email_verified.toString()}</Text>
                </Text>
                <Text style={{ fontSize: 15 }}>
                  Super User: <Text style={{fontWeight:"500"}}>{GET_USER_LOCAL().is_super.toString()}</Text>
                </Text>
                <Text style={{ fontSize: 15 }}>
                  Admin User: <Text style={{fontWeight:"500"}}>{GET_USER_LOCAL()?.org_admin?.toString()??false}</Text>
                </Text>
                <Text style={{ fontSize: 15 }}>
                  Staff User: <Text style={{fontWeight:"500"}}>{GET_USER_LOCAL().is_staff.toString()}</Text>
                </Text>
                {/* <Text style={{ fontSize: 15 }}>
                  Operator User: <Text style={{fontWeight:"500"}}>{GET_USER_LOCAL().is_operator.toString()}</Text>
                </Text> */}
                <Text style={{ fontSize: 15 }}>
                  Joined on: <Text style={{fontWeight:"500"}}>{timeConverter(GET_USER_LOCAL().created_on.$date)}</Text>
                </Text>
                {/* <Text style={{ fontSize: 15 }}>
                  Subscribed on: <Text style={{fontWeight:"500"}}>{timeConverter(GET_USER_LOCAL().subscription_date.$date)}</Text>
                </Text> */}
              </div>
            </Card>
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <Card style={{ margin: 10 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ fontSize: 15 ,marginBottom:10}}>
                  Primary Organization: <Text style={{fontWeight:"500"}}>{primary.map(org => org.name)}</Text>
                </Text>
                <List
                  header={<div>Connected Organizations</div>}
                  bordered
                  dataSource={GET_USER_LOCAL().organizations}
                  renderItem={item => (
                    <List.Item>
                       <Text style={{fontWeight:"500"}}> {item.name}</Text>
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </div>
        </div>
      </Card>
    );
  }
}
