import React, { Component } from "react";
import {
  Card,
  PageHeader,
  Select,
  Typography,
  Form,
  Button,
  Modal,
  List
} from "antd";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { saveTaskData } from "../../redux/actions/startTaskActions";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { Redirect } from "react-router-dom";

import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_DRIVER,
  // LOCATION_SEARCH,
  LOCATION_SEARCH_GOOGLE,
  REVERSE_LOCATION_SEARCH_GOOGLE,
  CREATE_QUICK_TASK,
  CREATE_ENTITY,
  GET_ASSIGNED_USER_SUB_ORG_LIST
} from "../../api/constants";
import { GET_USER_LOCAL, GET_ACCESS_TOKEN } from "../../utilities/utilities";
// import { Redirect } from "react-router-dom";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40]
});

class StartingFrom extends Component {
  state = {
    assigned_to: GET_USER_LOCAL()._id.$oid,
    assigned_to_label: GET_USER_LOCAL().name,
    userData: [],
    filtered: [],
    center_lat: 23.685,
    center_lon: 90.3563,
    start_location_lat: 23.685,
    start_location_lon: 90.3563,
    start_location_name: "",
    selected_value: [],
    selected_value_google: [],
    zoom: 15,
    location_input: "",
    show_google_search: false,
    start_search_data: [],
    start_search_data_google: [],
    entityList: [],
    startEntityModal: false,
    redirect: false,
    assigned_user_sub_orgs: [],
    sub_org_id: "",
    sub_org_name: ""
  };
  componentDidMount() {
    console.log("from starttask", this.props.startTask);
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState(
        {
          center_lat: latitude,
          start_location_lat: latitude,
          center_lon: longitude,
          start_location_lon: longitude
        },
        () => {
          GET_ACCESS_TOKEN().then(token => {
            const url = `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.start_location_lat}&lng=${this.state.start_location_lon}&access_token=${token}`;
            axios({
              method: "get",
              url: url
            }).then(response => {
              console.log("reverse api response", response);
              let name = response.data.google_loc_data[0].address;
              let id = response.data.google_loc_data[0]._id.$oid;
              this.setState({
                start_location_name: name,
                selected_value_google: { key: id, label: name },
                selected_value: { key: id, label: name }
              });
            });
          });
        }
      );
    };

    if (Object.keys(this.props.startTask).length !== 0) {
      this.setState(
        {
          assigned_to:
            this.props?.startTask?.assigned_to ?? GET_USER_LOCAL()._id.$oid,
          assigned_to_label:
            this.props?.startTask?.assigned_to_label ?? GET_USER_LOCAL().name,
          sub_org_id: this.props?.startTask?.sub_org_id ?? "",
          sub_org_name: this.props?.startTask?.sub_org_name ?? "",
          start_location_name: this.props?.startTask?.start_loc_name ?? "",
          start_location_lon:
            this.props?.startTask?.start_loc?.coordinates[0] ?? 90.3563,
          start_location_lat:
            this.props?.startTask?.start_loc?.coordinates[1] ?? 23.685,
          center_lon:
            this.props?.startTask?.start_loc?.coordinates[0] ?? 90.3563,
          center_lat: this.props?.startTask?.start_loc?.coordinates[1] ?? 23.685
        },
        () => {
          if (this.state.start_location_name === "") {
            navigator.geolocation.getCurrentPosition(success);
          }
          GET_ACCESS_TOKEN().then(token => {
            const url = `${GET_ASSIGNED_USER_SUB_ORG_LIST}?assigned_user_id=${this.state.assigned_to}&access_token=${token}`;
            axios({
              method: "get",
              url: url
            })
              .then(response => {
                if (response.data.data.length === 1) {
                  this.setState({
                    assigned_user_sub_orgs: response.data.data,
                    sub_org_id: response.data.data[0].id,
                    sub_org_name: response.data.data[0].name
                  },()=>{
                    this.getEntityList();

                  });
                } else {
                  this.setState({ assigned_user_sub_orgs: response.data.data });
                }
                console.log(
                  "response from assigned user sub org list",
                  response
                );
              })
              .catch(error => {
                console.log("error from assigned user sub org list api", error);
              });
          });
        }
      );
    } else {
      navigator.geolocation.getCurrentPosition(success);
    }

    this.getUserList();
  }

  getEntityList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ENTITY}?sub_org=${this.state.sub_org_id}&limit=1000&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from entity list", response);
          this.setState({
            entityList: response.data.data
          });
        })
        .catch(error => {
          console.log("error from entity list api", error);
        });
    });
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 100;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch(error => {
          console.log("error from driver list api", error);
        });
    });
  };

  // getStartLocation = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     const url = `${LOCATION_SEARCH}?loc_name=${this.state.location_input}&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         const data = response.data.loc_data.map(res => ({
  //           id: res._id.$oid,
  //           value: res.address
  //         }));
  //         this.setState({ start_search_data: data });
  //         console.log("response from location search", response);
  //       })
  //       .catch(error => {
  //         console.log("error from location search api", error);
  //       });
  //   });
  // };
  // handleChange = value => {
  //   this.setState({
  //     selected_value: value,
  //     selected_value_google: [],
  //     start_search_data: []
  //   });
  //   return this.setState({ start_location_name: value.label }, () => {
  //     GET_ACCESS_TOKEN().then(token => {
  //       const url = `${LOCATION_SEARCH}?loc_name=${this.state.start_location_name}&access_token=${token}`;
  //       axios({
  //         method: "get",
  //         url: url
  //       }).then(response => {
  //         console.log("response after selecting normal location", response);
  //         this.setState({
  //           start_location_lat:
  //             response.data?.loc_data[0]?.loc?.coordinates[1] ??
  //             this.state.center_lat,
  //           start_location_lon:
  //             response.data?.loc_data[0]?.loc?.coordinates[0] ??
  //             this.state.center_lon,
  //           center_lon:
  //             response.data?.loc_data[0]?.loc?.coordinates[0] ??
  //             this.state.center_lon,
  //           center_lat:
  //             response.data?.loc_data[0]?.loc?.coordinates[1] ??
  //             this.state.center_lat
  //         });
  //       });
  //     });
  //   });
  // };
  searchGooglemaps = debounce(() => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${LOCATION_SEARCH_GOOGLE}?textsearch=${this.state.location_input}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          const data = response.data.google_loc_datas.map(res => ({
            id: `${res._id.$oid}>${res.loc.coordinates[0]}>${res.loc.coordinates[1]}`,
            value: `${res.name}>${res.address}`
          }));
          this.setState({ start_search_data_google: data });
          console.log("response from location search google", response);
        })
        .catch(error => {
          console.log("error from location search api google", error);
        });
    });
  }, 500);

  handleChangeGoogle = value => {
    this.setState({
      selected_value_google: value,
      selected_value: [],
      start_search_data_google: []
    });
    return this.setState({
      start_location_name: value.label.split(">")[0],
      start_location_lon: value.key.split(">")[1],
      center_lon: value.key.split(">")[1],
      start_location_lat: value.key.split(">")[2],
      center_lat: value.key.split(">")[2]
    });
  };

  updatePosition = evt => {
    console.log(evt.target.getLatLng());
    this.setState(
      {
        start_location_lat: evt.target.getLatLng().lat,
        start_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat
      },
      () => {
        GET_ACCESS_TOKEN().then(token => {
          const url = `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.start_location_lat}&lng=${this.state.start_location_lon}&access_token=${token}`;
          axios({
            method: "get",
            url: url
          }).then(response => {
            console.log("reverse api response", response);
            let name = response.data.google_loc_data[0].address;
            let id = response.data.google_loc_data[0]._id.$oid;
            this.setState({
              start_location_name: name,
              selected_value_google: { key: id, label: name },
              selected_value: { key: id, label: name }
            });
          });
        });
      }
    );
  };

  setAssigned = value => {
    this.setState(
      { assigned_to_label: value.label, assigned_to: value.key, filtered: [] },
      () => {
        console.log(this.state.assigned_to);
        GET_ACCESS_TOKEN().then(token => {
          const url = `${GET_ASSIGNED_USER_SUB_ORG_LIST}?assigned_user_id=${this.state.assigned_to}&access_token=${token}`;
          axios({
            method: "get",
            url: url
          })
            .then(response => {
              if (response.data.data.length === 1) {
                this.setState({
                  assigned_user_sub_orgs: response.data.data,
                  sub_org_id: response.data.data[0].id,
                  sub_org_name: response.data.data[0].name
                });
              } else {
                this.setState({ assigned_user_sub_orgs: response.data.data });
              }
              console.log("response from assigned user sub org list", response);
            })
            .catch(error => {
              console.log("error from assigned user sub org list api", error);
            });
        });
      }
    );
  };

  setSubOrg = value => {
    this.setState({ sub_org_id: value.key, sub_org_name: value.label }, () => {
      this.getEntityList();
      console.log("sub org value", this.state.sub_org_id);
    });
  };

  handleSearchUser = value => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from search user", response);
          this.setState({
            userData: response.data.data
          });
        })
        .catch(error => {
          console.log("error from search user api", error);
        });
    });
  };

  addDestination = () => {
    if (this.state.start_location_name === "") {
      swal.fire("Info", "Please Enter Start Location", "info");
    } else {
      let data = {
        name: this?.props?.startTask?.name ?? "",
        task_type: this?.props?.startTask?.task_type ?? "",
        task_code: this?.props?.startTask?.task_code ?? "",
        colors: this?.props?.startTask?.colors ?? [],
        start_time: this?.props?.startTask?.start_time ?? Date.now(),
        started: this.props.startTask.start_time ? false : true,
        assigned_to: this.state.assigned_to,
        assigned_to_label: this.state.assigned_to_label,
        sub_org_id: this.state.sub_org_id,
        sub_org_name: this.state.sub_org_name,
        start_loc_name: this.state.start_location_name,
        start_loc: {
          coordinates: [
            this.state.start_location_lon,
            this.state.start_location_lat
          ]
        }
      };
      console.log(data);

      this.props.saveTaskData(data);
      this.props.history.push("/destination");
    }
  };

  chooseAsset = () => {
    if (this.state.start_location_name === "") {
      swal.fire("Info", "Please Enter Start Location", "info");
    } else {
      let data = {
        name: this?.props?.startTask?.name ?? "",
        task_type: this?.props?.startTask?.task_type ?? "",
        task_code: this?.props?.startTask?.task_code ?? "",
        colors: this?.props?.startTask?.colors ?? [],
        start_time: this?.props?.startTask?.start_time ?? "",
        started: this.props.startTask.start_time ? false : true,
        assigned_to: this.state.assigned_to,
        assigned_to_label: this.state.assigned_to_label,
        sub_org_id: this.state.sub_org_id,
        sub_org_name: this.state.sub_org_name,
        start_loc_name: this.state.start_location_name,
        start_loc: {
          coordinates: [
            this.state.start_location_lon,
            this.state.start_location_lat
          ]
        }
      };
      console.log(data);

      this.props.saveTaskData(data);
      this.props.history.push("/chooseAsset");
    }
  };

  createQuickTask = () => {
    if (this.state.start_location_name === "") {
      swal.fire("Info", "Please Enter Start Location", "info");
    } else {
      let data = {
        name: this?.props?.startTask?.name ?? "",
        task_type: this?.props?.startTask?.task_type ?? "",
        task_code: this?.props?.startTask?.task_code ?? "",
        colors: this?.props?.startTask?.colors ?? [],
        start_time: this?.props?.startTask?.start_time ?? Date.now(),
        started: this.props.startTask.start_time ? false : true,
        assigned_to: this.state.assigned_to,
        sub_org: this.state.sub_org_id,
        assigned_to_name: this.state.assigned_to_label,
        start_loc_name: this.state.start_location_name,
        start_loc: {
          coordinates: [
            this.state.start_location_lon,
            this.state.start_location_lat
          ]
        }
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_QUICK_TASK}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");
            this.props.saveTaskData({});
            if (response.data.message === "Data successfully inserted!") {
              this.setState({ redirect: true });
            }
            console.log("response from create quick task", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from create quick task", error);
          });
      });
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/startTask" />;
    }
    // const UserList = this.state.userData.map(r => {
    //   return (
    //     <Option
    //       key={r._id.$oid}
    //     >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
    //   );
    // });

    // const FliteredList = this.state.filtered.map(r => {
    //   return (
    //     <Option
    //       key={r._id.$oid}
    //     >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
    //   );
    // });
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Starting From"
          onBack={() => this.props.history.goBack()}
        />
        <Form>
          <Form.Item>
            <Text>
              Search Start Location
              {/* <Button
                type="link"
                onClick={() =>
                  this.setState({
                    show_google_search: !this.state.show_google_search
                  })
                }
              >
                {this.state.show_google_search
                  ? "Go Back"
                  : "Need More Results ?"}
              </Button> */}
              <Button
                type="link"
                onClick={() => this.setState({ startEntityModal: true })}
                style={{ margin: 5 }}
              >
                Select Start Location From Place
              </Button>
              <Button
                type="link"
                onClick={() => this.props.history.push("/createEntity")}
                style={{ margin: 5 }}
              >
                Create Place
              </Button>
            </Text>
            {/* {this.state.show_google_search ? ( */}
            <Select
              showSearch={true}
              labelInValue
              value={this.state.selected_value_google}
              filterOption={false}
              showArrow={false}
              placeholder="Search Google Maps"
              onSearch={value => {
                this.setState({ location_input: value }, () => {
                  console.log(
                    "input location value",
                    this.state.location_input
                  );
                  this.searchGooglemaps();
                });
              }}
              onChange={this.handleChangeGoogle}
              optionLabelProp="label"
            >
              {this.state.start_search_data_google.map(r => {
                return (
                  <Option key={r.id} label={r.value}>
                    {
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column"
                        }}
                      >
                        <Text>{r.value.split(">")[0]}</Text>
                        <Text style={{ fontSize: 10, color: "#9CB4CC" }}>
                          {r.value.split(">")[1]}
                        </Text>
                      </div>
                    }
                  </Option>
                );
              })}
            </Select>
            {/* ) : ( */}
            {/* <Select
                showSearch={true}
                labelInValue
                value={this.state.selected_value}
                filterOption={false}
                showArrow={false}
                placeholder="Search Start Location"
                onSearch={value => {
                  this.setState({ location_input: value }, () => {
                    console.log(
                      "input location value",
                      this.state.location_input
                    );
                    this.getStartLocation();
                  });
                }}
                onChange={this.handleChange}
              >
                {this.state.start_search_data.map(r => {
                  return <Option key={r.id}>{r.value}</Option>;
                })}
              </Select> */}
            {/* )} */}

            <Modal
              title="Select Start Location"
              visible={this.state.startEntityModal}
              onOk={() => this.setState({ startEntityModal: false })}
              onCancel={() => this.setState({ startEntityModal: false })}
            >
              <List
                size="small"
                bordered
                dataSource={this.state.entityList}
                renderItem={item => (
                  <List.Item
                    key={item._id.$oid}
                    onClick={() => {
                      this.setState({
                        start_location_name: item.address,
                        selected_value_google: {
                          key: item._id.$oid,
                          label: item.address
                        },
                        selected_value: {
                          key: item._id.$oid,
                          label: item.address
                        },
                        start_location_lon: item.loc.coordinates[0],
                        start_location_lat: item.loc.coordinates[1],
                        center_lon: item.loc.coordinates[0],
                        center_lat: item.loc.coordinates[1],
                        startEntityModal: false
                      });
                    }}
                  >{`${item.name} - ${item.address}`}</List.Item>
                )}
              />
            </Modal>
          </Form.Item>
          <Form.Item>
            <Text>Selected Location</Text>
            <Card>{this.state.start_location_name}</Card>
          </Form.Item>
          <Form.Item>
            <Map
              center={[this.state.center_lat, this.state.center_lon]}
              zoom={this.state.zoom}
              style={{ height: 500 }}
              onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
            >
              {/* <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
              /> */}
              <ReactLeafletGoogleLayer
                googleMapsLoaderConf={{
                  KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                  VERSION: "3.49.2"
                }}
                type={"roadmap"}
              />

              <Marker
                position={[
                  this.state.start_location_lat,
                  this.state.start_location_lon
                ]}
                draggable
                onDragend={this.updatePosition}
                icon={green}
              >
                <Popup>
                  <span>Starting From :{this.state.start_location_name}</span>
                </Popup>
              </Marker>
            </Map>
          </Form.Item>
          <Form.Item>
            <Text>Search Assigned User</Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              disabled={this.props.startTask.start_time ? false : true}
              onSearch={this.handleSearchUser}
              style={{ width: "100%" }}
              value={{
                key: this.state.assigned_to,
                label: this.state.assigned_to_label
              }}
              onChange={this.setAssigned}
              placeholder="Select User"
            >
              {this.state.userData.map(r => {
                return (
                  <Option
                    key={r._id.$oid}
                  >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Select Sub Organization</Text>
            <Select
              style={{ width: "100%" }}
              labelInValue
              value={{
                key: this.state.sub_org_id,
                label: this.state.sub_org_name
              }}
              disabled={
                this.state.assigned_user_sub_orgs.length < 2 ? true : false
              }
              onChange={this.setSubOrg}
              placeholder="Select Sub Organization"
            >
              {this.state.assigned_user_sub_orgs.map(r => {
                return <Option key={r.id}>{`${r.name}`}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.addDestination}
              >
                Add Destination
              </Button>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.chooseAsset}
              >
                Choose a Ride
              </Button>
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createQuickTask}
            >
              Just Save Right Away
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
const mapStateToProps = state => ({
  startTask: state.startTask.startTaskData
});
export default connect(mapStateToProps, { saveTaskData })(StartingFrom);
