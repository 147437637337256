import React, { Component } from "react";
import {
  Card,
  Button,
  Select,
  Typography,
  Table,
  Divider,
  Pagination,
  PageHeader
} from "antd";
import axios from "axios";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FileSaver from "file-saver";

import { WALLET_HISTORY,WALLET_PAYMENT_METHOD_CHOICES,WALLET_HISTORY_COMBINED_REPORT } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
} from "../../utilities/utilities";
import ReactGA from "react-ga4";

import "../login.css";

const { Option } = Select;
const { Text, Title } = Typography;
const { Column } = Table;

export default class WalletHistory extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    wallet_id: "",
    history_type: "add_history ",
    added_method: "",
    start_time:"" ,
    start_time_view: moment(),
    end_time: "",
    end_time_view: moment(),
    wallet_payment_methods: [],
  };
  componentDidMount() {
    this.setState({ wallet_id: this.props.location.state.wallet_id });
    this.getWalletHistory();
    this.getWalletPaymentMethodChoices();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getWalletPaymentMethodChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${WALLET_PAYMENT_METHOD_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from wallet payment method choices", response);

          this.setState({
            wallet_payment_methods: response.data.payment_method_choices,
          });
        })
        .catch((error) => {
          console.log("error from wallet payment method choices", error);
        });
    });
  };

  getWalletHistory = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${WALLET_HISTORY}${this.props.location.state.wallet_id}?end_time=${this.state.end_time}&start_time=${this.state.start_time}&added_method=${this.state.added_method}&history_type=${this.state.history_type}&limit=${this.state.limit}&page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from wallet history list", response);

          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from wallet history list", error);
        });
    });
  };

  refresh = () => {
    this.setState(
      {
        page: 0,
        total: 0,
        limit: 10,
        history_type: "add_history ",
        added_method: "",
        start_time: "",
        start_time_view: moment(),
        end_time: "",
        end_time_view: moment(),
      },
      () => {
        this.getWalletHistory();
      }
    );
  };

  downloadReport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${WALLET_HISTORY}${this.props.location.state.wallet_id}?fmt=xls&end_time=${this.state.end_time}&start_time=${this.state.start_time}&added_method=${this.state.added_method}&history_type=${this.state.history_type}&limit=${this.state.limit}&page=${this.state.page}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from wallet add deduct history excel report", blob);
          FileSaver.saveAs(
            blob,
            `conveyance_wallet_add_or_deduct_history_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from wallet add deduct history excel report", error);
        });
    });
  };
  downloadCombinedReport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${WALLET_HISTORY_COMBINED_REPORT}${this.props.location.state.wallet_id}?end_time=${this.state.end_time}&start_time=${this.state.start_time}&access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from wallet combined history excel report", blob);
          FileSaver.saveAs(
            blob,
            `conveyance_wallet_combined_history_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from wallet combined history excel report", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getWalletHistory();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getWalletHistory();
    });
  };

  render() {
    return (
      <Card style={{ margin: 10, minWidth: 1000 }}>
         <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Wallet History"
          onBack={() => this.props.history.goBack()}
        />
        <Card style={{ margin: 3 }}>
          <Title level={4}>
            Select daterange within which you would like to find your history
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getWalletHistory();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).add(59, "seconds").format("x"),
                        end_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getWalletHistory();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 5,
              }}
            >
              <Text>History Type</Text>
              <Select
                value={this.state.history_type}
                onChange={(value) => {
                  this.setState({ history_type: value }, () => {
                    this.getWalletHistory();
                    console.log(this.state.history_type);
                  });
                }}
                style={{ minWidth: 150 }}
              >
                <Option key="add_history ">add_history </Option>
                <Option key="deduction_history">deduction_history</Option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 5,
              }}
            >
              <Text>Added Method</Text>
              <Select
              style={{ minWidth: 150 }}
                value={this.state.add_method}
                onChange={(value) => this.setState({ add_method: value },()=> this.getWalletHistory())}
              >
                {this.state.wallet_payment_methods.map((r) => (
                  <Option key={r}>{r}</Option>
                ))}
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 20,
                }}
                onClick={() =>this.downloadReport()}
              >
               Download Add/Deduct Report
              </Button>
              <Button
                loading={this.state.show_pdf_loading_button}
                style={{
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 20,
                }}
                onClick={() =>this.downloadCombinedReport()}
              >
                Download Combined Report
              </Button>
            </div>
          </div>
        </Card>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record.id}
          size="small"
          bordered
        >
          <Column
            title={<>Added Method</>}
            key="added_method"
            render={(text, record) => (
              <span>{`${record?.added_method ?? ""}`}</span>
            )}
          />
         
           <Column
            title={<>Added Amount</>}
            key="added_amount"
            render={(text, record) => (
              <span>{`${record?.added_amount ?? 0}`}</span>
            )}
          />
           <Column
            title={<>Deducted Method</>}
            key="deducted_method"
            render={(text, record) => (
              <span>{`${record?.deducted_method ?? ""}`}</span>
            )}
          />
           <Column
            title={<>Deducted Amount</>}
            key="deducted_amount"
            render={(text, record) => (
              <span>{`${record?.deducted_amount ?? 0}`}</span>
            )}
          />
          <Column
            title={<>Current Balance</>}
            key="current_balance"
            render={(text, record) => (
              <span>{`${record?.current_balance ?? 0}`}</span>
            )}
          />
          <Column
            title={<>Added By</>}
            key="modified_by"
            render={(text, record) => (
              <span>{record.modified_by_name}</span>
            )}
          />
          <Column
            title={<>Added On</>}
            key="modified_on"
            render={(text, record) => (
              <span>{timeConverter(record.modified_on.$date)}</span>
            )}
          />
         
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}
