import React from "react";
import { Table, Divider, Card, PageHeader, Button,Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import { ORG_CONNECT_LIST, EDIT_CONNECT_LIST } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter,LOCAL_USER_INFO, GET_USER_LOCAL} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Column } = Table;

export default class ConnectionList extends React.Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.getConnectionList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

  }


  getConnectionList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${ORG_CONNECT_LIST}?page=${this.state.page}&d=false&org=${GET_USER_LOCAL().organization.$oid}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from connection list", response);
          // if (response.data.data.length === 0) {
          //   swal.fire("Info", "No Data Available", "info");
          // }
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from connection list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getConnectionList();
      }
    );
  };

  deleteConnection = id => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EDIT_CONNECT_LIST}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
          <Link to="/createConnection">
          <Tooltip placement="top" title="Create Connection">
            <Button
              shape="circle"
              icon="plus"
              style={{
                position: "fixed",
                zIndex: 100,
                bottom: 30,
                right: 20,
                backgroundColor: "#1A3337",
                height: 60,
                width: 60,
                fontSize: 30,
                paddingBottom: 5
              }}
            />
          </Tooltip>
        </Link>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Connected Organizations"
        />
        <Table dataSource={this.state.data} pagination={false} rowKey={record => record._id.$oid} size="small" bordered>
          <Column title="Name" dataIndex="org_name" key="org_name" />
          <Column title="Sender" dataIndex="sender_name" key="sender_name" />
          <Column title="Response" dataIndex="response" key="response" />
          <Column
            title="Sender Organization"
            dataIndex="sender_org_name"
            key="sender_org_name"
          />

          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                
                <Link
                  to={{
                    pathname: "/editConnection",
                    state: { org_connect_id: record._id.$oid }
                  }}
                >
                  <button
                    className="borderless-button"
                    style={{ color: "green" }}
                  >
                    View/Edit
                  </button>
                </Link>
                <div>
                  {LOCAL_USER_INFO().user.is_staff===true?(
                    <div>
                     <Divider type="vertical" />
                     <button
                       className="borderless-button"
                       style={{ color: "red" }}
                       onClick={() => {
                         if (window.confirm("Delete the item?")) {
                           this.deleteConnection(record._id.$oid);
                         }
                       }}
                     >
                       Delete
                     </button>
                     </div>
                  ):null}
                </div>
               
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
