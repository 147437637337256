import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_EXTRA_PACKAGE,
  CREATE_SERVICE,
  CREATE_COUNTRY
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

export default class CreateExtraPackage extends Component {
  state = {
    name: "",
    service: "",
    service_id: "",
    nos: null,
    description: "",
    country: "",
    countries: [],
    country_id: "",
    currency: "BDT",
    price: null,
    loading: false,
    data: []
  };

  componentDidMount() {
    this.countryList();
    this.getServices();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getServices = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${CREATE_SERVICE}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from service list", response);
          this.setState({
            data: response.data.data
          });
        })
        .catch(error => {
          console.log("error from service list api", error);
        });
    });
  };

  countryList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch(error => {
          console.log("error from country list api", error);
        });
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setDescription = evt => {
    evt.preventDefault();
    this.setState({ description: evt.target.value }, () => {
      console.log(this.state.description);
    });
  };

  setService = value => {
    this.setState({ service: value.label, service_id: value.key }, () => {
      console.log(this.state.service);
    });
  };
  setNos = evt => {
    evt.preventDefault();
    this.setState({ nos: evt.target.value }, () => {
      console.log(this.state.nos);
    });
  };
  setCountry = value => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);
    });
  };
  setCurrency = value => {
    this.setState({ currency: value }, () => {
      console.log(this.state.currency);
    });
  };
  setPrice = evt => {
    evt.preventDefault();
    this.setState({ price: evt.target.value }, () => {
      console.log(this.state.price);
    });
  };

  createExtraPackage = () => {
    if (
      this.state.name === "" ||
      this.state.description === "" ||
      this.state.country === "" ||
      this.state.price === null
    ) {
      swal.fire("info", "Please enter all informations", "info");
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        service_id: this.state.service_id,
        service_name: this.state.service,
        nos: Number(this.state.nos),
        description: this.state.description,
        country: this.state.country,
        currency: this.state.currency,
        price: this.state.price,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_EXTRA_PACKAGE}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "info");
            console.log("response from create extra package", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create extra package", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Create Extra Package"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>

            <Form.Item>
              <Text>Service Type</Text>
              <Select
                labelInValue
                value={{
                  key: this.state.service_id,
                  label: this.state.service
                }}
                onChange={this.setService}
                placeholder="Select Service"
              >
                {this.state.data.map(r => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>NOS (Number of Services)</Text>
              <Input
                placeholder="NOS (Number of Services)"
                type="number"
                value={this.state.nos}
                onChange={this.setNos}
              />
            </Form.Item>
        

          <Form.Item>
            <Text>Description</Text>

            <TextArea
              rows={4}
              placeholder="Description"
              type="text"
              value={this.state.description}
              onChange={this.setDescription}
            />
          </Form.Item>


      


          <Form.Item>
            <Text>Country</Text>
            <Select
              labelInValue
              value={{ key: this.state.country_id, label: this.state.country }}
              onChange={this.setCountry}
              placeholder="Select Country"
            >
              {this.state.countries.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Currency</Text>
            <Select defaultValue="BDT" onChange={this.setCurrency}>
              <Option value="BDT">BDT</Option>
              <Option value="NPR">NPR</Option>
              <Option value="AUD">AUD</Option>
              <Option value="USD">USD</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Price</Text>
            <Input
              placeholder="Price"
              type="number"
              value={this.state.price}
              onChange={this.setPrice}
            />
          </Form.Item>
    

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createExtraPackage}
            >
              Create Extra Package
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
