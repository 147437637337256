import React, { Component } from "react";
import {
  Table,
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Modal,
  Icon,
  Tooltip,
  Spin,
  Switch,
} from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import { debounce } from "lodash";
import {
  CREATE_RETURN_ORDER,
  CREATE_PRODUCT,
  CREATE_ENTITY,
  EDIT_ENTITY,
  CREATE_ORGANIZATION,
  GEO_CODING_API,
  REVERSE_GEO,
  ORDER_NUMBER,
  ORDER_PAYMENT_METHOD_CHOICES,
  ORDER_DISCOUNT_TYPE_CHOICES,
  CREATE_INVENTORY,
  REVERSE_LOCATION_SEARCH_GOOGLE,
  RETURN_ORDER_STATUS_CHOICES,
  ORGANIZATION_SETTINGS_LIST,
  CURRENT_USER_SUB_ORG_LIST,
  GET_ORDER_PRODUCT_STOCK_SETTINGS,
  CREATE_PRODUCT_CATEGORY,
  PROMOTION_ORDER_SEARCH,
} from "../../api/constants";
import "leaflet/dist/leaflet.css";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";

import {
  GET_ACCESS_TOKEN,
  CHECK_BOOL,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import { countryCode } from "../countryCode";

import { Spinner } from "../loader";
import CreateShop from "../shop/createShop";
import CreateProduct from "../product/createProduct";
import ReactGA from "react-ga4";

import "../login.css";
import "../order.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40],
});

class CreateReturnOrder extends Component {
  state = {
    order_number: "",
    name: "",
    details: "",
    items: [],
    itemObj: {},
    item: "",
    price: null,
    qty: 0,
    unit: "",
    drop_contact_name: "",
    drop_contact_no: "",
    drop_contact_email: "",
    start_entity_name: "",
    start_entity_key: "",
    end_entity_name: "",
    end_entity_key: "",
    end_loc: "",
    end_loc_name: "",
    delivery: true,
    delivered: false,
    rescheduled: false,
    startObj: {},
    endObj: {},
    pl: null,
    pw: null,
    ph: null,
    assigned_org_name: "",
    assigned_org_id: "",
    parcel_wght: null,
    total_price: null,
    total_price_before_discount: null,
    total_discount: null,
    delivery_charge: null,
    delivery_charge_included: true,
    other_charges: null,
    other_discount_type: "amount",
    other_discount: 0,
    bill_amount: null,
    received_amount: null,
    due_amount: null,
    received: false,
    delivery_date_time: Date.now(),
    delivery_date_time_view: moment(),
    data: [],
    dataS: [],
    orgList: [],
    loading: false,
    visible: false,
    showMap: false,
    showEntity: false,
    showProduct: false,
    delivery_time_flexible: false,

    query: "",
    start_location: "",
    value: [],
    dataMap: [],
    fetch: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    editEnd: false,
    zoom: 15,
    get_connected_orgs: true,

    unit_dim: null,
    unit_weight: null,
    max_weight: null,
    fragile: false,
    sensitive: false,
    fast: false,
    temperature: null,
    ucp: false,
    filtered: [],
    countryCode: [],
    code: "+880",
    filtered_items: [],
    selected_items: [],
    payment_method_choices: [],
    discount_type_choices: [],
    received_method: "cash",
    received_date: Date.now(),
    received_date_view: moment(),
    inventory_data: [],
    inventory_id: "",
    inventory_name: "",
    current_loc_lat: 23.685,
    current_loc_lon: 90.3563,
    current_loc_name: "",
    active_order_statuses: [],
    seq_no: "",
    status_name: "",
    org_setting_id: "",
    assigned_user_sub_orgs: [],
    sub_org_id: "",
    sub_org_name: "",
    allow_zero_stock: false,
    stock_quantity_type: "none",
    product_categories: [],
    category_name: "",
    barcode_search: false,
    linked_order_id: "",
    linked_order_name: "",
    linked_orders: [],
  };

  componentDidMount() {
    // if(GET_USER_LOCAL().org_admin){
    //   this.setState({get_connected_orgs:true});
    // }

    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState(
        {
          current_loc_lat: latitude,
          current_loc_lon: longitude,
        },
        () => {
          GET_ACCESS_TOKEN().then((token) => {
            const url = `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.current_loc_lat}&lng=${this.state.current_loc_lon}&access_token=${token}`;
            axios({
              method: "get",
              url: url,
            }).then((response) => {
              console.log("reverse api response", response);
              let name = response.data.google_loc_data[0].address;
              this.setState({
                current_loc_name: name,
              });
            });
          });
        }
      );
    };
    navigator.geolocation.getCurrentPosition(success);
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    window.scrollTo(0, 0);
    this.setState({ countryCode: countryCode });
    this.getUserSubOrg();
    // this.getInventoryList();
    // this.getOrderProductStockSettings();
    // this.getShopList();
    this.getOrganizationList();
    this.getOrderNumber();
    this.getPaymentMethodType();
    this.getDiscountType();
    this.getOrganizationSettingsList();
    this.getProductCategory();
  }

  getOrderList = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${PROMOTION_ORDER_SEARCH}?search_date_time_type=created_on&order_number=${
        value ? value : ""
      }&drop_contact_no=${
        this.state.drop_contact_no
      }&end_time=${Date.now()}&start_time=${moment()
        .subtract(12, "months")
        .format("x")}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from order list", response);
          this.setState({
            linked_orders: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from order list api", error);
        });
    });
  }, 500);

  getProductCategory = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT_CATEGORY}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ product_categories: response.data.data });
          console.log("data from product category list api", response);
        })
        .catch((error) => {
          console.log("error from product category list api", error);
        });
    });
  };

  getUserSubOrg = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?assigned_user_id=${
        GET_USER_LOCAL()._id.$oid
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            this.setState(
              {
                assigned_user_sub_orgs: response.data.data,
                sub_org_id: !this.props.userProfileData.data_level
                  .access_all_data
                  ? response.data.data[0].id
                  : "",
                sub_org_name: !this.props.userProfileData.data_level
                  .access_all_data
                  ? response.data.data[0].name
                  : "",
              },
              () => {
                this.getInventoryList();
                this.getShopList();
              }
            );
          } else {
            this.setState(
              { assigned_user_sub_orgs: response.data.data },
              () => {
                this.getInventoryList();
                this.getShopList();
              }
            );
          }
          console.log("response from assigned user sub org list", response);
        })
        .catch((error) => {
          console.log("error from assigned user sub org list api", error);
        });
    });
  };

  setSubOrg = (value) => {
    this.setState({ sub_org_id: value.key, sub_org_name: value.label }, () => {
      this.getInventoryList();
      this.getShopList();
      console.log("sub org value", this.state.sub_org_id);
    });
  };

  getOrganizationSettingsList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORGANIZATION_SETTINGS_LIST}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState(
            {
              org_setting_id: response.data?.data[0]?._id.$oid ?? "",
            },
            () => {
              this.getActiveOrderStatus();
            }
          );
          console.log("response from organization settings list", response);
        })
        .catch((error) => {
          console.log("error from organization settings list api", error);
        });
    });
  };

  getOrderProductStockSettings = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${GET_ORDER_PRODUCT_STOCK_SETTINGS}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState(
            {
              allow_zero_stock:
                response?.data?.stock_settings
                  ?.enable_order_for_availabl_stock_or_stock_quantity_zero ??
                false,
              stock_quantity_type:
                response?.data?.stock_settings
                  ?.select_available_stock_or_stock_quantity ?? "none",
            },
            () => {
              this.getProductList();
            }
          );
          console.log("response from order product stock settings", response);
        })
        .catch((error) => {
          console.log("error from order product stock settings", error);
        });
    });
  };
  getActiveOrderStatus = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${RETURN_ORDER_STATUS_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            active_order_statuses:
              response.data?.return_order_status_choices ?? [],
            status_name:
              response.data?.return_order_status_choices[0]?.name ?? [],
            seq_number:
              response.data?.return_order_status_choices[0]?.seq_name ?? [],
          });
          console.log("response from active order statuses", response);
        })
        .catch((error) => {
          console.log("error from active order statuses api", error);
        });
    });
  };

  getInventoryList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INVENTORY}?sub_org=${this.state.sub_org_id}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from inventory list", response);
          this.setState(
            {
              inventory_data: response.data.data,
              inventory_id:
                response.data.data.length > 0
                  ? response.data.data[0]._id.$oid
                  : "",
              inventory_name:
                response.data.data.length > 0
                  ? response.data.data[0].entity_name
                  : "",
            },
            () => {
              this.getOrderProductStockSettings();
            }
          );
        })
        .catch((error) => {
          console.log("error from inventory list api", error);
        });
    });
  };

  getPaymentMethodType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_PAYMENT_METHOD_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            payment_method_choices: response.data.payment_method_choices,
          });
          console.log(
            "response from order payment method type choices",
            response
          );
        })
        .catch((error) => {
          console.log(
            "error from order payment method type choices api",
            error
          );
        });
    });
  };
  getDiscountType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_DISCOUNT_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            discount_type_choices: response.data.order_discount_type_choices,
          });
          console.log("response from order disocunt type choices", response);
        })
        .catch((error) => {
          console.log("error from order disocunt type choices api", error);
        });
    });
  };

  getOrderNumber = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_NUMBER}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from order Number", response);
          this.setState({
            order_number: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from org list api", error);
        });
    });
  };

  getOrganizationList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ORGANIZATION}?access_token=${token}&get_connected_orgs=${this.state.get_connected_orgs}&limit=1000`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from organization list", response);
          this.setState({
            orgList: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from org list api", error);
        });
    });
  };

  getShopList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?selected_sub_org_for_drop_off_address=${this.state.sub_org_id}&limit=100&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("shop list api", response);
          this.setState({ dataS: response.data.data });
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  };

  searchPlace = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from place list", response);
          this.setState({
            dataS: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from place list api", error);
        });
    });
  }, 500);

  getProductList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_PRODUCT}?category_id=${this.state.category_name}&product_status=active&inventory_id=${this.state.inventory_id}&enable_order_for_availabl_stock_or_stock_quantity_zero=${this.state.allow_zero_stock}&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product", response);
          let products = response.data.data.map((p) => ({
            id: p._id.$oid,
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
            returned_from_order_id: p.returned_from_order_id,
          }));

          let products1 = response.data.data.map((p) => ({
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
            returned_from_order_id: p.returned_from_order_id,
          }));

          console.log("..........................", products);
          this.setState({
            data: [...this.state.data, ...products1],
            items: products,
          });
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  };

  setOrderStatus = (value) => {
    this.setState({ seq_no: value.key, status_name: value.label }, () => {
      console.log(this.state.status_name);
    });
  };

  setInventory = (value) => {
    this.setState(
      { inventory_name: value.label, inventory_id: value.key },
      () => {
        console.log(this.state.inventory_name);
        this.getProductList();
      }
    );
  };

  setProductCategory = (value) => {
    this.setState({ category_name: value }, () => {
      this.getProductList();
      console.log(this.state.category_name);
    });
  };

  itemSearch = debounce((value, event) => {
    let limit = 100;
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT}?barcode_search=${
        this.state.barcode_search
      }&category_name=${this.state.category_name}&sku=${
        this.state.barcode_search ? value : ""
      }&name=${
        !this.state.barcode_search ? value : ""
      }&product_status=active&inventory_id=${
        this.state.inventory_id
      }&enable_order_for_availabl_stock_or_stock_quantity_zero=${
        this.state.allow_zero_stock
      }&limit=${limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product list", response);
          let products = response.data.data.map((p) => ({
            id: p._id.$oid,
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
            returned_from_order_id: p.returned_from_order_id,
          }));

          let products1 = response.data.data.map((p) => ({
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id,
            product_img: p.product_img,
            product_img_thumb: p.product_img_thumb,
            related_promotion_count: p.related_promotion_count,
            parent_product_id: p.parent_product_id,
            returned_from_order_id: p.returned_from_order_id,
          }));

          console.log(".........................", products);
          this.setState({
            data: [...this.state.data, ...products1],
            items: products,
          });
        })
        .catch((error) => {
          console.log("error from product list api", error);
        });
    });
  }, 500);

  handleSearch = (value) => {
    const result = this.state.orgList.filter((org) => {
      return org.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered: result });
  };

  setOrderNumber = (evt) => {
    evt.preventDefault();
    this.setState({ order_number: evt.target.value }, () => {
      console.log(this.state.order_number);
    });
  };
  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setOtherDiscountType = (value) => {
    this.setState({ other_discount_type: value }, () => {
      console.log(this.state.other_discount_type);
    });
  };

  setOtherDiscount = (evt) => {
    evt.preventDefault();
    this.setState({ other_discount: Number(evt.target.value) }, () => {
      console.log(this.state.other_discount);
    });
  };

  calculateDiscount = () => {
    if (this.state.other_discount_type === "amount") {
      this.setState(
        {
          total_price:
            Number(this.state.total_price) - Number(this.state.other_discount),
        },
        () => {
          this.setState({ bill_amount: this.state.total_price });
        }
      );
    } else {
      let percentValue =
        (this.state.total_price * this.state.other_discount) / 100;
      this.setState(
        {
          total_price: Number(this.state.total_price) - Number(percentValue),
        },
        () => {
          // this.setState({ bill_amount: this.state.total_price });
        }
      );
    }
  };

  setReceivedMethod = (value) => {
    this.setState({ received_method: value }, () => {
      console.log(this.state.received_method);
    });
  };

  setDetails = (evt) => {
    evt.preventDefault();
    this.setState({ details: evt.target.value }, () => {
      console.log(this.state.details);
    });
  };
  setEndloc = (evt) => {
    evt.preventDefault();
    this.setState({ end_loc_name: evt.target.value }, () => {
      console.log(this.state.end_loc_name);
    });
  };

  setDropName = (evt) => {
    evt.preventDefault();
    this.setState({ drop_contact_name: evt.target.value }, () => {
      console.log(this.state.drop_contact_name);
    });
  };
  setCode = (value) => {
    this.setState({ code: value }, () => {
      console.log(this.state.code);
    });
  };
  setDropNumber = (evt) => {
    evt.preventDefault();
    this.setState({ drop_contact_no: evt.target.value }, () => {
      this.getOrderList();
      console.log(this.state.drop_contact_no);
    });
  };
  setDropEmail = (evt) => {
    evt.preventDefault();
    this.setState({ drop_contact_email: evt.target.value }, () => {
      console.log(this.state.drop_contact_email);
    });
  };
  setPl = (evt) => {
    evt.preventDefault();
    this.setState({ pl: evt.target.value }, () => {
      console.log(this.state.pl);
    });
  };
  setPw = (evt) => {
    evt.preventDefault();
    this.setState({ pw: evt.target.value }, () => {
      console.log(this.state.pw);
    });
  };
  setPWeight = (evt) => {
    evt.preventDefault();
    this.setState({ parcel_wght: evt.target.value }, () => {
      console.log(this.state.parcel_wght);
    });
  };
  setTotalPrice = (evt) => {
    evt.preventDefault();
    this.setState({ total_price: evt.target.value }, () => {
      this.setState({
        bill_amount:
          Number(this.state.total_price) +
          Number(this.state.delivery_charge) +
          Number(this.state.other_charges),
      });
      console.log(this.state.total_price);
    });
  };
  setDeliveryCharge = (evt) => {
    evt.preventDefault();
    this.setState({ delivery_charge: evt.target.value }, () => {
      this.setState({
        bill_amount:
          Number(this.state.total_price) +
          Number(this.state.delivery_charge) +
          Number(this.state.other_charges),
      });
      console.log(this.state.delivery_charge);
    });
  };
  setOtherCharge = (evt) => {
    evt.preventDefault();
    this.setState({ other_charges: evt.target.value }, () => {
      this.setState({
        bill_amount:
          Number(this.state.total_price) +
          Number(this.state.delivery_charge) +
          Number(this.state.other_charges),
      });
      console.log(this.state.other_charges);
    });
  };
  setBillAmount = (evt) => {
    evt.preventDefault();
    this.setState({ bill_amount: evt.target.value }, () => {
      console.log(this.state.bill_amount);
    });
  };
  setReceivedAmount = (evt) => {
    evt.preventDefault();
    this.setState({ received_amount: evt.target.value }, () => {
      this.setState({
        due_amount:
          Number(this.state.bill_amount) - Number(this.state.received_amount),
      });
      console.log(this.state.received_amount);
    });
  };
  setDueAmount = (evt) => {
    evt.preventDefault();
    this.setState({ due_amount: evt.target.value }, () => {
      console.log(this.state.due_amount);
    });
  };
  setPh = (evt) => {
    evt.preventDefault();
    this.setState({ ph: evt.target.value }, () => {
      console.log(this.state.ph);
    });
  };
  setDelivery = (value) => {
    this.setState({ delivery: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivery);
    });
  };
  setDeliverychargeincluded = (value) => {
    this.setState({ delivery_charge_included: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivery_charge_included);
    });
  };
  setDelivered = (value) => {
    this.setState({ delivered: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivered);
    });
  };
  // setRescheduled = value => {
  //   this.setState({ rescheduled: CHECK_BOOL(value) }, () => {
  //     console.log(this.state.rescheduled);
  //   });
  // };
  setReceived = (value) => {
    this.setState({ received: CHECK_BOOL(value) }, () => {
      console.log(this.state.received);
    });
  };
  setDeliveryTimeFlexible = (value) => {
    this.setState({ delivery_time_flexible: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivery_time_flexible);
    });
  };
  setAssignedOrg = (value) => {
    this.setState(
      {
        assigned_org_name: value.label,
        assigned_org_id: value.key,
      },
      () => {
        console.log(this.state.assigned_org_id);
      }
    );
  };

  setLinkedOrder = (value) => {
    this.setState(
      { linked_order_id: value.key, linked_order_name: value.label },
      () => {
        console.log(this.state.linked_order_name);
      }
    );
  };

  setStart = (value) => {
    this.setState(
      { start_entity_name: value.label, start_entity_key: value.key },
      () => {
        this.getShopList();
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_ENTITY}${this.state.start_entity_key}?access_token=${token}`;
          axios({
            method: "get",
            url: url,
          }).then((response) => {
            console.log("response fro edit entity", response);
            this.setState({
              startObj: {
                name: response.data.data.name,
                id: response.data.data._id.$oid,
                entity_type: response.data.data.entity_type,
                loc: response.data.data.loc,
                address: response.data.data.address,
                city: response.data.data.city,
                country: response.data.data.country,
                post_code: response.data.data.post_code,
                organization: response.data.data.organization.$oid,
              },
            });
          });
        });
      }
    );
  };
  setEnd = (value) => {
    this.setState(
      { end_entity_name: value.label, end_entity_key: value.key },
      () => {
        this.getShopList();
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_ENTITY}${this.state.end_entity_key}?access_token=${token}`;
          axios({
            method: "get",
            url: url,
          }).then((response) => {
            console.log("response fro edit entity", response);
            if (response.data.data.hasOwnProperty("contact")) {
              this.setState(
                {
                  drop_contact_name: response.data?.data?.contact?.name ?? "",
                  drop_contact_no:
                    response.data?.data?.contact?.mobile?.slice(4) ?? "",
                  drop_contact_email: response.data?.data?.contact?.email ?? "",
                },
                () => this.getOrderList()
              );
            }
            this.setState({
              endObj: {
                name: response.data.data.name,
                id: response.data.data._id.$oid,
                entity_type: response.data.data.entity_type,
                loc: response.data.data.loc,
                address: response.data.data.address,
                city: response.data.data.city,
                country: response.data.data.country,
                post_code: response.data.data.post_code,
                organization: response.data.data.organization.$oid,
              },
              end_loc: response.data.data.loc,
              end_loc_name: response.data.data.address,
              name: `${this.state.order_number}-${response.data.data.name}`,
            });
          });
        });
      }
    );
  };
  setTime = (value, dateString) => {
    this.setState(
      {
        delivery_date_time: moment(value).format("x"),
        delivery_date_time_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.delivery_date_time);
        console.log("Selected value: ", this.state.delivery_date_time_view);
      }
    );
  };
  setReceivedTime = (value, dateString) => {
    this.setState(
      {
        received_date: moment(value).format("x"),
        received_date_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.received_date);
        console.log("Selected value: ", this.state.received_date_view);
      }
    );
  };

  handleStart = (value) => {
    this.setState({ query: value, dataMap: [], fetching: true }, () => {
      console.log(this.state.query);
      if (this.state.query !== "") {
        setTimeout(() => {
          axios.get(GEO_CODING_API(this.state.query)).then((response) => {
            console.log(response);
            const data = response.data.features.map((res) => ({
              id: res.properties.place_id,
              value: res.properties.display_name,
            }));
            this.setState({ dataMap: data, fetch: false });
            console.log(response.data.features);
          });
        }, 3000);
      }
    });
  };

  handleStartChange = (value) => {
    this.setState({ value, dataMap: [], fetch: false });

    return this.setState({ end_loc_name: value.label }, () => {
      axios.get(GEO_CODING_API(this.state.end_loc_name)).then((response) => {
        this.setState(
          {
            end_location_lat: response.data.features[0].geometry.coordinates[1],
            end_location_lon: response.data.features[0].geometry.coordinates[0],
            center_lon: response.data.features[0].geometry.coordinates[0],
            center_lat: response.data.features[0].geometry.coordinates[1],
          },
          () => {
            this.setState({
              end_loc: {
                coordinates: [
                  this.state.end_location_lon,
                  this.state.end_location_lat,
                ],
              },
            });
          }
        );
      });
    });
  };

  updatePositionStart = (evt) => {
    console.log(evt.target.getLatLng());

    this.setState(
      {
        end_location_lat: evt.target.getLatLng().lat,
        end_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat,
      },
      () => {
        this.setState({
          end_loc: {
            coordinates: [
              this.state.end_location_lon,
              this.state.end_location_lat,
            ],
          },
        });
        axios
          .get(
            REVERSE_GEO(
              this.state.end_location_lat,
              this.state.end_location_lon
            )
          )
          .then((response) => {
            let name = response.data.features[0].properties.display_name;
            let id = response.data.features[0].properties.place_id;
            this.setState({
              end_loc_name: name,
              value: { key: id, label: name },
            });
          });
      }
    );
  };

  createOrder = () => {
    if (
      this.state.name === "" ||
      this.state.drop_contact_name === "" ||
      this.state.drop_contact_no === "" ||
      this.state.end_loc === null ||
      this.state.end_loc_name === null
    ) {
      swal.fire(
        "Info",
        "Please enter name,drop contact and delivery location",
        "info"
      );
    } else {
      this.setState({ loading: true });
      let final = this.state.data.filter((i) => i.qty !== 0);
      let data = {
        order_number: this.state.order_number,
        order_type: "return_order",
        name: this.state.name,
        details: this.state.details,
        sub_org: this.state.sub_org_id,
        inventory_id: this.state.inventory_id,
        inventory_entity_name: this.state.inventory_name,
        start_entity: this.state.startObj,
        end_entity: this.state.endObj,
        assigned_org: this.state.assigned_org_id,
        end_loc: this.state.end_loc,
        end_loc_name: this.state.end_loc_name,
        drop_contact_name: this.state.drop_contact_name,
        drop_contact_no:
          this.state.drop_contact_no !== ""
            ? this.state.code + this.state.drop_contact_no
            : "",
        drop_contact_email: this.state.drop_contact_email,
        order_status: {
          name: this.state.status_name,
          seq_no: Number(this.state.seq_no),
        },
        current_loc: {
          coordinates: [this.state.current_loc_lon, this.state.current_loc_lat],
        },
        current_loc_name: this.state.current_loc_name,
        other_charges: Number(this.state.other_charges),
        other_discount_type: this.state.other_discount_type,
        other_discount: Number(this.state.other_discount),
        placed_by: "",
        items: final,
        linked_order_id: this.state.linked_order_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_RETURN_ORDER}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });

            if (response.data.message === "Data successfully inserted!") {
              window.location.reload();
            }
            swal.fire("Info", response.data.message, "info");
            console.log("response from create order", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create order", error);
          });
      });
    }
  };

  calculateTotal = () => {
    this.setState(
      {
        total_price: 0,
      },
      () => {
        let final = this.state.data.filter((i) => i.qty !== 0);
        if (final.length > 0) {
          final.map((i) => {
            let total = i.unit_selling_price*i.qty;
            this.setState(
              (prevState) => ({
                total_price: prevState.total_price + total,
              }),
              () => {
                // this.setState({
                //   bill_amount:
                //     Number(this.state.total_price) +
                //     Number(this.state.delivery_charge) +
                //     Number(this.state.other_charges),
                // });
                console.log("total price", this.state.total_price);
              }
            );

            return null;
          });
        }
      }
    );
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <Card
          style={{
            marginBottom: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              className="login-form-button"
              style={{
                width: 200,
                fontSize: "medium",
                margin: 0,
                marginRight: 5,
                height: 40,
              }}
              onClick={() =>
                this.setState({
                  visible: true,
                  total_price: null,
                  total_price_before_discount: null,
                  total_discount: null,
                })
              }
            >
              Select Products
            </Button>
          </div>
        </Card>

        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Return Order"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                  marginTop: 10,
                }}
              >
                <Card
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    marginRight: 10,
                  }}
                >
                  <Form.Item>
                    <Text>Order Number</Text>
                    <Input
                      placeholder="Order Number"
                      type="text"
                      value={this.state.order_number}
                      onChange={this.setOrderNumber}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>
                      Search Shop/Dealer/Depo *{" "}
                      <Button
                        type="link"
                        onClick={() => this.setState({ showEntity: true })}
                      >
                        <Icon type="plus" style={{ fontSize: 20 }} />
                      </Button>
                    </Text>
                    <Select
                      labelInValue
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={true}
                      onSearch={this.searchPlace}
                      value={{
                        key: this.state.end_entity_key,
                        label: this.state.end_entity_name,
                      }}
                      onChange={this.setEnd}
                      placeholder="Select End Place"
                    >
                      {this.state.dataS.map((r) => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`Name : ${r.name} >>> Type : ${r.entity_type}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Text>
                      Shop/Dealer/Depo Address{" "}
                      <Button
                        type="link"
                        onClick={() => this.setState({ showMap: true })}
                      >
                        Select from Map
                      </Button>
                    </Text>
                    <TextArea
                      rows={3}
                      placeholder="End Location Address"
                      type="text"
                      value={this.state.end_loc_name}
                      onChange={this.setEndloc}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Select Sub Organization</Text>
                    <Select
                      style={{ width: "100%" }}
                      labelInValue
                      value={{
                        key: this.state.sub_org_id,
                        label: this.state.sub_org_name,
                      }}
                      disabled={
                        this.state.assigned_user_sub_orgs.length < 2 ||
                        this.state.selected_items.length > 0
                          ? true
                          : false
                      }
                      onChange={this.setSubOrg}
                      placeholder="Select Sub Organization"
                    >
                      {this.state.assigned_user_sub_orgs.map((r) => {
                        return <Option key={r.id}>{`${r.name}`}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Text>Return Order Status</Text>
                    <Select
                      value={{
                        key: this.state.seq_no,
                        label: this.state.status_name,
                      }}
                      labelInValue
                      onChange={this.setOrderStatus}
                    >
                      {this.state.active_order_statuses.map((r) => {
                        return <Option key={r.seq_no}>{r.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Text>Name *</Text>
                    <Input
                      placeholder="Name"
                      type="text"
                      value={this.state.name}
                      onChange={this.setName}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Details</Text>
                    <TextArea
                      rows={3}
                      placeholder="Details"
                      type="text"
                      value={this.state.details}
                      onChange={this.setDetails}
                    />
                  </Form.Item>

                  {/* <Form.Item>
                    <Text>Search Assigned Organization</Text>
                    <Select
                      labelInValue
                      value={{
                        key: this.state.assigned_org_id,
                        label: this.state.assigned_org_name
                      }}
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={false}
                      onSearch={this.handleSearch}
                      onChange={this.setAssignedOrg}
                      placeholder="Assigned Organization"
                    >
                      {this.state.filtered.length === 0
                        ? OrgList
                        : FliteredList}
                    </Select>
                  </Form.Item> */}

                  {/* <Form.Item>
                    <Text>
                      Search Pickup Location (Shop, Office, Factory etc.){" "}
                      <Button
                        type="link"
                        onClick={() => this.setState({ showEntity: true })}
                      >
                        <Icon type="plus" style={{ fontSize: 20 }} />
                      </Button>
                    </Text>
                    <Select
                      labelInValue
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={true}
                      onSearch={this.searchPlace}
                      value={{
                        key: this.state.start_entity_key,
                        label: this.state.start_entity_name,
                      }}
                      onChange={this.setStart}
                      placeholder="Select Start Place"
                    >
                      {this.state.dataS.map((r) => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`Name : ${r.name} >>> Type : ${r.entity_type}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item> */}
                </Card>
                <Card
                  style={{ display: "flex", flex: 1, flexDirection: "column" }}
                >
                  <Form.Item>
                    <Text>Client Contact Person *</Text>
                    <Input
                      placeholder="Client Contact Person"
                      type="text"
                      value={this.state.drop_contact_name}
                      onChange={this.setDropName}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Client Contact Number *</Text>
                    <Select
                      value={this.state.code}
                      onChange={this.setCode}
                      placeholder="Select Coode"
                    >
                      {this.state.countryCode.map((r) => {
                        return (
                          <Option key={r.dial_code}>
                            {r.name} {r.dial_code}
                          </Option>
                        );
                      })}
                    </Select>
                    <Input
                      placeholder="Example:1711123456"
                      type="text"
                      value={this.state.drop_contact_no}
                      onChange={this.setDropNumber}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Client Contact Email</Text>
                    <Input
                      placeholder="example@example.com"
                      type="text"
                      value={this.state.drop_contact_email}
                      onChange={this.setDropEmail}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Search Linked Order</Text>
                    <Select
                      labelInValue
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={true}
                      disabled={
                        this.state.linked_orders.length > 0 ? false : true
                      }
                      onSearch={this.getOrderList}
                      value={{
                        key: this.state.linked_order_id,
                        label: this.state.linked_order_name,
                      }}
                      onChange={this.setLinkedOrder}
                    >
                      {this.state.linked_orders.map((r) => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`Order Number : ${r.order_number}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Card>
                    
                    <Form.Item>
                      <Text>Discount Type</Text>
                      <Select
                        value={this.state.other_discount_type}
                        onChange={this.setOtherDiscountType}
                      >
                        {this.state.discount_type_choices.map((r) => {
                          return <Option key={r}>{r}</Option>;
                        })}
                      </Select>
                    </Form.Item>

                    <Form.Item>
                      <Text>Discount (Will be applied on total price)</Text>
                      <Input
                        placeholder="Discount"
                        type="number"
                        value={this.state.other_discount}
                        onChange={this.setOtherDiscount}
                      />
                      <Button type="primary" onClick={this.calculateDiscount}>
                        Add
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Text>Total Price</Text>
                      <Input
                        placeholder="Total Price"
                        type="number"
                        value={this.state.total_price}
                        disabled
                      />
                    </Form.Item>
                  </Card>
                </Card>
              </div>
            </div>

            <Modal
              title="Create New Place"
              visible={this.state.showEntity}
              onOk={() => {
                this.setState({ showEntity: false });
                this.getShopList();
              }}
              onCancel={() => {
                this.setState({ showEntity: false });
                this.getShopList();
              }}
              width={window.innerWidth - 200}
              closable={true}
            >
              <CreateShop />
            </Modal>
            <Modal
              title="Create New Product"
              visible={this.state.showProduct}
              onOk={() => {
                this.setState({ showProduct: false });
                this.getProductList();
              }}
              onCancel={() => {
                this.setState({ showProduct: false });
                this.getProductList();
              }}
              width={window.innerWidth - 200}
              closable={true}
            >
              <CreateProduct />
            </Modal>
            <Modal
              title="Select Products"
              visible={this.state.visible}
              onOk={() => {
                this.setState({ visible: false });
                this.calculateTotal();
              }}
              onCancel={() => this.setState({ visible: false })}
              width={window.innerWidth - 10}
              closable={true}
            >
              <Form.Item>
                <Text>Select Sub Organization</Text>
                <Select
                  style={{ width: "100%" }}
                  labelInValue
                  value={{
                    key: this.state.sub_org_id,
                    label: this.state.sub_org_name,
                  }}
                  disabled={
                    this.state.assigned_user_sub_orgs.length < 2 ||
                    this.state.selected_items.length > 0
                      ? true
                      : false
                  }
                  onChange={this.setSubOrg}
                  placeholder="Select Sub Organization"
                >
                  {this.state.assigned_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text>Select Inventory :</Text>
                  <Select
                    labelInValue
                    style={{ width: "100%" }}
                    value={{
                      key: this.state.inventory_id,
                      label: this.state.inventory_name,
                    }}
                    disabled={
                      this.state.inventory_data.length < 2 ||
                      this.state.selected_items.length > 0
                        ? true
                        : false
                    }
                    onChange={this.setInventory}
                    placeholder="Select Inventory"
                  >
                    {this.state.inventory_data.map((r) => {
                      return <Option key={r._id.$oid}>{r.entity_name}</Option>;
                    })}
                  </Select>
                </div>
              </Form.Item>
              <Form.Item>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text>Select Product Category</Text>
                  <Select
                    value={this.state.category_name}
                    onChange={this.setProductCategory}
                  >
                    {this.state.product_categories.map((r) => {
                      return <Option key={r._id.$oid}>{r.name}</Option>;
                    })}
                  </Select>
                </div>
              </Form.Item>
              <Form.Item>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text>
                    Search Product{" "}
                    <Button
                      type="link"
                      onClick={() => this.setState({ showProduct: true })}
                    >
                      Create Product
                    </Button>
                    <Switch
                      checkedChildren="Barcode Search"
                      unCheckedChildren="Text search"
                      checked={this.state.barcode_search}
                      onChange={(checked) =>
                        this.setState({ barcode_search: checked })
                      }
                    />
                  </Text>
                  <Select
                    className="customSelect"
                    onDropdownVisibleChange={() => {
                      const form = document.querySelector(".customSelect");

                      form.addEventListener("keydown", function (event) {
                        if (event.keyCode === 13) {
                          event.preventDefault();
                          event.stopPropagation();
                          return false;
                        }
                      });
                    }}
                    labelInValue
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue
                    onSearch={this.itemSearch}
                    onChange={(value, e) => {
                      let item = JSON.parse(e.props.value);
                      if (
                        this.state.data.some((od) => od.item_id === item.id)
                      ) {
                        return;
                      } else {
                        let inc = {
                          item: item.item,
                          parent_product_id: item.parent_product_id,
                          unit_selling_price: item.unit_selling_price,
                          qty: 1,
                          item_id: item.id,
                          unit: item.unit,
                          sku: item.sku,
                          unit_purchase_price: item.unit_purchase_price,
                          mrp: item.mrp,
                          inventory_id: item.inventory_id,
                          returned_from_order_id: item.returned_from_order_id,
                        };
                        let temp = [];
                        temp.push(inc);
                        //real data
                        let filteredData = this.state.data.filter(
                          (i) => i.item !== item.item
                        );

                        this.setState(
                          { data: [...temp, ...filteredData] },
                          () => {
                            this.setState(
                              {
                                data: this.state.data.filter(
                                  (value, index, self) =>
                                    self.findIndex(
                                      (t) => t.item_id === value.item_id
                                    ) === index
                                ),
                              },
                              () => {
                                console.log(
                                  "selection data state",
                                  this.state.data
                                );
                              }
                            );
                          }
                        );
                        //selected data
                        this.setState(
                          {
                            selected_items: [
                              ...this.state.selected_items,
                              ...temp,
                            ],
                          },
                          () => {
                            this.setState({
                              selected_items: this.state.selected_items.filter(
                                (value, index, self) =>
                                  self.findIndex(
                                    (t) => t.item_id === value.item_id
                                  ) === index
                              ),
                            });
                            console.log(this.state.selected_items);
                          }
                        );
                      }
                    }}
                    placeholder="Search Item"
                  >
                    {this.state.items.map((r) => (
                      <Option key={r.id} value={JSON.stringify(r)}>
                        <div>
                          {r.product_img_thumb ? (
                            <img
                              src={`${process.env.REACT_APP_BASE_PATH}${r.product_img_thumb}`}
                              alt="avatar"
                              style={{ width: 100, height: 45 }}
                            />
                          ) : null}
                          {`Name: ${r.item} > Unit: ${r.unit} > Promotions :${
                            r.related_promotion_count
                          } ${
                            this.state.stock_quantity_type === "available_stock"
                              ? `> Available Stock: ${r.available_stock}`
                              : this.state.stock_quantity_type ===
                                "stock_quantity"
                              ? `> Stock Quantity: ${r.stock_quantity}`
                              : ""
                          } `}
                        </div>
                      </Option>
                    ))}
                  </Select>
                </div>
              </Form.Item>
              {this.state.selected_items.length > 0 ? (
                <Table
                  dataSource={this.state.selected_items}
                  pagination={false}
                  rowKey={(record) => record.item_id}
                  size="small"
                >
                  <Column title="Product" dataIndex="item" key="product" />

                  <Column title="Unit" dataIndex="unit" key="unit" />

                  <Column
                    title="Unit Selling Price"
                    key="unit_selling_price"
                    render={(record) => (
                      <span>
                        <Input
                          disabled
                          type="number"
                          style={{ minWidth: 100 }}
                          value={
                            this.state.data.find((i) => i.item === record.item)
                              ?.unit_selling_price ?? 0
                          }
                        />
                      </span>
                    )}
                  />
                  <Column
                    title="Return from Order"
                    key="returned_from_order_id"
                    render={(record) => (
                      <span>
                        <Select
                          style={{ minWidth: 200 }}
                          showSearch={true}
                          filterOption={false}
                          showArrow={false}
                          autoClearSearchValue={true}
                          disabled={
                            this.state.linked_orders.length > 0 ? false : true
                          }
                          onSearch={this.getOrderList}
                          value={
                            this.state.data.find((i) => i.item === record.item)
                              ?.returned_from_order_id ?? ""
                          }
                          onChange={(value) => {
                            let item = this.state.data.find(
                              (i) => i.item === record.item
                            );
                            let inc = {
                              item: item.item,
                              parent_product_id: item.parent_product_id,
                              unit_selling_price: item.unit_selling_price,
                              qty: item.qty,
                              item_id: item.item_id,
                              unit: item.unit,
                              sku: item.sku,
                              unit_purchase_price: item.unit_purchase_price,
                              mrp: item.mrp,
                              inventory_id: item.inventory_id,
                              returned_from_order_id: value,
                            };

                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          }}
                        >
                          {this.state.linked_orders.map((r) => {
                            return (
                              <Option
                                key={r._id.$oid}
                              >{`Order Number : ${r.order_number}`}</Option>
                            );
                          })}
                        </Select>
                      </span>
                    )}
                  />
                  <Column
                    title="Decrease"
                    key="Decrease"
                    render={(record) => (
                      <span>
                        <Button
                          style={{
                            color: "red",
                          }}
                          type="link"
                          onClick={() => {
                            let item = this.state.data.find(
                              (i) => i.item === record.item
                            );
                            if (item.qty - 1 <= 0) {
                              swal.fire(
                                "Info",
                                "Quantity can not be zero",
                                "info"
                              );
                              return;
                            }
                            let inc = {
                              item: item.item,
                              parent_product_id: item.parent_product_id,
                              unit_selling_price: item.unit_selling_price,
                              qty: item.qty - 1,
                              item_id: item.item_id,
                              unit: item.unit,
                              sku: item.sku,
                              unit_purchase_price: item.unit_purchase_price,
                              mrp: item.mrp,
                              inventory_id: item.inventory_id,
                              returned_from_order_id:
                                item.returned_from_order_id,
                            };

                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          }}
                        >
                          <Icon type="minus" />
                        </Button>
                      </span>
                    )}
                  />

                  <Column
                    title="Quantity"
                    key="Quantity"
                    render={(record) => (
                      <span>
                        <Input
                          type="number"
                          value={
                            this.state.data.find((i) => i.item === record.item)
                              ?.qty ?? 0
                          }
                          onChange={(evt) => {
                            let item = this.state.data.find(
                              (i) => i.item === record.item
                            );

                            let inc = {
                              item: item.item,
                              parent_product_id: item.parent_product_id,
                              unit_selling_price: item.unit_selling_price,
                              qty: Number(evt.target.value),
                              item_id: item.item_id,
                              unit: item.unit,
                              sku: item.sku,
                              unit_purchase_price: item.unit_purchase_price,
                              mrp: item.mrp,
                              inventory_id: item.inventory_id,
                              returned_from_order_id:
                                item.returned_from_order_id,
                            };

                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          }}
                        />
                      </span>
                    )}
                  />
                  <Column
                    title="Increase"
                    key="Increase"
                    render={(record) => (
                      <span>
                        <Button
                          style={{
                            color: "green",
                          }}
                          type="link"
                          onClick={() => {
                            let item = this.state.data.find(
                              (i) => i.item === record.item
                            );

                            let inc = {
                              item: item.item,
                              parent_product_id: item.parent_product_id,
                              unit_selling_price: item.unit_selling_price,
                              qty: item.qty + 1,
                              item_id: item.item_id,
                              unit: item.unit,
                              sku: item.sku,
                              unit_purchase_price: item.unit_purchase_price,
                              mrp: item.mrp,
                              inventory_id: item.inventory_id,
                              returned_from_order_id:
                                item.returned_from_order_id,
                            };

                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          }}
                        >
                          <Icon type="plus" />
                        </Button>
                      </span>
                    )}
                  />

                  <Column
                    title="Remove"
                    key="Remove"
                    render={(record) => (
                      <span>
                        <Button
                          style={{
                            color: "red",
                          }}
                          type="link"
                          onClick={() => {
                            this.setState({
                              selected_items: this.state.selected_items.filter(
                                (item) => item.item !== record.item
                              ),
                            });
                            let item = this.state.data.find(
                              (i) => i.item === record.item
                            );

                            let inc = {
                              item: item.item,
                              parent_product_id: item.parent_product_id,
                              unit_selling_price: item.unit_selling_price,
                              qty: 0,
                              item_id: item.item_id,
                              unit: item.unit,
                              sku: item.sku,
                              unit_purchase_price: item.unit_purchase_price,
                              mrp: item.mrp,
                              inventory_id: item.inventory_id,
                              returned_from_order_id:
                                item.returned_from_order_id,
                            };

                            let arr = [];
                            arr.push(inc);

                            let filteredData = this.state.data.filter(
                              (i) => i.item !== item.item
                            );

                            this.setState(
                              { data: [...arr, ...filteredData] },
                              () => {
                                console.log("final state", this.state.data);
                              }
                            );
                          }}
                        >
                          <Icon type="close" />
                        </Button>
                      </span>
                    )}
                  />
                </Table>
              ) : null}
            </Modal>
            <Modal
              title="Select Location"
              visible={this.state.showMap}
              onOk={() => this.setState({ showMap: false })}
              onCancel={() => this.setState({ showMap: false })}
              width={window.innerWidth - 100}
              closable={true}
            >
              <Form>
                <Form.Item>
                  <Text>Search Location</Text>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {this.state.editEnd === true ? (
                      <Input
                        type="text"
                        placeholder="Start Location"
                        value={this.state.end_loc_name}
                        onChange={(evt) =>
                          this.setState({ end_loc_name: evt.target.value })
                        }
                      />
                    ) : (
                      <Select
                        showSearch={true}
                        labelInValue
                        value={this.state.value}
                        notFoundContent={fetch ? <Spin size="small" /> : null}
                        filterOption={false}
                        showArrow={false}
                        autoClearSearchValue={false}
                        placeholder="Search Location"
                        onSearch={this.handleStart}
                        onChange={this.handleStartChange}
                      >
                        {this.state.dataMap.map((r) => {
                          return <Option key={r.id}>{r.value}</Option>;
                        })}
                      </Select>
                    )}
                    {this.state.end_loc_name === "" ? null : (
                      <Tooltip
                        title={
                          this.state.editStart
                            ? "Search Location"
                            : "Edit Location"
                        }
                      >
                        <Button
                          type="primary"
                          shape="circle"
                          style={{ marginLeft: 10 }}
                          onClick={() =>
                            this.setState({ editEnd: !this.state.editEnd })
                          }
                        >
                          <Icon type={this.state.editEnd ? "search" : "edit"} />
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                </Form.Item>
              </Form>
              <Map
                center={[this.state.center_lat, this.state.center_lon]}
                zoom={this.state.zoom}
                style={{ height: 500 }}
                onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
              >
                {/* <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                /> */}
                <ReactLeafletGoogleLayer
                  googleMapsLoaderConf={{
                    KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                    VERSION: "3.49.2",
                  }}
                  type={"roadmap"}
                />

                <Marker
                  position={[
                    this.state.end_location_lat,
                    this.state.end_location_lon,
                  ]}
                  draggable
                  onDragend={this.updatePositionStart}
                  icon={green}
                >
                  <Popup>
                    <span>From :{this.state.end_loc_name}</span>
                  </Popup>
                </Marker>
              </Map>
            </Modal>
          </div>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createOrder}
            >
              Create Return Order
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(CreateReturnOrder);
