import React, { Component } from "react";
import {
  Table,
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Modal,
  Icon,
  Tooltip,
  Spin,
  Tag,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import { debounce } from "lodash";
import {
  CREATE_ENTITY,
  EDIT_ENTITY,
  CREATE_ORGANIZATION,
  GEO_CODING_API,
  REVERSE_GEO,
  EDIT_ORDER_WITH_PROMOTION,
  ORDER_ITEM_UPDATE,
  ORDER_PAYMENT_METHOD_CHOICES,
  ORDER_DISCOUNT_TYPE_CHOICES,
  REVERSE_LOCATION_SEARCH_GOOGLE,
  GET_ACTIVE_ORDER_STATUS,
  ORGANIZATION_SETTINGS_LIST,
  PROMOTIONS_FOR_PRODUCT,
  CREATE_PRE_ORDER_PROMOTIONS,
  DEDUCT_APPLIED_PROMOTION,
  PRE_ORDER_APPLIED_PROMOTIONS,
  IS_PROMOTION_APPLICABLE,
  GET_FREE_PROMOTION_PRODUCT,
} from "../../api/constants";
import "leaflet/dist/leaflet.css";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import {
  GET_ACCESS_TOKEN,
  CHECK_BOOL,
  randomId,
  // GET_USER_LOCAL
} from "../../utilities/utilities";

// import { Spinner } from "../loader";
import CreateShop from "../shop/createShop";
import CreateProduct from "../product/createProduct";

import "../login.css";
import "../order.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [48, 48],
});

class EditOrder extends Component {
  state = {
    order_number: "",
    name: "",
    details: "",
    items: [],
    itemObj: {},
    item: "",
    price: null,
    qty: 0,
    unit: "",
    drop_contact_name: "",
    drop_contact_no: "",
    drop_contact_email: "",
    start_entity_name: "",
    start_entity_key: "",
    end_entity_name: "",
    end_entity_key: "",
    end_loc: "",
    end_loc_name: "",
    delivery: true,
    delivered: false,
    rescheduled: false,
    startObj: {},
    endObj: {},
    pl: null,
    pw: null,
    ph: null,
    assigned_org_name: "",
    assigned_org_id: "",
    parcel_wght: null,
    total_price: null,
    delivery_charge: null,
    delivery_charge_included: true,
    other_charges: null,
    bill_amount: null,
    received_amount: null,
    due_amount: null,
    // received: false,
    delivery_date_time: moment(),
    delivery_date_time_view: moment(),
    modify_on: "",
    data: [],
    dataS: [],
    orgList: [],
    loading: false,
    visible: false,
    showMap: false,
    showEntity: false,
    showProduct: false,
    delivery_time_flexible: false,
    other_discount_type: "amount",
    other_discount: 0,
    other_discount_category:"manual",
    total_price_before_discount: null,
    total_discount: null,

    query: "",
    start_location: "",
    value: [],
    dataMap: [],
    fetch: false,
    center_lat: 23.685,
    center_lon: 90.3563,
    end_location_lat: 23.685,
    end_location_lon: 90.3563,
    editEnd: false,
    zoom: 15,
    get_connected_orgs: true,
    products: [],
    edit: false,

    unit_dim: null,
    unit_weight: null,
    max_weight: null,
    fragile: false,
    sensitive: false,
    fast: false,
    temperature: null,
    ucp: false,
    filtered: [],
    filtered_items: [],
    selected_items: [],
    order_item_edit_modal: false,
    order_id: "",
    parent_product_id: "",

    item_price: 0,
    item_unit_selling_price: 0,
    item_purchase_qty: 0,
    item_free_qty: 0,
    item_name: "",
    item_unit: "",
    item_id: "",
    item_parent_id: "",
    item_sku: "",
    item_unit_purchase_price: 0,
    item_mrp: 0,
    item_inventory_id: "",
    item_discount_type: "amount",
    item_discount: 0,
    item_raw_discount: 0,
    item_returned: false,
    return_item_qty: 0,

    promotional_item_price: 0,
    promotional_item_unit_selling_price: 0,
    promotional_item_purchase_qty: 0,
    promotional_item_free_qty: 0,
    promotional_item_name: "",
    promotional_item_unit: "",
    promotional_item_id: "",
    promotional_item_parent_id: "",
    promotional_item_sku: "",
    promotional_item_unit_purchase_price: 0,
    promotional_item_mrp: 0,
    promotional_item_inventory_id: "",
    promotional_item_discount_type: "amount",
    promotional_item_discount: 0,
    promotional_item_raw_discount: 0,
    promotional_item_returned: false,
    promotional_return_item_qty: 0,

    free_item_price: 0,
    free_item_unit_selling_price: 0,
    free_item_purchase_qty: 0,
    free_item_free_qty: 0,
    free_item_name: "",
    free_item_unit: "",
    free_item_id: "",
    free_item_parent_id: "",
    free_item_sku: "",
    free_item_unit_purchase_price: 0,
    free_item_mrp: 0,
    free_item_inventory_id: "",
    free_item_discount_type: "amount",
    free_item_discount: 0,
    free_item_raw_discount: 0,
    free_item_returned: false,
    free_return_item_qty: 0,

    total_refund: 0,
    payment_method_choices: [],
    discount_type_choices: [],
    received_method: "cash",
    received_date: Date.now(),
    received_date_view: moment(),
    total_received_amount: null,
    refunded_amount: null,
    refunded_date: Date.now(),
    refunded_date_view: moment(),
    refunded_method: "cash",
    total_refunded_amount: null,
    refundable_amount: null,
    show_received_modal: false,
    show_refund_modal: false,
    inventory_data: [],
    inventory_id: "",
    inventory_name: "",
    current_loc_lat: 23.685,
    current_loc_lon: 90.3563,
    current_loc_name: "",
    active_order_statuses: [],
    seq_no: "",
    status_name: "",
    org_setting_id: "",
    assigned_user_sub_orgs: [],
    sub_org_id: "",
    sub_org_name: "",
    allow_zero_stock: false,
    stock_quantity_type: "none",
    product_categories: [],
    category_name: "",
    barcode_search: false,
    promotions_for_product: [],
    applied_promotions: [],
    promotional_qty: 0,
    showProductPromotions: false,
    applied_promotion: {},
    new_apply_promotion: {},
    promotional_items: [],
    non_promotional_items: [],
    free_items: [],
    promotional_free_items_data_from_api: [],
  };

  componentDidMount() {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState(
        {
          current_loc_lat: latitude,
          current_loc_lon: longitude,
        },
        () => {
          GET_ACCESS_TOKEN().then((token) => {
            const url = `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.current_loc_lat}&lng=${this.state.current_loc_lon}&access_token=${token}`;
            axios({
              method: "get",
              url: url,
            }).then((response) => {
              console.log("reverse api response", response);
              let name = response.data.google_loc_data[0].address;
              this.setState({
                current_loc_name: name,
              });
            });
          });
        }
      );
    };
    navigator.geolocation.getCurrentPosition(success);
    this.props.getUserData();

    this.getOrderDetails();
    this.getOrganizationList();
    this.getPaymentMethodType();
    this.getDiscountType();
    this.getOrganizationSettingsList();
  }

  // getUserSubOrg = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     const url = `${GET_ASSIGNED_USER_SUB_ORG_LIST}?assigned_user_id=${
  //       GET_USER_LOCAL()._id.$oid
  //     }&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         if (response.data.data.length === 1) {
  //           this.setState({
  //             assigned_user_sub_orgs: response.data.data,
  //             sub_org_id: response.data.data[0].id,
  //             sub_org_name: response.data.data[0].name
  //           });
  //         } else {
  //           this.setState({ assigned_user_sub_orgs: response.data.data });
  //         }
  //         console.log("response from assigned user sub org list", response);
  //       })
  //       .catch(error => {
  //         console.log("error from assigned user sub org list api", error);
  //       });
  //   });
  // };

  // setSubOrg = value => {
  //   this.setState({ sub_org_id: value.key, sub_org_name: value.label }, () => {
  //     console.log("sub org value", this.state.sub_org_id);
  //   });
  // };

  getOrganizationSettingsList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORGANIZATION_SETTINGS_LIST}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState(
            {
              org_setting_id: response.data?.data[0]?._id.$oid ?? "",
            },
            () => {
              this.getActiveOrderStatus();
            }
          );
          console.log("response from organization settings list", response);
        })
        .catch((error) => {
          console.log("error from organization settings list api", error);
        });
    });
  };

  getActiveOrderStatus = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${GET_ACTIVE_ORDER_STATUS}${this.state.org_setting_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            active_order_statuses: response.data?.data ?? [],
          });
          console.log("response from active order statuses", response);
        })
        .catch((error) => {
          console.log("error from active order statuses api", error);
        });
    });
  };
 

  // getInventoryList = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     const url = `${CREATE_INVENTORY}?sub_org=${this.state.sub_org_id}&limit=100&access_token=${token}`;

  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         console.log("response from inventory list", response);
  //         this.setState(
  //           {
  //             inventory_data: response.data.data,
  //             inventory_id:
  //               response.data.data.length > 0
  //                 ? response.data.data[0]._id.$oid
  //                 : ""
  //           },
  //           () => {
  //             this.getProductList();
  //           }
  //         );
  //       })
  //       .catch(error => {
  //         console.log("error from inventory list api", error);
  //       });
  //   });
  // };

  getPaymentMethodType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_PAYMENT_METHOD_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            payment_method_choices: response.data.payment_method_choices,
          });
          console.log(
            "response from order payment method type choices",
            response
          );
        })
        .catch((error) => {
          console.log(
            "error from order payment method type choices api",
            error
          );
        });
    });
  };

  getDiscountType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORDER_DISCOUNT_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            discount_type_choices: response.data.order_discount_type_choices,
          });
          console.log("response from order disocunt type choices", response);
        })
        .catch((error) => {
          console.log("error from order disocunt type choices api", error);
        });
    });
  };

  getOrderDetails = () => {
    this.setState({ order_id: this.props.location.state.order_id });

    GET_ACCESS_TOKEN().then((token) => {
      let order_id = this.props.location.state.order_id;
      let url = `${EDIT_ORDER_WITH_PROMOTION}${order_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit order", response.data.data);
        this.setState(
          {
            name: response.data.data.name,
            status_name: response.data?.data?.order_status?.name ?? "",
            seq_no: response.data?.data?.order_status?.seq_no ?? "",
            details: response.data.data.details,
            sub_org_id: response?.data?.data?.sub_org?.id ?? "",
            sub_org_name: response?.data?.data?.sub_org?.name ?? "",
            inventory_id: response?.data?.data?.inventory_id ?? "",
            inventory_name: response?.data?.data?.inventory_entity_name ?? "",
            assigned_org_id: response.data.data.assigned_org.$oid,
            assigned_org_name: response.data.data.assigned_org_name,
            bill_amount: response.data.data.bill_amount,
            delivered: response.data.data.delivered,
            delivery: response.data.data.delivery,
            delivery_charge: response.data.data.delivery_charge,
            other_discount_type:
              response.data?.data?.other_discount_type ?? "amount",
            other_discount: response.data?.data?.other_discount ?? 0,
            other_discount_category: response.data?.data?.other_discount_category ?? "manual",
            delivery_charge_included:
              response.data?.data?.delivery_charge_included ?? true,
            delivery_date_time: response.data.data.delivery_date_time.$date,
            delivery_date_time_view:
              response.data.data.delivery_date_time.$date,
            drop_contact_email: response.data.data.drop_contact_email,
            drop_contact_name: response.data.data.drop_contact_name,
            drop_contact_no: response.data.data.drop_contact_no,
            due_amount: response.data.data.due_amount,
            end_loc: response.data.data.end_loc,
            end_location_lon: response.data.data.end_loc.coordinates
              ? response.data.data.end_loc.coordinates[0]
              : 90.3563,
            end_location_lat: response.data.data.end_loc.coordinates
              ? response.data.data.end_loc.coordinates[1]
              : 23.685,
            center_lon: response.data.data.end_loc.coordinates
              ? response.data.data.end_loc.coordinates[0]
              : 90.3563,
            center_lat: response.data.data.end_loc.coordinates
              ? response.data.data.end_loc.coordinates[1]
              : 23.685,
            end_loc_name: response.data.data.end_loc_name,
            order_number: response.data.data.order_number,
            other_charges: response.data.data.other_charges,
            pl: response.data.data.parcel_dim.pl,
            pw: response.data.data.parcel_dim.pw,
            ph: response.data.data.parcel_dim.ph,
            parcel_wght: response.data.data.parcel_wght,
            // received: response.data.data.received,
            // received_amount: response.data.data.received_amount,
            rescheduled: response.data.data.rescheduled,
            delivery_time_flexible:
              response.data.data.delivery_time_flexible || false,
            total_price_before_discount: response.data?.data?.total_price ?? 0,
            total_discount: response.data?.data?.discount_on_products ?? 0,
            total_refund: response.data?.data?.total_refund ?? 0,
            total_refunded_amount:
              response.data?.data?.total_refunded_amount ?? 0,
            total_received_amount:
              response.data?.data?.total_received_amount ?? 0,
            refundable_amount: response.data?.data?.refundable_amount ?? 0,
            total_price:
              response.data?.data
                ?.total_price_after_discount_on_product_and_other_discount ?? 0,
            start_entity_name: response.data.data.start_entity.name,
            start_entity_key: response.data.data.start_entity.id,
            startObj: response.data.data.start_entity,
            end_entity_name: response.data.data.end_entity.name,
            end_entity_key: response.data.data.end_entity.id,
            endObj: response.data.data.end_entity,
            products: response.data?.data?.items ?? [],
            applied_promotions: response.data?.data?.applied_promotions ?? [],
            modify_on: response.data.data.modified_on.$date,
          },
          () => {
            this.getShopList();
          }
        );
      });
    });
  };

  getOrganizationList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ORGANIZATION}?access_token=${token}&get_connected_orgs=${this.state.get_connected_orgs}&limit=1000`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from organization list", response);
          this.setState({
            orgList: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from org list api", error);
        });
    });
  };

  getShopList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?selected_sub_org_for_drop_off_address=${this.state.sub_org_id}&limit=100&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("shop list api", response);
          this.setState({ dataS: response.data.data });
        })
        .catch((error) => {
          console.log("error from shop list api", error);
        });
    });
  };

  searchPlace = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from place list", response);
          this.setState({
            dataS: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from place list api", error);
        });
    });
  }, 500);

  setOrderStatus = (value) => {
    this.setState({ seq_no: value.key, status_name: value.label }, () => {
      console.log(this.state.status_name);
    });
  };

  setInventory = (value) => {
    this.setState(
      { inventory_name: value.label, inventory_id: value.key },
      () => {
        console.log(this.state.inventory_name);
        this.getProductList();
      }
    );
  };

  setProductCategory = (value) => {
    this.setState({ category_name: value }, () => {
      this.getProductList();
      console.log(this.state.category_name);
    });
  };

  handleSearch = (value) => {
    const result = this.state.orgList.filter((org) => {
      return org.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered: result });
  };

  setReceivedMethod = (value) => {
    this.setState({ received_method: value }, () => {
      console.log(this.state.received_method);
    });
  };
  setRefundedMethod = (value) => {
    this.setState({ refunded_method: value }, () => {
      console.log(this.state.refunded_method);
    });
  };

  setOrderNumber = (evt) => {
    evt.preventDefault();
    this.setState({ order_number: evt.target.value }, () => {
      console.log(this.state.order_number);
    });
  };
  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setDetails = (evt) => {
    evt.preventDefault();
    this.setState({ details: evt.target.value }, () => {
      console.log(this.state.details);
    });
  };
  setEndloc = (evt) => {
    evt.preventDefault();
    this.setState({ end_loc_name: evt.target.value }, () => {
      console.log(this.state.end_loc_name);
    });
  };

  setDropName = (evt) => {
    evt.preventDefault();
    this.setState({ drop_contact_name: evt.target.value }, () => {
      console.log(this.state.drop_contact_name);
    });
  };
  setDropNumber = (evt) => {
    evt.preventDefault();
    this.setState({ drop_contact_no: evt.target.value }, () => {
      console.log(this.state.drop_contact_no);
    });
  };
  setDropEmail = (evt) => {
    evt.preventDefault();
    this.setState({ drop_contact_email: evt.target.value }, () => {
      console.log(this.state.drop_contact_email);
    });
  };
  setPl = (evt) => {
    evt.preventDefault();
    this.setState({ pl: evt.target.value }, () => {
      console.log(this.state.pl);
    });
  };
  setPw = (evt) => {
    evt.preventDefault();
    this.setState({ pw: evt.target.value }, () => {
      console.log(this.state.pw);
    });
  };
  setPWeight = (evt) => {
    evt.preventDefault();
    this.setState({ parcel_wght: evt.target.value }, () => {
      console.log(this.state.parcel_wght);
    });
  };
  setTotalPrice = (evt) => {
    evt.preventDefault();
    this.setState({ total_price: evt.target.value }, () => {
      this.setState({
        bill_amount:
          Number(this.state.total_price) +
          Number(this.state.delivery_charge) +
          Number(this.state.other_charges),
      });
      console.log(this.state.total_price);
    });
  };
  setDeliveryCharge = (evt) => {
    evt.preventDefault();
    this.setState({ delivery_charge: evt.target.value }, () => {
      this.setState({
        bill_amount:
          Number(this.state.total_price) +
          Number(this.state.delivery_charge) +
          Number(this.state.other_charges),
      });
      console.log(this.state.delivery_charge);
    });
  };
  setOtherCharge = (evt) => {
    evt.preventDefault();
    this.setState({ other_charges: evt.target.value }, () => {
      this.setState({
        bill_amount:
          Number(this.state.total_price) +
          Number(this.state.delivery_charge) +
          Number(this.state.other_charges),
      });
      console.log(this.state.other_charges);
    });
  };

  setOtherDiscountType = (value) => {
    this.setState({ other_discount_type: value }, () => {
      console.log(this.state.other_discount_type);
    });
  };

  setOtherDiscount = (evt) => {
    evt.preventDefault();
    this.setState(
      {
        other_discount:
          Number(evt.target.value) <= 0 ? "" : Number(evt.target.value),
      },
      () => {
        console.log(this.state.other_discount);
      }
    );
  };
  calculateDiscount = () => {
    if (this.state.other_discount_type === "fixed_amount") {
      this.setState(
        {
          bill_amount:
            Number(this.state.total_price) - Number(this.state.other_discount),
        },
        () => {
          // this.setState({ bill_amount: this.state.total_price });
        }
      );
    } else {
      let percentValue =
        (this.state.total_price * this.state.other_discount) / 100;
      this.setState(
        {
          bill_amount: Number(this.state.total_price) - Number(percentValue),
        },
        () => {
          // this.setState({ bill_amount: this.state.total_price });
        }
      );
    }
  };

  setBillAmount = (evt) => {
    evt.preventDefault();
    this.setState({ bill_amount: evt.target.value }, () => {
      console.log(this.state.bill_amount);
    });
  };
  setReceivedAmount = (evt) => {
    evt.preventDefault();
    this.setState({ received_amount: evt.target.value }, () => {
      console.log(this.state.received_amount);
      this.setState({
        due_amount: this.state.bill_amount - this.state.received_amount,
      });
    });
  };
  setRefundedAmount = (evt) => {
    evt.preventDefault();
    this.setState({ refunded_amount: evt.target.value }, () => {
      console.log(this.state.refunded_amount);
    });
  };
  setDueAmount = (evt) => {
    evt.preventDefault();
    this.setState({ due_amount: evt.target.value }, () => {
      console.log(this.state.due_amount);
    });
  };
  setPh = (evt) => {
    evt.preventDefault();
    this.setState({ ph: evt.target.value }, () => {
      console.log(this.state.ph);
    });
  };
  setDelivery = (value) => {
    this.setState({ delivery: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivery);
    });
  };
  setDeliverychargeincluded = (value) => {
    this.setState({ delivery_charge_included: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivery_charge_included);
    });
  };
  setDelivered = (value) => {
    this.setState({ delivered: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivered);
    });
  };
  // setRescheduled = value => {
  //   this.setState({ rescheduled: CHECK_BOOL(value) }, () => {
  //     console.log(this.state.rescheduled);
  //   });
  // };
  // setReceived = value => {
  //   this.setState({ received: CHECK_BOOL(value) }, () => {
  //     console.log(this.state.received);
  //   });
  // };
  setDeliveryTimeFlexible = (value) => {
    this.setState({ delivery_time_flexible: CHECK_BOOL(value) }, () => {
      console.log(this.state.delivery_time_flexible);
    });
  };
  setAssignedOrg = (value) => {
    this.setState({
      assigned_org_name: value.label,
      assigned_org_id: value.key,
    });
  };
  setDim = (evt) => {
    evt.preventDefault();
    this.setState({ unit_dim: evt.target.value }, () => {
      console.log(this.state.unit_dim);
    });
  };
  setWeight = (evt) => {
    evt.preventDefault();
    this.setState({ unit_weight: evt.target.value }, () => {
      console.log(this.state.unit_weight);
    });
  };
  setMaxweight = (evt) => {
    evt.preventDefault();
    this.setState({ max_weight: evt.target.value }, () => {
      console.log(this.state.max_weight);
    });
  };

  setFragile = (value) => {
    this.setState({ fragile: CHECK_BOOL(value) }, () => {
      console.log(this.state.fragile);
    });
  };
  setSensitive = (value) => {
    this.setState({ sensitive: CHECK_BOOL(value) }, () => {
      console.log(this.state.sensitive);
    });
  };
  setFast = (value) => {
    this.setState({ fast: CHECK_BOOL(value) }, () => {
      console.log(this.state.fast);
    });
  };
  setUcp = (value) => {
    this.setState({ ucp: CHECK_BOOL(value) }, () => {
      console.log(this.state.ucp);
    });
  };
  setTemperature = (evt) => {
    this.setState({ temperature: evt.target.value }, () => {
      console.log(this.state.temperature);
    });
  };
  setStart = (value) => {
    this.setState(
      { start_entity_name: value.label, start_entity_key: value.key },
      () => {
        this.getShopList();
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_ENTITY}${this.state.start_entity_key}?access_token=${token}`;
          axios({
            method: "get",
            url: url,
          }).then((response) => {
            console.log("response fro edit entity", response);
            this.setState({
              startObj: {
                name: response.data.data.name,
                id: response.data.data._id.$oid,
                entity_type: response.data.data.entity_type,
                loc: response.data.data.loc,
                address: response.data.data.address,
                city: response.data.data.city,
                country: response.data.data.country,
                post_code: response.data.data.post_code,
                organization: response.data.data.organization.$oid,
              },
            });
          });
        });
      }
    );
  };
  setEnd = (value) => {
    this.setState(
      { end_entity_name: value.label, end_entity_key: value.key },
      () => {
        this.getShopList();
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_ENTITY}${this.state.end_entity_key}?access_token=${token}`;
          axios({
            method: "get",
            url: url,
          }).then((response) => {
            console.log("response fro edit entity", response);
            if (response.data.data.hasOwnProperty("contact")) {
              this.setState({
                drop_contact_name: response.data?.data?.contact?.name ?? "",
                drop_contact_no: response.data?.data?.contact?.mobile ?? "",
                drop_contact_email: response.data?.data?.contact?.email ?? "",
              });
            }
            this.setState({
              endObj: {
                name: response.data.data.name,
                id: response.data.data._id.$oid,
                entity_type: response.data.data.entity_type,
                loc: response.data.data.loc,
                address: response.data.data.address,
                city: response.data.data.city,
                country: response.data.data.country,
                post_code: response.data.data.post_code,
                organization: response.data.data.organization.$oid,
              },
              end_loc: response.data.data.loc,
              end_loc_name: response.data.data.address,
              name: `${this.state.order_number}-${response.data.data.name}`,
            });
          });
        });
      }
    );
  };
  setTime = (value, dateString) => {
    this.setState(
      {
        delivery_date_time: moment(value).format("x"),
        delivery_date_time_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.delivery_date_time);
        console.log("Selected value: ", this.state.delivery_date_time_view);
      }
    );
  };
  setReceivedTime = (value, dateString) => {
    this.setState(
      {
        received_date: moment(value).format("x"),
        received_date_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.received_date);
        console.log("Selected value: ", this.state.received_date_view);
      }
    );
  };
  setRefundedTime = (value, dateString) => {
    this.setState(
      {
        refunded_date: moment(value).format("x"),
        refunded_date_view: value,
      },
      () => {
        console.log("Selected Time: ", this.state.refunded_date);
        console.log("Selected value: ", this.state.refunded_date_view);
      }
    );
  };
  handleStart = (value) => {
    this.setState({ query: value, dataMap: [], fetching: true }, () => {
      console.log(this.state.query);
      if (this.state.query !== "") {
        setTimeout(() => {
          axios.get(GEO_CODING_API(this.state.query)).then((response) => {
            console.log(response);
            const data = response.data.features.map((res) => ({
              id: res.properties.place_id,
              value: res.properties.display_name,
            }));
            this.setState({ dataMap: data, fetch: false });
            console.log(response.data.features);
          });
        }, 3000);
      }
    });
  };

  handleStartChange = (value) => {
    this.setState({ value, dataMap: [], fetch: false });

    return this.setState({ end_loc_name: value.label }, () => {
      axios.get(GEO_CODING_API(this.state.end_loc_name)).then((response) => {
        this.setState(
          {
            end_location_lat: response.data.features[0].geometry.coordinates[1],
            end_location_lon: response.data.features[0].geometry.coordinates[0],
            center_lon: response.data.features[0].geometry.coordinates[0],
            center_lat: response.data.features[0].geometry.coordinates[1],
          },
          () => {
            this.setState({
              end_loc: {
                coordinates: [
                  this.state.end_location_lon,
                  this.state.end_location_lat,
                ],
              },
            });
          }
        );
      });
    });
  };

  updatePositionStart = (evt) => {
    console.log(evt.target.getLatLng());

    this.setState(
      {
        end_location_lat: evt.target.getLatLng().lat,
        end_location_lon: evt.target.getLatLng().lng,
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat,
      },
      () => {
        this.setState({
          end_loc: {
            coordinates: [
              this.state.end_location_lon,
              this.state.end_location_lat,
            ],
          },
        });
        axios
          .get(
            REVERSE_GEO(
              this.state.end_location_lat,
              this.state.end_location_lon
            )
          )
          .then((response) => {
            let name = response.data.features[0].properties.display_name;
            let id = response.data.features[0].properties.place_id;
            this.setState({
              end_loc_name: name,
              value: { key: id, label: name },
            });
          });
      }
    );
  };

  createPreOrderPromotion = () => {
    let data = {
      order_number: this.state.order_number,
    };

    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_PRE_ORDER_PROMOTIONS}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          console.log("response from create pre order promotion ", response);
          this.getPreOrderAppliedPromotion();
        })
        .catch((error) => {
          console.log("error from create pre order promotion ", error);
        });
    });
  };

  getPreOrderAppliedPromotion = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PRE_ORDER_APPLIED_PROMOTIONS}?order_number=${this.state.order_number}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            applied_promotions: response.data.data[0].applied_promotions,
          });
          console.log("response from pre order applied promotions", response);
        })
        .catch((error) => {
          console.log("error from pre order applied promotions", error);
        });
    });
  };

  getPromotionsForProducts = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PROMOTIONS_FOR_PRODUCT}${this.state.item_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            promotions_for_product:
              response.data?.data?.related_promotions ?? [],
          },()=>{
            if(this.state.item_discount_type==="free_product"){
              let filtered=this.state.applied_promotions.filter(ap=>this.state.promotions_for_product.some(pp=>pp.product_qty_discount_tier.id===ap.applied_tier_row.id))
              this.getFreeProductDetails(filtered[0].applied_tier_row.free_product)
            }
          });
          console.log("response from promotions for product", response);
        })
        .catch((error) => {
          console.log("error from promotions for product", error);
        });
    });
  };

  getFreeProductDetails = (free_products) => {
    let data = {
      inventory_id: this.state.inventory_id,
      free_products: free_products,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${GET_FREE_PROMOTION_PRODUCT}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            promotional_free_items_data_from_api: response.data.free_product_list,
          });
          console.log("response from free product details", response);
        })
        .catch((error) => {
          console.log("error from free product details", error);
        });
    });
  };

  deductPromotion = () => {
    let data = {
      order_number: this.state.order_number,
      client_entity_id: this.state.end_entity_key,
      applied_promotion: this.state.applied_promotion,
    };

    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${DEDUCT_APPLIED_PROMOTION}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          console.log("response from deduct promotion ", response);
          this.isPromotionApplicable();
          this.getPreOrderAppliedPromotion();
        })
        .catch((error) => {
          console.log("error from deduct promotion ", error);
        });
    });
  };
  isPromotionApplicable = () => {
    let data = {
      order_number: this.state.order_number,
      client_entity_id: this.state.end_entity_key,
      applied_promotion: this.state.new_apply_promotion,
    };

    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${IS_PROMOTION_APPLICABLE}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          console.log("response from new apply promotion promotion ", response);
          this.setState({ new_apply_promotion: {} });
          this.getPreOrderAppliedPromotion();
        })
        .catch((error) => {
          console.log("error from new apply promotion promotion ", error);
        });
    });
  };
  updateOrder = () => {
    if (
      this.state.name === "" ||
      this.state.drop_contact_name === "" ||
      this.state.drop_contact_no === "" ||
      this.state.end_loc === null ||
      this.state.end_loc_name === null ||
      this.state.delivery_date_time === "" ||
      this.state.total_price === null
    ) {
      swal.fire("Info", "Please enter all informations", "info");
    } else {
      let data = {
        current_loc: {
          coordinates: [this.state.current_loc_lon, this.state.current_loc_lat],
        },
        current_loc_name: this.state.current_loc_name,
        order_status: {
          name: this.state.status_name,
          seq_no: Number(this.state.seq_no),
        },
        order_number: this.state.order_number,
        name: this.state.name,
        details: this.state.details,
        sub_org: this.state.sub_org_id,
        drop_contact_name: this.state.drop_contact_name,
        drop_contact_no: this.state.drop_contact_no,
        drop_contact_email: this.state.drop_contact_email,
        start_entity: this.state.startObj,
        end_entity: this.state.endObj,
        end_loc: this.state.end_loc,
        end_loc_name: this.state.end_loc_name,
        parcel_dim: {
          pl: Number(this.state.pl),
          pw: Number(this.state.pw),
          ph: Number(this.state.ph),
        },
        unit_dim: Number(this.state.unit_dim),
        unit_weight: Number(this.state.unit_weight),
        parcel_weight: Number(this.state.parcel_weight),
        max_weight: Number(this.state.max_weight),
        fragile: this.state.fragile,
        temperature: {
          sensitive: this.state.sensitive,
          temperature: Number(this.state.temperature),
          fast: this.state.fast,
        },
        ucp: this.state.ucp,
        assigned_org: this.state.assigned_org_id,
        assigned_org_name: this.state.assigned_org_name,
        parcel_wght: Number(this.state.parcel_wght),
        total_price: Number(this.state.total_price),
        delivery_charge: Number(this.state.delivery_charge),
        delivery_charge_included: this.state.delivery_charge_included,
        other_charges: Number(this.state.other_charges),
        other_discount_type: this.state.other_discount_type,
        other_discount: Number(this.state.other_discount),
        bill_amount: Number(this.state.bill_amount),
        received_amount: Number(this.state.received_amount),
        received_method: this.state.received_method,
        received_date: this.state.received_date,
        refunded_amount: Number(this.state.refunded_amount),
        refunded_method: this.state.refunded_method,
        refunded_date: this.state.refunded_date,
        due_amount: Number(this.state.due_amount),
        // received: this.state.received,
        delivery: this.state.delivery,
        delivered: this.state.delivered,
        delivery_time_flexible: this.state.delivery_time_flexible,
        delivery_date_time: this.state.delivery_date_time,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_ORDER_WITH_PROMOTION}${this.state.order_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({
              selected_items: [],
              received_amount: 0,
              refunded_amount: 0,
            });
            swal.fire("Info", response.data.message, "info");
            this.getOrderDetails();
            console.log("response from update order", response);
          })
          .catch((error) => {
            // this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            this.getOrderDetails();
            console.log("error from update order", error);
          });
      });
    }
  };

  calculateTotalValues = () => {
    let final = this.state.data.filter((i) => i.qty !== 0);
    final.map((i) => {
      let total = i.discounted_price;
      let total_before_discount = i.price;
      let total_discount = i.discount;
      this.setState(
        (prevState) => ({
          total_price: prevState.total_price + total,
          total_price_before_discount:
            prevState.total_price_before_discount + total_before_discount,
          total_discount: prevState.total_discount + total_discount,
        }),
        () => {
          this.setState({
            bill_amount:
              Number(this.state.total_price) +
              Number(this.state.delivery_charge) +
              Number(this.state.other_charges),
          });
          console.log("total price", this.state.total_price);
        }
      );

      return null;
    });
  };

  updateItem = () => {
    this.setState({ order_item_edit_modal: false });
    const data = {
      items: {
        promotional_items: this.state.promotional_items,

        non_promotional_items: this.state.non_promotional_items,

        free_items: this.state.free_items,
      },
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ORDER_ITEM_UPDATE}${this.state.order_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit bill item", response);

          this.getOrderDetails();
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");

          console.log("error from edit order", error);
        });
    });
  };

  setnonPromotionalItems = () => {
    let obj = {
      item_id: this.state.item_id,
      item: this.state.item_name,
      purchase_qty: this.state.item_purchase_qty,
      free_qty: 0,
      sku: this.state.item_sku,
      inventory_id: this.state.item_inventory_id,
      unit_selling_price: this.state.item_unit_selling_price,
      unit_purchase_price: this.state.item_unit_purchase_price,
      mrp: this.state.item_mrp,
      unit: this.state.item_unit,
      parent_product_id: this.state.item_parent_id,
      item_discount_type: this.state.item_discount_type,
      discount: 0,
      item_discount: 0,
      returned: this.state.item_returned,
      return_item_qty: this.state.return_item_qty,
    };
    let arr = [];
    arr.push(obj);
    this.setState({ non_promotional_items: arr }, () => {
      console.log("non promo items", this.state.non_promotional_items);
    });
  };

  setPromotionalItems = () => {
    let obj = {
      item_id: this.state.promotional_item_id,
      item: this.state.promotional_item_name,
      purchase_qty: this.state.promotional_item_purchase_qty,
      free_qty: 0,
      sku: this.state.promotional_item_sku,
      inventory_id: this.state.promotional_item_inventory_id,
      unit_selling_price: this.state.promotional_item_unit_selling_price,
      unit_purchase_price: this.state.promotional_item_unit_purchase_price,
      mrp: this.state.promotional_item_mrp,
      unit: this.state.promotional_item_unit,
      parent_product_id: this.state.promotional_item_parent_id,
      item_discount_type: this.state.promotional_item_discount_type,
      discount: this.state.promotional_item_raw_discount,
      item_discount: this.state.promotional_item_discount,
      returned: this.state.promotional_item_returned,
      return_item_qty: this.state.promotional_return_item_qty,
    };

    let arr = [];
    arr.push(obj);
    this.setState({ promotional_items: arr }, () => {
      console.log("promo items", this.state.promotional_items);
    });
  };

  setFreeItems = () => {
    const item=this.state.promotional_free_items_data_from_api[0]
      let obj = {
        item_id: item.item_id,
        item: item.item,
        purchase_qty: 0,
        free_qty: this.state.free_item_free_qty,
        sku: item.sku,
        inventory_id: this.state.inventory_id,
        unit_selling_price: item.unit_selling_price,
        unit_purchase_price: item.unit_purchase_price,
        mrp: item.mrp,
        unit: item.unit,
        parent_product_id: item.parent_product_id,
        item_discount_type: "free_product",
        discount: 0,
        item_discount: 0,
        returned: false,
        return_item_qty: 0,
      };
      let arr = [];
      arr.push(obj);
      this.setState({ free_items:arr }, () => {
        console.log("free items", this.state.free_items);
      });
    
    
  };

  deleteOrderItem = (id) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = ` ${ORDER_ITEM_UPDATE}${this.state.order_id}?item_id=${id}&access_token=${token}`;
      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          swal.fire("Success", response.data.message, "success");
          this.getOrderDetails();
          console.log("deleted", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  getItemDiscount = (record) => {
    const item_discount =
      record.product_qty_discount_tier.discount_type === "percentage"
        ? (this.state.promotional_item_unit_selling_price *
            this.state.promotional_item_purchase_qty *
            record.product_qty_discount_tier.discount) /
          100
        : record.product_qty_discount_tier.discount *
          (this.state.promotional_item_purchase_qty /
            record.product_qty_discount_tier.buy_product[0].product_qty);
    return item_discount;
  };

  render() {
    const applied_promotions = this.state.applied_promotions;
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => this.props.history.goBack()}
          title="Update Order"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <Card
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  marginRight: 10,
                }}
              >
                <Form.Item>
                  <Text>Order Number</Text>
                  <Input
                    placeholder="Order Number"
                    type="text"
                    value={this.state.order_number}
                    onChange={this.setOrderNumber}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Name</Text>
                  <Input
                    placeholder="Name"
                    type="text"
                    value={this.state.name}
                    onChange={this.setName}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Details</Text>
                  <TextArea
                    rows={3}
                    placeholder="Details"
                    type="text"
                    value={this.state.details}
                    onChange={this.setDetails}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Order Status</Text>
                  <Select
                    value={{
                      key: this.state.seq_no,
                      label: this.state.status_name,
                    }}
                    labelInValue
                    onChange={this.setOrderStatus}
                  >
                    {this.state.active_order_statuses.map((r) => {
                      return <Option key={r.seq_no}>{r.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Text>Select Sub Organization</Text>
                  <Select
                    style={{ width: "100%" }}
                    labelInValue
                    value={{
                      key: this.state.sub_org_id,
                      label: this.state.sub_org_name,
                    }}
                    disabled={true}
                    onChange={this.setSubOrg}
                    placeholder="Select Sub Organization"
                  >
                    {this.state.assigned_user_sub_orgs.map((r) => {
                      return <Option key={r.id}>{`${r.name}`}</Option>;
                    })}
                  </Select>
                </Form.Item>
                {/* <Form.Item>
                  <Text> Search Assigned Organization</Text>
                  <Select
                    labelInValue
                    disabled={GET_USER_LOCAL().org_admin ? false : true}
                    value={{
                      key: this.state.assigned_org_id,
                      label: this.state.assigned_org_name
                    }}
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue={false}
                    onSearch={this.handleSearch}
                    onChange={this.setAssignedOrg}
                    placeholder="Assigned Organization"
                  >
                    {this.state.filtered.length === 0 ? OrgList : FliteredList}
                  </Select>
                </Form.Item> */}
                <Form.Item>
                  <Text>
                    Search Pickup Location (Shop, Office, Factory etc.){" "}
                    <Button
                      type="link"
                      onClick={() => this.setState({ showEntity: true })}
                    >
                      <Icon type="plus" style={{ fontSize: 20 }} />
                    </Button>
                  </Text>
                  <Select
                    labelInValue
                    showSearch={true}
                    filterOption={false}
                    showArrow={false}
                    autoClearSearchValue={true}
                    onSearch={this.searchPlace}
                    value={{
                      key: this.state.start_entity_key,
                      label: this.state.start_entity_name,
                    }}
                    onChange={this.setStart}
                    placeholder="Select Start Place"
                  >
                    {this.state.dataS.map((r) => {
                      return (
                        <Option
                          key={r._id.$oid}
                        >{`Name : ${r.name} >>> Address : ${r.address} >>> Type : ${r.entity_type}`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                {this.props.userProfileData.order_and_delivery.order
                  .order_detail.order_location_update ? (
                  <Form.Item>
                    <Text>
                      Search Delivery Location (Shop, Office, Factory etc.){" "}
                      <Button
                        type="link"
                        onClick={() => this.setState({ showEntity: true })}
                      >
                        <Icon type="plus" style={{ fontSize: 20 }} />
                      </Button>
                    </Text>
                    <Select
                      labelInValue
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue={true}
                      onSearch={this.searchPlace}
                      value={{
                        key: this.state.end_entity_key,
                        label: this.state.end_entity_name,
                      }}
                      onChange={this.setEnd}
                      placeholder="Select End Place"
                    >
                      {this.state.dataS.map((r) => {
                        return (
                          <Option
                            key={r._id.$oid}
                          >{`Name : ${r.name} >>> Address : ${r.address} >>> Type : ${r.entity_type}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : null}
                {this.props.userProfileData.order_and_delivery.order
                  .order_detail.order_location_update ? (
                  <Form.Item>
                    <Text>
                      Delivery Location Address{" "}
                      <Button
                        type="link"
                        onClick={() => this.setState({ showMap: true })}
                      >
                        Select from Map
                      </Button>
                    </Text>
                    <TextArea
                      rows={3}
                      placeholder="End Location Address"
                      type="text"
                      value={this.state.end_loc_name}
                      onChange={this.setEndloc}
                    />
                  </Form.Item>
                ) : null}

                <Form.Item>
                  <Text>Drop Contact Name</Text>
                  <Input
                    placeholder="Drop Contact Name"
                    type="text"
                    value={this.state.drop_contact_name}
                    onChange={this.setDropName}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Drop Contact Number</Text>
                  <Input
                    placeholder="Example : +8801711123456"
                    type="text"
                    value={this.state.drop_contact_no}
                    onChange={this.setDropNumber}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Drop Contact Email</Text>
                  <Input
                    placeholder="example@example.com"
                    type="text"
                    value={this.state.drop_contact_email}
                    onChange={this.setDropEmail}
                  />
                </Form.Item>
                <Form.Item>
                  <Card style={{ flex: 1 }}>
                    <Table
                      dataSource={this.state.products}
                      pagination={false}
                      rowKey={() => randomId(10)}
                      size="small"
                    >
                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Product</Text>
                            </div>
                          </>
                        }
                        dataIndex="item"
                        key="product"
                      />
                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Unit</Text>
                            </div>
                          </>
                        }
                        dataIndex="unit"
                        key="unit"
                      />
                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Unit Price</Text>
                            </div>
                          </>
                        }
                        dataIndex="unit_selling_price"
                        key="unit_selling_price"
                      />
                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Discount Type</Text>
                            </div>
                          </>
                        }
                        dataIndex="item_discount_type"
                        key="item_discount_type"
                      />
                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Discount</Text>
                            </div>
                          </>
                        }
                        dataIndex="discount"
                        key="discount"
                      />
                      {/* <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Per Item Discount</Text>
                            </div>
                          </>
                        }
                        // dataIndex="unit_item_discount"
                        key="unit_item_discount"
                        render={(record) => (
                          <Text>
                            {record.item_discount_type === "amount"
                              ? record.unit_item_discount
                              : record.item_discount_type === "percentage"
                              ? `${record.unit_item_discount}%`
                              : 0}
                          </Text>
                        )}
                      /> */}
                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Purchase Quantity</Text>
                            </div>
                          </>
                        }
                        key="purchase_qty"
                        dataIndex="purchase_qty"
                      />
                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Free Quantity</Text>
                            </div>
                          </>
                        }
                        key="free_qty"
                        dataIndex="free_qty"
                      />
                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Promotional Quantity</Text>
                            </div>
                          </>
                        }
                        key="qty"
                        dataIndex="promotional_qty"
                      />

                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Price</Text>
                            </div>
                          </>
                        }
                        dataIndex="price"
                        key="price"
                      />

                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Discounted Price</Text>
                            </div>
                          </>
                        }
                        dataIndex="item_discounted_price"
                        key="item_discounted_price"
                      />

                      <Column
                        title={
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Text ellipsis={true}>Action</Text>
                            </div>
                          </>
                        }
                        key="action"
                        render={(text, record) => (
                          <span>
                            {this.props.userProfileData.order_and_delivery.order
                              .order_detail.order_product_status_update ? (
                              <Button
                                type="link"
                                style={{ color: "green" }}
                                onClick={() => {
                                  window.open(
                                    `https://web.conveyance.app/OrderItemDetails/${this.state.order_id}/${record.item_id}`
                                  );
                                }}
                              >
                                Update Status
                              </Button>
                            ) : null}
                            {this.props.userProfileData.order_and_delivery.order
                              .order_item_update ? (
                              <Button
                                type="link"
                                style={{ color: "green" }}
                                onClick={() =>
                                  this.setState(
                                    {
                                      item_price: record.price ?? 0,
                                      parent_product_id:
                                        record.parent_product_id ?? "",
                                      item_name: record.item ?? "",
                                      item_unit: record.unit ?? "",
                                      item_unit_selling_price:
                                        record.unit_selling_price ?? 0,
                                      item_sku: record.sku ?? "",
                                      item_unit_purchase_price:
                                        record.unit_purchase_price ?? 0,
                                      item_mrp: record.mrp ?? 0,
                                      item_inventory_id:
                                        record.inventory_id ?? "",
                                      item_purchase_qty:
                                        record.purchase_qty -
                                          record.promotional_qty ?? 0,
                                      item_free_qty: record.free_qty ?? 0,
                                      item_id: record.item_id ?? "",
                                      item_parent_id:
                                        record.parent_product_id ?? "",
                                      item_discount_type:
                                        record.item_discount_type ?? "amount",
                                      item_discount: record.item_discount ?? 0,
                                      item_raw_discount: record.discount ?? 0,
                                      item_returned: record.returned ?? false,
                                      return_item_qty:
                                        record.return_item_qty ?? 0,
                                      promotional_qty:
                                        record.promotional_qty ?? 0,
                                    },
                                    () => {
                                      if (record.promotional_qty > 0) {
                                        this.setState(
                                          {
                                            promotional_item_price:
                                              this.state.item_price,
                                            promotional_item_name:
                                              this.state.item_name,
                                            promotional_item_unit:
                                              this.state.item_unit,
                                            promotional_item_unit_selling_price:
                                              this.state
                                                .item_unit_selling_price,
                                            promotional_item_sku:
                                              this.state.item_sku,
                                            promotional_item_unit_purchase_price:
                                              this.state
                                                .item_unit_purchase_price,
                                            promotional_item_mrp:
                                              this.state.item_mrp,
                                            promotional_item_inventory_id:
                                              this.state.item_inventory_id,
                                            promotional_item_purchase_qty:
                                              this.state.promotional_qty,
                                            promotional_item_free_qty:
                                              this.state.item_free_qty,
                                            promotional_item_id:
                                              this.state.item_id,
                                            promotional_item_parent_id:
                                              this.state.item_parent_id,
                                            promotional_item_discount_type:
                                              this.state.item_discount_type,
                                            promotional_item_discount:
                                              this.state.item_discount,
                                            promotional_item_raw_discount:
                                              this.state.item_raw_discount,
                                            promotional_item_returned:
                                              this.state.item_returned,
                                            promotional_return_item_qty:
                                              this.state.return_item_qty,
                                            free_item_free_qty: this.state.item_free_qty,
                                          },
                                          () => {
                                            this.setPromotionalItems();
                                          }
                                        );
                                      }

                                      this.setnonPromotionalItems();
                                      this.getPromotionsForProducts();
                                      this.setState({
                                        order_item_edit_modal: true,
                                      });
                                    }
                                  )
                                }
                              >
                                Edit
                              </Button>
                            ) : null}
                            {this.props.userProfileData.order_and_delivery.order
                              .order_item_update ? (
                              <Button
                                type="link"
                                style={{ color: "red" }}
                                onClick={() =>
                                  this.deleteOrderItem(record.item_id)
                                }
                              >
                                Delete
                              </Button>
                            ) : null}
                          </span>
                        )}
                      />
                    </Table>
                    <Text
                      style={{ margin: 5 }}
                    >{`Total Price : ${this.state.total_price}`}</Text>
                  </Card>
                </Form.Item>
                <Form.Item>
                  <Text>Total Before Discount *</Text>
                  <Input
                    placeholder="Total Before Discount"
                    type="text"
                    disabled={true}
                    value={this.state.total_price_before_discount}
                    // onChange={this.setTotalPrice}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Total Product Discount *</Text>
                  <Input
                    placeholder="Total Discount"
                    type="text"
                    disabled={true}
                    value={this.state.total_discount}
                    // onChange={this.setTotalPrice}
                  />
                </Form.Item>
                
                   <Card>
                   <Form.Item>
                     <Text>Other Discount Type</Text>
                     <Select
                        disabled={this.state.other_discount_category==="manual"?false:true}
                       value={this.state.other_discount_type}
                       onChange={this.setOtherDiscountType}
                     >
                       {this.state.discount_type_choices.map((r) => {
                         return <Option key={r}>{r}</Option>;
                       })}
                     </Select>
                   </Form.Item>
 
                   <Form.Item>
                     <Text>Other Discount (Will be applied on total price)</Text>
                     <Input
                      disabled={this.state.other_discount_category==="manual"?false:true}
                       placeholder="Other Discount"
                       type="number"
                       value={this.state.other_discount}
                       onChange={this.setOtherDiscount}
                     />
                     <Button type="primary" onClick={this.calculateDiscount}>
                       Add
                     </Button>
                   </Form.Item>
                 </Card>
               
               
                <Form.Item>
                  <Text>Total Price</Text>
                  <Input
                    placeholder="Total Price"
                    disabled={true}
                    type="text"
                    value={this.state.total_price}
                    onChange={this.setTotalPrice}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Delivery Charge Included</Text>
                  <Select
                    value={this.state.delivery_charge_included.toString()}
                    onChange={this.setDeliverychargeincluded}
                  >
                    <Option key={true}>True</Option>
                    <Option key={false}>False</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Text>Delivery Charge</Text>
                  <Input
                    placeholder="Delivery Charge"
                    disabled={!this.state.delivery_charge_included}
                    type="text"
                    value={this.state.delivery_charge}
                    onChange={this.setDeliveryCharge}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Other Charges</Text>
                  <Input
                    placeholder="Other Charges"
                    type="text"
                    value={this.state.other_charges}
                    onChange={this.setOtherCharge}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Bill Amount</Text>
                  <Input
                    placeholder="Bill Amount"
                    type="text"
                    disabled={true}
                    value={this.state.bill_amount}
                    onChange={this.setBillAmount}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>
                    Total Received Amount
                    {this.props.userProfileData.order_and_delivery.order
                      .order_detail.add_received_amount ? (
                      <Button
                        type="link"
                        onClick={() =>
                          this.setState({ show_received_modal: true })
                        }
                      >
                        Add Received Amount
                      </Button>
                    ) : null}
                  </Text>
                  <Input
                    placeholder="Total Received Amount"
                    type="text"
                    value={this.state.total_received_amount}
                    disabled
                  />
                </Form.Item>

                <Form.Item>
                  <Text>Total Item Return Amount</Text>
                  <Input
                    placeholder="Total Item Return Amount"
                    type="text"
                    value={this.state.total_refund}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Refundable Amount</Text>
                  <Input
                    placeholder="Refundable Amount"
                    type="text"
                    value={this.state.refundable_amount}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Due Amount</Text>
                  <Input
                    placeholder="Due Amount"
                    type="text"
                    value={this.state.due_amount}
                    onChange={this.setDueAmount}
                    disabled
                  />
                </Form.Item>
                <Form.Item>
                  <Text>
                    Total Refunded Amount
                    {this.props.userProfileData.order_and_delivery.order
                      .order_detail.add_refund_amount ? (
                      <Button
                        type="link"
                        disabled={
                          this.state.total_received_amount +
                            Number(this.state.received_amount) >
                            0 && this.state.refundable_amount > 0
                            ? false
                            : true
                        }
                        onClick={() =>
                          this.setState({ show_refund_modal: true })
                        }
                      >
                        Add Refunded Amount
                      </Button>
                    ) : null}
                  </Text>
                  <Input
                    placeholder="Total Refunded Amount"
                    type="text"
                    value={this.state.total_refunded_amount}
                    disabled
                  />
                </Form.Item>
                {this.props.userProfileData.order_and_delivery.order
                  .order_detail.change_delivery_time ? (
                  <Form.Item>
                    <div
                      style={{
                        marginBottom: 5,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Text>Delivery On</Text>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          inputVariant="outlined"
                          value={moment(this.state.delivery_date_time_view)}
                          onChange={this.setTime}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Form.Item>
                ) : null}

                <Form.Item>
                  <Text>UCP</Text>
                  <Select
                    value={this.state.ucp.toString()}
                    onChange={this.setUcp}
                  >
                    <Option key={true}>True</Option>
                    <Option key={false}>False</Option>
                  </Select>
                </Form.Item>
              </Card>
              {this.state.ucp ? (
                <Card
                  style={{ display: "flex", flex: 1, flexDirection: "column" }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Form.Item style={{ marginRight: 5 }}>
                      <Text>Parcel Length</Text>
                      <Input
                        placeholder="Parcel Length"
                        type="text"
                        value={this.state.pl}
                        onChange={this.setPl}
                      />
                    </Form.Item>
                    <Form.Item style={{ marginRight: 5 }}>
                      <Text>Parcel Width</Text>
                      <Input
                        placeholder="Parcel Width"
                        type="text"
                        value={this.state.pw}
                        onChange={this.setPw}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Text>Parcel Height</Text>
                      <Input
                        placeholder="Parcel Height"
                        type="text"
                        value={this.state.ph}
                        onChange={this.setPh}
                      />
                    </Form.Item>
                  </div>
                  <Form.Item>
                    <Text>Parcel Weight</Text>
                    <Input
                      placeholder="Parcel Weight"
                      type="text"
                      value={this.state.parcel_wght}
                      onChange={this.setPWeight}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Unit Dimension</Text>
                    <Input
                      placeholder="Unit Dimension"
                      type="text"
                      value={this.state.unit_dim}
                      onChange={this.setDim}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Text>Unit Weight (In Kilogram)</Text>
                    <Input
                      placeholder="Unit Weight"
                      type="text"
                      value={this.state.unit_weight}
                      onChange={this.setWeight}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Unit Maximum Weight (In Kilogram)</Text>
                    <Input
                      placeholder="Unit Maximum Weight"
                      type="text"
                      value={this.state.max_weight}
                      onChange={this.setMaxweight}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Temperature</Text>
                    <Input
                      placeholder="Temperature"
                      type="text"
                      value={this.state.temperature}
                      onChange={this.setTemperature}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Text>Fragile</Text>
                    <Select
                      value={this.state.fragile.toString()}
                      onChange={this.setFragile}
                    >
                      <Option key={true}>True</Option>
                      <Option key={false}>False</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Text>Sensitive</Text>
                    <Select
                      value={this.state.sensitive.toString()}
                      onChange={this.setSensitive}
                    >
                      <Option key={true}>True</Option>
                      <Option key={false}>False</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Text>Fast</Text>
                    <Select
                      value={this.state.fast.toString()}
                      onChange={this.setFast}
                    >
                      <Option key={true}>True</Option>
                      <Option key={false}>False</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Text>Delivery</Text>
                    <Select
                      defaultValue={this.state.delivery.toString()}
                      onChange={this.setDelivery}
                    >
                      <Option key={true}>True</Option>
                      <Option key={false}>False</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Text>Delivered</Text>
                    <Select
                      defaultValue={this.state.delivered.toString()}
                      onChange={this.setDelivered}
                    >
                      <Option key={true}>True</Option>
                      <Option key={false}>False</Option>
                    </Select>
                  </Form.Item>
                  {/* <Form.Item>
                    <Text>Received</Text>
                    <Select
                      defaultValue={this.state.received.toString()}
                      onChange={this.setReceived}
                    >
                      <Option key={true}>True</Option>
                      <Option key={false}>False</Option>
                    </Select>
                  </Form.Item> */}
                  <Form.Item>
                    <Text>Delivery Time Flexible</Text>
                    <Select
                      defaultValue="False"
                      onChange={this.setDeliveryTimeFlexible}
                    >
                      <Option key={true}>True</Option>
                      <Option key={false}>False</Option>
                    </Select>
                  </Form.Item>
                  {/* <Form.Item>
                    <Text>Rescheduled</Text>
                    <Select
                      defaultValue={this.state.rescheduled.toString()}
                      onChange={this.setRescheduled}
                    >
                      <Option key={true}>True</Option>
                      <Option key={false}>False</Option>
                    </Select>
                  </Form.Item> */}
                </Card>
              ) : null}
            </div>
            <Modal
              title={this.state.item_name}
              visible={this.state.order_item_edit_modal}
              onOk={() =>
                this.setState(
                  {
                    item_price: Number(
                      this.state.item_unit_selling_price *
                        this.state.item_purchase_qty
                    ),
                  },
                  () => {
                    this.setnonPromotionalItems();
                    this.updateItem();
                  }
                )
              }
              onCancel={() =>
                this.setState({
                  order_item_edit_modal: false,
                  item_price: 0,
                  item_unit_selling_price: 0,
                  item_purchase_qty: 0,
                  item_free_qty: 0,
                  item_name: "",
                  item_unit: "",
                  item_id: "",
                  item_parent_id: "",
                  item_sku: "",
                  item_unit_purchase_price: 0,
                  item_mrp: 0,
                  item_inventory_id: "",
                  item_discount_type: "amount",
                  item_discount: 0,
                  item_returned: false,
                  return_item_qty: 0,
                  promotions_for_product: [],
                })
              }
            >
              <Form
                style={{
                  display: "flex",
                  alignSelf: "center",
                  flexDirection: "column",
                }}
              >
                {this.state.promotional_qty > 0 ? (
                  <Text
                    style={{ color: "#1976D2" }}
                  >{`Promotional Quantity : ${this.state.promotional_qty}`}</Text>
                ) : null}
                {this.state.promotions_for_product.length > 0 ? (
                  <Tag
                    color="#42A5F5"
                    style={{ width: 150 }}
                    onClick={() =>
                      this.setState(
                        {
                          showProductPromotions: true,
                        },
                        () => {
                          this.createPreOrderPromotion();
                        }
                      )
                    }
                  >{`${this.state.promotions_for_product.length} Promotions Available`}</Tag>
                ) : null}
                {/* <Form.Item>
                  <Text>Item Discount Type</Text>
                  <Select
                    value={this.state.item_discount_type}
                    onChange={(value) => {
                      this.setState({ item_discount_type: value }, () => {
                        console.log(this.state.item_discount_type);
                      });
                    }}
                  >
                    {this.state.discount_type_choices.map((r) => {
                      return <Option key={r}>{r}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Text>Item Discount</Text>
                  <Input
                    placeholder="Item Discount"
                    type="number"
                    value={this.state.unit_item_discount}
                    onChange={(evt) => {
                      evt.preventDefault();
                      this.setState(
                        { unit_item_discount: Number(evt.target.value) },
                        () => {
                          console.log(this.state.unit_item_discount);
                        }
                      );
                    }}
                  />
                </Form.Item> */}
                <Form.Item>
                  <Text>Updated Quantity</Text>
                  <Input
                    disabled={
                      this.state.status_name === "order_delivered" ||
                      this.state.status_name === "order_completed" ||
                      this.state.status_name === "order_cancelled" ||
                      this.state.status_name ===
                        "order_cancelled_by_organization"
                        ? true
                        : false
                    }
                    placeholder="Quantity"
                    type="number"
                    value={this.state.item_purchase_qty}
                    onChange={(evt) => {
                      if (Number(evt.target.value) < 1) {
                        swal.fire(
                          "info",
                          "Use delete button to remove item",
                          "info"
                        );
                      } else {
                        this.setState(
                          {
                            item_purchase_qty: Number(evt.target.value),
                          },
                          () => this.setnonPromotionalItems()
                        );
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Text>Item Returned</Text>
                  <Select
                    value={this.state.item_returned.toString()}
                    disabled={
                      this.state.status_name === "order_delivered" ||
                      this.state.status_name === "order_completed" ||
                      this.state.status_name === "order_cancelled" ||
                      this.state.status_name ===
                        "order_cancelled_by_organization"
                        ? false
                        : true
                    }
                    onChange={(value) => {
                      this.setState(
                        { item_returned: CHECK_BOOL(value) },
                        () => {
                          this.setnonPromotionalItems();
                          console.log(this.state.item_returned);
                        }
                      );
                    }}
                  >
                    <Option key={true}>True</Option>
                    <Option key={false}>False</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Text>Returned Item Quantity</Text>
                  <Input
                    disabled={
                      this.state.status_name === "order_delivered" ||
                      this.state.status_name === "order_completed" ||
                      this.state.status_name === "order_cancelled" ||
                      this.state.status_name ===
                        "order_cancelled_by_organization"
                        ? false
                        : true
                    }
                    placeholder="Returned Item Quantity"
                    type="number"
                    value={this.state.return_item_qty}
                    onChange={(evt) =>
                      this.setState(
                        {
                          return_item_qty: Number(evt.target.value),
                        },
                        () => this.setnonPromotionalItems()
                      )
                    }
                  />
                </Form.Item>
              </Form>
            </Modal>
            <Modal
              title="Product Promotions"
              visible={this.state.showProductPromotions}
              footer={null}
              onCancel={() => {
                this.setState({ showProductPromotions: false });
              }}
              width={window.innerWidth - 200}
              closable={true}
            >
              <Table
                dataSource={this.state.promotions_for_product}
                pagination={false}
                rowKey={(record) => record.product_qty_discount_tier.id}
                size="small"
              >
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Name</Text>
                      </div>
                    </>
                  }
                  key="item_name"
                  render={(record) => (
                    <Text>
                      {
                        record.product_qty_discount_tier.buy_product[0]
                          .product_name
                      }
                    </Text>
                  )}
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Price</Text>
                      </div>
                    </>
                  }
                  key="item_price"
                  render={(record) => (
                    <Text>{this.state.item_unit_selling_price}</Text>
                  )}
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Discount</Text>
                      </div>
                    </>
                  }
                  key="discount"
                  render={(record) => (
                    <Text>{record.product_qty_discount_tier.discount}</Text>
                  )}
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Discount Type</Text>
                      </div>
                    </>
                  }
                  key="discount_type"
                  render={(record) => (
                    <Text>
                      {record.product_qty_discount_tier.discount_type}
                    </Text>
                  )}
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Ordered Price</Text>
                      </div>
                    </>
                  }
                  key="ordered_price"
                  render={(record) => (
                    <Text>
                      {applied_promotions.some(
                        (ap) =>
                          ap.applied_tier_row.id ===
                          record.product_qty_discount_tier.id
                      ) ? (
                        <Text>
                          {this.state.promotional_item_unit_selling_price *
                            this.state.promotional_item_purchase_qty}
                        </Text>
                      ) : (
                        0
                      )}
                    </Text>
                  )}
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Discounted Price</Text>
                      </div>
                    </>
                  }
                  key="discounted_price"
                  render={(record) => (
                    <Text>
                      {applied_promotions.some(
                        (ap) =>
                          ap.applied_tier_row.id ===
                          record.product_qty_discount_tier.id
                      ) ? (
                        <Text>
                          {record.product_qty_discount_tier.discount_type ===
                          "fixed_amount"
                            ? this.state.promotional_item_unit_selling_price *
                                this.state.promotional_item_purchase_qty -
                              record.product_qty_discount_tier.discount *
                                (this.state.promotional_item_purchase_qty /
                                  record.product_qty_discount_tier
                                    .buy_product[0].product_qty)
                            : this.state.promotional_item_unit_selling_price *
                                this.state.promotional_item_purchase_qty -
                              (record.product_qty_discount_tier.discount *
                                (this.state.promotional_item_purchase_qty /
                                  record.product_qty_discount_tier
                                    .buy_product[0].product_qty)) /
                                100}
                        </Text>
                      ) : (
                        0
                      )}
                    </Text>
                  )}
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Minimum Quantity</Text>
                      </div>
                    </>
                  }
                  key="minimum_qty"
                  render={(record) => (
                    <Text>
                      {
                        record.product_qty_discount_tier.buy_product[0]
                          .product_qty
                      }
                    </Text>
                  )}
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Free Quantity</Text>
                      </div>
                    </>
                  }
                  key="free_qty"
                  render={(record) => (
                    <Text>
                      {
                       applied_promotions.some(
                        (ap) =>
                          ap.applied_tier_row.id ===
                          record.product_qty_discount_tier.id
                      )? this.state.free_item_free_qty:0
                      }
                    </Text>
                  )}
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Promotion Applied</Text>
                      </div>
                    </>
                  }
                  key="promotion_applied"
                  render={(record) => (
                    <Text>
                      {applied_promotions.some(
                        (ap) =>
                          ap.applied_tier_row.id ===
                          record.product_qty_discount_tier.id
                      ) ? (
                        <Text style={{ color: "green" }}>Applied</Text>
                      ) : (
                        <Text style={{ color: "red" }}>Not Applied</Text>
                      )}
                    </Text>
                  )}
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Promotional quantity</Text>
                      </div>
                    </>
                  }
                  key="promotional_quantity"
                  render={(record) => (
                    <Text>
                      {applied_promotions.some(
                        (ap) =>
                          ap.applied_tier_row.id ===
                          record.product_qty_discount_tier.id
                      ) ? (
                        <div>
                          <Button
                            style={{
                              color: "red",
                            }}
                            type="link"
                            onClick={() => {
                              if (
                                this.state.promotional_item_purchase_qty -
                                  record.product_qty_discount_tier
                                    .buy_product[0].product_qty ===
                                0
                              ) {
                                this.setState(
                                  {
                                    promotional_item_purchase_qty:
                                      this.state.promotional_item_purchase_qty -
                                      record.product_qty_discount_tier
                                        .buy_product[0].product_qty,
                                  },
                                  () => {
                                    let filtered = applied_promotions.filter(
                                      (ap) =>
                                        ap.applied_tier_row.id ===
                                        record.product_qty_discount_tier.id
                                    );
                                    console.log("filtered value", filtered);
                                    this.setState(
                                      {
                                        applied_promotion: filtered[0],
                                        promotioal_items: [],
                                      },
                                      () => this.deductPromotion()
                                    );
                                  }
                                );
                              } else if (
                                this.state.promotional_item_purchase_qty -
                                  record.product_qty_discount_tier
                                    .buy_product[0].product_qty >
                                0
                              ) {
                                this.setState(
                                  {
                                    promotional_item_purchase_qty:
                                      this.state.promotional_item_purchase_qty -
                                      record.product_qty_discount_tier
                                        .buy_product[0].product_qty,
                                    promotional_item_raw_discount:
                                      record.product_qty_discount_tier.discount,
                                    promotional_item_discount_type:
                                      record.product_qty_discount_tier
                                        .discount_type,
                                  },
                                  () => {
                                    if(record.product_qty_discount_tier.discount_type==="free_product"){
                                      this.setState({free_item_free_qty:this.state.free_item_free_qty-record.product_qty_discount_tier.free_product[0].product_qty},()=>{
                                        this.setFreeItems();
                                      })
                                      
                                    }
                                    this.setState(
                                      {
                                        promotional_item_discount:
                                          this.getItemDiscount(record),
                                      },
                                      () => this.setPromotionalItems()
                                    );
                                  }
                                );
                              }
                            }}
                          >
                            <Icon type="minus" />
                          </Button>
                          <Text>
                            {this.state.promotional_item_purchase_qty}
                          </Text>
                          <Button
                            style={{
                              color: "red",
                            }}
                            type="link"
                            onClick={() => {
                              this.setState(
                                {
                                  promotional_item_purchase_qty:
                                    this.state.promotional_item_purchase_qty +
                                    record.product_qty_discount_tier
                                      .buy_product[0].product_qty,
                                  promotional_item_raw_discount:
                                    record.product_qty_discount_tier.discount,
                                  promotional_item_discount_type:
                                    record.product_qty_discount_tier
                                      .discount_type,
                                },
                                () => {
                                  if(record.product_qty_discount_tier.discount_type==="free_product"){
                                    this.setState({free_item_free_qty:this.state.free_item_free_qty+record.product_qty_discount_tier.free_product[0].product_qty},()=>{
                                      this.setFreeItems();
                                    })
                                    
                                  }
                                  this.setState(
                                    {
                                      promotional_item_discount:
                                        this.getItemDiscount(record),
                                    },
                                    () => this.setPromotionalItems()
                                  );
                                }
                              );
                            }}
                          >
                            <Icon type="plus" />
                          </Button>
                        </div>
                      ) : null}
                    </Text>
                  )}
                />
                <Column
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Text ellipsis={true}>Apply Promotion</Text>
                      </div>
                    </>
                  }
                  key="apply_promotion"
                  render={(record) => (
                    <Text>
                      {applied_promotions.some(
                        (ap) =>
                          ap.applied_tier_row.id ===
                          record.product_qty_discount_tier.id
                      ) ? null : (
                        <Button
                          type="link"
                          style={{ color: "green" }}
                          onClick={() => {
                            swal
                              .fire({
                                title:
                                  "Are you sure you want to apply this promotion?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes",
                              })
                              .then((result) => {
                                if (result.value) {
                                  let filtered=applied_promotions.filter(ap=>this.state.promotions_for_product.some(pp=>pp.product_qty_discount_tier.id===ap.applied_tier_row.id))
                                  console.log("filtered",filtered)
                                  let obj = {
                                    promotion_id: record._id.$oid,
                                    promotion_type: record.promotion_type,
                                    usage_qty: 1,
                                    applied_tier_row:
                                      record.product_qty_discount_tier,
                                  };
                                  this.setState(
                                    {
                                      new_apply_promotion: obj,
                                      applied_promotion: filtered[0],
                                      promotional_item_purchase_qty:
                                        record.product_qty_discount_tier
                                          .buy_product[0].product_qty,
                                      promotional_item_raw_discount:
                                        record.product_qty_discount_tier
                                          .discount,
                                      promotional_item_discount_type:
                                        record.product_qty_discount_tier
                                          .discount_type,
                                    },
                                    () => {
                                      this.deductPromotion();
                                      if(record.product_qty_discount_tier.discount_type==="free_product"){
                                        this.setState({free_item_free_qty:record.product_qty_discount_tier.free_product[0].product_qty},()=>{
                                          this.setFreeItems();
                                        })
                                        
                                      }
                                      this.setState(
                                        {
                                          promotional_item_discount:
                                            this.getItemDiscount(record),
                                        },
                                        () => {
                                          this.setPromotionalItems();
                                        }
                                      );
                                    }
                                  );
                                }
                              });
                          }}
                        >
                          Apply
                        </Button>
                      )}
                      
                    </Text>
                  )}
                />
              </Table>
            </Modal>
            <Modal
              title="Create New Place"
              visible={this.state.showEntity}
              onOk={() => {
                this.setState({ showEntity: false });
                this.getShopList();
              }}
              onCancel={() => {
                this.setState({ showEntity: false });
                this.getShopList();
              }}
              width={window.innerWidth - 200}
              closable={true}
            >
              <CreateShop />
            </Modal>
            <Modal
              title="Create New Product"
              visible={this.state.showProduct}
              onOk={() => {
                this.setState({ showProduct: false });
                this.getProductList();
              }}
              onCancel={() => {
                this.setState({ showProduct: false });
                this.getProductList();
              }}
              width={window.innerWidth - 200}
              closable={true}
            >
              <CreateProduct />
            </Modal>
            <Modal
              title="Add Received Amount"
              visible={this.state.show_received_modal}
              onOk={() => {
                this.setState({ show_received_modal: false });
                this.updateOrder();
              }}
              onCancel={() => {
                this.setState({ show_received_modal: false });
              }}
              width={window.innerWidth - 200}
              closable={true}
            >
              <Form>
                <Form.Item>
                  <Text>Received Amount Payment Type</Text>
                  <Select
                    value={this.state.received_method}
                    onChange={this.setReceivedMethod}
                    placeholder="Received Amount Payment Type"
                  >
                    {this.state.payment_method_choices.map((r) => {
                      return <Option key={r}>{r}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Text>Received Amount</Text>
                  <Input
                    placeholder="Received Amount"
                    type="text"
                    value={this.state.received_amount}
                    onChange={this.setReceivedAmount}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Received On </Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.received_date_view}
                        onChange={this.setReceivedTime}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
            <Modal
              title="Add Refund Amount"
              visible={this.state.show_refund_modal}
              onOk={() => {
                if (
                  this.state.refundable_amount >=
                  Number(this.state.refunded_amount)
                ) {
                  this.setState({ show_refund_modal: false });
                  this.updateOrder();
                } else if (
                  this.state.total_received_amount +
                    Number(this.state.received_amount) >=
                  Number(this.state.refunded_amount)
                ) {
                  this.setState({ show_refund_modal: false });
                  this.updateOrder();
                } else {
                  swal.fire(
                    "Info",
                    "Refunded amount can not be greater than refundable amount or total received amount",
                    "info"
                  );
                }
              }}
              onCancel={() => {
                this.setState({ show_refund_modal: false });
              }}
              width={window.innerWidth - 200}
              closable={true}
            >
              <Form>
                <Form.Item>
                  <Text>Refunded Amount Payment Type</Text>
                  <Select
                    value={this.state.refunded_method}
                    onChange={this.setRefundedMethod}
                    placeholder="Refunded Amount Payment Type"
                  >
                    {this.state.payment_method_choices.map((r) => {
                      return <Option key={r}>{r}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Text>Refunded Amount</Text>
                  <Input
                    placeholder="Refunded Amount"
                    type="text"
                    value={this.state.refunded_amount}
                    onChange={this.setRefundedAmount}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Refunded On </Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.refunded_date_view}
                        onChange={this.setRefundedTime}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </Form>
            </Modal>
            {/* <Modal
              title="Add/Edit Products"
              visible={this.state.visible}
              onOk={() => {
                let final = this.state.data.filter(i => i.qty !== 0);
                final.map(i => {
                  let total = i.price;
                  this.setState(
                    prevState => ({
                      total_price: prevState.total_price + total
                    }),
                    () => {
                      this.setState({
                        bill_amount:
                          Number(this.state.total_price) +
                          Number(this.state.delivery_charge) +
                          Number(this.state.other_charges)
                      });
                      console.log("total price", this.state.total_price);
                    }
                  );

                  return null;
                });

                this.setState({ visible: false });
              }}
              onCancel={() => this.setState({ visible: false })}
              width={window.innerWidth - 200}
              closable={true}
            >
              
            </Modal> */}
            <Modal
              title="Select Location"
              visible={this.state.showMap}
              onOk={() => this.setState({ showMap: false })}
              onCancel={() => this.setState({ showMap: false })}
              width={window.innerWidth - 100}
              closable={true}
            >
              <Form>
                <Form.Item>
                  <Text>Search Location</Text>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {this.state.editEnd === true ? (
                      <Input
                        type="text"
                        placeholder="Start Location"
                        value={this.state.end_loc_name}
                        onChange={(evt) =>
                          this.setState({ end_loc_name: evt.target.value })
                        }
                      />
                    ) : (
                      <Select
                        showSearch={true}
                        labelInValue
                        value={this.state.value}
                        notFoundContent={fetch ? <Spin size="small" /> : null}
                        filterOption={false}
                        showArrow={false}
                        autoClearSearchValue={false}
                        placeholder="Search Location"
                        onSearch={this.handleStart}
                        onChange={this.handleStartChange}
                      >
                        {this.state.dataMap.map((r) => {
                          return <Option key={r.id}>{r.value}</Option>;
                        })}
                      </Select>
                    )}
                    {this.state.end_loc_name === "" ? null : (
                      <Tooltip
                        title={
                          this.state.editStart
                            ? "Search Location"
                            : "Edit Location"
                        }
                      >
                        <Button
                          type="primary"
                          shape="circle"
                          style={{ marginLeft: 10 }}
                          onClick={() =>
                            this.setState({ editEnd: !this.state.editEnd })
                          }
                        >
                          <Icon type={this.state.editEnd ? "search" : "edit"} />
                        </Button>
                      </Tooltip>
                    )}
                  </div>
                </Form.Item>
              </Form>
              <Map
                center={[this.state.center_lat, this.state.center_lon]}
                zoom={this.state.zoom}
                style={{ height: 500 }}
                onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
              >
                {/* <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                /> */}
                <ReactLeafletGoogleLayer
                  googleMapsLoaderConf={{
                    KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                    VERSION: "3.49.2",
                  }}
                  type={"roadmap"}
                />

                <Marker
                  position={[
                    this.state.end_location_lat,
                    this.state.end_location_lon,
                  ]}
                  draggable
                  onDragend={this.updatePositionStart}
                  icon={green}
                >
                  <Popup>
                    <span>From :{this.state.end_loc_name}</span>
                  </Popup>
                </Marker>
              </Map>
            </Modal>
          </div>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.updateOrder}
            >
              Update Order
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(EditOrder);
