import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Modal,
  Tooltip,
  Timeline,
  Icon,
} from "antd";
import {
  CREATE_DRIVER,
  ASSET_SEARCH,
  // REVERSE_GEO,
  // GEO_CODING_API,
  CREATE_VISIT_PLAN,
  // LOCATION_SEARCH_GOOGLE,
  // REVERSE_LOCATION_SEARCH_GOOGLE
  CREATE_ENTITY,
  EDIT_ENTITY,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import { debounce } from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import ReactGA from "react-ga4";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40],
});
const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40],
});

// const selected = new L.Icon({
//   iconUrl: require("../.././assets/selected.png"),
//   iconSize: [20, 35]
// });

export default class CreateVisitPlan extends Component {
  state = {
    plan_name: "",
    plan_note: "",
    plan_date: Date.now(),
    plan_date_view: moment(),
    center_lat: 23.685,
    center_lon: 90.3563,
    plan_start_loc_lon: 90.3563,
    plan_start_loc_lat: 23.685,
    plan_start_loc_name: "",
    plan_start_loc_id: "",
    plan_end_loc_lon: 90.3563,
    plan_end_loc_lat: 23.6953,
    plan_end_loc_name: "",
    plan_end_loc_id: "",
    drop_contact_name: "",
    drop_contact_no: "",
    org_name: "",
    visit_duration: "",
    location_infos: [],
    zoom: 12,
    visit_hour: "",
    asset_id: "",
    asset_name: "",
    assetData: [],
    assign_to_id: "",
    assign_to_name: "",
    userData: [],
    filtered: [],
    location_modal: false,
    value: [],
    value1: [],
    data: [],
    query: "",
    is_draft: false,
    places: [],
  };

  componentDidMount() {
    this.getAssetList();
    this.getUserList();
    this.getEntityList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getEntityList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=100&is_inventory=false&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ places: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  };

  handleSearchPlace = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=10&name=${value}&is_inventory=false&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ places: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  }, 500);

  handleStartChange = (value) => {
    this.setState(
      { plan_start_loc_id: value.key, plan_start_loc_name: value.label },
      () => {
        console.log(this.state.plan_start_loc_id);
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_ENTITY}${this.state.plan_start_loc_id}?access_token=${token}`;
          axios({
            method: "get",
            url: url,
          }).then((response) => {
            console.log("response fro edit supplier", response);
            this.setState({
              plan_start_loc_name: response.data.data.name,
              plan_start_loc_lon: response.data.data.loc.coordinates[0],
              center_lon: response.data.data.loc.coordinates[0],
              plan_start_loc_lat: response.data.data.loc.coordinates[1],
              center_lat: response.data.data.loc.coordinates[1],
            });
          });
        });
      }
    );
  };
  handleEndChange = (value) => {
    this.setState(
      { plan_end_loc_id: value.key, plan_end_loc_name: value.label },
      () => {
        console.log(this.state.plan_end_loc_id);
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_ENTITY}${this.state.plan_end_loc_id}?access_token=${token}`;
          axios({
            method: "get",
            url: url,
          }).then((response) => {
            console.log("response fro edit supplier", response);
            this.setState({
              plan_end_loc_name: response.data.data.name,
              plan_end_loc_lon: response.data.data.loc.coordinates[0],
              center_lon: response.data.data.loc.coordinates[0],
              plan_end_loc_lat: response.data.data.loc.coordinates[1],
              center_lat: response.data.data.loc.coordinates[1],
            });
          });
        });
      }
    );
  };

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10000;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  getAssetList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${ASSET_SEARCH}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ assetData: response.data.data });
          console.log("response from asset list", response);
        })
        .catch((error) => {
          console.log("error from asset list api", error);
        });
    });
  };

  setName = (evt) => {
    this.setState({ plan_name: evt.target.value });
    console.log(this.state.plan_name);
  };
  setNote = (evt) => {
    this.setState({ plan_note: evt.target.value });
    console.log(this.state.plan_note);
  };
  setVisit = (evt) => {
    this.setState({ visit_hour: evt.target.value });
    console.log(this.state.visit_hour);
  };
  setDate = (value, dateString) => {
    this.setState(
      { plan_date: moment(value).format("x"), plan_date_view: value },
      () => {
        console.log("Selected Time: ", this.state.plan_date);
        console.log("Selected value: ", this.state.plan_date_view);
      }
    );
  };

  setAssigned = (value) => {
    this.setState(
      { assign_to_name: value.label, assign_to_id: value.key, filtered: [] },
      () => {
        console.log(this.state.assign_to_id);
      }
    );
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from driver list", response);
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  }, 500);

  setAssignedAsset = (value) => {
    this.setState({ asset_name: value.label, asset_id: value.key }, () => {
      console.log(this.state.asset_id);
    });
  };

  setDraft = (value) => {
    this.setState({ is_draft: CHECK_BOOL(value) }, () => {
      console.log(this.state.is_draft);
    });
  };

  // handleStart = debounce(value => {
  //   this.setState({ query: value, data: [] }, () => {
  //     console.log(this.state.query);
  //     if (this.state.query !== "") {
  //       GET_ACCESS_TOKEN().then(token => {
  //         axios
  //           .get(
  //             `${LOCATION_SEARCH_GOOGLE}?textsearch=${this.state.query}&access_token=${token}`
  //           )
  //           .then(response => {
  //             console.log(response);
  //             const data = response.data.google_loc_datas.map(res => ({
  //               id: `${res._id.$oid}>${res.loc.coordinates[0]}>${res.loc.coordinates[1]}`,
  //               value: `${res.name}>${res.address}`
  //             }));
  //             this.setState({ data: data, fetch: false });
  //             console.log(response.data.google_loc_datas);
  //           });
  //       });
  //     }
  //   });
  // }, 500);

  // handleStartChange = value => {
  //   this.setState({ value, data: [], fetch: false });

  //   return this.setState({
  //     plan_start_loc_name: value.label.split(">")[0],
  //     plan_start_loc_lon: value.key.split(">")[1],
  //     center_lon: value.key.split(">")[1],
  //     plan_start_loc_lat: value.key.split(">")[2],
  //     center_lat: value.key.split(">")[2]
  //   });
  // };
  // handleEndChange = value => {
  //   this.setState({ value1: value, data: [], fetch: false });

  //   return this.setState(
  //     {
  //       plan_end_loc_name: value.label.split(">")[0],
  //       plan_end_loc_lon: value.key.split(">")[1],
  //       center_lon: value.key.split(">")[1],
  //       plan_end_loc_lat: value.key.split(">")[2],
  //       center_lat: value.key.split(">")[2]
  //     }
  //   );
  // };

  // updatePositionStart = evt => {
  //   console.log(evt.target.getLatLng());

  //   this.setState(
  //     {
  //       plan_start_loc_lat: evt.target.getLatLng().lat,
  //       plan_start_loc_lon: evt.target.getLatLng().lng,
  //       center_lon: evt.target.getLatLng().lng,
  //       center_lat: evt.target.getLatLng().lat
  //     },
  //     () => {
  //       GET_ACCESS_TOKEN().then(token => {
  //         axios
  //           .get(
  //             `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.plan_start_loc_lat}&lng=${this.state.plan_start_loc_lon}&access_token=${token}`
  //           )
  //           .then(response => {
  //             let name = response.data.google_loc_data[0].address;
  //             let id = response.data.google_loc_data[0]._id.$oid;
  //             this.setState({
  //               plan_start_loc_name: name,
  //               value: { key: id, label: name }
  //             });
  //           });
  //       });
  //     }
  //   );
  // };
  // updatePositionEnd = evt => {
  //   console.log(evt.target.getLatLng());

  //   this.setState(
  //     {
  //       plan_end_loc_lat: evt.target.getLatLng().lat,
  //       plan_end_loc_lon: evt.target.getLatLng().lng,
  //       center_lon: evt.target.getLatLng().lng,
  //       center_lat: evt.target.getLatLng().lat
  //     },
  //     () => {
  //       GET_ACCESS_TOKEN().then(token => {
  //         axios
  //           .get(
  //             `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.plan_end_loc_lat}&lng=${this.state.plan_end_loc_lon}&access_token=${token}`
  //           )
  //           .then(response => {
  //             let name = response.data.google_loc_data[0].address;
  //             let id = response.data.google_loc_data[0]._id.$oid;
  //             this.setState({
  //               plan_end_loc_name: name,
  //               value: { key: id, label: name }
  //             });
  //           });
  //       });
  //     }
  //   );
  // };

  createVisitPlan = () => {
    if (
      this.state.location_infos.length < 1 ||
      this.state.plan_start_loc_name === "" ||
      this.state.plan_name === "" ||
      this.state.assign_to_id === ""
    ) {
      swal.fire(
        "info",
        "Please Select start location,end location,plan name,assigned user for creating visit plan",
        "info"
      );
    } else {
      this.setState(
        {
          plan_end_loc_lon:
            this.state.location_infos[this.state.location_infos.length - 1].loc
              .coordinates[0],
          plan_end_loc_lat:
            this.state.location_infos[this.state.location_infos.length - 1].loc
              .coordinates[1],
          plan_end_loc_name:
            this.state.location_infos[this.state.location_infos.length - 1]
              .loc_name,
        },
        () => {
          GET_ACCESS_TOKEN().then((token) => {
            let url = `${CREATE_VISIT_PLAN}?access_token=${token}`;
            let data = {
              plan_name: this.state.plan_name,
              note: this.state.plan_note,
              visiting_time: this.state.plan_date,
              visiting_location_infos: this.state.location_infos,
              start_loc: {
                coordinates: [
                  this.state.plan_start_loc_lon,
                  this.state.plan_start_loc_lat,
                ],
              },
              start_loc_name: this.state.plan_start_loc_name,
              end_loc: {
                coordinates: [
                  this.state.plan_end_loc_lon,
                  this.state.plan_end_loc_lat,
                ],
              },
              end_loc_name: this.state.plan_end_loc_name,
              asset_id: this.state.asset_id,
              assign_person: this.state.assign_to_id,
              is_draft: this.state.is_draft,
            };
            console.log(data);
            axios({
              method: "post",
              url: url,
              data: data,
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
            })
              .then((response) => {
                // this.setState({ loading: false });
                swal.fire("Success", response.data.message, "success");
                console.log("response from create visit plan", response);
              })
              .catch((error) => {
                // this.setState({ loading: false });
                swal.fire("Error", error.message, "error");
                console.log("error from create visit plan", error);
              });
          });
        }
      );
    }
  };
  render() {
    const UserList = this.state.userData.map((r) => {
      return (
        <Option
          key={r._id.$oid}
        >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
      );
    });

    const FliteredList = this.state.filtered.map((r) => {
      return (
        <Option
          key={r._id.$oid}
        >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
      );
    });
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Create Visit Plan"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Plan Name</Text>
            <Input
              placeholder="Plan Name"
              type="text"
              value={this.state.plan_name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Plan Note</Text>

            <TextArea
              rows={4}
              placeholder="Plan Note"
              type="text"
              value={this.state.plan_note}
              onChange={this.setNote}
            />
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Plan Date & Time</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.plan_date_view}
                  onChange={this.setDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <Card>
              <div style={{ textAlign: "center" }}>
                <Tooltip placement="top" title="Add Locations">
                  <Button
                    onClick={() => this.setState({ location_modal: true })}
                    shape="circle"
                    icon="plus"
                    style={{
                      backgroundColor: "#1A3337",
                      height: 60,
                      width: 60,
                      fontSize: 32,
                      paddingBottom: 10,
                    }}
                  />
                </Tooltip>
              </div>
              <Modal
                width={window.innerWidth - 200}
                title="Add Visit Location"
                visible={this.state.location_modal}
                onOk={() => {
                  this.setState({
                    location_modal: false,
                  });
                }}
                onCancel={() => this.setState({ location_modal: false })}
              >
                <Card>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Card style={{ marginRight: 5 }}>
                      <Form
                        style={{
                          marginTop: 10,
                          width: (window.innerWidth - 200) / 2,
                        }}
                      >
                        <Timeline>
                          <Timeline.Item color="teal">
                            <Form.Item>
                              <Text>Start Location</Text>
                              <Select
                                showSearch={true}
                                labelInValue
                                filterOption={false}
                                showArrow={false}
                                autoClearSearchValue={false}
                                onSearch={this.handleSearchPlace}
                                value={{
                                  key: this.state.plan_start_loc_id,
                                  label: this.state.plan_start_loc_name,
                                }}
                                onChange={this.handleStartChange}
                              >
                                {this.state.places.map((r) => {
                                  return (
                                    <Option key={r._id.$oid}>{r.name}</Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Timeline.Item>
                          {this.state.location_infos.length > 0 ? (
                            <div>
                              {this.state.location_infos.map((loc, index) => (
                                <Timeline.Item key={index} color="gold">
                                  <p
                                    style={{
                                      fontFamily: "AirbnbCerealApp-Book",
                                    }}
                                  >
                                    {" "}
                                    <Icon
                                      type="environment"
                                      style={{
                                        color: "rgba(0,0,0,.25)",
                                        margin: 5,
                                      }}
                                    />
                                    <Text>Location : {loc.loc_name}</Text>
                                  </p>
                                </Timeline.Item>
                              ))}
                            </div>
                          ) : null}

                          <Timeline.Item color="gold">
                            <Form.Item>
                              <Text>Next Location</Text>
                              <Select
                                showSearch={true}
                                labelInValue
                                filterOption={false}
                                showArrow={false}
                                autoClearSearchValue={false}
                                onSearch={this.handleSearchPlace}
                                value={{
                                  key: this.state.plan_end_loc_id,
                                  label: this.state.plan_end_loc_name,
                                }}
                                onChange={this.handleEndChange}
                              >
                                {this.state.places.map((r) => {
                                  return (
                                    <Option key={r._id.$oid}>{r.name}</Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item>
                              <Text>Contact Person Name</Text>
                              <Input
                                placeholder="Contact Person Name"
                                type="text"
                                value={this.state.drop_contact_name}
                                onChange={(evt) =>
                                  this.setState({
                                    drop_contact_name: evt.target.value,
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item>
                              <Text>Contact Person Number</Text>
                              <Input
                                placeholder="Contact Person Number"
                                type="number"
                                value={this.state.drop_contact_no}
                                onChange={(evt) =>
                                  this.setState({
                                    drop_contact_no: evt.target.value,
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item>
                              <Text>Organization Name</Text>
                              <Input
                                placeholder="Organization Name"
                                type="text"
                                value={this.state.org_name}
                                onChange={(evt) =>
                                  this.setState({ org_name: evt.target.value })
                                }
                              />
                            </Form.Item>
                            <Form.Item>
                              <Text>Visit Hour</Text>
                              <Input
                                placeholder="Visit Hour"
                                type="text"
                                value={this.state.visit_duration}
                                onChange={(evt) =>
                                  this.setState({
                                    visit_duration: evt.target.value,
                                  })
                                }
                              />
                            </Form.Item>
                          </Timeline.Item>
                          <Timeline.Item color="gold">
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Button
                                className="login-form-button"
                                htmlType="submit"
                                onClick={() => {
                                  let loc_object = {
                                    loc: {
                                      coordinates: [
                                        this.state.plan_end_loc_lon,
                                        this.state.plan_end_loc_lat,
                                      ],
                                    },
                                    loc_name: this.state.plan_end_loc_name,
                                    representative_name:
                                      this.state.drop_contact_name,
                                    representative_mobile:
                                      this.state.drop_contact_no,
                                    visiting_org: this.state.org_name,
                                    visit_duration: Number(
                                      this.state.visit_duration
                                    ),
                                  };

                                  let temp = [];
                                  temp.push(loc_object);
                                  this.setState(
                                    {
                                      location_infos: [
                                        ...this.state.location_infos,
                                        ...temp,
                                      ],
                                    },
                                    () => {
                                      console.log(this.state.location_infos);
                                      this.setState({
                                        plan_end_loc_lat: 23.685,
                                        plan_end_loc_lon: 90.3563,
                                        plan_end_loc_name: "",
                                      });
                                    }
                                  );
                                }}
                              >
                                Add Data
                              </Button>

                              <Button
                                style={{ marginTop: 5 }}
                                type="link"
                                onClick={() => {
                                  this.setState({
                                    center_lat: 23.685,
                                    center_lon: 90.3563,
                                    plan_start_loc_lon: 90.3563,
                                    plan_start_loc_lat: 23.685,
                                    plan_start_loc_name: "",
                                    plan_end_loc_lon: 90.3563,
                                    plan_end_loc_lat: 23.685,
                                    plan_end_loc_name: "",
                                    drop_contact_name: "",
                                    drop_contact_no: "",
                                    org_name: "",
                                    visit_duration: "",
                                    location_infos: [],
                                    value: [],
                                    value1: [],
                                  });
                                }}
                              >
                                Clear Data
                              </Button>
                            </div>
                          </Timeline.Item>
                        </Timeline>
                      </Form>
                    </Card>
                    {/* <Card> */}
                    <Map
                      center={[this.state.center_lat, this.state.center_lon]}
                      zoom={this.state.zoom}
                      style={{ height: 700 }}
                      onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
                    >
                      {/* <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                      /> */}

                      <ReactLeafletGoogleLayer
                        googleMapsLoaderConf={{
                          KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                          VERSION: "3.49.2",
                        }}
                        type={"roadmap"}
                      />

                      <Marker
                        position={[
                          this.state.plan_start_loc_lat,
                          this.state.plan_start_loc_lon,
                        ]}
                        // draggable
                        // onDragend={this.updatePositionStart}
                        icon={green}
                      >
                        <Popup>
                          <span>From :{this.state.plan_start_loc_name}</span>
                        </Popup>
                      </Marker>
                      {/* <Marker
                        position={[
                          this.state.plan_end_loc_lat,
                          this.state.plan_end_loc_lon
                        ]}
                        draggable
                        onDragend={this.updatePositionEnd}
                        icon={red}
                      >
                        <Popup>
                          <span>To :{this.state.plan_end_loc_name}</span>
                        </Popup>
                      </Marker> */}

                      {this.state.location_infos.length > 0 ? (
                        <div>
                          {this.state.location_infos.map((loc, index) => (
                            <Marker
                              key={index}
                              position={[
                                loc.loc.coordinates[1],
                                loc.loc.coordinates[0],
                              ]}
                              icon={red}
                            >
                              <Popup>
                                <span>To :{loc.loc_name}</span>
                              </Popup>
                            </Marker>
                          ))}
                        </div>
                      ) : null}
                    </Map>
                    {/* </Card> */}
                  </div>
                </Card>
              </Modal>
            </Card>
          </Form.Item>
          {this.state.location_infos.length > 0 ? (
            <Card>
              <Timeline>
                <Timeline.Item color="teal">
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    {" "}
                    <Icon
                      type="environment"
                      style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                    />
                    Start Location : {this.state.plan_start_loc_name}
                  </p>
                </Timeline.Item>

                {this.state.location_infos.map((loc, index) => (
                  <Timeline.Item key={index} color="gold">
                    <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                      {" "}
                      <Icon
                        type="environment"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      <Text>Location : {loc.loc_name}</Text>
                    </p>
                    <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                      {" "}
                      <Icon
                        type="contacts"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      <Text>
                        Representative Name : {loc.representative_name}
                      </Text>
                    </p>
                    <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                      {" "}
                      <Icon
                        type="mobile"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      <Text>
                        Representative Mobile : {loc.representative_mobile}
                      </Text>
                    </p>
                    <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                      {" "}
                      <Icon
                        type="audit"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      <Text>Visiting Org : {loc.visiting_org}</Text>
                    </p>
                    <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                      {" "}
                      <Icon
                        type="clock-circle"
                        style={{ color: "rgba(0,0,0,.25)", margin: 5 }}
                      />
                      <Text>Visit Duration : {loc.visit_duration}</Text>
                    </p>
                  </Timeline.Item>
                ))}
              </Timeline>
            </Card>
          ) : null}
          <Form.Item>
            <Text>Visit Hour</Text>
            <Input
              placeholder="Visit Hour"
              type="number"
              value={this.state.visit_hour}
              onChange={this.setVisit}
            />
          </Form.Item>
          <Form.Item>
            <Text>Search Assigned User</Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              style={{ width: "100%" }}
              value={{
                key: this.state.assign_to_id,
                label: this.state.assign_to_name,
              }}
              onChange={this.setAssigned}
              placeholder="Select User"
            >
              {this.state.filtered.length === 0 ? UserList : FliteredList}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Assign to (Asset)</Text>
            <Select
              labelInValue
              style={{ width: "100%" }}
              value={{
                key: this.state.asset_id,
                label: this.state.asset_name,
              }}
              onChange={this.setAssignedAsset}
              placeholder="Select Asset"
            >
              {this.state.assetData.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Save Draft</Text>
            <Select
              value={this.state.is_draft.toString()}
              onChange={this.setDraft}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createVisitPlan}
            >
              Create Plan
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
