import React, { Component } from "react";
import {
  Table,
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Modal,
  Icon
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import {
  CREATE_MATERIAL,
  EDIT_REQUISITION,
  CREATE_SUPPLIER,
  CREATE_PROCEDURE,
  CREATE_ASSET,
  BILL_TYPE_CHOICES,
  CREATE_TASK,
  CREATE_ATTENDANCE
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  CHECK_BOOL,
  GET_USER_LOCAL,
  timeConverter
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import CreateMaterial from "../material/createMaterial";
// import CreateMaterialType from "../mtype/createMtype";
import CreateSupplier from "../supplier/createSupplier";

import "../login.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;

export default class EditRequisition extends Component {
  state = {
    name: "",
    details: "",
    items: [],
    itemObj: {},
    item: "",
    price: null,
    qty: 0,
    unit: "",
    contact_no: "",
    supplier_name: "",
    supplier_name_key: "",
    rcmnd_price: null,
    appr_price: null,
    approved: false,
    recommended: false,
    authorised: false,
    data: [],
    dataS: [],
    loading: false,
    order_id: "",
    products: [],
    edit: false,
    req_id: "",
    procedureData: [],
    recommender_id: "",
    authoriser_id: "",
    approver_id: "",
    asset_id: "",
    asset_name: "",
    assetData: [],
    miscellaneous_bill_type_choices: [],
    misc_bill_type: "",
    taskData: [],
    started: [],
    completed: [],
    task_name: "",
    task_id: "",
    attendanceData: [],
    attendance_name: "",
    attendance_id: "",
    showSupplier: false,
    filtered_items: [],
    selected_items: []
  };

  componentDidMount() {
    this.getProcedureList();
    this.getProductList();
    this.getSupplierList();
    this.getRequisition();
    this.getAssetList();
    this.getBillTypeChoices();
    this.started();
    this.getAttendanceList();
    this.setState({ req_id: this.props.location.state.req_id });
  }

  getAttendanceList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_ATTENDANCE}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from Attendance list", response);
          this.setState({
            attendanceData: response.data.data
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from attendance list api", error);
        });
    });
  };

  completed = () => {
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_TASK}?limit=1000&ts=${time}&assigned=true&accepted=true&started=true&completed=true&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from Task list completed", response);
          this.setState(
            {
              completed: response.data.data
            },
            () => {
              this.setState(
                { taskData: this.state.started.concat(this.state.completed) },
                () => {
                  console.log("taskdata for admin", this.state.taskData);
                }
              );
            }
          );
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from Task list api completed", error);
        });
    });
  };

  started = () => {
    let date = new Date();
    let time = date.getTime();
    console.log(moment(time));
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_TASK}?limit=1000&ts=${time}&assigned=true&accepted=true&started=true&completed=false&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from Task list started", response);
          this.setState(
            {
              started: response.data.data
            },
            () => {
              this.completed();
            }
          );
        })
        .catch(error => {
          console.log("error from Task list api", error);
        });
    });
  };

  getBillTypeChoices = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${BILL_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({
            miscellaneous_bill_type_choices:
              response.data.miscellaneous_bill_type_choices
          });
          console.log("response from bill type choices", response);
        })
        .catch(error => {
          console.log("error from bill type choices api", error);
        });
    });
  };

  getAssetList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ASSET}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ assetData: response.data.data });
          console.log("response from asset list", response);
        })
        .catch(error => {
          console.log("error from asset list api", error);
        });
    });
  };

  getProcedureList = () => {
    let limit = 1000;
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_PROCEDURE}?limit=${limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from procedure list", response);
          let requistion = response.data.data.filter(
            proce => proce.name.toLowerCase() === "requisition"
          );
          let recommender_id = requistion[0].recommender;
          let authoriser_id = requistion[0].authorizer;
          let approver_id = requistion[0].approver;
          this.setState(
            {
              recommender_id: recommender_id,
              authoriser_id: authoriser_id,
              approver_id: approver_id
            },
            () => {
              console.log("recommender", this.state.recommender_id);
              console.log("auhtorizer", this.state.authoriser_id);
              console.log("approver", this.state.approver_id);
            }
          );
        })
        .catch(error => {
          console.log("error from procedure list api", error);
        });
    });
  };

  getRequisition = () => {
    GET_ACCESS_TOKEN().then(token => {
      let req_id = this.props.location.state.req_id;
      let url = `${EDIT_REQUISITION}${req_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit requisition", response.data.data);
        this.setState({
          name: response.data.data.name,
          details: response.data.data.details,
          price: response.data.data.total_price,
          contact_no: response.data.data.contact_no,
          supplier_name: response.data.data.supplier.name,
          supplier_name_key: response.data.data.supplier.id,
          rcmnd_price: response.data.data.rcmnd_price,
          appr_price: response.data.data.appr_price,
          approved: response.data.data.approved,
          recommended: response.data.data.recommended,
          authorised: response.data.data.authorized,
          products: response.data.data.items,
          asset_id: response.data.data.asset_id,
          asset_name: response.data?.data?.asset_name ?? "",
          misc_bill_type: response.data?.data?.misc_bill_type ?? "",
          task_id: response.data?.data?.task_id ?? "",
          task_name: response.data?.data?.task_name ?? "",
          attendance_id: response.data?.data?.attendance_id ?? ""
        });
      });
    });
  };

  getSupplierList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_SUPPLIER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ dataS: response.data.data });
        })
        .catch(error => {
          console.log("error from supplier list api", error);
        });
    });
  };

  getProductList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_MATERIAL}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          let products = response.data.data.map(p => ({
            id: p._id.$oid,
            item: p.name,
            unit_price: p.unit_price,
            price: p.price,
            unit: p.unit,
            qty: 0,
            recommended_price: p.recommended_price,
            authorized_price: p.authorized_price,
            approved_price: p.approved_price
          }));

          let products1 = response.data.data.map(p => ({
            item: p.name,
            unit_price: p.unit_price,
            price: p.price,
            unit: p.unit,
            qty: 0,
            recommended_price: p.recommended_price,
            authorized_price: p.authorized_price,
            approved_price: p.approved_price
          }));

          console.log("..........................", products);
          this.setState({ data: products1, items: products });
        })
        .catch(error => {
          console.log("error from supplier list api", error);
        });
    });
  };

  itemSearch = value => {
    const result = this.state.items.filter(item => {
      return item.item.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered_items: result });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  setDetails = evt => {
    evt.preventDefault();
    this.setState({ details: evt.target.value }, () => {
      console.log(this.state.details);
    });
  };

  setContact = evt => {
    evt.preventDefault();
    this.setState({ contact_no: evt.target.value }, () => {
      console.log(this.state.contact_no);
    });
  };
  setAssignedAsset = value => {
    this.setState({ asset_name: value.label, asset_id: value.key }, () => {
      console.log(this.state.asset_id);
    });
  };
  setSupplier = value => {
    this.setState(
      { supplier_name: value.label, supplier_name_key: value.key },
      () => {
        console.log(this.state.supplier_name);
      }
    );
  };
  setBillType = value => {
    this.setState({ misc_bill_type: value }, () => {
      console.log(this.state.misc_bill_type);
    });
  };
  setTask = value => {
    this.setState({ task_name: value.label, task_id: value.key }, () => {
      console.log(this.state.task_id);
    });
  };
  setAttendance = value => {
    this.setState(
      { attendance_name: value.label, attendance_id: value.key },
      () => {
        console.log(this.state.attendance_id);
      }
    );
  };
  setRcprice = evt => {
    evt.preventDefault();
    this.setState({ rcmnd_price: evt.target.value }, () => {
      console.log(this.state.rcmnd_price);
    });
  };
  setApprice = evt => {
    evt.preventDefault();
    this.setState({ appr_price: evt.target.value }, () => {
      console.log(this.state.appr_price);
    });
  };

  setApprroved = value => {
    this.setState({ approved: CHECK_BOOL(value) }, () => {
      console.log(this.state.approved);
    });
  };
  setRecommended = value => {
    this.setState({ recommended: CHECK_BOOL(value) }, () => {
      console.log(this.state.recommended);
    });
  };
  setAuthorized = value => {
    this.setState({ authorised: CHECK_BOOL(value) }, () => {
      console.log(this.state.authorised);
    });
  };

  editRequisition = () => {
    this.setState({ loading: true });

    let final = this.state.data.filter(i => i.qty !== 0);
    let data = {
      name: this.state.name,
      details: this.state.details,
      asset_id: this.state.asset_id,
      asset_name: this.state.asset_name,
      items: final,
      contact_no: this.state.contact_no,
      supplier: {
        name: this.state.supplier_name,
        id: this.state.supplier_name_key
      },
      approved: this.state.approved,
      recommended: this.state.recommended,
      authorized: this.state.authorised,
      misc_bill_type: this.state.misc_bill_type,
      task_id: this.state.task_id,
      task_name: this.state.task_name,
      attendance_id: this.state.attendance_id
    };
    console.log("edit true", data);

    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_REQUISITION}${this.state.req_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          console.log("response from edit requisition", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from edit requisition", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const ItemList = this.state.items
      .filter(item => !this.state.products.some(p => item.id === p.id))
      .map(r => {
        return (
          <Option key={r.id} value={JSON.stringify(r)}>
            {r.item}
          </Option>
        );
      });

    const FilteredItem = this.state.filtered_items
      .filter(item => !this.state.products.some(p => item.id === p.id))
      .map(r => {
        return (
          <Option key={r.id} value={JSON.stringify(r)}>
            {r.item}
          </Option>
        );
      });
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Edit Requisition"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1 }}>
              <Form.Item>
                <Text>Name</Text>
                <Input
                  placeholder="Name"
                  type="text"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </Form.Item>
              <Form.Item>
                <Text>Details</Text>
                <TextArea
                  rows={4}
                  placeholder="Details"
                  type="text"
                  value={this.state.details}
                  onChange={this.setDetails}
                />
              </Form.Item>
              <Form.Item>
                <Text>Contact Number</Text>
                <Input
                  placeholder="+8801711..."
                  type="text"
                  value={this.state.contact_no}
                  onChange={this.setContact}
                />
              </Form.Item>
              <Form.Item>
                <Text>Bill Type</Text>
                <Select
                  value={this.state.misc_bill_type}
                  onChange={this.setBillType}
                  placeholder="Bill Type"
                >
                  {this.state.miscellaneous_bill_type_choices.map(r => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>
                  Supplier Name{" "}
                  <Button
                    type="link"
                    onClick={() => this.setState({ showSupplier: true })}
                  >
                    Create Supplier
                  </Button>
                </Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.supplier_name_key,
                    label: this.state.supplier_name
                  }}
                  onChange={this.setSupplier}
                  placeholder="Select Supplier"
                >
                  {this.state.dataS.map(r => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              {GET_USER_LOCAL().org_admin ? (
                <Form.Item>
                  <Text>Select Task for Bill</Text>
                  <Select
                    labelInValue
                    value={{
                      key: this.state.task_id,
                      label: this.state.task_name
                    }}
                    onChange={this.setTask}
                    placeholder="Select Task for Bill"
                  >
                    {this.state.taskData.map(r => {
                      return <Option key={r._id.$oid}>{r.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
              ) : null}

              {GET_USER_LOCAL().org_admin ? (
                <Form.Item>
                  <Text>Select Attendance for Bill</Text>
                  <Select
                    labelInValue
                    value={{
                      key: this.state.attendance_id,
                      label: this.state.attendance_name
                    }}
                    onChange={this.setAttendance}
                    placeholder="Select Attendance for Bill"
                  >
                    {this.state.attendanceData.map(r => {
                      return (
                        <Option key={r._id.$oid}>{`${
                          r.created_by_name
                        }-${timeConverter(r.created_on.$date)}`}</Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : null}
              <Form.Item>
                <Text>Assign to (Asset)</Text>
                <Select
                  labelInValue
                  style={{ width: "100%" }}
                  value={{
                    key: this.state.asset_id,
                    label: this.state.asset_name
                  }}
                  onChange={this.setAssignedAsset}
                  placeholder="Select Asset"
                >
                  {this.state.assetData.map(r => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Select Items (Optional)</Text>
                <Card>
                  
                  <Table
                    dataSource={this.state.products}
                    pagination={false}
                    rowKey={record => record.item}
                    size="small"
                  >
                    <Column title="Item" dataIndex="item" key="product" />

                    <Column
                      title="Recommended Price"
                      dataIndex="recommended_price"
                      key="recommended_price"
                    />
                    <Column
                      title="Authorized Price"
                      dataIndex="authorized_price"
                      key="authorized_price"
                    />
                    <Column
                      title="Approved Price"
                      dataIndex="approved_price"
                      key="approved_price"
                    />
                    <Column title="Unit" dataIndex="unit" key="unit" />
                    <Column
                      title="Unit Price"
                      dataIndex="unit_price"
                      key="unit_price"
                    />
                    <Column title="Quantity" key="qty" dataIndex="qty" />
                    <Column title="Price" dataIndex="price" key="price" />
                    <Column
                      title="Action"
                      key="action"
                      render={(text, record) => (
                        <span>
                          <Button
                            type="link"
                            style={{ color: "green" }}
                            onClick={() =>
                              this.setState(
                                {
                                  item_price: record.price,
                                  item_name: record.item,
                                  item_unit: record.unit,
                                  item_unit_price: record.unit_price,
                                  item_qty: record.qty,
                                  item_id: record.id,
                                  item_recommended_price:
                                    record.recommended_price,
                                  item_authorized_price:
                                    record.authorized_price,
                                  item_approved_price: record.approved_price
                                },
                                () => this.setState({ item_edit_modal: true })
                              )
                            }
                          >
                            Edit
                          </Button>
                        </span>
                      )}
                    />
                  </Table>
                  <Text style={{margin:5}}>{`Total Price : ${this.state.price}`}</Text>
                  <Form.Item>
                    <Text>
                      Search Item{" "}
                      <Button
                        type="link"
                        onClick={() => this.setState({ showEntity: true })}
                      >
                        Create Item
                      </Button>
                    </Text>
                    <Select
                      labelInValue
                      showSearch={true}
                      filterOption={false}
                      showArrow={false}
                      autoClearSearchValue
                      onSearch={this.itemSearch}
                      onChange={(value, e) => {
                        let item=JSON.parse(e.props.value)
                          let inc = {
                            item: item.item,
                            unit_price: item.unit_price,
                            price: item.price,
                            qty: 1,
                            unit: item.unit,
                            recommended_price: item.recommended_price,
                            authorized_price: item.authorized_price,
                            approved_price: item.approved_price,
                            id:item.id
                          };
                          inc.price = inc.unit_price * inc.qty;
                          let temp = [];
                          temp.push(inc);
                          //real data
                          let filteredData = this.state.data.filter(
                            i => i.item !== item.item
                          );

                          this.setState(
                            { data: [...temp, ...filteredData] },
                            () => {
                              this.setState({
                                data: this.state.data.filter(
                                  (value, index, self) =>
                                    self.findIndex(t => t.id === value.id) ===
                                    index
                                )
                              },()=>{
                                console.log(
                                  "selection data state",
                                  this.state.data
                                );
                              });
                            }
                          );
                          //selected data
                          this.setState(
                            {
                              selected_items: [
                                ...this.state.selected_items,
                                ...temp
                              ]
                            },
                            () => {
                              this.setState({
                                selected_items: this.state.selected_items.filter(
                                  (value, index, self) =>
                                    self.findIndex(t => t.id === value.id) ===
                                    index
                                )
                              });
                              console.log(this.state.selected_items);
                            }
                          );
                      }}
                      placeholder="Search Item"
                    >
                      {this.state.filtered_items.length === 0
                        ? ItemList
                        : FilteredItem}
                    </Select>
                  </Form.Item>
                  {this.state.selected_items.length > 0 ? (
                    <Table
                      dataSource={this.state.selected_items}
                      pagination={false}
                      rowKey={record => record.id}
                      size="small"
                    >
                      <Column title="Item" dataIndex="item" key="product" />
                      <Column
                        title="Unit"
                        dataIndex="unit"
                        key="unit"
                        style={{ paddingLeft: 15 }}
                      />
                      <Column
                        title="Unit Price"
                        key="unit_price"
                        dataIndex="unit_price"
                      />
                      <Column
                        title="Decrease"
                        key="Decrease"
                        render={record => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#F5F5F5",
                                color: "red"
                              }}
                              shape="circle"
                              onClick={() => {
                                if (
                                  this.state.data.find(
                                    i => i.item === record.item
                                  ).qty <= 0
                                ) {
                                  return;
                                } else {
                                  let item = this.state.data.find(
                                    i => i.item === record.item
                                  );
                                  let inc = {
                                    item: item.item,
                                    unit_price: item.unit_price,
                                    price: item.price,
                                    qty: item.qty - 1,
                                    unit: item.unit,
                                    recommended_price: item.recommended_price,
                                    authorized_price: item.authorized_price,
                                    approved_price: item.approved_price
                                  };
                                  inc.price = inc.unit_price * inc.qty;
                                  let arr = [];
                                  arr.push(inc);

                                  let filteredData = this.state.data.filter(
                                    i => i.item !== item.item
                                  );

                                  this.setState(
                                    { data: [...arr, ...filteredData] },
                                    () => {
                                      console.log(
                                        "final state",
                                        this.state.data
                                      );
                                    }
                                  );
                                }
                              }}
                            >
                              <Icon type="minus" />
                            </Button>
                          </span>
                        )}
                      />

                      <Column
                        title="Quantity"
                        key="quantity"
                        render={record => (
                          <span>
                            <Text>
                              {
                                this.state.data.find(
                                  i => i.item === record.item
                                ).qty
                              }
                            </Text>
                          </span>
                        )}
                      />
                      <Column
                        title="Increase"
                        key="Increase"
                        render={record => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#F5F5F5",
                                color: "green"
                              }}
                              shape="circle"
                              onClick={() => {
                                let item = this.state.data.find(
                                  i => i.item === record.item
                                );
                                let inc = {
                                  item: item.item,
                                  unit_price: item.unit_price,
                                  price: item.price,
                                  qty: item.qty + 1,
                                  unit: item.unit,
                                  recommended_price: item.recommended_price,
                                  authorized_price: item.authorized_price,
                                  approved_price: item.approved_price
                                };
                                inc.price = inc.unit_price * inc.qty;
                                let arr = [];
                                arr.push(inc);

                                let filteredData = this.state.data.filter(
                                  i => i.item !== item.item
                                );

                                this.setState(
                                  { data: [...arr, ...filteredData] },
                                  () => {
                                    console.log("final state", this.state.data);
                                  }
                                );
                              }}
                            >
                              <Icon type="plus" />
                            </Button>
                          </span>
                        )}
                      />
                      <Column
                        title="Price"
                        key="price"
                        render={record => (
                          <span style={{ paddingLeft: 15 }}>
                            <Text>
                              {
                                this.state.data.find(
                                  i => i.item === record.item
                                ).price
                              }
                            </Text>
                          </span>
                        )}
                      />
                      <Column
                        title="Remove"
                        key="Remove"
                        render={record => (
                          <span>
                            <Button
                              style={{
                                backgroundColor: "#F5F5F5",
                                color: "red"
                              }}
                              shape="circle"
                              onClick={() => {
                                this.setState({
                                  selected_items: this.state.selected_items.filter(
                                    item => item.item !== record.item
                                  )
                                });
                                let item = this.state.data.find(
                                  i => i.item === record.item
                                );
                                let inc = {
                                  item: item.item,
                                  unit_price: item.unit_price,
                                  price: 0,
                                  qty: 0,
                                  unit: item.unit,
                                  recommended_price: item.recommended_price,
                                  authorized_price: item.authorized_price,
                                  approved_price: item.approved_price
                                };
                                let arr = [];
                                arr.push(inc);

                                let filteredData = this.state.data.filter(
                                  i => i.item !== item.item
                                );

                                this.setState(
                                  { data: [...arr, ...filteredData] },
                                  () => {
                                    console.log("final state", this.state.data);
                                  }
                                );
                              }}
                            >
                              <Icon type="close" />
                            </Button>
                          </span>
                        )}
                      />
                    </Table>
                  ) : null}
                   
                </Card>
              </Form.Item>

              <Form.Item>
                <Text>Recommended</Text>
                <Select
                  value={this.state.recommended.toString()}
                  onChange={this.setRecommended}
                  disabled={
                    this.state.recommender_id === GET_USER_LOCAL()._id.$oid ||
                    GET_USER_LOCAL().org_admin
                      ? false
                      : true
                  }
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Authorized</Text>
                <Select
                  value={this.state.authorised.toString()}
                  onChange={this.setAuthorized}
                  disabled={
                    this.state.authoriser_id === GET_USER_LOCAL()._id.$oid ||
                    GET_USER_LOCAL().org_admin
                      ? false
                      : true
                  }
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Approved</Text>
                <Select
                  value={this.state.approved.toString()}
                  onChange={this.setApprroved}
                  disabled={
                    this.state.approver_id === GET_USER_LOCAL()._id.$oid ||
                    GET_USER_LOCAL().org_admin
                      ? false
                      : true
                  }
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </Form.Item>
            </div>
            <Modal
              title="Create New Item"
              visible={this.state.showEntity}
              onOk={() => {
                this.setState({ showEntity: false });
                this.getProductList();
              }}
              onCancel={() => {
                this.setState({ showEntity: false });
                this.getProductList();
              }}
              width={window.innerWidth - 200}
              closable={true}
            >
              <CreateMaterial />
            </Modal>

            <Modal
              title="Create New Supplier"
              visible={this.state.showSupplier}
              onOk={() => {
                this.setState({ showSupplier: false });
                this.getSupplierList();
              }}
              onCancel={() => {
                this.setState({ showSupplier: false });
                this.getSupplierList();
              }}
              width={window.innerWidth - 200}
              closable={true}
            >
              <CreateSupplier />
            </Modal>
          </div>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editRequisition}
            >
              Update Requisition
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
