import React from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Divider,
  Modal
} from "antd";
import axios from "axios";
import moment from "moment";
import swal from "sweetalert2";
import {
  CREATE_ENTITY,
  CREATE_ASSET,
  EDIT_ENTITY,
  EDIT_ATTENDANCE,
  REVERSE_GEO
} from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";
import { Spinner } from "../loader";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "../login.css";
import CreateShop from "../shop/createShop";
import CreateAsset from "../assets/createAsset";

const { Text } = Typography;
const { Option } = Select;

export default class EditAttendance extends React.Component {
  state = {
    attendance: false,
    checkin_time: "",
    checkin_time_view: moment(),
    checkout_time: "",
    checkout_time_view: moment(),
    entity: "",
    entity_key: null,
    entity_address: "",
    // entity_loc_lat: 23.685,
    // entity_loc_lon: 90.3563,
    entity_loc: null,
    asset: "",
    asset_key: null,
    loc_lat: 23.685,
    loc_lon: 90.3563,
    loc_name: "",
    entity_loc_name: "",
    loading: false,
    dataS: [],
    assetData: [],
    userData: [],
    user_id: "",
    user_name: "",
    attendance_id: "",
    showEntity: false,
    showAsset: false
  };

  componentDidMount() {
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(latitude, longitude);
      this.setState(
        {
          loc_lat: latitude,
          loc_lon: longitude
        },
        () => {
          axios
            .get(REVERSE_GEO(this.state.loc_lat, this.state.loc_lon))
            .then(response => {
              let name = response.data.features[0].properties.display_name;
              this.setState({
                loc_name: name
              });
            });
        }
      );
    };
    navigator.geolocation.getCurrentPosition(success);
    this.getAttendanceDetails();
    this.getShopList();
    this.getAssetList();
  }

  getAttendanceDetails = () => {
    this.setState({ attendance_id: this.props.location.state.attendance_id });

    GET_ACCESS_TOKEN().then(token => {
      let attendance_id = this.props.location.state.attendance_id;
      let url = `${EDIT_ATTENDANCE}${attendance_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit Attendance", response.data.data);
        this.setState({
          checkin_time:
            response.data.data.checkin_time !== null
              ? response.data.data.checkin_time.$date
              : "",
          checkin_time_view:
            response.data.data.checkin_time !== null
              ? response.data.data.checkin_time.$date
              : moment(),
          checkout_time:
            response.data.data.checkout_time !== null
              ? response.data.data.checkout_time.$date
              : "",
          checkout_time_view:
            response.data.data.checkout_time !== null
              ? response.data.data.checkout_time.$date
              : moment(),
          asset_key:
            response.data.data.asset_confirm.asset !== null
              ? response.data.data.asset_confirm.asset
              : null,
          entity_key:
            response.data.data.entity_confirm.entity !== null
              ? response.data.data.entity_confirm.checkout_entity
              : null,
          entity_loc:
            response.data.data.entity_confirm.checkin_loc !== null
              ? response.data.data.entity_confirm.checkin_loc.coordinates
              : null,
          attendance: response.data.data.attendance,
          loc_lon: response.data.data.loc.coordinates[0],
          loc_lat: response.data.data.loc.coordinates[1]
        });
      });
    });
  };

  getShopList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ENTITY}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ dataS: response.data.data });
        })
        .catch(error => {
          console.log("error from shop list api", error);
        });
    });
  };

  getAssetList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ASSET}?limit=${limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ assetData: response.data.data });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from asset list api", error);
        });
    });
  };

  setAttendance = value => {
    this.setState({ attendance: CHECK_BOOL(value) }, () => {
      console.log(this.state.attendance);
    });
  };

  setCheckin = (value, dateString) => {
    this.setState(
      { checkin_time: moment(value).format("x"), checkin_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.checkin_time);
        console.log("Selected value: ", this.state.checkin_time_view);
      }
    );
  };

  setCheckout = (value, dateString) => {
    this.setState(
      { checkout_time: moment(value).format("x"), checkout_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.checkout_time);
        console.log("Selected value: ", this.state.checkout_time_view);
      }
    );
  };

  setEntity = value => {
    this.setState({ asset_key: null, entity_key: value }, () => {
      GET_ACCESS_TOKEN().then(token => {
        let url = `${EDIT_ENTITY}${this.state.entity_key}?access_token=${token}`;
        axios({
          method: "get",
          url: url
        }).then(response => {
          console.log("response fro edit entity", response);
          this.setState(
            {
              entity_loc: response.data.data.loc.coordinates,
              entity_loc_name: response.data.data.name,
              entity_address: response.data.data.address
            },
            () => console.log(this.state.entity_loc)
          );
        });
      });
    });
  };

  setAsset = value => {
    this.setState({ entity_key: null, entity_loc: null, asset_key: value });
  };

  updateAttendance = () => {
    if (this.state.checkin_time === "") {
      swal.fire("Info", "Please enter checkin time", "info");
    } else if (this.state.checkout_time === "") {
      swal.fire("Info", "Please enter checkout time", "info");
    } else if (this.state.entity_key !== null) {
      this.setState({ loading: true });

      let data = {
        attendance_id: this.state.attendance_id,
        attendance: this.state.attendance,
        loc: {
          coordinates: [this.state.loc_lon, this.state.loc_lat]
        },
        loc_name: this.state.loc_name,
        checkin_time: this.state.checkin_time,
        checkout_time: this.state.checkout_time,
        entity_confirm: {
          checkin_entity: this.state.entity_key,
          checkout_entity: this.state.entity_key,
          checkin_loc: {
            coordinates: this.state.entity_loc
          },
          checkin_loc_name: this.state.entity_address,
          checkout_loc: {
            coordinates: this.state.entity_loc
          },
          checkout_loc_name: this.state.entity_address
        },
        asset_confirm: {}
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${EDIT_ATTENDANCE}${this.state.attendance_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");
            console.log("response from edit Attendance", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from edit Attendance", error);
          });
      });
    } else if (this.state.asset_key !== null) {
      this.setState({ loading: true });

      let data = {
        attendance_id: this.state.attendance_id,
        attendance: this.state.attendance,
        loc: {
          coordinates: [this.state.loc_lon, this.state.loc_lat]
        },
        loc_name: this.state.loc_name,
        checkin_time: this.state.checkin_time,
        checkout_time: this.state.checkout_time,
        entity_confirm: {},
        asset_confirm: {
          asset: this.state.asset_key
        }
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${EDIT_ATTENDANCE}${this.state.attendance_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");
            console.log("response from edit Attendance", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from edit Attendance", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          onBack={() => this.props.history.goBack()}
          title="Edit Attendance"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Attendance</Text>
            <Select
              defaultValue={this.state.attendance.toString()}
              onChange={this.setAttendance}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Checkin Time</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.checkin_time_view}
                  onChange={this.setCheckin}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Text>Checkout Time</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.checkout_time_view}
                  onChange={this.setCheckout}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>

          <Form.Item>
            <Text>
              Select Place
              <Button
                type="link"
                onClick={() => this.setState({ showEntity: true })}
              >
                Create Place
              </Button>
            </Text>
            <Select
              allowClear={true}
              value={this.state.entity_key}
              onChange={this.setEntity}
              placeholder="Select Entity"
            >
              {this.state.dataS.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Divider>Only Select Place or Asset</Divider>
          <Form.Item>
            <Text>
              Select Asset
              <Button
                type="link"
                onClick={() => this.setState({ showAsset: true })}
              >
                Create Asset
              </Button>
            </Text>
            <Select
              allowClear={true}
              value={this.state.asset_key}
              onChange={this.setAsset}
              placeholder="Select Asset"
            >
              {this.state.assetData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.updateAttendance}
            >
              Update Attendance
            </Button>
          </Form.Item>
        </Form>
        <Modal
          title="Create New Place"
          visible={this.state.showEntity}
          onOk={() => {
            this.setState({ showEntity: false });
            this.getShopList();
          }}
          onCancel={() => {
            this.setState({ showEntity: false });
            this.getShopList();
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <CreateShop />
        </Modal>
        <Modal
          title="Create New Asset"
          visible={this.state.showAsset}
          onOk={() => {
            this.setState({ showAsset: false });
            this.getAssetList();
          }}
          onCancel={() => {
            this.setState({ showAsset: false });
            this.getAssetList();
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <CreateAsset />
        </Modal>
      </Card>
    );
  }
}
