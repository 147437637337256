import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Tag
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_PLAN,
  CREATE_SERVICE,
  CREATE_COUNTRY
} from "../../api/constants";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

export default class CreatePlan extends Component {
  state = {
    name: "",
    service: "",
    service_id: "",
    nos: null,
    description: "",
    country: "",
    countries: [],
    country_id: "",
    currency: "BDT",
    price: null,
    days: null,
    no_users: null,
    loading: false,
    pay_go: false,
    is_pack: false,
    data: [],
    pack_details: []
  };

  componentDidMount() {
    this.countryList();
    this.getServices();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getServices = () => {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${CREATE_SERVICE}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from service list", response);
          this.setState({
            data: response.data.data
          });
        })
        .catch(error => {
          console.log("error from service list api", error);
        });
    });
  };

  countryList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch(error => {
          console.log("error from country list api", error);
        });
    });
  };

  setIspack = value => {
    this.setState({ is_pack: CHECK_BOOL(value) }, () => {
      console.log(this.state.is_pack);
    });
  };

  setPaygo = value => {
    this.setState({ pay_go: CHECK_BOOL(value) }, () => {
      console.log(this.state.pay_go);
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setDescription = evt => {
    evt.preventDefault();
    this.setState({ description: evt.target.value }, () => {
      console.log(this.state.description);
    });
  };

  setService = value => {
    this.setState({ service: value.label, service_id: value.key }, () => {
      console.log(this.state.service);
    });
  };
  setNos = evt => {
    evt.preventDefault();
    this.setState({ nos: evt.target.value }, () => {
      console.log(this.state.nos);
    });
  };
  setCountry = value => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);
    });
  };
  setCurrency = value => {
    this.setState({ currency: value }, () => {
      console.log(this.state.currency);
    });
  };
  setPrice = evt => {
    evt.preventDefault();
    this.setState({ price: evt.target.value }, () => {
      console.log(this.state.price);
    });
  };
  setDays = evt => {
    evt.preventDefault();
    this.setState({ days: evt.target.value }, () => {
      console.log(this.state.days);
    });
  };
  // setUsers = evt => {
  //   evt.preventDefault();
  //   this.setState({ no_users: evt.target.value }, () => {
  //     console.log(this.state.no_users);
  //   });
  // };

  createPlan = () => {
    if (
      this.state.name === "" ||
      this.state.description === "" ||
      this.state.country === "" ||
      this.state.price === null ||
      this.state.days === null
    ) {
      swal.fire("info", "Please enter all informations", "info");
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        pack_details: this.state.pack_details,
        description: this.state.description,
        country: this.state.country,
        currency: this.state.currency,
        price: this.state.price,
        days: this.state.days,
        is_pack: this.state.is_pack,
        pay_go: this.state.pay_go
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_PLAN}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "info");
            console.log("response from create plan", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create plan", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Create Plan"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>

          <Card>
            <Form.Item>
              <Text>Service Type</Text>
              <Select
                labelInValue
                value={{
                  key: this.state.service_id,
                  label: this.state.service
                }}
                onChange={this.setService}
                placeholder="Select Service"
              >
                {this.state.data.map(r => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Text>NOS (Number of Services)</Text>
              <Input
                placeholder="NOS (Number of Services)"
                type="number"
                value={this.state.nos}
                onChange={this.setNos}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={() => {
                  if (this.state.service_id === "" || this.state.nos === null) {
                    swal.fire(
                      "Info",
                      "Please enter a service type and NOS",
                      "info"
                    );
                  } else {
                    let serviceObj = {
                      service_id: this.state.service_id,
                      service_name: this.state.service,
                      nos: Number(this.state.nos)
                    };

                    let tempPack = [];
                    tempPack.push(serviceObj);
                    this.setState(
                      {
                        pack_details: [...this.state.pack_details, ...tempPack]
                      },
                      () => {
                        console.log(
                          "pack details array",
                          this.state.pack_details
                        );
                      }
                    );
                  }
                }}
              >
                Add Pack Details
              </Button>
            </Form.Item>
            {this.state.pack_details.length > 0 ? (
              <Card style={{ marginTop: 5 }}>
                {this.state.pack_details.map((tag, index) => {
                  return (
                    <Tag
                      color="blue"
                      key={tag.service_id}
                    >{`Name => ${tag.service_name}, Number => ${tag.nos}`}</Tag>
                  );
                })}
              </Card>
            ) : null}
          </Card>

          <Form.Item>
            <Text>Description</Text>

            <TextArea
              rows={4}
              placeholder="Description"
              type="text"
              value={this.state.description}
              onChange={this.setDescription}
            />
          </Form.Item>

          <Form.Item>
            <Text>Is Pack</Text>
            <Select
              value={this.state.is_pack.toString()}
              onChange={this.setIspack}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Pay as You Go</Text>
            <Select
              value={this.state.pay_go.toString()}
              onChange={this.setPaygo}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Country</Text>
            <Select
              labelInValue
              value={{ key: this.state.country_id, label: this.state.country }}
              onChange={this.setCountry}
              placeholder="Select Country"
            >
              {this.state.countries.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Currency</Text>
            <Select defaultValue="BDT" onChange={this.setCurrency}>
              <Option value="BDT">BDT</Option>
              <Option value="NPR">NPR</Option>
              <Option value="AUD">AUD</Option>
              <Option value="USD">USD</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Price</Text>
            <Input
              placeholder="Price"
              type="number"
              value={this.state.price}
              onChange={this.setPrice}
            />
          </Form.Item>
          <Form.Item>
            <Text>Days</Text>
            <Input
              placeholder="Days"
              type="number"
              value={this.state.days}
              onChange={this.setDays}
            />
          </Form.Item>
          {/* <Form.Item>
            <Text>Number of Users</Text>
            <Input
              placeholder="Number of Users"
              type="number"
              value={this.state.no_users}
              onChange={this.setUsers}
            />
          </Form.Item> */}

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createPlan}
            >
              Create Plan
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
