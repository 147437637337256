import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Radio,
  Checkbox,
  Tag,
  Icon,
  Collapse,
  Table,
  Divider,
  Tabs,
  Pagination
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Map, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import {
  EDIT_ASSET,
  CREATE_CUSTOM_FIELD,
  ADD_CUSTOM_FIELDS_TO_TASK,
  MULTI_IMAGE_UPLOAD,
  ASSET_TAG_PRINT,
  ASSET_HISTORY,
  TASK_FROM_ASSET,
  USER_ROUTE,
  VEHICLE_RUNTIME_LOG
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  isValidURL,
  GET_USER_LOCAL,
  LOCAL_USER_INFO,
  randomId,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";
import moment from "moment";
import { debounce } from "lodash";

import "../login.css";

const { Text, Title } = Typography;
const { Option } = Select;
const { Panel } = Collapse;
const { Column } = Table;
const { TabPane } = Tabs;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [20, 30],
});

export default class SingleAsset extends Component {
  state = {
    name: "",
    asset: "",
    user_id: "",
    loc: { coordinates: [90.3563, 23.685] },
    address: "",
    city: "",
    country: "",
    connect_key: [],
    asset_id: "",
    cities: [],
    countries: [],
    city_id: "",
    country_id: "",
    pin: "",
    created_on: "",
    loading: false,
    custom_fields: [],
    custom_field_data: {},
    customArrayHandle: [],
    customArrayText: "",
    getCustomFieldData: {},
    org_id: "",
    assetTasks: [],
    assetBills: [],
    routeData: [],
    registration_number: "",
    purchase_unit: "",
    vehicle_owners_name: "",
    vehicle_sellers_name: "",
    chasis_number: "",
    engine_number: "",
    cc: "",
    model: "",
    brand_name: "",
    body_color: "",
    fuel_used: "",
    fuel_expense_unit: "",
    maint_expense_unit: "",
    vehicle_purchase_amount: "",
    current_mileage: "",
    remarks: "",
    sub_org: "",
    maint_category: "yearly",
    maint_renewal_date: "",
    maint_renewal_date_view: moment(),
    mileage_settings: 0,

    tax_token_file: "",
    tax_token_file_thumb: "",
    tax_token_expiry_date: Date.now(),
    tax_token_expiry_date_view: moment(),
    tax_token_renewal_reminder_date: Date.now(),
    tax_token_renewal_reminder_date_view: moment(),

    fitness_document_file: "",
    fitness_document_file_thumb: "",
    fitness_expiry_date: Date.now(),
    fitness_expiry_date_view: moment(),
    fitness_document_renewal_reminder_date: Date.now(),
    fitness_document_renewal_reminder_date_view: moment(),

    route_permit_document_file: "",
    route_permit_document_file_thumb: "",
    route_permit_document_expiry_date: Date.now(),
    route_permit_document_expiry_date_view: moment(),
    route_permit_document_renewal_reminder_date: Date.now(),
    route_permit_document_renewal_reminder_date_view: moment(),

    insurance_document_file: "",
    insurance_document_file_thumb: "",
    insurance_document_expiry_date: Date.now(),
    insurance_document_expiry_date_view: moment(),
    insurance_document_renewal_reminder_date: Date.now(),
    insurance_document_renewal_reminder_date_view: moment(),

    purchase_date: Date.now(),
    purchase_date_view: moment(),
    registration_date: Date.now(),
    registration_date_view: moment(),
    activeTab: "1",

    //new fields
    asset_variant: "",
    asset_sub_variant: "",
    asset_status: "",
    body: "",
    year: "",
    length: "",
    width: "",
    height: "",
    wheelbase: "",
    kerb_weight: "",
    doors: "",
    seating_capacity: "",
    no_of_rows: "",
    fuel_tank_capacity: "",
    load_capacity: "",
    primary_fuel_type: "",
    combined_range: "",
    hv_braking_system: "",
    battery_type: "",
    battery_capacity: "",
    elec_motor_power: "",
    range: "",
    charging_port_type: "",
    charging_time: "",
    battery_health: "",
    charging_cycle_count: "",
    ev_braking_system: "",
    dv_fuel_type: "",
    no_of_engine_cylinders: "",
    fuel_efficiency: "",
    engine_displacement: "",
    turbo_charger: "",
    no_of_fuel_tank: "",
    fuel_cost_per_1000_km: "",
    fuel_cost_per_fil: "",
    add_blue_system: "",
    dpf_status: "",
    egr_system: "",
    dv_fuel_tank_capacity: "",
    other_fuel_type: "",
    other_fuel_tank_capacity: "",
    fueling_time: "",
    fuel_range: "",
    engine_type: "fuel",
    pressure_rating: "",
    tank_inspection_date: Date.now(),
    tank_inspection_date_view: moment(),
    data: [],
    page: 0,
    total: 0,
    limit: 10,
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    this.setState({ org_id: GET_USER_LOCAL().organization.$oid });
    this.getCustomFields();
    this.getAsset();
    this.assetHistoryTask();
    this.assetHistoryBill();
    this.getVehicleRuntimeLog();
    this.setState({ asset_id: this.props.location.state.asset_id });
  }

  getVehicleRuntimeLog = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${VEHICLE_RUNTIME_LOG}${this.props.location.state.asset_id}?page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from vehicle runtime list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from vehicle runtime list api", error);
        });
    });
  }, 500);

  pageChange = (current, pageSize) => {
    this.setState({ page: current-1 }, () => {
      console.log("page number", this.state.page);
      this.getVehicleRuntimeLog();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getVehicleRuntimeLog();
    });
  };

  getCustomFields = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${CREATE_CUSTOM_FIELD}?field_for=asset&access_token=${token}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({
            custom_fields: response.data.data,
          });
          console.log("response from get custom fields", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from get custom fields", error);
        });
    });
  };

  getAsset = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let asset_id = this.props.location.state.asset_id;
      let url = `${EDIT_ASSET}${asset_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro view asset", response.data.data);
        this.setState({
          name: response.data.data.name,
          asset: response.data.data.asset,
          user_id: response.data.data.user_id,
          address: response.data.data.address,
          city: response.data.data.city,
          country: response.data.data.country,
          created_on: response.data.data.created_on.$date,
          pin: response.data.data.pin,
          getCustomFieldData: response.data?.data?.custom_fields ?? {},
          registration_number: response.data.data.registration_number ?? "",
          purchase_unit: response.data.data.purchase_unit ?? "",
          vehicle_owners_name: response.data.data.vehicle_owners_name ?? "",
          vehicle_sellers_name: response.data.data.vehicle_sellers_name ?? "",
          chasis_number: response.data.data.chasis_number ?? "",
          engine_number: response.data.data.engine_number ?? "",
          cc: response.data.data.cc ?? "",
          model: response.data.data.model ?? "",
          brand_name: response.data.data.brand_name ?? "",
          body_color: response.data.data.body_color ?? "",
          fuel_used: response.data.data.fuel_used ?? "",
          fuel_expense_unit: response.data.data.fuel_expense_unit ?? "",
          maint_expense_unit: response.data.data.maint_expense_unit ?? "",
          vehicle_purchase_amount:
            response.data.data.vehicle_purchase_amount ?? "",
          current_mileage: response.data.data.current_mileage ?? "",
          remarks: response.data.data.remarks ?? "",
          sub_org: response.data.data.sub_org.name ?? "",
          maint_category: response.data.data.maint_category ?? "yearly",
          maint_renewal_date:
            response?.data?.data?.maint_renewal_date?.$date ?? "",
          maint_renewal_date_view:
            response?.data?.data?.maint_renewal_date?.$date ?? moment(),
          mileage_settings: response.data.data.mileage_settings ?? 0,

          tax_token_file: response.data.data.tax_token_file ?? "",
          tax_token_file_thumb: response.data.data.tax_token_file_thumb ?? "",
          tax_token_expiry_date:
            response?.data?.data?.tax_token_expiry_date?.$date ?? "",
          tax_token_renewal_reminder_date:
            response?.data?.data?.tax_token_renewal_reminder_date?.$date ?? "",

          fitness_document_file: response.data.data.fitness_document_file ?? "",
          fitness_document_file_thumb:
            response.data.data.fitness_document_file_thumb ?? "",
          fitness_expiry_date:
            response?.data?.data?.fitness_expiry_date?.$date ?? "",
          fitness_document_renewal_reminder_date:
            response?.data?.data?.fitness_document_renewal_reminder_date
              ?.$date ?? "",

          route_permit_document_file:
            response.data.data.route_permit_document_file ?? "",
          route_permit_document_file_thumb:
            response.data.data.route_permit_document_file_thumb ?? "",
          route_permit_document_expiry_date:
            response?.data?.data?.route_permit_document_expiry_date?.$date ??
            "",
          route_permit_document_renewal_reminder_date:
            response?.data?.data?.route_permit_document_renewal_reminder_date
              ?.$date ?? "",

          insurance_document_file:
            response.data.data.insurance_document_file ?? "",
          insurance_document_file_thumb:
            response.data.data.insurance_document_file_thumb ?? "",
          insurance_document_expiry_date:
            response?.data?.data?.insurance_document_expiry_date?.$date ?? "",
          insurance_document_renewal_reminder_date:
            response?.data?.data?.insurance_document_renewal_reminder_date
              ?.$date ?? "",

          purchase_date: response?.data?.data?.purchase_date?.$date ?? "",
          registration_date:
            response?.data?.data?.registration_date?.$date ?? "",

          asset_variant: response?.data?.data?.asset_variant ?? "",
          asset_sub_variant: response?.data?.data?.asset_sub_variant ?? "",
          asset_status: response?.data?.data?.asset_status ?? "",
          body: response?.data?.data?.body ?? "",
          year: response?.data?.data?.year ?? "",
          length: response?.data?.data?.length ?? "",
          width: response?.data?.data?.width ?? "",
          height: response?.data?.data?.height ?? "",
          wheelbase: response?.data?.data?.wheelbase ?? "",
          kerb_weight: response?.data?.data?.kerb_weight ?? "",
          doors: response?.data?.data?.doors ?? "",
          seating_capacity: response?.data?.data?.seating_capacity ?? "",
          no_of_rows: response?.data?.data?.no_of_rows ?? "",
          fuel_tank_capacity: response?.data?.data?.fuel_tank_capacity ?? "",
          load_capacity: response?.data?.data?.load_capacity ?? "",
          primary_fuel_type: response?.data?.data?.primary_fuel_type ?? "",
          combined_range: response?.data?.data?.combined_range ?? "",
          hv_braking_system: response?.data?.data?.hv_braking_system ?? "",
          battery_type: response?.data?.data?.battery_type ?? "",
          battery_capacity: response?.data?.data?.battery_capacity ?? "",
          elec_motor_power: response?.data?.data?.elec_motor_power ?? "",
          range: response?.data?.data?.range ?? "",
          charging_port_type: response?.data?.data?.charging_port_type ?? "",
          charging_time: response?.data?.data?.charging_time ?? "",
          battery_health: response?.data?.data?.battery_health ?? "",
          charging_cycle_count:
            response?.data?.data?.charging_cycle_count ?? "",
          ev_braking_system: response?.data?.data?.ev_braking_system ?? "",
          dv_fuel_type: response?.data?.data?.dv_fuel_type ?? "",
          no_of_engine_cylinders:
            response?.data?.data?.no_of_engine_cylinders ?? "",
          fuel_efficiency: response?.data?.data?.fuel_efficiency ?? "",
          engine_displacement: response?.data?.data?.engine_displacement ?? "",
          turbo_charger: response?.data?.data?.turbo_charger ?? "",
          no_of_fuel_tank: response?.data?.data?.no_of_fuel_tank ?? "",
          fuel_cost_per_1000_km:
            response?.data?.data?.fuel_cost_per_1000_km ?? "",
          fuel_cost_per_fil: response?.data?.data?.fuel_cost_per_fil ?? "",
          add_blue_system: response?.data?.data?.add_blue_system ?? "",
          dpf_status: response?.data?.data?.dpf_status ?? "",
          egr_system: response?.data?.data?.egr_system ?? "",
          dv_fuel_tank_capacity:
            response?.data?.data?.dv_fuel_tank_capacity ?? "",
          other_fuel_type: response?.data?.data?.other_fuel_type ?? "",
          other_fuel_tank_capacity:
            response?.data?.data?.other_fuel_tank_capacity ?? "",
          fueling_time: response?.data?.data?.fueling_time ?? "",
          fuel_range: response?.data?.data?.fuel_range ?? "",
          engine_type: response?.data?.data?.engine_type ?? "",
          pressure_rating: response?.data?.data?.pressure_rating ?? "",
          tank_inspection_date:
            response?.data?.data?.tank_inspection_date?.$date ?? Date.now(),
          tank_inspection_date_view:
            response?.data?.data?.tank_inspection_date?.$date ?? moment(),
        });
      });
    });
  };

  onChange = (activeTab) => {
    console.log(`onChange ${activeTab}`);
    this.setState({
      activeTab,
    });
  };

  assetHistoryTask = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ASSET_HISTORY}${this.state.asset_id}?field_for=task&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response from asset task history", response);
        this.setState({ assetTasks: response.data.data });
      });
    });
  };

  assetHistoryBill = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ASSET_HISTORY}${this.state.asset_id}?field_for=bill&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response from asset bill history", response);
        this.setState({ assetBills: response.data.data });
      });
    });
  };

  assetTagprint = (item_id, item) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_TAG_PRINT}?asset_id=${this.state.asset_id}&org_id=${this.state.org_id}&access_token=${token}`;
      axios
        .get(url)
        .then((response) => {
          console.log("response from asset print list fmt", response);
          if (response.data.status === "success") {
            window
              .open(
                `${process.env.REACT_APP_BASE_PATH}${response.data.data.asset_tag_pdf_file_path}`,
                "_blank"
              )
              .focus();
          } else {
            swal.fire("Info", response.data.message, "info");
          }
        })
        .catch((error) => {
          console.log("error from asset print list fmt", error);
        });
    });
  };

  updateCustomAssetData = (checked) => {
    if (Object.keys(this.state.custom_field_data).length === 0) {
      swal.fire("Info", "Please Enter Atleast One Value", "info");
    } else {
      const data = {
        field_for: "asset",
        custom_fields: this.state.custom_field_data,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${ADD_CUSTOM_FIELDS_TO_TASK}${this.state.asset_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Success", response.data.message, "success");
            if (response.data.message === "Data successfully updated!") {
              this.getAsset();
            }
            console.log(
              "response from custom field asset data update ",
              response
            );
          })
          .catch((error) => {
            this.getAsset();
            swal.fire("Error", error.message, "error");
            console.log("error from custom field asset data update ", error);
          });
      });
    }
  };

  generateTask = () => {
    //   this.setState({ loading: true });
    let data = {
      name: this.state.name,
      details: "",
      end_loc: "",
      end_loc_name: "",
      task_code: randomId(10),
      price: 0,
      start_loc: "",
      start_loc_name: "",
      end_time: "",
      start_time: "",
      assigned_to: this.state.user_id,
      assigned_to_name: "",
      start_dist: 1000,
      end_dist: 1000,
      trip_duration: 0,
      delay_mins: null,
      assigned_asset: this.state.asset_id,
      assigned_asset_name: this.state.name,
      prev_task_id: "",
      m_task_id: "",
      contact_no: "",
      contact_name: "",
      drop_contact_name: "",
      drop_contact_no: "",
      colors: [],
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${TASK_FROM_ASSET}${this.state.asset_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          // this.setState({ loading: false });
          swal.fire("Info", response.data.message, "info");
          console.log("response from generate task from asset put", response);
        })
        .catch((error) => {
          // this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from generate task from asset put", error);
        });
    });
  };

  getRouteHistory = (e, task) => {
    console.log("checkbox", e.target.checked);
    console.log("task", task);
    if (e.target.checked) {
      let date = new Date();
      let time = date.getTime();
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${USER_ROUTE}?limit=1000&ts=${time}&search_start_time=${true}&search_end_time=${true}&start_time=${
          task.started_on.$date
        }&end_time=${task.completed_on.$date}&search_user=${
          task.assigned_to
        }&access_token=${token}`;

        axios({
          method: "get",
          url: url,
        })
          .then((response) => {
            console.log("response from user route api", response);
            if (response.data.data.length === 0) {
              swal.fire("Info", "No Route Data Found", "info");
            } else {
              this.setState({ routeData: response.data.data });
            }
          })
          .catch((error) => {
            console.log("error from user route api", error);
          });
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const { custom_field_data } = this.state;
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          onBack={() => this.props.history.goBack()}
          title="View Asset"
        />
        <Card className="view">
          <Collapse defaultActiveKey={"General Information"}>
            <Panel header="General Information" key="General Information">
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Name</Text>:{" "}
                {this.state.name}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Asset (IMEI or Device Number)
                </Text>
                : {this.state.asset}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Registration Number</Text>:{" "}
                {this.state.registration_number}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Address</Text>:{" "}
                {this.state.address}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Country</Text>:{" "}
                {this.state.country}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>City</Text>:{" "}
                {this.state.city}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Purchase Date</Text>:{" "}
                {timeConverter(this.state.purchase_date)}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Registration Date</Text>:{" "}
                {timeConverter(this.state.registration_date)}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Purchase Unit</Text>:{" "}
                {this.state.purchase_unit}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Vehicle Owners Name</Text>:{" "}
                {this.state.vehicle_owners_name}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Vehicle Sellers Name</Text>
                : {this.state.vehicle_sellers_name}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Chasis Number</Text>:{" "}
                {this.state.chasis_number}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Select Sub Organization
                </Text>
                : {this.state.sub_org}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Remarks</Text>:{" "}
                {this.state.remarks}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Asset Status</Text>:{" "}
                {this.state.asset_status}
              </p>
            </Panel>
            <Panel header="Body Specs" key="Body Specs">
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Model</Text>:{" "}
                {this.state.model}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Brand Name</Text>:{" "}
                {this.state.brand_name}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Body Color</Text>:{" "}
                {this.state.body_color}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Asset Type</Text>:{" "}
                {this.state.asset_variant}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Body</Text>:{" "}
                {this.state.body}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Year</Text>:{" "}
                {this.state.year}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Length</Text>:{" "}
                {this.state.length}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Width</Text>:{" "}
                {this.state.width}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Height</Text>:{" "}
                {this.state.height}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Wheelbase</Text>:{" "}
                {this.state.wheelbase}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Kerb Weight</Text>:{" "}
                {this.state.kerb_weight}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Doors</Text>:{" "}
                {this.state.doors}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Seating Capacity</Text>:{" "}
                {this.state.seating_capacity}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>No. of Rows</Text>:{" "}
                {this.state.no_of_rows}
              </p>

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Load Capacity</Text>:{" "}
                {this.state.load_capacity}
              </p>
            </Panel>
            <Panel
              header="Engine, Battery and Fuel Specs"
              key="Engine, Battery and Fuel Specs"
            >
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Engine Type</Text>:{" "}
                {this.state.engine_type}
              </p>

              {this.state.engine_type === "hybrid" && (
                <>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Primary Fuel Type
                    </Text>
                    : {this.state.primary_fuel_type}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Combined Range</Text>:{" "}
                    {this.state.combined_range}
                  </p>
                </>
              )}

              {(this.state.engine_type === "electric" ||
                this.state.engine_type === "hybrid") && (
                <>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Battery Type</Text>:{" "}
                    {this.state.battery_type}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Battery Capacity</Text>
                    : {this.state.battery_capacity}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Elec Motor Power</Text>
                    : {this.state.elec_motor_power}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Range</Text>:{" "}
                    {this.state.range}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Charging Port Type
                    </Text>
                    : {this.state.charging_port_type}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Charging Time</Text>:{" "}
                    {this.state.charging_time}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Battery Health</Text>:{" "}
                    {this.state.battery_health}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Charging Cycle Count
                    </Text>
                    : {this.state.charging_cycle_count}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Regenerative Braking System
                    </Text>
                    : {this.state.ev_braking_system}
                  </p>
                </>
              )}

              {this.state.engine_type === "fuel" && (
                <>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>DV Fuel Type</Text>:{" "}
                    {this.state.dv_fuel_type}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Fuel Efficiency</Text>:{" "}
                    {this.state.fuel_efficiency}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Engine Displacement
                    </Text>
                    : {this.state.engine_displacement}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Turbo Charger</Text>:{" "}
                    {this.state.turbo_charger}
                  </p>
                </>
              )}
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Ad Blue System</Text>:{" "}
                {this.state.add_blue_system}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Diesel Particulate Filter (DPF) Status
                </Text>
                : {this.state.dpf_status}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Exhaust Gas Recirculation (EGR) System
                </Text>
                : {this.state.egr_system}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  DV Fuel Tank Capacity
                </Text>
                : {this.state.dv_fuel_tank_capacity}
              </p>

              {this.state.engine_type === "cng" && (
                <>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>
                      No. of Engine Cylinders
                    </Text>
                    : {this.state.no_of_engine_cylinders}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Other Fuel Type</Text>:{" "}
                    {this.state.other_fuel_type}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Other Fuel Tank Capacity
                    </Text>
                    : {this.state.other_fuel_tank_capacity}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Fueling Time</Text>:{" "}
                    {this.state.fueling_time}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Fuel Range</Text>:{" "}
                    {this.state.fuel_range}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>Pressure Rating</Text>:{" "}
                    {this.state.pressure_rating}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Tank Inspection Date
                    </Text>
                    :{" "}
                    {this.state.tank_inspection_date_view
                      ? this.state.tank_inspection_date_view.toString()
                      : ""}
                  </p>
                </>
              )}

              {(this.state.engine_type === "fuel" ||
                this.state.engine_type === "cng" ||
                this.state.engine_type === "hybrid") && (
                <>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Fuel Cost per 1000 km
                    </Text>
                    : {this.state.fuel_cost_per_1000_km}
                  </p>
                  <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                    <Text style={{ fontWeight: "bold" }}>
                      Fuel Cost per Fill
                    </Text>
                    : {this.state.fuel_cost_per_fil}
                  </p>
                </>
              )}

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Engine Number</Text>:{" "}
                {this.state.engine_number}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>CC</Text>: {this.state.cc}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Vehicle Purchase Amount
                </Text>
                : {this.state.vehicle_purchase_amount}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Fuel Tank Capacity</Text>:{" "}
                {this.state.fuel_tank_capacity}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>HV Braking System</Text>:{" "}
                {this.state.hv_braking_system}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>No. of Fuel Tank</Text>:{" "}
                {this.state.no_of_fuel_tank}
              </p>
            </Panel>
            <Panel header="Maintenance Specs" key="Maintenance Specs">
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Fuel Used</Text>:{" "}
                {this.state.fuel_used}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Fuel Expense Unit</Text>:{" "}
                {this.state.fuel_expense_unit}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Maintainance Expense Unit
                </Text>
                : {this.state.maint_expense_unit}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Current Mileage</Text>:{" "}
                {this.state.current_mileage}
              </p>
              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>
                  Maintainance Category
                </Text>
                : {this.state.maint_category}
              </p>

              {this.state.maint_category !== "per mileage" && (
                <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Maintainance Renewal Date
                  </Text>
                  :{" "}
                  {this.state.maint_renewal_date
                    ? timeConverter(this.state.maint_renewal_date)
                    : ""}
                </p>
              )}

              <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                <Text style={{ fontWeight: "bold" }}>Mileage Settings</Text>:{" "}
                {this.state.mileage_settings}
              </p>
            </Panel>
          </Collapse>
          <Card style={{ margin: 5 }}>
            <Title style={{ marginLeft: 1 }} level={4}>
              Document Specs
            </Title>
            <Tabs
              destroyInactiveTabPane={true}
              activeKey={this.state.activeTab}
              onChange={this.onChange}
            >
              <TabPane tab="Tax Token Information" key="1">
                <img
                  src={`${process.env.REACT_APP_BASE_PATH}${this.state.tax_token_file}`}
                  alt="avatar"
                  style={{ width: 500, height: 500, margin: 10 }}
                />
                <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Tax Token Expiry Date
                  </Text>
                  :{" "}
                  {this.state.tax_token_expiry_date
                    ? timeConverter(this.state.tax_token_expiry_date)
                    : ""}
                </p>
                <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Tax Token Renewal Reminder Date
                  </Text>
                  :{" "}
                  {this.state.tax_token_renewal_reminder_date
                    ? timeConverter(this.state.tax_token_renewal_reminder_date)
                    : ""}
                </p>
              </TabPane>
              <TabPane tab="Fitness Documents" key="2">
                <img
                  src={`${process.env.REACT_APP_BASE_PATH}${this.state.fitness_document_file}`}
                  alt="avatar"
                  style={{ width: 500, height: 500, margin: 10 }}
                />
                <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Fitness Expiry Date
                  </Text>
                  :{" "}
                  {this.state.fitness_expiry_date
                    ? timeConverter(this.state.fitness_expiry_date)
                    : ""}
                </p>
                <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Fitness Document Renewal Reminder Date
                  </Text>
                  :{" "}
                  {this.state.fitness_document_renewal_reminder_date
                    ? timeConverter(
                        this.state.fitness_document_renewal_reminder_date
                      )
                    : ""}
                </p>
              </TabPane>

              <TabPane tab="Route Permit Documents" key="3">
                <img
                  src={`${process.env.REACT_APP_BASE_PATH}${this.state.route_permit_document_file}`}
                  alt="avatar"
                  style={{ width: 500, height: 500, margin: 10 }}
                />
                <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Route Permit Expiry Date
                  </Text>
                  :{" "}
                  {this.state.route_permit_document_expiry_date
                    ? timeConverter(
                        this.state.route_permit_document_expiry_date
                      )
                    : ""}
                </p>
                <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Route Permit Document Renewal Reminder Date
                  </Text>
                  :{" "}
                  {this.state.route_permit_document_renewal_reminder_date
                    ? timeConverter(
                        this.state.route_permit_document_renewal_reminder_date
                      )
                    : ""}
                </p>
              </TabPane>

              <TabPane tab="Insurance Documents" key="4">
                <img
                  src={`${process.env.REACT_APP_BASE_PATH}${this.state.insurance_document_file}`}
                  alt="avatar"
                  style={{ width: 500, height: 500, margin: 10 }}
                />
                <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Insurance Document Expiry Date
                  </Text>
                  :{" "}
                  {this.state.insurance_document_expiry_date
                    ? timeConverter(this.state.insurance_document_expiry_date)
                    : ""}
                </p>
                <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
                  <Text style={{ fontWeight: "bold" }}>
                    Insurance Document Renewal Reminder Date
                  </Text>
                  :{" "}
                  {this.state.insurance_document_renewal_reminder_date
                    ? timeConverter(
                        this.state.insurance_document_renewal_reminder_date
                      )
                    : ""}
                </p>
              </TabPane>
            </Tabs>
          </Card>
          <Button
            onClick={this.generateTask}
            className="login-form-button"
            style={{ marginTop: 10 }}
          >
            Generate Task
          </Button>
          <Button
            onClick={this.assetTagprint}
            className="login-form-button"
            style={{ marginTop: 10 }}
          >
            Print Tag
          </Button>
        </Card>
        <Card className="view">
          <PageHeader
            style={{
              border: "1px solid rgb(235, 237, 240)",
            }}
            title="Asset History"
          />
          <Collapse>
            <Panel
              header={`Total Tasks Assigned : ${this.state.assetTasks.length}`}
              key="1"
            >
              <Table
                dataSource={this.state.assetTasks}
                pagination={false}
                rowKey={(record) => record._id.$oid}
                size="small"
                bordered
              >
                <Column
                  title="Task Name"
                  key="name"
                  render={(text, record) => (
                    <span>{`${record?.task_code ?? ""} : ${
                      record?.name ?? ""
                    }`}</span>
                  )}
                />
                <Column
                  title="Task Status"
                  dataIndex="task_status"
                  key="task_status"
                />
                <Column
                  title="Assigned To"
                  key="assigned_to"
                  render={(text, record) => (
                    <span>{record?.assigned_to?.name ?? ""}</span>
                  )}
                />
                <Column
                  title="Assigned Asset"
                  key="assigned_asset"
                  render={(text, record) => (
                    <span>{record?.assigned_asset?.name ?? ""}</span>
                  )}
                />

                <Column
                  title="Start Time"
                  key="start_time"
                  render={(text, record) => (
                    <span>
                      {record.start_time.$date
                        ? timeConverter(record?.start_time?.$date)
                        : ""}
                    </span>
                  )}
                />

                <Column
                  title="Start Location"
                  dataIndex="start_loc_name"
                  key="start_loc_name"
                />
                <Column
                  title="End Time"
                  key="end_time"
                  render={(text, record) => (
                    <span>
                      {record.end_time.$date
                        ? timeConverter(record?.end_time?.$date)
                        : ""}
                    </span>
                  )}
                />

                <Column
                  title="End Location"
                  dataIndex="end_loc_name"
                  key="end_loc_name"
                />

                <Column
                  title="Drop Contact"
                  key="drop_contact_name"
                  render={(text, record) => (
                    <span>{`${record?.drop_contact_name ?? ""} : ${
                      record?.drop_contact_no ?? ""
                    }`}</span>
                  )}
                />
                <Column
                  title="Action"
                  key="action"
                  render={(text, record) => (
                    <span>
                      <Link
                        to={{
                          pathname: "/viewTask",
                          state: {
                            task_id: record._id.$oid,
                            assigned_to: record.assigned_to,
                            is_public: record.is_public,
                            is_started: record.started,
                            is_accepted: record.accepted,
                            is_completed: record.completed,
                          },
                        }}
                      >
                        <Button type="link" style={{ color: "green" }}>
                          View
                        </Button>
                      </Link>
                      <Divider type="vertical" />
                      <Link
                        to={{
                          pathname: "/editTask",
                          state: {
                            task_id: record._id.$oid,
                            assigned_to: record.assigned_to,
                            is_public: record.is_public,
                            is_started: record.started,
                            is_accepted: record.accepted,
                            is_completed: record.completed,
                          },
                        }}
                      >
                        <Button type="link" style={{ color: "green" }}>
                          Edit
                        </Button>
                      </Link>
                      <div>
                        {LOCAL_USER_INFO().user.is_staff === true ? (
                          <div>
                            <Divider type="vertical" />
                            <Button
                              type="link"
                              style={{ color: "red" }}
                              onClick={() => {
                                if (window.confirm("Delete the item?")) {
                                  this.deleteTask(record._id.$oid);
                                }
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        ) : null}
                      </div>
                      {record.hasOwnProperty("order_ids") &&
                      record.assigned_to ? (
                        <div>
                          {record.assigned_to._id.$oid ===
                          GET_USER_LOCAL()._id.$oid ? (
                            <div>
                              <Divider type="vertical" />
                              <Link
                                to={{
                                  pathname: "/viewOrder",
                                  state: {
                                    order_id: record.order_ids[0],
                                    assigned_to: record.assigned_to._id.$oid,
                                  },
                                }}
                              >
                                <Button type="link" style={{ color: "gold" }}>
                                  Order
                                </Button>
                              </Link>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </span>
                  )}
                />
              </Table>
            </Panel>
            <Panel
              header={`Total Bills Assigned : ${this.state.assetBills.length}`}
              key="2"
            >
              <Table
                dataSource={this.state.assetBills}
                pagination={false}
                rowKey={(record) => record._id.$oid}
                size="small"
                bordered
              >
                <Column title="Name" dataIndex="name" key="name" />
                <Column title="Details" dataIndex="details" key="details" />
                <Column title="Bill Type" dataIndex="misc_bill_type" />
                <Column
                  title="Approved Price"
                  dataIndex="total_approved_price"
                  key="total_approved_price"
                />
                <Column
                  title="Authorised Price"
                  dataIndex="total_authorized_price"
                  key="total_authorized_price"
                />
                <Column
                  title="Recommended Price"
                  dataIndex="total_recommended_price"
                  key="total_recommended_price"
                />
                <Column
                  title="Total Price"
                  dataIndex="total_price"
                  key="total_price"
                />
                {/* <Column
            title="Supplier Name"
            dataIndex="supplier.name"
            key="supplier.name"
          /> */}
                <Column
                  title="Material Name"
                  dataIndex="items"
                  key="items"
                  render={(items) => (
                    <span>
                      {items.map((item) => (
                        <Tag
                          color="blue"
                          key={item.item}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 2,
                          }}
                        >
                          {item.item}
                        </Tag>
                      ))}
                    </span>
                  )}
                />
                <Column
                  title="Material Type"
                  dataIndex="items"
                  key="items.mtype"
                  render={(items) => (
                    <span>
                      {items.map((item) => (
                        <Tag
                          color="blue"
                          key={item.item}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 2,
                          }}
                        >
                          {item?.mtype?.name ?? ""}
                        </Tag>
                      ))}
                    </span>
                  )}
                />

                <Column
                  title="Item Quantity"
                  dataIndex="items"
                  key="items2"
                  render={(items) => (
                    <span>
                      {items.map((item) => (
                        <Tag
                          color="green"
                          key={item.item}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 2,
                          }}
                        >
                          {item.qty} {item.unit}
                        </Tag>
                      ))}
                    </span>
                  )}
                />
                <Column
                  title="Item Price"
                  dataIndex="items"
                  key="items3"
                  render={(items) => (
                    <span>
                      {items.map((item) => (
                        <Tag
                          color="red"
                          key={item.item}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 2,
                          }}
                        >
                          {item.price}
                        </Tag>
                      ))}
                    </span>
                  )}
                />

                <Column
                  title="Action"
                  key="action"
                  render={(text, record) => (
                    <span>
                      <Link
                        to={{
                          pathname: "/viewBill",
                          state: { bill_id: record._id.$oid },
                        }}
                      >
                        <Button type="link" style={{ color: "green" }}>
                          View
                        </Button>
                      </Link>
                      {record.misc_bill_type === "Purchase(General)" ||
                      record.misc_bill_type === "Purchase(Assets)" ||
                      record.misc_bill_type === "Entertainment" ? (
                        <Link
                          to={{
                            pathname: "/editBill",
                            state: { bill_id: record._id.$oid },
                          }}
                        >
                          <Button type="link" style={{ color: "green" }}>
                            Edit
                          </Button>
                        </Link>
                      ) : null}

                      {GET_USER_LOCAL().org_admin ? (
                        <div>
                          <Button
                            type="link"
                            style={{ color: "red" }}
                            onClick={() => {
                              if (window.confirm("Delete the item?")) {
                                this.deleteOrder(record._id.$oid);
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      ) : null}
                      {record.requisition_id !== "" ? (
                        <div>
                          <Link
                            to={{
                              pathname: "/editRequisition",
                              state: { req_id: record.requisition_id },
                            }}
                          >
                            <Button type="link" style={{ color: "gold" }}>
                              Requisition
                            </Button>
                          </Link>
                        </div>
                      ) : null}
                    </span>
                  )}
                />
              </Table>
            </Panel>
          </Collapse>
        </Card>
        <Card className="view">
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Vehicle Runtime Log"
        />
        
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Assigned Driver</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="current_mileage"
            render={(text, record) => <span>{record.assigned_driver?.name??""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Distance Crossed</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="current_mileage"
            render={(text, record) => <span>{record.distance_crossed}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Duration</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="current_mileage"
            render={(text, record) => <span>{record.duration}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Other Asset Duration</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="current_mileage"
            render={(text, record) => <span>{record.other_asset_duration}</span>}
          />

          
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Modified on</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="modified_on"
            render={(text, record) => (
              <span>{record.modified_on.$date?timeConverter(record.modified_on.$date):""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created on</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
        </Table>

        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page+1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>

        {this.state.assetTasks.filter((t) => t.task_status === "COMPLETED")
          .length > 0 && this.state.routeData.length > 0 ? (
          <Card className="view">
            <Map
              center={[this.state.center_lat, this.state.center_lon]}
              zoom={this.state.zoom}
              style={{ height: 800 }}
              onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
            >
              <ReactLeafletGoogleLayer
                googleMapsLoaderConf={{
                  KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                  VERSION: "3.49.2",
                }}
                type={"roadmap"}
              />

              {this.state.routeData.map((route) => (
                <Marker
                  key={route._id.$oid}
                  position={[
                    route.loc.coordinates[1],
                    route.loc.coordinates[0],
                  ]}
                  icon={red}
                >
                  <Popup>
                    <span>{timeConverter(route.timestamp.$date)}</span>
                  </Popup>
                </Marker>
              ))}
              <Polyline
                color="purple"
                positions={[
                  this.state.routeData.map((m) => [
                    m.loc.coordinates[1],
                    m.loc.coordinates[0],
                  ]),
                ]}
              />
            </Map>
          </Card>
        ) : null}

        {this.state.assetTasks.filter((t) => t.task_status === "COMPLETED")
          .length > 0 && GET_USER_LOCAL().org_admin ? (
          <Card className="view">
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              title="Select Task To View Route History"
            />
            {this.state.assetTasks
              .filter((t) => t.task_status === "COMPLETED")
              .map((task) => (
                <Checkbox
                  style={{ marginTop: 10 }}
                  key={task._id.$oid}
                  onChange={(e) => this.getRouteHistory(e, task)}
                >{`Task Code : ${task.task_code}-Assigned User:${task.assigned_to_name}`}</Checkbox>
              ))}
          </Card>
        ) : null}

        {Object.keys(this.state.getCustomFieldData).length !== 0 ? (
          <Card
            style={{
              marginLeft: 10,
              width: "100%",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              title="Custom Field Data"
            />

            <div style={{ display: "flex", flexDirection: "column" }}>
              {Object.keys(this.state.getCustomFieldData).map((el, index) => (
                <Text
                  className="task-view-text"
                  style={{ fontSize: 15 }}
                  key={index}
                >
                  <Icon type="book" style={{ margin: 5 }} />
                  {el} :{" "}
                  {Array.isArray(this.state.getCustomFieldData[el]) ? (
                    <div>
                      {this.state.getCustomFieldData[el].map((i, index) => {
                        if (isValidURL(i)) {
                          return (
                            <img
                              src={i}
                              alt=""
                              style={{ margin: 10, height: 200, width: 200 }}
                              key={index}
                            />
                          );
                        } else {
                          return (
                            <Text
                              className="task-view-text"
                              style={{ fontSize: 15 }}
                              key={index}
                            >
                              {`${i},`}
                            </Text>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    <Text
                      className="task-view-text"
                      style={{ fontSize: 15 }}
                      key={index}
                    >
                      {this.state.getCustomFieldData[el]}
                    </Text>
                  )}
                </Text>
              ))}
            </div>
          </Card>
        ) : null}

        {this.state.custom_fields.length > 0 ? (
          <div style={{ display: "flex", flex: 1 }}>
            <Card
              style={{
                marginTop: 10,
                marginLeft: 10,
                width: "100%",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              }}
            >
              <PageHeader
                style={{
                  border: "1px solid rgb(235, 237, 240)",
                }}
                title="Update Custom Fields"
              />
              <Form>
                {this.state.custom_fields.map((f, index) => {
                  return (
                    <Form.Item key={f._id.$oid}>
                      <Text>{`${f.label} (Type : ${f._type}) : `}</Text>
                      {f._type === "dropdown" ? (
                        <Select
                          onChange={(value) => {
                            let obj = {
                              [f.key_name]: value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from dropdown input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : f._type === "radio" ? (
                        <Radio.Group
                          onChange={(e) => {
                            let obj = {
                              [f.key_name]: e.target.value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from radio input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item, index) => {
                            return (
                              <Radio key={index} value={item}>
                                {item}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      ) : f._type === "checkbox" ? (
                        <Checkbox.Group
                          options={f.criteria.choices}
                          onChange={(checkedValues) => {
                            let obj = {
                              [f.key_name]: checkedValues,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from checkbox input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        />
                      ) : f._type === "multiselect" ? (
                        <Select
                          mode="multiple"
                          onChange={(value) => {
                            let obj = {
                              [f.key_name]: value,
                            };

                            this.setState(
                              {
                                custom_field_data: Object.assign(
                                  this.state.custom_field_data,
                                  obj
                                ),
                              },
                              () => {
                                console.log(
                                  "from multiselect input",
                                  this.state.custom_field_data
                                );
                              }
                            );
                          }}
                        >
                          {f.criteria.choices.map((item) => {
                            return <Option key={item}>{item}</Option>;
                          })}
                        </Select>
                      ) : (
                        <Input
                          type={
                            f._type === "image"
                              ? "file"
                              : f._type === "int" ||
                                f._type === "float" ||
                                f._type === "decimal"
                              ? "number"
                              : "text"
                          }
                          multiple={f._type === "image" ? true : false}
                          name={f._type === "image" ? "file[]" : ""}
                          onChange={(evt) => {
                            if (f._type === "list") {
                              this.setState({
                                customArrayText: evt.target.value,
                              });
                            } else if (f._type === "image") {
                              console.log(evt.target.files);
                              const { files } = evt.target;
                              GET_ACCESS_TOKEN().then((token) => {
                                const img = new FormData();

                                for (let i = 0; i < files.length; i++) {
                                  img.append(`imgFile`, files[i]);
                                }

                                console.log("in upload", img);

                                axios({
                                  method: "put",
                                  url: `${MULTI_IMAGE_UPLOAD}task?access_token=${token}`,
                                  data: img,
                                  headers: {
                                    "Content-Type": "multipart/form-data",
                                  },
                                })
                                  .then((response) => {
                                    swal.fire(
                                      "Info",
                                      response.data.message,
                                      "info"
                                    );
                                    if (
                                      response.data.message ===
                                      "Image successfully uploaded!"
                                    ) {
                                      let temp = [];
                                      response.data.data.map((res) => {
                                        temp.push(
                                          `${process.env.REACT_APP_BASE_PATH}${res.upload_path}`
                                        );
                                        return null;
                                      });

                                      let customObj = {
                                        [f.key_name]: temp,
                                      };
                                      this.setState(
                                        {
                                          custom_field_data: Object.assign(
                                            this.state.custom_field_data,
                                            customObj
                                          ),
                                        },
                                        () => {
                                          console.log(
                                            "from image input",
                                            this.state.custom_field_data
                                          );
                                        }
                                      );
                                    }
                                    console.log(
                                      "response from image api",
                                      response
                                    );
                                  })
                                  .catch((error) => {
                                    swal.fire("Error", error.message, "error");
                                    console.log("Error from upload", error);
                                  });
                              });
                            } else if (
                              f._type === "int" ||
                              f._type === "float" ||
                              f._type === "decimal"
                            ) {
                              let obj = {
                                [f.key_name]: Number(evt.target.value),
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  ),
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            } else {
                              let obj = {
                                [f.key_name]: evt.target.value,
                              };

                              this.setState(
                                {
                                  custom_field_data: Object.assign(
                                    this.state.custom_field_data,
                                    obj
                                  ),
                                },
                                () => {
                                  console.log(
                                    "from string input",
                                    this.state.custom_field_data
                                  );
                                }
                              );
                            }
                          }}
                        />
                      )}

                      {f._type === "list" ? (
                        <div>
                          <Button
                            style={{ backgroundColor: "teal", color: "white" }}
                            onClick={() => {
                              this.setState(
                                {
                                  customArrayHandle: [
                                    ...this.state.customArrayHandle,
                                    this.state.customArrayText,
                                  ],
                                },
                                () => {
                                  let obj = {
                                    [f.key_name]: this.state.customArrayHandle,
                                  };
                                  console.log("array object", obj);
                                  this.setState(
                                    {
                                      custom_field_data: Object.assign(
                                        this.state.custom_field_data,
                                        obj
                                      ),
                                    },
                                    () => {
                                      console.log(
                                        "from list input",
                                        this.state.custom_field_data
                                      );
                                    }
                                  );
                                }
                              );
                            }}
                          >
                            Add
                          </Button>
                          {custom_field_data.hasOwnProperty(f.key_name) &&
                          this.state.customArrayHandle.length > 0 ? (
                            <Button
                              style={{
                                marginLeft: 10,
                                backgroundColor: "#1A3337",
                                color: "white",
                              }}
                              onClick={() => {
                                this.setState({
                                  customArrayText: "",
                                  customArrayHandle: [],
                                });
                              }}
                            >
                              Adding Complete
                            </Button>
                          ) : null}
                        </div>
                      ) : null}
                      {f._type === "list" &&
                      custom_field_data.hasOwnProperty(f.key_name) ? (
                        <Card style={{ marginTop: 5 }}>
                          {custom_field_data[f.key_name].map((tag, index) => {
                            return (
                              <Tag color="blue" key={index}>
                                {tag}
                              </Tag>
                            );
                          })}
                        </Card>
                      ) : null}
                    </Form.Item>
                  );
                })}
                {this.state.custom_fields.length > 0 ? (
                  <Form.Item>
                    <Button
                      className="login-form-button"
                      htmlType="submit"
                      onClick={this.updateCustomAssetData}
                    >
                      Update Custom Fields
                    </Button>
                  </Form.Item>
                ) : null}
              </Form>
            </Card>
          </div>
        ) : null}
      </Card>
    );
  }
}
