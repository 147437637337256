import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_MTYPE, CREATE_BILL_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateMtype extends Component {
  state = {
    name: "",
    // mtype: "",
    data: [],
    total: "",
    bill_type_id: "",
    bill_type_name: ""
  };

  componentDidMount() {
    this.getBillTypeList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

  }

  getBillTypeList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_BILL_TYPE}?limit=50&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from bill type list", response);

          this.setState({
            data: response.data.data,
            total: response.data.total
          });
        })
        .catch(error => {
          console.log("error from bill type list api", error);
        });
    });
  };
  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  // setMtype = evt => {
  //   evt.preventDefault();
  //   this.setState({ mtype: evt.target.value }, () => {
  //     console.log(this.state.mtype);
  //   });
  // };
  setBillType = value => {
    this.setState(
      { bill_type_name: value.label, bill_type_id: value.key },
      () => {
        console.log(this.state.bill_type_name);
      }
    );
  };

  createMtype = () => {
    if (this.state.name === "" || this.state.mtype === "") {
      swal.fire("info", "Please enter all informations", "info");
    } else {
      const data = {
        name: this.state.name,
        // mtype: this.state.mtype,
        bill_type:{
          id:this.state.bill_type_id,
          name:this.state.bill_type_name
        }
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${CREATE_MTYPE}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Success", response.data.message, "success");
            console.log("response from create mtype", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from create mtype", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Create Item Type"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          {/* <Form.Item>
            <Text>Material Type</Text>
            <Input
              placeholder="Material Type"
              type="text"
              value={this.state.mtype}
              onChange={this.setMtype}
            />
          </Form.Item> */}
          <Form.Item>
            <Text>Select Bill Type</Text>
            <Select
              labelInValue
              value={{
                key: this.state.bill_type_id,
                label: this.state.bill_type_name
              }}
              onChange={this.setBillType}
              placeholder="Select Bill Type"
            >
              {this.state.data.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createMtype}
            >
              Create Item Type
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
