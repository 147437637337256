import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Tag,
  Tooltip,
  Typography,
  Select,
  Pagination,
  Divider,
  Input,
  Icon,
  Modal,
} from "antd";
import { connect } from "react-redux";
import { saveBillListParams } from "../../redux/actions/billListParamsAction";
import { getUserData } from "../../redux/actions/userProfileAction";
import { debounce } from "lodash";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
// import DateFnsUtils from "@date-io/date-fns";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import {
  EDIT_BILL,
  BILL_SEARCH,
  CREATE_BILL,
  CONVEYANCE_BILL_PDF,
  PURCHASE_BILL_PDF,
  CREATE_BILL_TYPE,
  SYNC_QUICKBOOKS_BILL,
  SYNC_XERO_BILL,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
  CHECK_BOOL,
  randomId,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import FileSaver from "file-saver";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;

class BillList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    loading: false,
    param: "upcoming",
    timezone_name: "",
    timezone_choice: "org_timezone",
    from: "",
    from_view: moment(),
    to: "",
    to_view: moment(),
    report_type: "conveyance",
    custom_field: false,
    bill_type_name: "",
    name: "",
    approved: "",
    recommended: "",
    authorized: "",
    approved_price: "",
    recommended_price: "",
    authorized_price: "",
    approver_name: "",
    recommender_name: "",
    authorizer_name: "",
    task_name: "",
    asset_name: "",
    requisition_name: "",
    supplier_name: "",
    created_by_name: "",
    modified_by_name: "",
    start_time: "",
    start_time_view: moment(),
    end_time: "",
    end_time_view: moment(),
    search_date_time_type: "created_on",
    datarange: "",
    sort_by: "created_on",
    sort_type: "dsc",
    coveyance_bill_ids: [],
    bill_no: "",
    bill_report_no: "",
    bill_report_no_status: "all",
    miscellaneous_bill_type_choices: [],
    purchase_bill_ids: [],
    loading_sync_quickbooks: false,
    loading_sync_xero: false,
    show_xero_modal: false,
    due_days_to_pay: 1,
    bill_id: "",
  };
  componentDidMount() {
    this.props.getUserData();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.setState({ timezone_name: timezone });
    console.log("time zone", timezone);
    this.getBillTypeChoices();

    console.log("redux", this.props.billListParams);
    if (Object.keys(this.props.billListParams).length !== 0) {
      console.log("inside if");
      this.setState(
        {
          bill_type_name: this.props?.billListParams?.bill_type_name ?? "",
          bill_no: this.props?.billListParams?.bill_no ?? "",
          name: this.props?.billListParams?.name ?? "",
          approved: this.props?.billListParams?.approved ?? "",
          recommended: this.props?.billListParams?.recommended ?? "",
          authorized: this.props?.billListParams?.authorized ?? "",
          approved_price: this.props?.billListParams?.approved_price ?? "",
          recommended_price:
            this.props?.billListParams?.recommended_price ?? "",
          authorized_price: this.props?.billListParams?.authorized_price ?? "",
          approver_name: this.props?.billListParams?.approver_name ?? "",
          recommender_name: this.props?.billListParams?.recommender_name ?? "",
          authorizer_name: this.props?.billListParams?.authorizer_name ?? "",
          task_name: this.props?.billListParams?.task_name ?? "",
          start_time: this.props?.billListParams?.start_time ?? "",
          start_time_view:
            this.props?.billListParams?.start_time_view ?? moment(),
          end_time: this.props?.billListParams?.end_time ?? "",
          end_time_view: this.props?.billListParams?.end_time_view ?? moment(),
          asset_name: this.props?.billListParams?.asset_name ?? "",
          requisition_name: this.props?.billListParams?.requisition_name ?? "",
          supplier_name: this.props?.billListParams?.supplier_name ?? "",
          created_by_name: this.props?.billListParams?.created_by_name ?? "",
          modified_by_name: this.props?.billListParams?.modified_by_name ?? "",
          search_date_time_type:
            this.props?.billListParams?.search_date_time_type ?? "created_on",
          limit: this.props?.billListParams?.limit ?? 10,
          page: this.props?.billListParams?.page ?? 0,
          sort_by: this.props?.billListParams?.sort_by ?? "created_on",
          bill_report_no: this.props?.billListParams?.bill_report_no ?? "",
          bill_report_no_status:
            this.props?.billListParams?.bill_report_no_status ?? "all",
          sort_type: this.props?.billListParams?.sort_type ?? "dsc",
        },
        () => {
          this.getBillList();
        }
      );
    } else {
      this.getBillList();
    }
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getBillTypeChoices = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_BILL_TYPE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            miscellaneous_bill_type_choices: response.data.data,
          });
          console.log("response from bill type choices", response);
        })
        .catch((error) => {
          console.log("error from bill type choices api", error);
        });
    });
  };

  getBillList = debounce(() => {
    let reduxdata = {
      bill_type_name: this.state.bill_type_name,
      bill_no: this.state.bill_no,
      bill_report_no_status: this.state.bill_report_no_status,
      bill_report_no: this.state.bill_report_no,
      name: this.state.name,
      approved: this.state.approved,
      recommended: this.state.recommended,
      authorized: this.state.authorized,
      approved_price: this.state.approved_price,
      recommended_price: this.state.recommended_price,
      authorized_price: this.state.authorized_price,
      approver_name: this.state.approver_name,
      recommender_name: this.state.recommender_name,
      authorizer_name: this.state.authorizer_name,
      task_name: this.state.task_name,
      start_time: this.state.start_time,
      start_time_view: this.state.start_time_view,
      end_time: this.state.end_time,
      end_time_view: this.state.end_time_view,
      asset_name: this.state.asset_name,
      requisition_name: this.state.requisition_name,
      supplier_name: this.state.supplier_name,
      created_by_name: this.state.created_by_name,
      modified_by_name: this.state.modified_by_name,
      search_date_time_type: this.state.search_date_time_type,
      sort_by: this.state.sort_by,
      sort_type: this.state.sort_type,
      limit: this.state.limit,
      page: this.state.page,
    };
    this.props.saveBillListParams(reduxdata);
    console.log("reduxdata going in", reduxdata);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${BILL_SEARCH}?page=${this.state.page}&limit=${this.state.limit}&bill_no=${this.state.bill_no}&bill_report_no_status=${this.state.bill_report_no_status}&bill_report_no=${this.state.bill_report_no}&sort_type=${this.state.sort_type}&sort_by=${this.state.sort_by}&modified_by_name=${this.state.modified_by_name}&created_by_name=${this.state.created_by_name}&supplier_name=${this.state.supplier_name}&requisition_name=${this.state.requisition_name}&asset_name=${this.state.asset_name}&task_name=${this.state.task_name}&recommender_name=${this.state.recommender_name}&authorizer_name=${this.state.authorizer_name}&approver_name=${this.state.approver_name}&authorized_price=${this.state.authorized_price}&recommended_price=${this.state.recommended_price}&approved_price=${this.state.approved_price}&authorized=${this.state.authorized}&recommended=${this.state.recommended}&approved=${this.state.approved}approved&end_time=${this.state.end_time}&start_time=${this.state.start_time}&name=${this.state.name}&bill_type_name=${this.state.bill_type_name}&search_date_time_type=${this.state.search_date_time_type}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from bill list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
            datarange: response.data.show_data,
          });
        })
        .catch((error) => {
          console.log("error from bill list api", error);
        });
    });
  }, 500);

  refresh = () => {
    this.setState(
      {
        bill_type_name: "",
        bill_no: "",
        bill_report_no: "",
        bill_report_no_status: "",
        name: "",
        approved: "",
        recommended: "",
        authorized: "",
        approved_price: "",
        recommended_price: "",
        authorized_price: "",
        approver_name: "",
        recommender_name: "",
        authorizer_name: "",
        task_name: "",
        asset_name: "",
        requisition_name: "",
        supplier_name: "",
        created_by_name: "",
        modified_by_name: "",
        sort_by: "created_on",
        sort_type: "dsc",
        start_time: "",
        start_time_view: moment(),
        end_time: "",
        end_time_view: moment(),
        search_date_time_type: "created_on",
        page: 0,
        limit: 10,
      },
      () => {
        this.getBillList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getBillList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getBillList();
    });
  };

  setTimezonetype = (value) => {
    this.setState({ timezone_choice: value }, () => {
      console.log(this.state.timezone_choice);
    });
  };
  setReporttype = (value) => {
    this.setState({ report_type: value }, () => {
      console.log(this.state.report_type);
    });
  };
  setCustomField = (value) => {
    this.setState({ custom_field: CHECK_BOOL(value) }, () => {
      console.log(this.state.custom_field);
    });
  };

  handleParamChange = (value) => {
    this.setState({ param: value }, () => {
      console.log(this.state.param);
      this.getBillList();
    });
  };

  setTo = (value, dateString) => {
    this.setState(
      { to: moment(value).format("x") / 1000, to_view: value },
      () => {
        console.log("Selected Time: ", this.state.to);
      }
    );
  };
  setFrom = (value, dateString) => {
    this.setState(
      { from: moment(value).format("x") / 1000, from_view: value },
      () => {
        console.log("Selected Time: ", this.state.from);
      }
    );
  };

  addTo = (record) => {
    let addedArray = [];
    addedArray.push(record._id.$oid);
    this.setState(
      {
        coveyance_bill_ids: [...this.state.coveyance_bill_ids, ...addedArray],
      },
      () => {
        console.log("added data conveyance", this.state.coveyance_bill_ids);
      }
    );
  };
  addToPurchase = (record) => {
    let addedArray = [];
    addedArray.push(record._id.$oid);
    this.setState(
      {
        purchase_bill_ids: [...this.state.purchase_bill_ids, ...addedArray],
      },
      () => {
        console.log("added data purchase", this.state.purchase_bill_ids);
      }
    );
  };

  removefrom = (record) => {
    let addedFiltered = this.state.coveyance_bill_ids.filter(
      (m) => m !== record._id.$oid
    );
    this.setState({ coveyance_bill_ids: addedFiltered }, () => {
      console.log("removed data conveyance", this.state.coveyance_bill_ids);
    });
  };
  removefromPurchase = (record) => {
    let addedFiltered = this.state.purchase_bill_ids.filter(
      (m) => m !== record._id.$oid
    );
    this.setState({ purchase_bill_ids: addedFiltered }, () => {
      console.log("removed data purchase", this.state.purchase_bill_ids);
    });
  };

  deleteOrder = (id) => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_BILL}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  handleSaveFile = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url =
        this.state.timezone_choice === "org_timezone"
          ? `${CREATE_BILL}?fmt=xls&timezone_choice=${this.state.timezone_choice}&custom_field=${this.state.custom_field}&report_type=${this.state.report_type}&access_token=${token}`
          : `${CREATE_BILL}?fmt=xls&timezone_choice=${this.state.timezone_choice}&timezone_name=${this.state.timezone_name}&custom_field=${this.state.custom_field}&report_type=${this.state.report_type}&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from bill list fmt", blob);

          FileSaver.saveAs(
            blob,
            `conveyance_bill_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from bill list api", error);
        });
    });
  };

  printConveyanceBills = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CONVEYANCE_BILL_PDF}?bill_ids=${this.state.coveyance_bill_ids}&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from conveyance bill pdf report", blob);
          FileSaver.saveAs(
            blob,
            `conveyance_bill_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
          this.getBillList();
          this.setState({ coveyance_bill_ids: [] });
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("response from conveyance bill pdf report", error);
        });
    });
  };
  printPurchaseBills = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PURCHASE_BILL_PDF}?bill_ids=${this.state.purchase_bill_ids}&access_token=${token}`;
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          console.log("response from purchase bill pdf report", blob);
          FileSaver.saveAs(
            blob,
            `purchase_bill_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.pdf`
          );
          this.getBillList();
          this.setState({ purchase_bill_ids: [] });
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("response from purchase bill pdf report", error);
        });
    });
  };

  selectOrRemoveAllConveyanceBills = () => {
    if (this.state.coveyance_bill_ids.length > 0) {
      this.setState({ coveyance_bill_ids: [] }, () => {
        console.log("all conveyance bills", this.state.coveyance_bill_ids);
      });
    } else {
      this.setState(
        {
          coveyance_bill_ids: this.state.data
            .filter(
              (bill) =>
                bill.misc_bill_type.name === this.state.bill_type_name &&
                !bill.bill_report_no
            )
            .map((b_id) => b_id._id.$oid),
        },
        () => {
          console.log("all conveyance bills", this.state.coveyance_bill_ids);
        }
      );
    }
  };
  selectOrRemoveAllPurchaseBills = () => {
    if (this.state.purchase_bill_ids.length > 0) {
      this.setState({ purchase_bill_ids: [] }, () => {
        console.log("all purchase bills", this.state.purchase_bill_ids);
      });
    } else {
      console.log("all purchase bills", this.state.bill_type_name);
      this.setState(
        {
          purchase_bill_ids: this.state.data
            .filter(
              (bill) =>
                bill.misc_bill_type.name === this.state.bill_type_name &&
                !bill.bill_report_no
            )
            .map((b_id) => b_id._id.$oid),
        },
        () => {
          console.log("all purchase bills", this.state.purchase_bill_ids);
        }
      );
    }
  };

  syncBillQuickbooks = (bill_id) => {
    this.setState({ loading_sync_quickbooks: true });
    console.log(bill_id);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${SYNC_QUICKBOOKS_BILL}${bill_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading_sync_quickbooks: false });
          swal.fire("Info", response.data.message, "info");
          console.log("response from quickbooks sync bill", response);
        })
        .catch((error) => {
          this.setState({ loading_sync_quickbooks: false });
          swal.fire("Error", error.message, "error");
          console.log("error from quickbooks sync bill", error);
        });
    });
  };

  syncBillXero = () => {
    this.setState({ loading_sync_xero: true });
    let data = {
      due_days_to_pay: Number(this.state.due_days_to_pay),
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${SYNC_XERO_BILL}${this.state.bill_id}?access_token=${token}`;
      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({ loading_sync_xero: false });
          swal.fire("Info", response.data.message, "info");
          console.log("response from xero sync bill", response);
        })
        .catch((error) => {
          this.setState({ loading_sync_xero: false });
          swal.fire("Error", error.message, "error");
          console.log("error from xero sync bill", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.bill.bill.bill_create ? (
          <Link to="/createBill">
            <Tooltip placement="top" title="Create New Bill">
              <Button
                shape="circle"
                icon="plus"
                style={{
                  position: "fixed",
                  zIndex: 100,
                  bottom: 30,
                  right: 20,
                  backgroundColor: "#1A3337",
                  height: 60,
                  width: 60,
                  fontSize: 30,
                  paddingBottom: 5,
                }}
              />
            </Tooltip>
          </Link>
        ) : null}

        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Bills"
        />

        {/* <Card style={{ marginTop: 5, marginBottom: 5 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left"
            }}
          >
            <div style={{ margin: 10 }}>
              <Text> Timezone : {""}</Text>
              <Select
                value={this.state.timezone_choice}
                onChange={this.setTimezonetype}
                placeholder="View as"
              >
                <Option key="user_timezone">User</Option>
                <Option key="org_timezone">Organization</Option>
              </Select>
            </div>
            <div style={{ margin: 10 }}>
              <Text> Report Type : {""}</Text>
              <Select
                value={this.state.report_type}
                onChange={this.setReporttype}
                placeholder="View as"
              >
                <Option key="conveyance">Conveyance</Option>
                <Option key="other">Other</Option>
              </Select>
            </div>
            <div style={{ margin: 10 }}>
              <Text> Custom Field : {""}</Text>
              <Select
                style={{ width: 80 }}
                value={this.state.custom_field.toString()}
                onChange={this.setCustomField}
                placeholder="Custom Field"
              >
                <Option key={true}>True</Option>
                <Option key={false}>False</Option>
              </Select>
            </div>
            <div style={{ margin: 10 }}>
              <Button
                onClick={this.handleSaveFile}
                style={{
                  width: 200,
                  backgroundColor: "#1A3337",
                  color: "white"
                }}
              >
                Download Report
              </Button>
            </div>
          </div>
        </Card> */}
        <Card>
          <Title level={4}>
            Select date range within which you would like to find your bills
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).format("x"),
                        start_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        end_time: moment(value).format("x"),
                        end_time_view: value,
                        page: 0,
                      },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 15,
              }}
            >
              <Text style={{ margin: 5 }}>Filter Time By : </Text>
              <Select
                value={this.state.search_date_time_type}
                onChange={(value) => {
                  this.setState({ search_date_time_type: value }, () => {
                    this.getBillList();
                    console.log(this.state.search_date_time_type);
                  });
                }}
                style={{ minWidth: 130 }}
              >
                <Option key="created_on">Created on</Option>
                <Option key="modified_on">Modified on</Option>
                <Option key="recommended_on">Recommended on</Option>
                <Option key="authorized_on">Authorized on</Option>
                <Option key="approved_on">Approved on</Option>
              </Select>
            </div>
          </div>
        </Card>

        {/* <Card style={{ marginTop: 5 }}>
          <div
            style={{ display: "flex", flexDirection: "row",  justifyContent: "left",marginBottom: 10 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.from_view}
                  onChange={this.setFrom}
                />
              </MuiPickersUtilsProvider>
            </div>
            <Text style={{ paddingTop: 20 }}>---</Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.to_view}
                  onChange={this.setTo}
                />
              </MuiPickersUtilsProvider>
            </div>
            <Button
              onClick={this.downloadPdfReport}
              style={{
                width: 200,
                backgroundColor: "#1A3337",
                color: "white",
                margin:10,
                marginTop:15
              }}
            >
              Download PDF
            </Button>
          </div>
        </Card> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: 15,
            }}
          >
            <Text style={{ margin: 5 }}>Bill Type : </Text>
            <Select
              value={this.state.bill_type_name}
              onChange={(value) => {
                this.setState(
                  {
                    bill_type_name: value,
                    page: 0,
                    coveyance_bill_ids: [],
                    purchase_bill_ids: [],
                  },
                  () => {
                    this.getBillList();
                    console.log(this.state.bill_type_name);
                  }
                );
              }}
              style={{ minWidth: 130 }}
            >
              {this.state.miscellaneous_bill_type_choices.map((r) => {
                return <Option key={r.name}>{r.name}</Option>;
              })}
            </Select>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: 15,
            }}
          >
            <Text style={{ margin: 5 }}>Filter Type : </Text>
            <Select
              value={this.state.bill_report_no_status}
              onChange={(value) => {
                this.setState({ bill_report_no_status: value, page: 0 }, () => {
                  this.getBillList();
                  console.log(this.state.bill_report_no_status);
                });
              }}
              style={{ minWidth: 130 }}
            >
              <Option key="report generated">Report Generated</Option>
              <Option key="not generated">Not Generated</Option>
              <Option key="all">All</Option>
            </Select>
          </div>
          <div style={{ marginTop: 10, textAlign: "center" }}>
            <Divider type="vertical" />
          </div>
          <div style={{ margin: 10, textAlign: "center", marginTop: 14 }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
          <div style={{ margin: 10, textAlign: "center", marginTop: 14 }}>
            {this.state.coveyance_bill_ids.length > 0 ? (
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#1A3337",
                }}
                htmlType="submit"
                onClick={this.printConveyanceBills}
              >
                Print Bills
              </Button>
            ) : this.state.purchase_bill_ids.length > 0 ? (
              <Button
                style={{
                  color: "white",
                  backgroundColor: "#1A3337",
                }}
                htmlType="submit"
                onClick={this.printPurchaseBills}
              >
                Print Bills
              </Button>
            ) : null}
          </div>
        </div>

        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                {/* <div>Select All</div> */}
                <button
                  style={{
                    height: 15,
                    width: 14,
                    border: "1px solid grey",
                    backgroundColor:
                      this.state.coveyance_bill_ids.length ||
                      this.state.purchase_bill_ids.length > 0
                        ? "#1E88E5"
                        : "white",
                  }}
                  onClick={() => {
                    if (
                      this.state.bill_type_name ===
                      ("Conveyance" || "Entertainment" || "Travel Allowance")
                    ) {
                      this.selectOrRemoveAllConveyanceBills();
                    } else if (this.state.bill_type_name === "") {
                      swal.fire(
                        "Warning",
                        "Please Select a bill type!",
                        "info"
                      );
                    } else {
                      this.selectOrRemoveAllPurchaseBills();
                    }
                  }}
                ></button>
              </>
            }
            key="item_id"
            render={(text, record) => (
              <div>
                {record.misc_bill_type.name === this.state.bill_type_name ? (
                  <button
                    disabled={!record.bill_report_no ? false : true}
                    style={{
                      height: 15,
                      width: 14,
                      border: "1px solid grey",
                      backgroundColor:
                        this.state.coveyance_bill_ids.some(
                          (d) => d === record._id.$oid
                        ) ||
                        this.state.purchase_bill_ids.some(
                          (d) => d === record._id.$oid
                        )
                          ? "#1E88E5"
                          : record.bill_report_no
                          ? "#ECEFF1"
                          : "white",
                    }}
                    onClick={() => {
                      if (
                        this.state.bill_type_name ===
                        ("Conveyance" || "Entertainment" || "Travel Allowance")
                      ) {
                        if (
                          this.state.coveyance_bill_ids.some(
                            (d) => d === record._id.$oid
                          )
                        ) {
                          this.removefrom(record);
                        } else {
                          this.addTo(record);
                        }
                      } else if (this.state.bill_type_name === "") {
                        swal.fire(
                          "Warning",
                          "Please Select a bill type!",
                          "info"
                        );
                      } else {
                        if (
                          this.state.purchase_bill_ids.some(
                            (d) => d === record._id.$oid
                          )
                        ) {
                          this.removefromPurchase(record);
                        } else {
                          this.addToPurchase(record);
                        }
                      }
                    }}
                  ></button>
                ) : null}
              </div>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Bill No.</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "bill_no", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "bill_no", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.bill_no}
                  onChange={(evt) => {
                    this.setState(
                      { bill_no: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="bill_no"
            key="bill_no"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Report No.</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "bill_report_no", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "bill_report_no", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.bill_report_no}
                  onChange={(evt) => {
                    this.setState(
                      { bill_report_no: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="bill_report_no"
            key="bill_report_no"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <div>Name</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "name", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.name}
                  onChange={(evt) => {
                    this.setState({ name: evt.target.value, page: 0 }, () => {
                      this.getBillList();
                    });
                  }}
                />
              </>
            }
            dataIndex="name"
            key="name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Details</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="details"
            key="details"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Bill Type</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "misc_bill_type", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "misc_bill_type", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.bill_type_name}
                  onChange={(evt) => {
                    this.setState(
                      { bill_type_name: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            render={(text, record) => (
              <span>{record?.misc_bill_type?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Requested Price</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="total_price"
            key="total_price"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Recommended Price</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_recommended_price",
                            sort_type: "asc",
                          },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_recommended_price",
                            sort_type: "dsc",
                          },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.recommended_price}
                  onChange={(evt) => {
                    this.setState(
                      { recommended_price: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="total_recommended_price"
            key="total_recommended_price"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <div>Recommended</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "recommended", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "recommended", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.recommended}
                  onChange={(evt) => {
                    this.setState(
                      { recommended: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Recommended"
            render={(text, record) => (
              <Tag
                color={
                  record.recommended === "pending"
                    ? "blue"
                    : record.recommended === "approved"
                    ? "green"
                    : "red"
                }
              >
                {record.recommended}
              </Tag>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <div>Recommender</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "recommender_name", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "recommender_name", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.recommender_name}
                  onChange={(evt) => {
                    this.setState(
                      { recommender_name: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Recommender"
            render={(text, record) => (
              <span>{record?.recommender_name?.toString() ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Authorized Price</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_authorized_price",
                            sort_type: "asc",
                          },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          {
                            sort_by: "total_authorized_price",
                            sort_type: "dsc",
                          },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.authorized_price}
                  onChange={(evt) => {
                    this.setState(
                      { authorized_price: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="total_authorized_price"
            key="total_authorized_price"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <div>Authorized</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "authorized", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "authorized", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.authorized}
                  onChange={(evt) => {
                    this.setState(
                      { authorized: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Authorized"
            render={(text, record) => (
              <Tag
                color={
                  record.authorized === "pending"
                    ? "blue"
                    : record.authorized === "approved"
                    ? "green"
                    : "red"
                }
              >
                {record.authorized}
              </Tag>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <div>Authorizer</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "authorizer_name", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "authorizer_name", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.authorizer_name}
                  onChange={(evt) => {
                    this.setState(
                      { authorizer_name: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Authorizer"
            render={(text, record) => (
              <span>{record?.authorizer_name?.toString() ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Approved Price</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "total_approved_price", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "total_approved_price", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.approved_price}
                  onChange={(evt) => {
                    this.setState(
                      { approved_price: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="total_approved_price"
            key="total_approved_price"
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <div>Approved</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "approved", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "approved", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.approved}
                  onChange={(evt) => {
                    this.setState(
                      { approved: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Approved"
            render={(text, record) => (
              <Tag
                color={
                  record.approved === "pending"
                    ? "blue"
                    : record.approved === "approved"
                    ? "green"
                    : "red"
                }
              >
                {record.approved}
              </Tag>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <div>Approver</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "approver_name", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "approver_name", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.approver_name}
                  onChange={(evt) => {
                    this.setState(
                      { approver_name: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Approver"
            render={(text, record) => (
              <span>{record?.approver_name?.toString() ?? ""}</span>
            )}
          />

          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Task Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_name", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "task_name", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.task_name}
                  onChange={(evt) => {
                    this.setState(
                      { task_name: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Task Name"
            render={(text, record) => <span>{record?.task_name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Asset Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "asset_name", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "asset_name", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.asset_name}
                  onChange={(evt) => {
                    this.setState(
                      { asset_name: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Asset Name"
            render={(text, record) => <span>{record?.asset_name ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Supplier Name</Text>
                </div>

                <Input
                  value={this.state.supplier_name}
                  onChange={(evt) => {
                    this.setState(
                      { supplier_name: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
                {/* <Icon
                  type="caret-up"
                  onClick={() => {
                    this.setState(
                      { sort_by: "total_recommended_price", sort_type: "asc" },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
                <Icon
                  type="caret-down"
                  onClick={() => {
                    this.setState(
                      { sort_by: "total_recommended_price", sort_type: "dsc" },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                /> */}
              </>
            }
            key="Supplier Name"
            render={(text, record) => (
              <span>{record?.supplier?.name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Requisition Name</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "requisition_name", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "requisition_name", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.requisition_name}
                  onChange={(evt) => {
                    this.setState(
                      { requisition_name: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Requisition Name"
            render={(text, record) => (
              <span>{record?.requisition_name ?? ""}</span>
            )}
          />
          {/* <Column
            title="Supplier Name"
            dataIndex="supplier.name"
            key="supplier.name"
          /> */}
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Item Name</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="items"
            key="items"
            render={(items) => (
              <span>
                {items.map((item) => (
                  <Tag
                    color="blue"
                    key={randomId(15)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2,
                    }}
                  >
                    {item.item}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Item type</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="items"
            key="items.mtype"
            render={(items) => (
              <span>
                {items.map((item) => (
                  <Tag
                    color="blue"
                    key={randomId(15)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2,
                    }}
                  >
                    {item?.mtype?.name ?? ""}
                  </Tag>
                ))}
              </span>
            )}
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Item Quantity</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="items"
            key="items2"
            render={(items) => (
              <span>
                {items.map((item) => (
                  <Tag
                    color="green"
                    key={randomId(15)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2,
                    }}
                  >
                    {item.qty} {item.unit}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Item Price</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="items"
            key="items3"
            render={(items) => (
              <span>
                {items.map((item) => (
                  <Tag
                    color="red"
                    key={randomId(15)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2,
                    }}
                  >
                    {item.price}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created On</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Created By</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_by_name", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "created_by_name", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.created_by_name}
                  onChange={(evt) => {
                    this.setState(
                      { created_by_name: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Created By"
            render={(text, record) => (
              <span>{record?.created_by_name ?? ""}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created For</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="created_for"
            render={(text, record) => (
              <span>{`Name-${record?.created_for?.name ?? ""} ${
                record?.created_for?.mobile ?? ""
              }`}</span>
            )}
          />
          <Column
            title={
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 2,
                  }}
                >
                  <Text ellipsis={true}>Modified By</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 2,
                    }}
                  >
                    <Icon
                      type="caret-up"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_by_name", sort_type: "asc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                    <Icon
                      type="caret-down"
                      onClick={() => {
                        this.setState(
                          { sort_by: "modified_by_name", sort_type: "dsc" },
                          () => {
                            this.getBillList();
                          }
                        );
                      }}
                    />
                  </div>
                </div>

                <Input
                  value={this.state.modified_by_name}
                  onChange={(evt) => {
                    this.setState(
                      { modified_by_name: evt.target.value, page: 0 },
                      () => {
                        this.getBillList();
                      }
                    );
                  }}
                />
              </>
            }
            key="Modified By"
            render={(text, record) => (
              <span>{record?.modified_by_name ?? ""}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {record?.misc_bill_type?.name === "Purchase General" ||
                record?.misc_bill_type?.name === "Purchase Assets" ||
                record?.misc_bill_type?.name === "Entertainment" ? (
                  <Button
                    loading={this.state.bill_id===record._id.$oid?this.state.loading_sync_quickbooks:false}
                    onClick={() => {
                      this.setState({ bill_id: record._id.$oid }, () => {
                        this.syncBillQuickbooks(record._id.$oid);
                      });
                    }}
                    type="link"
                    style={{ color: "green" }}
                  >
                    Sync With Quickbooks
                  </Button>
                ) : null}

                {record?.misc_bill_type?.name === "Purchase General" ||
                record?.misc_bill_type?.name === "Purchase Assets" ||
                record?.misc_bill_type?.name === "Entertainment" ? (
                  <Button
                    onClick={() =>
                      this.setState({
                        bill_id: record._id.$oid,
                        show_xero_modal: true,
                      })
                    }
                    type="link"
                    style={{ color: "green" }}
                  >
                    Sync With Xero
                  </Button>
                ) : null}

                <Link
                  to={{
                    pathname: "/createReminder",
                    state: { bill_id: record._id.$oid, bill_name: record.name },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    Add Reminder
                  </Button>
                </Link>
                {this.props.userProfileData.incident.incident_create &&
                !record.incident_id ? (
                  <Link
                    to={{
                      pathname: "/createIncident",
                      state: {
                        bill_id: record._id.$oid,
                        bill_name: record.bill_no,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Add Incident
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.bill.bill.bill_detail ? (
                  <Link
                    to={{
                      pathname: "/viewBill",
                      state: { bill_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View
                    </Button>
                  </Link>
                ) : null}

                {record.bill_report_url ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_BASE_PATH}${record.bill_report_url}`
                      )
                    }
                  >
                    View Downloaded Bill
                  </Button>
                ) : null}
                {this.props.userProfileData.bill.bill.bill_update &&
                (record.misc_bill_type.name === "Purchase General" ||
                  record.misc_bill_type.name === "Purchase Assets" ||
                  record.misc_bill_type.name === "Entertainment") ? (
                  <Link
                    to={{
                      pathname: "/editBill",
                      state: { bill_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}

                {GET_USER_LOCAL().org_admin ? (
                  <div>
                    <Button
                      type="link"
                      style={{ color: "red" }}
                      onClick={() => {
                        if (window.confirm("Delete the item?")) {
                          this.deleteOrder(record._id.$oid);
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                ) : null}
                {/* {record.requisition_id !== "" ? (
                  <div>
                    <Link
                      to={{
                        pathname: "/editRequisition",
                        state: { req_id: record.requisition_id }
                      }}
                    >
                      <Button type="link" style={{ color: "gold" }}>
                        Requisition
                      </Button>
                    </Link>
                  </div>
                ) : null} */}
              </span>
            )}
          />
        </Table>
        <div style={{ marginRight: 10, marginTop: 15 }}>
          <Text>{`Displaying ${this.state.datarange} of ${this.state.total}`}</Text>
        </div>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
        <Modal
          destroyOnClose={true}
          footer={null}
          visible={this.state.show_xero_modal}
          onCancel={() => {
            this.setState({
              show_xero_modal: false,
              bill_id: "",
              due_days_to_pay: 1,
            });
          }}
          closable={true}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 5,
            }}
          >
            <Text>Due Days To Pay</Text>
            <Input
              placeholder="Due Days To Pay"
              type="number"
              value={this.state.due_days_to_pay}
              onChange={(evt) =>
                this.setState({ due_days_to_pay: evt.target.value })
              }
            />
          </div>
          <Button
            loading={this.state.loading_sync_xero}
            className="login-form-button"
            htmlType="submit"
            onClick={() => this.syncBillXero()}
          >
            Sync
          </Button>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  billListParams: state.billListParams.billListParamsData,
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { saveBillListParams, getUserData })(
  BillList
);
