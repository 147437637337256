import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Input,Select } from "antd";
import { connect } from "react-redux";
import { saveTaskData } from "../../redux/actions/startTaskActions";
import axios from "axios";
import swal from "sweetalert2";
import { SHIPMENT_NUMBER, CREATE_TASK_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

class StartTask extends Component {
  state = {
    name: "",
    colors: [],
    colorId: [],
    label: "",
    task_code: "",
    taskTypes: [],
    task_type: ""
  };

  componentDidMount() {
    this.getShipmentNumber();
    this.getTaskType();

    if (Object.keys(this.props.startTask).length !== 0) {
      this.setState({
        name: this.props?.startTask?.name ?? "",
        colors: this.props?.startTask?.colors ?? [],
        task_type: this.props?.startTask?.task_type ?? ""
      });
    }
  }

  getTaskType = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_TASK_TYPE}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from task type list", response);

          this.setState({
            taskTypes: response.data.data
          });
        })
        .catch(error => {
          console.log("error from task type list api", error);
        });
    });
  };

  getShipmentNumber = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${SHIPMENT_NUMBER}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ task_code: response.data.data[0] });
          console.log("response from shipment number list", response);
        })
        .catch(error => {
          console.log("error from shipment number list api", error);
        });
    });
  };

  colors = [
    {
      id: 1,
      color: "#f44336"
    },
    {
      id: 2,
      color: "#E91E63"
    },
    {
      id: 3,
      color: "#9C27B0"
    },
    {
      id: 4,
      color: "#673AB7"
    },
    {
      id: 5,
      color: "#3F51B5"
    },
    {
      id: 6,
      color: "#2196F3"
    },
    {
      id: 7,
      color: "#009688"
    },
    {
      id: 8,
      color: "#4CAF50"
    },
    {
      id: 9,
      color: "#FFEB3B"
    },
    {
      id: 10,
      color: "#FF9800"
    }
  ];

  setName = evt => {
    this.setState({ name: evt.target.value });
    console.log(this.state.name);
  };

  setTaskType = value => {
    this.setState({ task_type: value }, () => {
      console.log(this.state.task_type);
    });
  };

  setTaskCode = evt => {
    this.setState({ task_code: evt.target.value });
    console.log(this.state.task_code);
  };

  saveTaskdataStartNow = () => {
    if (this.state.name === "") {
      swal.fire("Info", "Please Enter Task Name", "info");
    } else {
      let data = {
        name: this.state.name,
        task_code: this.state.task_code,
        task_type: this.state.task_type,
        colors: this.state.colors
      };
      console.log(data);

      this.props.saveTaskData(data);
      this.props.history.push("/startingFrom");
    }
  };

  saveTaskdataStartLater = () => {
    if (this.state.name === "") {
      swal.fire("Info", "Please Enter Task Name", "info");
    } else {
      let data = {
        name: this.state.name,
        task_code: this.state.task_code,
        task_type: this.state.task_type,
        colors: this.state.colors
      };
      console.log(data);

      this.props.saveTaskData(data);
      this.props.history.push("/startTime");
    }
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Start a Quick Task"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Task Number</Text>

            <Input
              type="text"
              placeholder="Type Task Number"
              value={this.state.task_code}
              onChange={this.setTaskCode}
              allowClear={true}
            />
          </Form.Item>
          <Form.Item>
            <Text>Task Name</Text>

            <Input
              type="text"
              placeholder="Task Name"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Task Type</Text>
            <Select
              value={this.state.task_type}
              onChange={this.setTaskType}
              placeholder="Task Type"
            >
              {this.state.taskTypes.map(r => {
                return <Option key={r.name}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column"
              }}
            >
              <div style={{ display: "flex" }}>
                <Text>
                  Select Colors :{" "}
                  {this.state.colors.length > 1
                    ? `${this.state.colors.length} Colors Selected`
                    : `${this.state.colors.length} Color Selected`}
                </Text>
                <button
                  className="borderless-button"
                  style={{ color: "red" }}
                  onClick={() => {
                    this.setState({ colors: [], colorId: "" }, () => {
                      console.log(this.state.colors);
                    });
                  }}
                >
                  Reset
                </button>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {this.colors.map(color => (
                  <div key={color.id}>
                    {this.state.colorId === color.id ? (
                      <Button
                        style={{
                          borderRadius: 50,
                          backgroundColor: color.color,
                          height: 40,
                          width: 40,
                          margin: 5,
                          borderColor: "black"
                        }}
                        onClick={() => {
                          this.state.colors.push(color.color);
                          this.setState({ colorId: color.id }, () => {
                            console.log(this.state.colorId);
                          });
                          console.log(this.state.colors);
                        }}
                      />
                    ) : (
                      <Button
                        style={{
                          borderRadius: 50,
                          backgroundColor: color.color,
                          height: 35,
                          width: 35,
                          margin: 5
                        }}
                        onClick={() => {
                          this.state.colors.push(color.color);
                          this.setState({ colorId: color.id }, () => {
                            console.log(this.state.colorId);
                          });
                          console.log(this.state.colors);
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.saveTaskdataStartLater}
              >
                Start Later
              </Button>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.saveTaskdataStartNow}
              >
                Start Now
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  startTask: state.startTask.startTaskData
});

export default connect(mapStateToProps, { saveTaskData })(StartTask);
