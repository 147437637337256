import React, { Component } from "react";
import {
  Card,
  Typography,
  PageHeader,
  Table,
  Button,
  Pagination,
  Select,
} from "antd";
import axios from "axios";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Spinner } from "../loader";
// import swal from "sweetalert2";

// import { ACHIEVEMENT_DETAILS,APPROVE_ACHIEVEMENT } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import ReactGA from "react-ga4";

import "../login.css";

const { Text } = Typography;
const { Column } = Table;
const { Option } = Select;

class AchievementDetails extends Component {
  state = {
    api: "",
    label: "",
    duration_type: "",
    approved: false,
    created_on: "",
    start_date: "",
    end_date: "",
    achievements: [],
    target_name: "",
    approved_on: "",
    product_name: "",
    status: "",
    achievement_for: "",
    page: 1,
    total: 0,
    limit: 10,
    loading: false,
  };

  componentDidMount() {
    this.setState({ api: this.props.location.state.api });
    this.getAchievementDetails();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getAchievementDetails = () => {
    this.setState({ loading: true });
    let api = this.props.location.state.api;
    GET_ACCESS_TOKEN().then((token) => {
      // const url = `${process.env.REACT_APP_BASE_PATH}${api}?access_token=${token}`;
      const url = `${process.env.REACT_APP_BASE_PATH}${api}${token}&page=${this.state.page}&limit=${this.state.limit}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            label: response?.data?.label ?? "",
            target_name: response?.data?.target ?? "",
            product_name: response?.data?.item_name ?? "",
            status: response?.data?.status ?? "",
            achievement_for: response?.data?.achievement_for ?? "",
            duration_type: response?.data?.duration_type ?? "",
            approved: response?.data?.approved ?? false,
            created_on: response?.data?.created_on.$date ?? "",
            start_date: response?.data?.start_date.$date ?? "",
            end_date: response?.data?.end_date.$date ?? "",
            approved_on: response?.data?.approved_on?.$date ?? "",
            achievements: response?.data?.achievements?.data ?? [],
            total: response?.data?.achievements?.total ?? 10,
          });
          this.setState({ loading: false });
          console.log("response from achievement details", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from achievement details", error);
        });
    });
  };

  approveAchievement = () => {
    GET_ACCESS_TOKEN().then((token) => {
      // const url = `${process.env.REACT_APP_BASE_PATH}${api}?access_token=${token}`;
      const url = `${process.env.REACT_APP_BASE_PATH}${this.state.api}${token}&approve=true`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            label: response?.data?.label ?? "",
            target_name: response?.data?.target ?? "",
            duration_type: response?.data?.duration_type ?? "",
            approved: response?.data?.approved ?? false,
            created_on: response?.data?.created_on.$date ?? "",
            start_date: response?.data?.start_date.$date ?? "",
            end_date: response?.data?.end_date.$date ?? "",
            achievements: response?.data?.achievements?.data ?? [],
          });
          console.log("response from approve achievement", response);
        })
        .catch((error) => {
          console.log("error from approve achievement", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current }, () => {
      console.log("page number", this.state.page);
      this.getAchievementDetails();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getAchievementDetails();
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Achievement Details"
          onBack={() => this.props.history.goBack()}
        />

        <Card
          style={{
            marginTop: 5,
            width: "100%",
          }}
        >
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Target Name</Text>:{" "}
            {this.state.target_name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Label</Text>:{" "}
            {this.state.label}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Achievement For</Text>:{" "}
            {this.state.achievement_for}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Product Name</Text>:{" "}
            {this.state.product_name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Status</Text>:{" "}
            {this.state.status}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Duration Type</Text>:{" "}
            {this.state.duration_type}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Approved</Text>:{" "}
            {this.state.approved.toString()}
            {this.props.userProfileData.data_level.access_all_data ? (
              <Button type="link" onClick={this.approveAchievement}>
                Approve
              </Button>
            ) : null}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Approved On</Text>:{" "}
            {this.state.approved_on !== ""
              ? timeConverter(this.state.approved_on)
              : ""}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Start Time</Text>:{" "}
            {timeConverter(this.state.start_date)}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>End Time</Text>:{" "}
            {timeConverter(this.state.end_date)}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Created On</Text>:{" "}
            {timeConverter(this.state.created_on)}
          </p>
        </Card>
        <Card
          style={{
            marginTop: 5,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{ margin: 5, display: "flex", flexDirection: "column" }}
            >
              <Text>{`Displaying : `}</Text>
              <Select value={this.state.limit} onChange={this.setLimit}>
                <Option key={10}>10</Option>
                <Option key={25}>25</Option>
                <Option key={50}>50</Option>
                <Option key={100}>100</Option>
              </Select>
            </div>
          </div>
          <Table
            dataSource={this.state.achievements}
            pagination={false}
            rowKey={(record) => record.id.$oid}
            size="small"
            bordered
          >
            <Column title="Name" dataIndex="name" key="name" />
            <Column
              title="Target For"
              dataIndex="target_for"
              key="target_for"
            />
            <Column
              title="Target Table"
              dataIndex="target_table"
              key="target_table"
            />
            <Column
              title="Target Value"
              dataIndex="target_value"
              key="target_value"
            />
            <Column
              title="Achieved Value"
              dataIndex="achievement"
              key="achievement"
            />
            <Column
              title="Achievement Percentage"
              dataIndex="achievement_percentage"
              key="achievement_percentage"
            />
            <Column
              title="From"
              key="target_from"
              render={(text, record) => (
                <span>{timeConverter(record.target_from.$date)}</span>
              )}
            />
            <Column
              title="To"
              key="target_to"
              render={(text, record) => (
                <span>{timeConverter(record.target_to.$date)}</span>
              )}
            />
            <Column
              title="Created On"
              key="created_on"
              render={(text, record) => (
                <span>{timeConverter(record.created_on.$date)}</span>
              )}
            />
          </Table>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.limit)}
              current={this.state.page}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(AchievementDetails);
