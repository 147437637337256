import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
    BULK_USER_UPLOAD,
    SAMPLE_USER_UPLOAD_FILE
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;

export default class UserBulkUpload extends Component {
  state = {
    users:null,
    url:null
  };

  
  uploadFile = () => {
    GET_ACCESS_TOKEN().then(token => {
      const users = new FormData();

      users.append("users", this.state.users);

      console.log("in upload", users);

      axios({
        method: "post",
        url: `${BULK_USER_UPLOAD}?access_token=${token}`,
        data: users,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
        //   if (response.data.message === "Image successfully uploaded!") {
        //     this.setState({
        //       imgUrl: `${process.env.REACT_APP_BASE_PATH}${response.data.data.upload_path}`
        //     });
        //   }
          console.log("response from file upload api", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload file", error);
        });
    });
  };
  
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Upload Bulk Users"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
           <Form.Item>
            <Text>Upload The Excel File Containing User Information <Button type="link" onClick={()=>window.open(`${SAMPLE_USER_UPLOAD_FILE}`)}>Download Sample</Button></Text>
          
            <input
              type="file"
              onChange={e => {
                console.log("excel file inside", this.state.users);
                this.setState(
                  {
                    url: URL.createObjectURL(e.target.files[0]),
                    users: e.target.files[0]
                  }
                );
              }}
            />
            
          
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.uploadFile}
            >
              Upload
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
