import React, { Component } from "react";
import { List, Card, Button, PageHeader,Typography } from "antd";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Spinner } from "../loader";

import { CREATE_NOTIFICATION, UPDATE_NOTIFICATION } from "../../api/constants";
import { GET_ACCESS_TOKEN,timeConverter } from "../../utilities/utilities";

const { Text } = Typography;


export default class NotificationList extends Component {
  state = {
    notification_data: [],
    notification_page: 0,
    total_notification: 0,
    loading: false,
    redirecttoTask: false,
    redirecttoOrder: false,
    task_id: "",
    order_id: ""
  };

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_NOTIFICATION}?page=${this.state.notification_page}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from notification list", response);

          this.setState({
            notification_data:
              this.state.notification_page === 0
                ? response.data.data
                : [...this.state.notification_data, ...response.data.data],
            total_notification: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: true });
          console.log("error from notification list", error);
        });
    });
  };

  updateNotification = (notification_id, payload) => {
    let type = payload.split(":")[0];
    let id = payload.split(":")[1];
    if (type === "task") {
      this.setState({ task_id: id }, () => {
        this.setState({ redirecttoTask: true });
      });
    } else if (type === "order") {
      this.setState({ order_id: id }, () => {
        this.setState({ redirecttoOrder: true });
      });
    }
    const data = {
      notification_id: notification_id
    };
    GET_ACCESS_TOKEN().then(token => {
      const url = `${UPDATE_NOTIFICATION}${notification_id}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "Accept": "application/json",
          "Content-type": "application/x-www-form-urlencoded",
          "Authorization": `Bearer ${token}`
        }
      })
        .then(response => {
          console.log("response from notification update", response);
        })
        .catch(error => {
          console.log("error from notification update", error);
        });
    });
  };

  loadMoreNotifiaction = () => {
    this.setState(
      {
        notification_page: this.state.notification_page + 1
      },
      () => {
        this.getNotifications();
      }
    );
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    if (this.state.redirecttoTask) {
      return (
        <Redirect
          push
          to={{
            pathname: "/viewTask",
            state: { task_id: this.state.task_id }
          }}
        />
      );
    }
    if (this.state.redirecttoOrder) {
      return (
        <Redirect
          push
          to={{
            pathname: "/viewOrder",
            state: { order_id: this.state.order_id }
          }}
        />
      );
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Notifications"
          onBack={() => this.props.history.goBack()}
        />
        <List
          dataSource={this.state.notification_data}
          renderItem={item => (
            <List.Item
              onClick={() =>
                this.updateNotification(item._id.$oid,item.data.payload)
              }
              style={{
                backgroundColor: item.viewed ? "#FAFAFA" : "#ECEFF1",
                padding: 10
              }}
              key={item._id.$oid}
            >
              <List.Item.Meta
                title={<Text>{item.data.payload}</Text>}
                description={<div style={{display:"flex",flexDirection:"column"}}>
                <Text>{item.msg}</Text>
                <Text>{timeConverter(item.created_on.$date)}</Text>
                </div>}
              />
            </List.Item>
          )}
        >
          {this.state.total_notification > 20 ? (
            <div style={{ textAlign: "center", marginTop: 15 }}>
              <Button
                style={{ color: "white", backgroundColor: "#0d98ad" }}
                onClick={this.loadMoreNotifiaction}
              >
                Load More ...
              </Button>
            </div>
          ) : null}
        </List>
      </Card>
    );
  }
}
