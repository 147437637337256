import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { Redirect } from "react-router-dom";

import {
  CREATE_TARGET,
  CREATE_DRIVER,
  CREATE_SUB_ORG,
  CREATE_ORGANIZATION,
  TARGET_TYPE_CHOICES,
  TARGET_FOR_CHOICES,
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import ReactGA from "react-ga4";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { debounce } from "lodash";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateTarget extends Component {
  state = {
    name: "",
    description: "",
    label: "",
    target_value: 1,
    users: [],
    user_id: [],
    sub_orgs: [],
    sub_org_id: [],
    organizations: [],
    organization_id: [],
    start_time: Date.now(),
    start_time_view: moment(),
    end_time: Date.now(),
    end_time_view: moment(),
    duration_type: "DAILY",
    priority: 1,
    target_types: [],
    target_type: "",
    target_for_ops: [],
    target_for: "",
    target_id: "",
    redirect_to_achievement: false,
    sub_org_search_value:""
  };

  componentDidMount() {
    this.getTargetTypes();
    this.getTargetForOtions();
    this.getUserList();
    this.getSubOrgList();
    this.getOrganizationList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getTargetForOtions = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${TARGET_FOR_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from target for options", response);
          this.setState({
            target_for_ops: response.data.target_for_choices,
          });
        })
        .catch((error) => {
          console.log("error from target for options api", error);
        });
    });
  };
  getTargetTypes = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${TARGET_TYPE_CHOICES}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from target types", response);
          this.setState({
            target_types: response.data.target_type_choices,
          });
        })
        .catch((error) => {
          console.log("error from target types api", error);
        });
    });
  };
  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user list", response);
          this.setState({
            users: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from driver list api", error);
        });
    });
  };

  handleSearchUser = debounce((value) => {
    console.log("searc text", value);
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            users: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  getSubOrgList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_SUB_ORG}?name=${this.state.sub_org_search_value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from sub organization list", response);
          this.setState({
            sub_orgs: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from sub organization list api", error);
        });
    });
  }, 500);

  handleSuborgSearch = (value) => {
    this.setState({ sub_org_search_value: value }, () => {
      this.getSubOrgList();
    });
  };

  setSuborg = (value) => {
    this.setState({ sub_org_id: value }, () => {
      console.log(this.state.sub_org_id);
    });
  };

  getOrganizationList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ORGANIZATION}?limit=100&access_token=${token}&get_connected_orgs=true&sort_type=asc`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from organization list", response);
          this.setState({
            organizations: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from org list api", error);
        });
    });
  };

  setUser = (value) => {
    this.setState({ user_id: value }, () => {
      console.log(this.state.user_id);
    });
  };

  setOrg = (value) => {
    this.setState({ organization_id: value }, () => {
      console.log(this.state.organization_id);
    });
  };
  setDurationType = (value) => {
    this.setState({ duration_type: value }, () => {
      console.log(this.state.duration_type);
    });
  };
  setSubOrgUser = (value) => {
    this.setState({ select_sub_org_or_user: value }, () => {
      console.log(this.state.select_sub_org_or_user);
    });
  };
  setTargetType = (value) => {
    this.setState({ target_type: value }, () => {
      console.log(this.state.target_type);
    });
  };
  setTargetFor = (value) => {
    this.setState({ target_for: value }, () => {
      console.log(this.state.target_for);
    });
  };

  setEnd = (value, dateString) => {
    this.setState(
      { end_time: moment(value).format("x"), end_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.end_time);
      }
    );
  };
  setStart = (value, dateString) => {
    this.setState(
      { start_time: moment(value).format("x"), start_time_view: value },
      () => {
        console.log("Selected Time: ", this.state.start_time);
      }
    );
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value, label: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setDescription = (evt) => {
    evt.preventDefault();
    this.setState({ description: evt.target.value }, () => {
      console.log(this.state.description);
    });
  };
  setTargetValue = (evt) => {
    evt.preventDefault();
    this.setState({ target_value: evt.target.value }, () => {
      console.log(this.state.target_value);
    });
  };
  setPriority = (evt) => {
    evt.preventDefault();
    this.setState({ priority: evt.target.value }, () => {
      console.log(this.state.priority);
    });
  };

  createTarget = () => {
    if (
      this.state.name === "" ||
      Number(this.state.target_value) < 1 ||
      this.state.priority < 1
    ) {
      swal.fire(
        "Warning",
        "Please enter name,priority and target value",
        "warning"
      );
    } else if (
      this.state.user_id < 1 &&
      this.state.sub_org_id < 1 &&
      this.state.organization_id < 1
    ) {
      swal.fire(
        "Warning",
        "Please select user,sub org or organization",
        "warning"
      );
    } else {
      const data = {
        name: this.state.name,
        description: this.state.description,
        label: this.state.label,
        priority: Number(this.state.priority),
        target_type: this.state.target_type,
        target_for: this.state.target_for,
        duration_type: this.state.duration_type,
        target_value: Number(this.state.target_value),
        start_time: Number(this.state.start_time),
        end_time: Number(this.state.end_time),
        assigned_to: {
          user: this.state.user_id,
          sub_orgs: this.state.sub_org_id,
          organization: this.state.organization_id,
        },
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_TARGET}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            if (response.data.message === "Data successfully inserted!") {
              this.setState({ target_id: response.data.data.target_id }, () => {
                this.setState({ redirect_to_achievement: true });
              });
            }

            console.log("response from create target", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from create target", error);
          });
      });
    }
  };
  render() {
    if (this.state.redirect_to_achievement) {
      return (
        <Redirect
          to={{
            pathname: "/createAchievement",
            state: {
              target_id: this.state.target_id,
              target_for: this.state.target_for,
            },
          }}
        />
      );
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Create Target"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Description</Text>
            <Input
              placeholder="Description"
              type="text"
              value={this.state.description}
              onChange={this.setDescription}
            />
          </Form.Item>

          <Form.Item>
            <Text>Target Value</Text>
            <Input
              placeholder="Target Value"
              type="number"
              value={this.state.target_value}
              onChange={this.setTargetValue}
            />
          </Form.Item>
          <Form.Item>
            <Text>Priority</Text>
            <Input
              placeholder="Priority"
              type="number"
              value={this.state.priority}
              onChange={this.setPriority}
            />
          </Form.Item>
          <Form.Item>
            <Text>Target Type</Text>
            <Select
              value={this.state.target_type}
              onChange={this.setTargetType}
              placeholder="Target Type"
            >
              {this.state.target_types.map((t) => (
                <Option key={t}>{t}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Target For</Text>
            <Select
              value={this.state.target_for}
              onChange={this.setTargetFor}
              placeholder="Target For"
            >
              {this.state.target_for_ops
                .filter((t) => t === "order" || t === "task")
                .map((tf) => (
                  <Option key={tf}>{tf}</Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Duration Type</Text>
            <Select
              value={this.state.duration_type}
              onChange={this.setDurationType}
              placeholder="Duration Type"
            >
              <Option key="DAILY">DAILY</Option>
              <Option key="WEEKLY">WEEKLY</Option>
              <Option key="MONTHLY">MONTHLY</Option>
              <Option key="CUSTOM">CUSTOM</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 5,
              }}
            >
              <Text>Start Time</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={this.setStart}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: 5,
              }}
            >
              <Text>End Time</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={this.setEnd}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>

          <Form.Item>
            <Text>Search User</Text>
            <Select
              value={this.state.user_id}
              disabled={this.state.sub_org_id.length > 0 ? true : false}
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              mode="multiple"
              onSearch={this.handleSearchUser}
              onChange={this.setUser}
              placeholder="Search User"
            >
              {this.state.users.map((r) => {
                return (
                  <Option
                    key={r._id.$oid}
                  >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Search Sub Org</Text>
            <Select
                disabled={this.state.user_id.length > 0 ? true : false}
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={false}
                mode="multiple"
                onSearch={this.handleSuborgSearch}
                value={this.state.sub_org_id}
                onChange={this.setSuborg}
              >
                {this.state.sub_orgs.map((r) => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
          </Form.Item>

          {/* <Form.Item>
            <Text>Select Organization</Text>
            <Select
              value={this.state.organization_id}
              mode="multiple"
              onChange={this.setOrg}
              placeholder="Select Organization"
            >
              {this.state.organizations.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item> */}

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createTarget}
            >
              Create Target
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
