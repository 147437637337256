import React, { Component } from "react";
import { Card, PageHeader, Typography, Pagination } from "antd";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L from "leaflet";
import axios from "axios";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Spinner } from "../loader";

import { FINDER_ASSET_ROUTE_HISTORY } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  randomId,
} from "../../utilities/utilities";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40],
});

const { Title } = Typography;

export default class RouteHistory extends Component {
  state = {
    asset_id: "",
    asset_provider_id: "",
    center_lat: 23.685,
    center_lon: 90.3563,
    zoom: 13,
    page: 1,
    limit: 10,
    total: 0,
    start_date: Date.now(),
    start_date_view: moment(),
    end_date: Date.now(),
    end_date_view: moment(),
    routeData: [],
    loading: false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState(
      {
        asset_id: this.props.location.state.asset_id,
        asset_provider_id: this.props?.location?.state?.asset_provider_id,
      },
      () => this.getAssetRouteHistory()
    );
  }

  getAssetRouteHistory = () => {
    this.setState({ loading: true });
    const data = {
      asset_id: this.state.asset_id,
      asset_provider_id: this.state.asset_provider_id,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      page: this.state.page,
      limit: this.state.limit,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${FINDER_ASSET_ROUTE_HISTORY}?access_token=${token}`;

      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from finder route history", response);
          this.setState({
            routeData: response.data.data ?? [],
            total: response.data.total ?? 0,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from finder route history", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current }, () => {
      console.log("page number", this.state.page);
      this.getAssetRouteHistory();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getAssetRouteHistory();
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title={`Finder Route History`}
          onBack={() => this.props.history.goBack()}
        />
        <Card style={{ margin: 3 }}>
          <Title level={4}>
            Select daterange within which you would like to find your Assets
          </Title>

          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  minDate={moment().subtract(30, "days")}
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.start_date_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_date: moment(value).format("x"),
                        start_date_view: value,
                        page: 1,
                      },
                      () => {
                        this.getAssetRouteHistory();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  minDate={moment().subtract(30, "days")}
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.end_date_view}
                  onChange={(value, dateString) => {
                    let end_date=moment(value);
                    let start_date=moment(this.state.start_date_view);
                    console.log(end_date.diff(start_date, 'days'))
                    if (
                      end_date.diff(start_date, 'days')> 7
                    ) {
                      swal.fire(
                        "Info",
                        "You can only select maximum 7 days data",
                        "info"
                      );
                    } else {
                      this.setState(
                        {
                          end_date: moment(value).format("x"),
                          end_date_view: value,
                          page: 1,
                        },
                        () => {
                          this.getAssetRouteHistory();
                        }
                      );
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </Card>

        <Card style={{ margin: 10 }}>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.limit)}
              current={this.state.page}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ margin: 10 }}
            />
          ) : null}
          <Map
            center={
              this.state.routeData.length > 0
                ? [
                    this.state.routeData[0].location.coordinates[1],
                    this.state.routeData[0].location.coordinates[0],
                  ]
                : [this.state.center_lat, this.state.center_lon]
            }
            zoom={this.state.zoom}
            style={{ height: 800 }}
            onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
          >
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.49.2",
              }}
              type={"roadmap"}
            />

            {this.state.routeData.map((route, index) => (
              <Marker
                key={randomId(20)}
                position={[
                  route.location.coordinates[1],
                  route.location.coordinates[0],
                ]}
                icon={red}
              >
                <Popup>
                  <span>{`${index + 1} : ${timeConverter(
                    route.time * 1000
                  )}`}</span>
                </Popup>
              </Marker>
            ))}
          </Map>
        </Card>
      </Card>
    );
  }
}
