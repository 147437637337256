import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Select,
  Typography,
  Input,
  Form,
  Icon
} from "antd";
// import { connect } from "react-redux";
// import { getUserData } from "../../redux/actions/userProfileAction";
import axios from "axios";
import swal from "sweetalert2";
import { debounce } from "lodash";

import {
  CREATE_INVENTORY,
  INTERNAL_INVENTORY_TRANSFER,
  CREATE_PRODUCT
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text } = Typography;

export default class InternalTransfer extends Component {
  state = {
    inventories: [],
    from_inventory: "",
    to_inventory: "",
    data: [],
    items: [],
    filtered_items: [],
    selected_items: [],
    post_data: []
  };
  componentDidMount() {
    this.getInventoryList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }
  getInventoryList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_INVENTORY}?access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from inventory list", response);
          this.setState({
            inventories: response.data.data
          });
        })
        .catch(error => {
          console.log("error from inventory list api", error);
        });
    });
  };

  getProductList = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_PRODUCT}?product_status=active&inventory_id=${this.state.from_inventory}&enable_order_for_availabl_stock_or_stock_quantity_zero=false&limit=1000&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from product", response);
          let products = response.data.data.map(p => ({
            id: p._id.$oid,
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id
          }));

          let products1 = response.data.data.map(p => ({
            item: p.name,
            unit_selling_price: p.unit_selling_price,
            price: p.price,
            unit: p.unit,
            item_id: p.product_id,
            qty: 0,
            stock_quantity: p.stock_quantity,
            available_stock: p.available_stock,
            sku: p.sku,
            unit_purchase_price: p.unit_purchase_price,
            mrp: p.mrp,
            inventory_id: p.inventory_id
          }));

          console.log("..........................", products);
          this.setState({ data: products1, items: products });
        })
        .catch(error => {
          console.log("error from shop list api", error);
        });
    });
  };

  searchInventory = debounce(value => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_INVENTORY}?entity_name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from place list", response);
          this.setState({
            inventories: response.data.data
          });
        })
        .catch(error => {
          console.log("error from place list api", error);
        });
    });
  }, 500);
  setFromInventory = value => {
    this.setState({ from_inventory: value }, () => {
      this.getInventoryList();
      this.getProductList();
    });
  };
  setToInventory = value => {
    this.setState({ to_inventory: value }, () => {
      this.getInventoryList();
    });
  };
  itemSearch = value => {
    const result = this.state.items.filter(item => {
      return item.item.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered_items: result });
  };
  transferInventory = () => {
    if (this.state.from_inventory === this.state.to_inventory) {
      swal.fire(
        "Info",
        "Source and destination invenotry can not be same",
        "info"
      );
    } else if (
      this.state.to_inventory === "" ||
      this.state.from_inventory === "" ||
      this.state.post_data.length === 0
    ) {
      swal.fire("Info", "Please select an inventory and product", "info");
    } else {
      let data = {
        from_inventory: this.state.from_inventory,
        to_inventory: this.state.to_inventory,
        products: this.state.post_data
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${INTERNAL_INVENTORY_TRANSFER}?access_token=${token}`;

        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            swal.fire("Info", response.data.message, "info");
            this.getInventoryList();
            console.log("response from inventory tranfer", response);
          })
          .catch(error => {
            swal.fire("Error", error.message, "error");
            console.log("error from inventory tranfer", error);
          });
      });
    }
  };
  render() {
    const ItemList = this.state.items.map(r => {
      return (
        <Option key={r.id} value={JSON.stringify(r)}>
          {`Name: ${r.item} > Unit: ${r.unit} > Stock Quantity: ${r.stock_quantity}`}
        </Option>
      );
    });

    const FilteredItem = this.state.filtered_items.map(r => {
      return (
        <Option key={r.id} value={JSON.stringify(r)}>
          {`Name: ${r.item} > Unit: ${r.unit} > Stock Quantity: ${r.stock_quantity}`}
        </Option>
      );
    });
    return (
      <Card>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Internal Inventory Transfer"
        />
        <Form>
          <Form.Item>
            <Text>Search Source Inventory</Text>
            <Select
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={true}
              onSearch={this.searchInventory}
              value={this.state.from_inventory}
              onChange={this.setFromInventory}
            >
              {this.state.inventories.map(r => {
                return (
                  <Option
                    key={r._id.$oid}
                  >{`Name : ${r.entity_name} >>> Address : ${r.entity_address}`}</Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text>Search Product</Text>
              <Select
                labelInValue
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue
                onSearch={this.itemSearch}
                onChange={(value, e) => {
                  let item = JSON.parse(e.props.value);
                  let post_object = {
                    product_id: item.id,
                    required_stock_quantity: 1,
                    cost_amount: item.price
                  };
                  let inc = {
                    item: item.item,
                    available_stock: item.available_stock,
                    stock_quantity: item.stock_quantity,
                    unit_selling_price: item.unit_selling_price,
                    price: item.price,
                    discounted_price: 0,
                    discount: 0,
                    qty: 1,
                    item_discount_type: "amount",
                    unit_item_discount: 0,
                    item_id: item.id,
                    unit: item.unit,
                    sku: item.sku,
                    unit_purchase_price: item.unit_purchase_price,
                    mrp: item.mrp,
                    inventory_id: item.inventory_id
                  };
                  inc.price = inc.unit_purchase_price * inc.qty;
                  post_object.cost_amount = inc.unit_purchase_price * inc.qty;

                  let post_array = [];
                  post_array.push(post_object);

                  this.setState(
                    {
                      post_data: [...this.state.post_data, ...post_array]
                    },
                    () => {
                      this.setState(
                        {
                          post_data: this.state.post_data.filter(
                            (value, index, self) =>
                              self.findIndex(
                                t => t.product_id === value.product_id
                              ) === index
                          )
                        },
                        () => {
                          console.log("working or not", this.state.post_data);
                        }
                      );
                    }
                  );
                  let temp = [];
                  temp.push(inc);
                  //real data
                  let filteredData = this.state.data.filter(
                    i => i.item !== item.item
                  );

                  this.setState({ data: [...temp, ...filteredData] }, () => {
                    this.setState(
                      {
                        data: this.state.data.filter(
                          (value, index, self) =>
                            self.findIndex(t => t.item_id === value.item_id) ===
                            index
                        )
                      },
                      () => {
                        console.log("selection data state", this.state.data);
                      }
                    );
                  });
                  //selected data
                  this.setState(
                    {
                      selected_items: [...this.state.selected_items, ...temp]
                    },
                    () => {
                      this.setState({
                        selected_items: this.state.selected_items.filter(
                          (value, index, self) =>
                            self.findIndex(t => t.item_id === value.item_id) ===
                            index
                        )
                      });
                      console.log(this.state.selected_items);
                    }
                  );
                }}
                placeholder="Search Item"
              >
                {this.state.filtered_items.length === 0
                  ? ItemList
                  : FilteredItem}
              </Select>
            </div>
          </Form.Item>

          <Form.Item>
            {this.state.selected_items.length > 0 ? (
              <Table
                dataSource={this.state.selected_items}
                pagination={false}
                rowKey={record => record.item_id}
                size="small"
              >
                <Column title="Product" dataIndex="item" key="product" />
                <Column
                  title="Unit Purchase Price"
                  key="unit_purchase_price"
                  dataIndex="unit_purchase_price"
                />
                <Column
                  title="Stock Quantity"
                  key="stock_quantity"
                  dataIndex="stock_quantity"
                />
                <Column
                  title="Available Stock"
                  key="available_stock"
                  dataIndex="available_stock"
                />

                <Column
                  title="Rquired Stock Qunatity"
                  key="Rquired Stock Qunatity"
                  render={record => (
                    <span>
                      <Input
                        type="number"
                        value={
                          this.state.data.find(i => i.item === record.item).qty
                        }
                        onChange={evt => {
                          let item = this.state.data.find(
                            i => i.item === record.item
                          );
                          if (Number(evt.target.value <= 0)) {
                            return;
                          }

                          if (Number(evt.target.value) > item.stock_quantity) {
                            swal.fire(
                              "Info",
                              "Quantity must be less than stock quantity",
                              "info"
                            );
                            return;
                          }

                          let post_object = {
                            product_id: item.item_id,
                            required_stock_quantity: Number(evt.target.value),
                            cost_amount: item.price
                          };

                          let inc = {
                            item: item.item,
                            available_stock: item.available_stock,
                            stock_quantity: item.stock_quantity,
                            unit_selling_price: item.unit_selling_price,
                            price: item.price,
                            discounted_price: 0,
                            discount: 0,
                            qty: Number(evt.target.value),
                            item_discount_type: item.item_discount_type,
                            unit_item_discount: item.unit_item_discount,
                            item_id: item.item_id,
                            unit: item.unit,
                            sku: item.sku,
                            unit_purchase_price: item.unit_purchase_price,
                            mrp: item.mrp,
                            inventory_id: item.inventory_id
                          };

                          inc.price =
                            inc.unit_purchase_price * Number(evt.target.value);
                          post_object.cost_amount =
                            inc.unit_purchase_price * Number(evt.target.value);

                          let post_array = [];
                          post_array.push(post_object);

                          let filteredPostData = this.state.post_data.filter(
                            i => i.product_id !== item.item_id
                          );

                          this.setState(
                            {
                              post_data: [
                                ...filteredPostData,
                                ...post_array
                              ]
                            },
                            () => {
                              console.log(
                                "working or not",
                                this.state.post_data
                              );
                            }
                          );

                          let arr = [];
                          arr.push(inc);

                          let filteredData = this.state.data.filter(
                            i => i.item !== item.item
                          );

                          this.setState(
                            { data: [...arr, ...filteredData] },
                            () => {
                              console.log("final state", this.state.data);
                            }
                          );
                        }}
                      />
                    </span>
                  )}
                />

                <Column
                  title="Cost Amount"
                  key="Cost Amount"
                  render={record => (
                    <span>
                      <Text>
                        {
                          this.state.data.find(
                            i => i.item_id === record.item_id
                          ).price
                        }
                      </Text>
                    </span>
                  )}
                />

                <Column
                  title="Remove"
                  key="Remove"
                  render={record => (
                    <span>
                      <Button
                        style={{
                          color: "red"
                        }}
                        type="link"
                        onClick={() => {
                          this.setState({
                            selected_items: this.state.selected_items.filter(
                              item => item.item !== record.item
                            )
                          });
                          this.setState({
                            post_data: this.state.post_data.filter(
                              item => item.product_id !== record.item_id
                            )
                          });
                          let item = this.state.data.find(
                            i => i.item === record.item
                          );
                          let inc = {
                            item: item.item,
                            available_stock: item.available_stock,
                            stock_quantity: item.stock_quantity,
                            unit_selling_price: item.unit_selling_price,
                            price: 0,
                            qty: 0,
                            item_discount_type: "amount",
                            unit_item_discount: 0,
                            item_id: item.item_id,
                            unit: item.unit,
                            sku: item.sku,
                            unit_purchase_price: item.unit_purchase_price,
                            mrp: item.mrp,
                            inventory_id: item.inventory_id
                          };
                          let arr = [];
                          arr.push(inc);

                          let filteredData = this.state.data.filter(
                            i => i.item !== item.item
                          );

                          this.setState(
                            { data: [...arr, ...filteredData] },
                            () => {
                              console.log("final state", this.state.data);
                            }
                          );
                        }}
                      >
                        <Icon type="close" />
                      </Button>
                    </span>
                  )}
                />
              </Table>
            ) : null}
          </Form.Item>
          <Form.Item>
            <Text>Search Destination Inventory</Text>
            <Select
              showSearch={true}
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={true}
              onSearch={this.searchInventory}
              value={this.state.to_inventory}
              onChange={this.setToInventory}
            >
              {this.state.inventories.map(r => {
                return (
                  <Option
                    key={r._id.$oid}
                  >{`Name : ${r.entity_name} >>> Address : ${r.entity_address}`}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.transferInventory}
            >
              Transfer Inventory
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
