import React from "react";
import { Table, Card, PageHeader, Button, Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import { CREATE_CONTAINER, EDIT_CONTAINER } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Column } = Table;

export default class ContainerList extends React.Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false
  };
  componentDidMount() {
    this.getContainerList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

  }

  getContainerList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_CONTAINER}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response from container list", response);
          // if (response.data.data.length === 0) {
          //   swal.fire("Info", "No Data Available", "info");
          // }
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from container list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1
      },
      () => {
        this.getContainerList();
      }
    );
  };

  deleteContainer = id => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EDIT_CONTAINER}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <Link to="/createContainer">
          <Tooltip placement="top" title="Create New Container">
            <Button
              shape="circle"
              icon="plus"
              style={{
                position: "fixed",
                zIndex: 100,
                bottom: 30,
                right: 20,
                backgroundColor: "#1A3337",
                height: 60,
                width: 60,
                fontSize: 30,
                paddingBottom: 5
              }}
            />
          </Tooltip>
        </Link>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Containers"
        />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Length" dataIndex="cl" key="cl" />
          <Column title="Height" dataIndex="ch" key="ch" />
          <Column title="Width" dataIndex="cw" key="cw" />
          <Column title="Dimension" dataIndex="unit_dim" key="unit_dim" />
          <Column title="Max Weight" dataIndex="max_weight" key="max_weight" />
          <Column
            title="Gross Weight"
            dataIndex="gross_weight"
            key="gross_weight"
          />
          <Column
            title="Unit Weight"
            dataIndex="unit_weight"
            key="unit_weight"
          />
          <Column title="PIN" dataIndex="pin" key="pin" />

          <Column
            title="Created On"
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Modified On"
            key="modified_on"
            render={(text, record) => (
              <span>{timeConverter(record.modified_on.$date)}</span>
            )}
          />

          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                <Link
                  to={{
                    pathname: "/createReminder",
                    state: { container_id: record._id.$oid,container_name:record.name }
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    Add Reminder
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: "/viewContainer",
                    state: { container_id: record._id.$oid }
                  }}
                >
                  <Button
                    type="link"
                    style={{ color: "green" }}
                  >
                    View
                  </Button>
                </Link>
                <div>
                  {GET_USER_LOCAL().is_staff ||GET_USER_LOCAL().org_admin ? (
                    <Link
                    to={{
                      pathname: "/editContainer",
                      state: { container_id: record._id.$oid }
                    }}
                  >
                    <Button
                      type="link"
                      style={{ color: "green" }}
                    >
                      Edit
                    </Button>
                  </Link>
                  ):null}
                  </div>
                
                <div>
                  {GET_USER_LOCAL().is_staff ||GET_USER_LOCAL().org_admin ? (
                    <div>
                      <Button
                        type="link"
                        style={{ color: "red" }}
                        onClick={() => {
                          if (window.confirm("Delete the item?")) {
                            this.deleteContainer(record._id.$oid);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  ) : null}
                </div>
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}
      </Card>
    );
  }
}
