import React, { Component } from "react";
import { Card, Button, Typography, PageHeader, Select } from "antd";
import axios from "axios";
// import swal from "sweetalert2";
// import { merge } from "lodash";
import { Redirect } from "react-router-dom";
// import DateFnsUtils from "@date-io/date-fns";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import moment from "moment";

import {
  CREATE_PRODUCT,
  GET_ACTIVE_ORDER_STATUS,
  TARGET_QUERY_CHOICES,
  ACHIEVEMENT_WITH_QUERY,
  ORGANIZATION_SETTINGS_LIST,
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import ReactGA from "react-ga4";
// import { forIn } from "lodash";
// import moment from "moment";
// import DateFnsUtils from "@date-io/date-fns";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { debounce } from "lodash";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateAchievement extends Component {
  state = {
    products: [],
    selected_item: "",
    order_statuses: [],
    status: "",
    target_id: "",
    target_for: "",
    target_query_choices: [],
    target_query_id: "",
    org_setting_id: "",
    product_name: "",
    achievements: "",
    page: 1,
    limit: 10,
    redirect_to_target_list: false,
  };

  componentDidMount() {
    this.setState({
      target_id: this.props.location.state.target_id,
      target_for: this.props?.location?.state?.target_for ?? "",
    });
    this.getTargetQueryOptions();
    this.getProducts();
    this.getOrganizationSettingsList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }
  getAchievement = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ACHIEVEMENT_WITH_QUERY}?status=${this.state.status}&item_id=${this.state.selected_item}&p_id=${this.state.target_query_id}&page=${this.state.page}&limit=${this.state.limit}&target_id=${this.state.target_id}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from achievement with query", response);
          if (response.data.status_code===200) {
            this.setState({ redirect_to_target_list: true });
          }
        })
        .catch((error) => {
          console.log("error from achievement with query", error);
        });
    });
  };
  getTargetQueryOptions = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${TARGET_QUERY_CHOICES}?target_table=${this.state.target_for}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from target query choices", response);
          this.setState({
            target_query_choices: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from target query choices", error);
        });
    });
  };

  setTargetQuery = (value) => {
    this.setState({ target_query_id: value }, () => {
      console.log(this.state.target_query_id);
    });
  };

  getProducts = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT}?name=${this.state.product_name}&is_parent_product=true&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from products", response);
          this.setState({
            products: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from products", error);
        });
    });
  }, 500);

  getOrganizationSettingsList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ORGANIZATION_SETTINGS_LIST}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState(
            {
              org_setting_id: response.data?.data[0]?._id.$oid ?? "",
            },
            () => {
              this.getOrderStatus();
            }
          );
          console.log("response from organization settings list", response);
        })
        .catch((error) => {
          console.log("error from organization settings list api", error);
        });
    });
  };

  getOrderStatus = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${GET_ACTIVE_ORDER_STATUS}${this.state.org_setting_id}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from target types", response);
          this.setState({
            order_statuses: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from target types api", error);
        });
    });
  };
  onItemSearch = (value) => {
    this.setState({ product_name: value }, () => {
      this.getProducts();
    });
  };
  setSelectedItem = (value) => {
    this.setState({ selected_item: value }, () => {
      console.log(this.state.selected_item);
    });
  };
  setOrdeStatus = (value) => {
    this.setState({ status: value }, () => {
      console.log(this.state.status);
    });
  };

  render() {
    if (this.state.redirect_to_target_list) {
      return (
        <Redirect
          to={{
            pathname: "/achievementList",
          }}
        />
      );
    }
    return (
      <div style={{ margin: 5, backgroundColor: "#F5F5F5" }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Achievement Settings"
        />
        <Card>
          {this.state.target_for === "order" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 10,
              }}
            >
              <Text>Set Order Status</Text>
              <Select value={this.state.status} onChange={this.setOrdeStatus}>
                {this.state.order_statuses.map((odr) => (
                  <Option key={odr.name}>{odr.name}</Option>
                ))}
              </Select>
            </div>
          ) : null}
          {this.state.target_for === "task" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 10,
              }}
            >
              <Text>Set Task Status</Text>
              <Select value={this.state.status} onChange={this.setOrdeStatus}>
                <Option key="STARTED">STARTED</Option>
                <Option key="ACCEPTED">ACCEPTED</Option>
                <Option key="COMPLETED">COMPLETED</Option>
              </Select>
            </div>
          ) : null}
          {this.state.target_for === "order" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 10,
              }}
            >
              <Text>Search Parent Product:</Text>
              <Select
                showSearch={true}
                filterOption={false}
                showArrow={false}
                autoClearSearchValue={true}
                onSearch={this.onItemSearch}
                style={{ width: "100%" }}
                value={this.state.selected_item}
                onChange={this.setSelectedItem}
              >
                {this.state.products.map((r) => {
                  return <Option key={r._id.$oid}>{`${r.name}`}</Option>;
                })}
              </Select>
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 10,
            }}
          >
            <Text>Select Achievement Query</Text>
            <Select
              disabled={
                this.state.target_query_choices.length < 1 ? true : false
              }
              value={this.state.target_query_id}
              onChange={this.setTargetQuery}
            >
              {this.state.target_query_choices.map((tf) => (
                <Option key={tf.p_id}>{tf.label}</Option>
              ))}
            </Select>
          </div>
          <Button
            style={{ marginTop: 20 }}
            className="login-form-button"
            htmlType="submit"
            onClick={this.getAchievement}
          >
            Save Achievement
          </Button>
        </Card>
        {/* {this.state.achievements.length > 0 ? (
          <Card>
            <Table
              dataSource={this.state.achievements}
              pagination={false}
              rowKey={(record) => record.created_on.$date}
              size="small"
              bordered
            >
              <Column title="Name" dataIndex="name" key="name" />
              <Column
                title="Target For"
                dataIndex="target_for"
                key="target_for"
              />
              <Column
                title="Target Table"
                dataIndex="target_table"
                key="target_table"
              />
              <Column
                title="Target Value"
                dataIndex="target_value"
                key="target_value"
              />
              <Column
                title="Achieved Value"
                dataIndex="achievement"
                key="achievement"
              />
              <Column
                title="From"
                key="target_from"
                render={(text, record) => (
                  <span>{timeConverter(record.target_from.$date)}</span>
                )}
              />
              <Column
                title="To"
                key="target_to"
                render={(text, record) => (
                  <span>{timeConverter(record.target_to.$date)}</span>
                )}
              />
              <Column
                title="Created On"
                key="created_on"
                render={(text, record) => (
                  <span>{timeConverter(record.created_on.$date)}</span>
                )}
              />
            </Table>
          </Card>
        ) : null} */}
      </div>
    );
  }
}
