import React, { Component } from "react";
import { Card, PageHeader, Typography, Select, Button,Table } from "antd";
import "leaflet/dist/leaflet.css";
import "../App.css";
import { Map, Marker, Popup, Polyline } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L from "leaflet";
import axios from "axios";
import swal from "sweetalert2";

import { USER_ROUTE } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter, GET_USER_LOCAL } from "../../utilities/utilities";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40]
});
const green = new L.Icon({
  iconUrl: require("../.././assets/mymarker.png"),
  iconSize: [30, 40]
});
const user = new L.Icon({
  iconUrl: require("../.././assets/user.png"),
  iconSize: [20, 30]
});

const { Text } = Typography;
const { Option } = Select;
const { Column } = Table;


export default class UserRoute extends Component {
  state = {
    search_user: "",
    search_start_time: true,
    search_end_time: true,
    start_time: "",
    end_time: "",
    user_name: "",
    routeData: [],
    center_lat: 23.685,
    center_lon: 90.3563,
    zoom: 13,
    accuracy: "all",
    limit: 500,
    total: 0
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getUserRoute();
  }

  setAccuracy = value => {
    this.setState({ accuracy: value }, () => {
      console.log(this.state.accuracy);
      this.getUserRoute();
    });
  };

  getUserRoute = () => {
    this.setState(
      {
        search_user: this.props.location.state.search_user,
        start_time: this.props?.location?.state?.start_time ?? Date.now(),
        end_time: this.props?.location?.state?.end_time ?? Date.now(),
        user_name: this.props.location.state.user_name
      },
      () => {
        console.log("date", this.state.start_time);
        let date = new Date();
        let time = date.getTime();
        GET_ACCESS_TOKEN().then(token => {
          const url = `${USER_ROUTE}?sort_type=asc&limit=${this.state.limit}&ts=${time}&search_start_time=${this.state.search_start_time}&search_end_time=${this.state.search_end_time}&start_time=${this.state.start_time}&end_time=${this.state.end_time}&search_user=${this.state.search_user}&accuracy=${this.state.accuracy}&access_token=${token}`;

          axios({
            method: "get",
            url: url
          })
            .then(response => {
              console.log("response from user route api", response);
              if (response.data.data.length === 0) {
                swal.fire("Info", "No Route Data Found", "info");
              } else {
                this.setState({
                  routeData: response.data.data,
                  total: response.data.total
                });
              }
            })
            .catch(error => {
              console.log("error from user route api", error);
            });
        });
      }
    );
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title={`Route History for ${this.state.user_name}`}
          onBack={() => this.props.history.goBack()}
        />
        <Card style={{ margin: 10 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Text className="task-view-text">
              From: {""}
              <Text>{timeConverter(this.state.start_time)}</Text>
            </Text>
            <Text className="task-view-text">
              To: {""}
              <Text>{timeConverter(this.state.end_time)}</Text>
            </Text>
            <div style={{ marginLeft: 10, marginTop: 5 }}>
              <Text> Accuracy : {""}</Text>
              <Select
                style={{ minWidth: 100 }}
                value={this.state.accuracy}
                onChange={this.setAccuracy}
                placeholder="Accuracy"
              >
                <Option key="all">Show all</Option>
                <Option key="low">Low</Option>
                <Option key="medium">Medium</Option>
                <Option key="high">High</Option>
              </Select>
            </div>
          </div>
        </Card>
        {this.state.total > 500 ? (
          <Card style={{ margin: 10 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button
                type="primary"
                style={{ marginRight: 5 }}
                onClick={() =>
                  this.setState({ limit: this.state.limit - 500 }, () => {
                    this.getUserRoute();
                  })
                }
                disabled={this.state.limit<=500?true:false}
              >
                View Less Data
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 5 }}
                onClick={() =>
                  this.setState({ limit: this.state.limit + 500 }, () => {
                    this.getUserRoute();
                  })
                }
                disabled={this.state.limit>=this.state.total?true:false}
              >
                View More Data
              </Button>
            </div>
          </Card>
        ) : null}

        <Card style={{ margin: 10 }}>
          <Text style={{color:"#FFB72B",fontSize:"large"}}>Location data accuracy depends on device settings. The quality of the routes may vary based on devices.</Text>
          <Map
            center={
              this.state.routeData.length > 0
                ? [
                    this.state.routeData[0].loc.coordinates[1],
                    this.state.routeData[0].loc.coordinates[0]
                  ]
                : [this.state.center_lat, this.state.center_lon]
            }
            zoom={this.state.zoom}
            style={{ height: 800 }}
            onZoomEnd={e => this.setState({ zoom: e.target._zoom })}
          >
            <ReactLeafletGoogleLayer
              googleMapsLoaderConf={{
                KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                VERSION: "3.49.2"
              }}
              type={"roadmap"}
            />

            {this.state.routeData.map((route, index) => (
              <Marker
                key={route._id.$oid}
                position={[route.loc.coordinates[1], route.loc.coordinates[0]]}
                icon={
                  index === 0
                    ? green
                    : index === this.state.routeData.length - 1
                    ? red
                    : user
                }
              >
                <Popup>
                  <span>{`${index + 1} : ${timeConverter(
                    route.timestamp.$date
                  )}`}</span>
                </Popup>
              </Marker>
            ))}
            <Polyline
              color="purple"
              positions={[
                this.state.routeData.map(m => [
                  m.loc.coordinates[1],
                  m.loc.coordinates[0]
                ])
              ]}
            />
          </Map>
        </Card>
        {GET_USER_LOCAL().org_admin||GET_USER_LOCAL().is_staff?(
          <Card style={{ margin: 10 }}>
             <Table
          dataSource={this.state.routeData}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          
          <Column
            title="Data Status"
            key="Data Status"
            render={(text, record) => (
              <span>{record?.data_status}</span>
            )}
          />
          <Column
            title="Longitude"
            key="Longitude"
            render={(text, record) => (
              <span>{record?.loc?.coordinates[0]??""}</span>
            )}
          />
          <Column
            title="Lattitude"
            key="Lattitude"
            render={(text, record) => (
              <span>{record?.loc?.coordinates[1]??""}</span>
            )}
          />
          <Column
            title="Accuracy"
            key="Accuracy"
            render={(text, record) => (
              <span>{record?.accuracy}</span>
            )}
          />
          <Column
            title="Altitude"
            key="Altitude"
            render={(text, record) => (
              <span>{record?.altitude}</span>
            )}
          />
          <Column
            title="Bearing"
            key="Bearing"
            render={(text, record) => (
              <span>{record?.bearing}</span>
            )}
          />
          <Column
            title="Speed"
            key="Speed"
            render={(text, record) => (
              <span>{record?.speed}</span>
            )}
          />
          

          <Column
            title="Timestamp"
            key="timestamp"
            render={(text, record) => (
              <span>{timeConverter(record.timestamp.$date)}</span>
            )}
          />
        </Table>
          </Card>
        ):null}
      </Card>
    );
  }
}
