import React from "react";
import { Form, Icon, Input, Button, Card } from "antd";
import { Redirect } from "react-router-dom";
import swal from "sweetalert2";
import axios from "axios";

import { GET_ACCESS_TOKEN } from "../utilities/utilities";
import { EMAIL_VERIFY, RESEND_EMAIL } from "../api/constants";
import { Spinner } from "./loader";
import "./login.css";
import Logo from "../assets/logo.png";

class VerifyAccountEmail extends React.Component {
  state = {
    email: "",
    code: "",
    loading: false,
    redirect: false,
    width: window.innerWidth,
    height: window.innerHeight
  };

  componentDidMount() {
    this.setState({ email: this.props.location.state.email });
    const handleResize = () => {
      this.setState({ height: window.innerHeight, width: window.innerWidth });
    };
    window.addEventListener("resize", handleResize);
  }


  setCode = evt => {
    evt.preventDefault();
    this.setState({ code: evt.target.value });
    console.log(this.state.code);
  };

  verifyUser = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      const url = `${EMAIL_VERIFY}${this.state.code}&access_token=${token}&is_json=true`;
      axios({
        method: "get",
        url: url,
        headers:{
            'content-type': 'application/x-www-form-urlencoded' 
        }
      })
        .then(response => {
          this.setState({ loading: false });
          if (response.data.hasOwnProperty("status")) {
            swal.fire("Information", response.data.status, "info");
          }
          if (response.data.status === "email verified") {
            this.setState({ redirect: true });
          }
          console.log("response from verify user email api", response);
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error in verify email user", error);
        });
    });
  };

  resendCode = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then(token => {
      axios({
        method: "get",
        url: `${RESEND_EMAIL}&access_token=${token}`
      })
        .then(response => {
          this.setState({ loading: false });
          swal.fire("Information", response.data.status, "info");
          console.log("response from resend email", response);
        })
        .catch(error => {
         this.setState({ loading: false });
          console.log("error in resend email", error);
          swal.fire("Error", error, "error");
        });
    });
  };
  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/createOrganization" />;
    } else if (this.state.loading === true) {
      return <Spinner/>;
    } else if (this.state.toLogin === true) {
      return <Redirect to="/" />;
    }
    return (
      <div className="container" style={{width:this.state.width}}>
        <div style={{ textAlign: "center", paddingBottom: 10 }}>
          <img src={Logo} alt="" style={{width:300,height:70}}/>
        </div>
        <Card
          title={`We sent an email with your code to ${this.state.email}`}
          className="card"
          style={{width:450,margin:"auto"}}
        >
          <Form className="login-form">
            <Form.Item>
              <Input
                type="number"
                prefix={
                  <Icon type="number" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Enter Verification Code"
                value={this.state.code}
                onChange={this.setCode}
              />
            </Form.Item>
            <Form.Item>
              <Button className="login-form-button" htmlType="submit" onClick={this.verifyUser}>
                Verify Account
              </Button>
              <button className="resend-code" onClick={this.resendCode}>
                I didn't receive the code
              </button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

export default VerifyAccountEmail;
