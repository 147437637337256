import React, { Component } from "react";
import { Card, Button, PageHeader,Descriptions } from "antd";
import axios from "axios";
// import swal from "sweetalert2";
import { EDIT_MONTHLYBILL } from "../../api/constants";
import { GET_ACCESS_TOKEN, LOCAL_USER_INFO } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";



import "../login.css";



export default class ViewMonthlyBill extends Component {
  state = {
    name: "",
    address: "",
    city: "",
    country: "",
    connect_key: "",
    post_code: "",
    telephone: "",
    website: "",
    sub_orgs: "",
    credit_days: null,
    tax_amount: null,
    plans: [],
    created_on: "",
    discount: null,
    active: false,
    loading: false,
    monthlyBill_id: "",
    user_id: "",
    billed_amount: null,
    dues: null,
    balance: null
  };
  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    // let is_super = LOCAL_USER_INFO().user.is_super;
    // let is_staff = LOCAL_USER_INFO().user.is_staff;
    console.log(this.props.history);
    this.setState({
      monthlyBill_id: this.props.location.state.monthlyBill_id,
      loading: true,
      user_id: LOCAL_USER_INFO().user._id
    });
    
    GET_ACCESS_TOKEN().then(token => {
      let monthlyBill_id = this.props.location.state.monthlyBill_id;
      let url = `${EDIT_MONTHLYBILL}${monthlyBill_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ loading: false });
          console.log("response fro edit monthly bill", response);
          this.setState({
            name: response.data.data.org.name,
            address: response.data.data.org.address,
            city: response.data.data.org.city,
            country: response.data.data.org.country,
            connect_key: response.data.data.org.connect_key,
            post_code: response.data.data.org.post_code,
            telephone: response.data.data.org.telephone,
            website: response.data.data.org.website,
            sub_orgs: response.data.data.org.sub_orgs,
            credit_days: response.data.data.org.credit_days,
            tax_amount: response.data.data.org.tax_amount,
            plans: response.data.data.org.plans,
            created_on: response.data.data.org.created_on,
            discount: response.data.data.org.discount,
            active: response.data.data.org.active,
            billed_amount: response.data.data.billed_amount,
            dues: response.data.data.dues,
            balance: response.data.data.balance
          });
        })
        .catch(error => {
          this.setState({ loading: false });
          console.log("error from view onthly bill", error);
        });
    });
  }

  //   updateOrganization = () => {
  //     this.setState({ loading: true });
  //     const data = {
  //       name: this.state.name,
  //       address: this.state.address,
  //       city: this.state.city,
  //       country: this.state.country,
  //       connect_key: this.state.connect_key,
  //       post_code: this.state.post_code,
  //       telephone: this.state.telephone,
  //       website: this.state.website,
  //       sub_orgs: this.state.sub_orgs,
  //       deleted: false
  //     };
  //     console.log(data);
  //     let url = `${EDIT_ORGANIZATION}${this.state.org_id}`;
  //     GET_ACCESS_TOKEN().then(token => {
  //       axios({
  //         method: "put",
  //         url: url,
  //         data: data,
  //         headers: {
  //           Authorization: `Bearer ${token}`
  //         }
  //       })
  //         .then(response => {
  //           this.setState({ loading: false });
  //           swal.fire("success", response.data.message, "success");
  //           console.log("response from edit org", response);
  //         })
  //         .catch(error => {
  //           this.setState({ loading: false });
  //           swal.fire("error", error, "error");
  //           console.log("error from edit org", error);
  //         });
  //     });
  //   };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          onBack={() => this.props.history.goBack()}
          title="View Monthly Bill"
        />
         <Descriptions
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
        layout="vertical"
      >
        <Descriptions.Item label="Name">{this.state.name}</Descriptions.Item>
        <Descriptions.Item label="Address">{this.state.address}</Descriptions.Item>
        <Descriptions.Item label="City">{this.state.city}</Descriptions.Item>
        <Descriptions.Item label="Country"> {this.state.country}</Descriptions.Item>
        <Descriptions.Item label="Post Code">{this.state.post_code}</Descriptions.Item>
        <Descriptions.Item label="Telephone">{this.state.telephone}</Descriptions.Item>
        {/* <Descriptions.Item label="Remarks">{this.state.remarks}</Descriptions.Item> */}
        <Descriptions.Item label="Credit Days">{this.state.credit_days}</Descriptions.Item>
        <Descriptions.Item label="Tax Amount">{this.state.tax_amount}</Descriptions.Item>
        <Descriptions.Item label=" Discount Amount">{this.state.discount_amount}</Descriptions.Item>
        <Descriptions.Item label=" Total Amount">{this.state.sum_total}</Descriptions.Item>
        <Descriptions.Item label=" Billed Amount">{this.state.billed_amount}</Descriptions.Item>
        {/* <Descriptions.Item label=" Bill Date"> {this.state.month}/{this.state.year}</Descriptions.Item> */}
        <Descriptions.Item label="  Balance"> {this.state.balance}</Descriptions.Item>
        <Descriptions.Item label="  Dues"> {this.state.dues}</Descriptions.Item>
        <Descriptions.Item label="  Payments"> {this.state.payments}</Descriptions.Item>
        {/* <Descriptions.Item label="  Paid"> {this.state.has_paid.toString()}</Descriptions.Item> */}
        <Descriptions.Item label="  Active"> {this.state.active.toString()}</Descriptions.Item>
      </Descriptions>
        {this.state.balance === 0 ? null : (
          <Card.Grid className="card-grid">
            {this.state.country.split(" ")[0]==="Bangladesh"||this.state.country.split(" ")[0]==="Nepal"?(
              <Button
              className="login-form-button"
              htmlType="submit"
              onClick={() =>
                (window.location.href = `https://web.conveyance.app/paymentPage/${this.state.monthlyBill_id}/${this.state.user_id}`)
              }
            >
              Pay Now
            </Button>
            ):(
              <Button
              className="login-form-button"
              htmlType="submit"
              onClick={() =>
                (window.location.href = `https://web.conveyance.app/paymentPageInt/${this.state.monthlyBill_id}/${this.state.user_id}`)
              }
            >
              Pay Now
            </Button>
            )}
            
          </Card.Grid>
        )}
      </Card>
    );
  }
}
