import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Pagination,
  Select,
  Typography,
  Input,
} from "antd";
import axios from "axios";
// import swal from "sweetalert2";

import { INCIDENT_BILLS } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text } = Typography;

export default class IncidentBills extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10
  };
  componentDidMount() {
    this.getIncidentBills();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getIncidentBills = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${INCIDENT_BILLS}${this.props?.location?.state?.incident_id ?? ""}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from incident bills", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from incident bills api", error);
        });
    });
  }

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getIncidentBills();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getIncidentBills();
    });
  };

  

  render() {
    return (
      <Card style={{ margin: 10 }}>
      

        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title={`Bills For ${this.props?.location?.state?.incident_name??""}`}
          onBack={() => this.props.history.goBack()}
        />
        
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
         
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Name</Text>
                  <Input disabled/>
                </div>
              </>
            }
            key="name"
            render={(text, record) => <span>{record.name ?? ""}</span>}
          />
          
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Bill No.</Text>
                  <Input disabled/>
                </div>
              </>
            }
            key="bill_no"
            render={(text, record) => <span>{record.bill_no ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Price</Text>
                  <Input disabled/>
                </div>
              </>
            }
            key="total_price"
            render={(text, record) => <span>{record.total_price ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Approved Price</Text>
                  <Input disabled/>
                </div>
              </>
            }
            key="total_approved_price"
            render={(text, record) => <span>{record.total_approved_price ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Recommended</Text>
                  <Input disabled/>
                </div>
              </>
            }
            key="recommended"
            render={(text, record) => <span>{record.recommended ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Authorized</Text>
                  <Input disabled/>
                </div>
              </>
            }
            key="authorized"
            render={(text, record) => <span>{record.authorized ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Approved</Text>
                  <Input disabled/>
                </div>
              </>
            }
            key="approved"
            render={(text, record) => <span>{record.approved ?? ""}</span>}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created By</Text>
                  <Input disabled/>
                </div>
              </>
            }
            key="created_by_name"
            render={(text, record) => <span>{record.created_by_name ?? ""}</span>}
          />
          

          <Column
            title="Created On"
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

