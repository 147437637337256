import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Typography,
  Select,
  Pagination,
  Modal,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
// import swal from "sweetalert2";
// import { debounce } from "lodash";

import { CREATE_PROMOTION } from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  //   GET_USER_LOCAL
  //   CHECK_BOOL
} from "../../utilities/utilities";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;

class PromotionList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    show_modal: false,
    modal_data: [],
    promotion_type: [],
  };
  componentDidMount() {
    this.getPromotionList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getPromotionList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PROMOTION}?page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from promotion list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from promotion list api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getPromotionList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getPromotionList();
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Promotions"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            {/* <Text>{`  Entries `}</Text> */}
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Promotion Name" dataIndex="name" key="name" />
          <Column
            title="Promotion For"
            dataIndex="promotion_for_type"
            key="promotion_for_type"
          />
          <Column
            title="Promotion Type"
            dataIndex="promotion_type"
            key="promotion_type"
          />
          <Column
            title="Start Date"
            key="start_date"
            render={(text, record) => (
              <span>{timeConverter(record.start_date.$date)}</span>
            )}
          />
          <Column
            title="End Date"
            key="end_date"
            render={(text, record) => (
              <span>{timeConverter(record.end_date.$date)}</span>
            )}
          />
          <Column
            title="Created On"
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />

          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.order_and_delivery.promotion
                  .promotion_detail ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() =>
                      this.setState({
                        modal_data:
                          record.all_order_count_discount_tier.length > 0
                            ? record.all_order_count_discount_tier
                            : record.all_order_total_val_discount_tier.length >
                              0
                            ? record.all_order_total_val_discount_tier
                            : record.order_receivable_discount_tier.length > 0
                            ? record.order_receivable_discount_tier
                            : record.order_total_discount_tier.length > 0
                            ? record.order_total_discount_tier
                            : record.product_qty_discount_tier.length > 0
                            ? record.product_qty_discount_tier
                            : record.wallet_payable_discount_tier.length > 0
                            ? record.wallet_payable_discount_tier
                            : [],
                        show_modal: true,
                        promotion_type: record.promotion_type,
                      })
                    }
                  >
                    View Details
                  </Button>
                ) : null}
                {this.props.userProfileData.order_and_delivery.promotion
                  .promotion_update ? (
                  <Link
                    to={{
                      pathname: "/editPromotion",
                      state: {
                        promotion_id: record._id.$oid,
                      },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
        <Modal
          title="Details"
          visible={this.state.show_modal}
          onOk={() => {
            this.setState({ showEntity: false });
          }}
          onCancel={() => {
            this.setState({
              show_modal: false,
              modal_data: [],
              promotion_type: "",
            });
          }}
          footer={null}
          width={window.innerWidth - 200}
          closable={true}
          destroyOnClose={true}
        >
          <Card style={{ margin: 5 }}>
            {this.state.promotion_type === "product_qty_discount" &&
            this.state.modal_data.length > 0 ? (
              <Table
                dataSource={this.state.modal_data}
                pagination={false}
                rowKey={(record) => record.id}
                size="small"
                bordered
              >
                <Column
                  title="Buy Products"
                  key="buy_products"
                  render={(text, record) => (
                    <span>
                      {record.buy_product.map((p) => (
                        <Tag
                          key={p.product_id}
                        >{`Name:${p.product_name}|Quantity:${p.product_qty}`}</Tag>
                      ))}
                    </span>
                  )}
                />
                <Column
                  title="Free Products"
                  key="free_products"
                  render={(text, record) => (
                    <span>
                      {record.free_product.map((p) => (
                        <Tag
                          key={p.product_id}
                        >{`Name:${p.product_name}|Quantity:${p.product_qty}`}</Tag>
                      ))}
                    </span>
                  )}
                />
                <Column
                  title="Description"
                  dataIndex="description"
                  key="description"
                />
                <Column title="Label" dataIndex="label" key="label" />
                <Column
                  title="Discount Type"
                  dataIndex="discount_type"
                  key="discount_type"
                />
                <Column title="Discount" dataIndex="discount" key="discount" />
                <Column
                  title="Discount Amount Limits"
                  dataIndex="discount_amount_limit"
                  key="discount_amount_limit"
                />
              </Table>
            ) : this.state.promotion_type === "all_order_count_discount" &&
              this.state.modal_data.length > 0 ? (
              <Table
                dataSource={this.state.modal_data}
                pagination={false}
                rowKey={(record) => record.id}
                size="small"
                bordered
              >
                <Column
                  title="Order Quantity"
                  dataIndex="order_qty"
                  key="order_qty"
                />
                <Column
                  title="Description"
                  dataIndex="description"
                  key="description"
                />
                <Column title="Label" dataIndex="label" key="label" />
                <Column
                  title="Discount Type"
                  dataIndex="discount_type"
                  key="discount_type"
                />
                <Column title="Discount" dataIndex="discount" key="discount" />
                <Column
                  title="Discount Amount Limits"
                  dataIndex="discount_amount_limit"
                  key="discount_amount_limit"
                />
              </Table>
            ) : this.state.promotion_type === "all_order_total_val_discount" &&
              this.state.modal_data.length > 0 ? (
              <Table
                dataSource={this.state.modal_data}
                pagination={false}
                rowKey={(record) => record.id}
                size="small"
                bordered
              >
                <Column
                  title="All Order Total Value"
                  dataIndex="all_order_total_val"
                  key="all_order_total_val"
                />
                <Column
                  title="Description"
                  dataIndex="description"
                  key="description"
                />
                <Column title="Label" dataIndex="label" key="label" />
                <Column
                  title="Discount Type"
                  dataIndex="discount_type"
                  key="discount_type"
                />
                <Column title="Discount" dataIndex="discount" key="discount" />
                <Column
                  title="Discount Amount Limits"
                  dataIndex="discount_amount_limit"
                  key="discount_amount_limit"
                />
              </Table>
            ) : this.state.promotion_type === "order_total_discount" &&
              this.state.modal_data.length > 0 ? (
              <Table
                dataSource={this.state.modal_data}
                pagination={false}
                rowKey={(record) => record.id}
                size="small"
                bordered
              >
                <Column
                  title="Order Total"
                  dataIndex="order_total"
                  key="order_total"
                />
                <Column
                  title="Description"
                  dataIndex="description"
                  key="description"
                />
                <Column title="Label" dataIndex="label" key="label" />
                <Column
                  title="Discount Type"
                  dataIndex="discount_type"
                  key="discount_type"
                />
                <Column title="Discount" dataIndex="discount" key="discount" />
                <Column
                  title="Discount Amount Limits"
                  dataIndex="discount_amount_limit"
                  key="discount_amount_limit"
                />
              </Table>
            ) : this.state.promotion_type === "wallet_payable_discount" &&
              this.state.modal_data.length > 0 ? (
              <Table
                dataSource={this.state.modal_data}
                pagination={false}
                rowKey={(record) => record.id}
                size="small"
                bordered
              >
                <Column
                  title="Payable Amount"
                  dataIndex="payable_amount"
                  key="payable_amount"
                />
                <Column
                  title="Description"
                  dataIndex="description"
                  key="description"
                />
                <Column title="Label" dataIndex="label" key="label" />
                <Column
                  title="Discount Type"
                  dataIndex="discount_type"
                  key="discount_type"
                />
                <Column title="Discount" dataIndex="discount" key="discount" />
                <Column
                  title="Discount Amount Limits"
                  dataIndex="discount_amount_limit"
                  key="discount_amount_limit"
                />
                <Column
                  title="Calculated Discount Amount"
                  dataIndex="calculated_discount_amount"
                  key="calculated_discount_amount"
                />
              </Table>
            ) : this.state.promotion_type === "order_receivable_discount" &&
              this.state.modal_data.length > 0 ? (
              <Table
                dataSource={this.state.modal_data}
                pagination={false}
                rowKey={(record) => record.id}
                size="small"
                bordered
              >
                <Column
                  title="Receivable Amount"
                  dataIndex="receivable_amount"
                  key="receivable_amount"
                />
                <Column
                  title="Description"
                  dataIndex="description"
                  key="description"
                />
                <Column title="Label" dataIndex="label" key="label" />
                <Column
                  title="Discount Type"
                  dataIndex="discount_type"
                  key="discount_type"
                />
                <Column title="Discount" dataIndex="discount" key="discount" />
                <Column
                  title="Discount Amount Limits"
                  dataIndex="discount_amount_limit"
                  key="discount_amount_limit"
                />
                <Column
                  title="Calculated Discount Amount"
                  dataIndex="calculated_discount_amount"
                  key="calculated_discount_amount"
                />
              </Table>
            ) : null}
          </Card>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(PromotionList);
