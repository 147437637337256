import React, { Component } from "react";
import { Card, Tabs,Typography } from "antd";
// import { Link } from "react-router-dom";

import "../login.css";
// import TaskList from "./taskList";
import AdvancedTaskList from "./advancedTaskList";
// import BasicListTab from "./basicListTab";
import ReactGA from "react-ga4";

// const { CheckableTag } = Tag;
const { TabPane } = Tabs;
const { Title } = Typography;
// const tagsData = ['upcoming', 'accepted', 'started', 'completed','cancelled','is_public','search_start_time','search_end_time'];
export default class TaskListTab extends Component {
  state = {
    activeKeyParent: "2"
  };

  componentDidMount() {
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

    let keyParent = JSON.parse(localStorage.getItem("activeKeyParent"));
    if (keyParent) {
      this.setState({ activeKeyParent: keyParent });
    }
  }

  onChange = activeKeyParent => {
    console.log(`onChange ${activeKeyParent}`);
    this.setState(
      {
        activeKeyParent
      },
      () => {
        localStorage.setItem(
          "activeKeyParent",
          JSON.stringify(this.state.activeKeyParent)
        );
      }
    );
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <Title style={{marginLeft:15}} level={3}>Task List</Title>
        {/* <Link to="/createTask">
          <Tooltip placement="top" title="Create New Task">
            <Button
              shape="circle"
              icon="plus"
              style={{
                position: "fixed",
                zIndex: 100,
                bottom: 30,
                right: 20,
                backgroundColor: "#1A3337",
                height: 60,
                width: 60,
                fontSize: 30,
                paddingBottom: 5
              }}
            />
          </Tooltip>
        </Link> */}
        <Tabs
          destroyInactiveTabPane={true}
          activeKey={this.state.activeKeyParent}
          onChange={this.onChange}
        >
          {/* <TabPane tab="Basic List" key="1">
            <BasicListTab />
          </TabPane> */}
          <TabPane tab="Advanced List" key="2">
            <AdvancedTaskList />
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}
