import React, { Component } from "react";
import {
  Card,
  //   Form,
  //   Input,
  // Table,
  Typography,
  PageHeader,
  // Pagination,
  // Button,
    // Select
} from "antd";
import axios from "axios";

import {  CREATE_TARGET } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import ReactGA from "react-ga4";

import "../login.css";

const { Text } = Typography;
// const { Column } = Table;
// const { Option } = Select;

export default class TargetDetails extends Component {
  state = {
    target_id: "",
    name: "",
    duration_type: "",
    target_for: "",
    target_type: "",
    target_value: 0,
    created_on: "",
    start_time: "",
    end_time: "",
    user: [],
    sub_org: [],
    achievements: [],
    page: 0,
    limit: 10,
    total_documents: 0,
    achievement_id:""
  };

  componentDidMount() {
    this.setState({ target_id: this.props.location.state.target_id });
    this.getTargetDetails();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }
  // getAchievement = () => {
  //   let target_id = this.props.location.state.target_id;
  //   GET_ACCESS_TOKEN().then((token) => {
  //     const url = `${EDIT_ACHIEVEMENT}${this.state.achievement_id}?target_id=${target_id}&page_number=${this.state.page}&page_size=${this.state.limit}&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url,
  //     })
  //       .then((response) => {
  //         this.setState({
  //           achievements: response?.data?.data?.results ?? [],
  //           total_documents: response?.data?.data?.total_documents ?? 0,
  //         });
  //         console.log("response from achievement details", response);
  //       })
  //       .catch((error) => {
  //         console.log("error from achievement details", error);
  //       });
  //   });
  // };
  // pageChange = (current, pageSize) => {
  //   this.setState({ page: current - 1 }, () => {
  //     console.log("page number", this.state.page);
  //     this.getAchievement();
  //   });
  // };

  // setLimit = (value) => {
  //   this.setState({ limit: value }, () => {
  //     console.log(this.state.limit);
  //     this.getAchievement();
  //   });
  // };
  getTargetDetails = () => {
    let target_id = this.props.location.state.target_id;
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_TARGET}?target_id=${target_id}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            name: response.data?.data?.name ?? "",
            duration_type: response.data?.data?.duration_type ?? "",
            target_for: response.data?.data?.target_for ?? "",
            target_type: response.data?.data?.target_type ?? "",
            target_value: response.data?.data?.target_value ?? 0,
            created_on: response.data?.data?.created_on?.$date ?? "",
            start_time: response.data?.data?.start_time?.$date ?? "",
            end_time: response.data?.data?.end_time?.$date ?? "",
            user: response.data?.data?.assigned_to?.user ?? [],
            sub_org: response.data?.data?.assigned_to?.sub_org ?? [],
            achievement_id: response.data?.data?.achievement_handle.$oid ?? "",
          },()=>{
            // this.getAchievement();
          });
          console.log("response from target details", response);
        })
        .catch((error) => {
          console.log("error from target details", error);
        });
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Target Details"
          onBack={() => this.props.history.goBack()}
        />

        <Card
          style={{
            marginTop: 5,
            width: "100%",
          }}
        >
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Target Name</Text>:{" "}
            {this.state.name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Target Type</Text>:{" "}
            {this.state.target_type}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Target For</Text>:{" "}
            {this.state.target_for}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Target Value</Text>:{" "}
            {this.state.target_value}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Duration Type</Text>:{" "}
            {this.state.duration_type}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Start Time</Text>:{" "}
            {timeConverter(this.state.start_time)}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>End Time</Text>:{" "}
            {timeConverter(this.state.end_time)}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Created On</Text>:{" "}
            {timeConverter(this.state.created_on)}
          </p>
        </Card>
        {/* {this.state.user.length > 0 ? (
          <Card
            style={{
              marginTop: 5,
              width: "100%",
            }}
          >
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              title="Users"
            />
            <Table
              dataSource={this.state.user}
              pagination={false}
              rowKey={(record) => record.user_id.$oid}
              size="small"
              bordered
            >
              <Column title="Ranking" dataIndex="ranking" key="ranking" />
              <Column
                title="Target Value"
                dataIndex="target_value"
                key="target_value"
              />
            </Table>
          </Card>
        ) : null} */}
      </Card>
    );
  }
}
