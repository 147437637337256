import React, { Component } from 'react';
import { Table, Divider, Card, PageHeader, Button,Tooltip } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";

import { CREATE_YOUTUBE_LINK, EDIT_YOUTUBE_LINK } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Column } = Table;

export default class ViewYoutubeLink extends Component {
    state = {
        data: [],
        page: 0,
        total: 0,
        loading: false
      };
      componentDidMount() {
        this.getLinkList();
      }
    
      getLinkList = () => {
        this.setState({ loading: true });
        GET_ACCESS_TOKEN().then(token => {
          const url = `${CREATE_YOUTUBE_LINK}?page=${this.state.page}&access_token=${token}`;

          axios({
            method: "get",
            url: url
          })
            .then(response => {
              this.setState({ loading: false });
              console.log("response from link list", response);
              // if (response.data.data.length === 0) {
              //   swal.fire("Info", "No Data Available", "info");
              // }
              this.setState({
                data:
                  this.state.page === 0
                    ? response.data.data
                    : [...this.state.data, ...response.data.data],
                total: response.data.total
              });
            })
            .catch(error => {
              this.setState({ loading: false });
              console.log("error from link list api", error);
            });
        });
      };
    
      handleLoadMore = () => {
        this.setState(
          {
            page: this.state.page + 1
          },
          () => {
            this.getLinkList();
          }
        );
      };
    
      deleteUnit = id => {
        this.setState({ loading: true });
        GET_ACCESS_TOKEN().then(token => {
          const url = `${EDIT_YOUTUBE_LINK}${id}?access_token=${token}`;

          axios({
            method: "delete",
            url: url
          })
            .then(response => {
              this.setState({ loading: false });
              swal.fire("Success", response.data.message, "success");
              setTimeout(() => window.location.reload(), 1000);
              console.log("deleted", response);
            })
            .catch(error => {
              this.setState({ loading: false });
              swal.fire("Error", error.message, "error");
              console.log("error from del", error);
            });
        });
      };
    
      render() {
        if (this.state.loading === true) {
          return <Spinner />;
        }
        return (
          <Card style={{ margin: 10 }}>
                <Link to="/createYoutubeLink">
          <Tooltip placement="top" title="Create New Link">
            <Button
              shape="circle"
              icon="plus"
              style={{
                position: "fixed",
                zIndex: 100,
                bottom: 30,
                right: 20,
                backgroundColor: "#1A3337",
                height: 60,
                width: 60,
                fontSize: 30,
                paddingBottom: 5
              }}
            />
          </Tooltip>
        </Link>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)"
              }}
              title="Youtube Links"
            />
            <Table dataSource={this.state.data} pagination={false} rowKey={record => record._id.$oid} size="small" bordered>
              <Column title="Name" dataIndex="name" key="name" />
              <Column title="Type" dataIndex="type" key="type" />
              <Column title="URL" dataIndex="link_url" key="link_url" />
              <Column
                title="Created On"
                key="created_by"
                render={(text, record) => (
                  <span>{timeConverter(record.created_on.$date)}</span>
                )}
              />
              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <span>
                    <Link
                      to={{
                        pathname: "/editYoutubeLink",
                        state: { youtube_link_id: record._id.$oid }
                      }}
                    >
                      <button
                        className="borderless-button"
                        style={{ color: "green" }}
                      >
                        Edit
                      </button>
                    </Link>
                    <Divider type="vertical" />
                    <button
                      className="borderless-button"
                      style={{ color: "red" }}
                      onClick={() => {
                        if (window.confirm("Delete the item?")) {
                          this.deleteUnit(record._id.$oid);
                        }
                      }}
                    >
                      Delete
                    </button>
                  </span>
                )}
              />
            </Table>
            {this.state.total > 10 ? (
              <div style={{ textAlign: "center", marginTop: 15 }}>
                <Button
                  style={{ color: "white", backgroundColor: "#0d98ad" }}
                  onClick={this.handleLoadMore}
                >
                  Load More ...
                </Button>
              </div>
            ) : null}
          </Card>
        );
      }
}
