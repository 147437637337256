import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Tag,
  Modal,
  Icon,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_PRODUCT,
  CREATE_UNIT,
  CREATE_INVENTORY,
  CREATE_PRODUCT_CATEGORY,
  EDIT_PRODUCT,
  ORG_IMAGE_UPLOAD
} from "../../api/constants";
import { debounce } from "lodash";
import { GET_ACCESS_TOKEN, CHECK_BOOL } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";
import CreateUnit from "../unit/createUnit";
// import CreateShop from "../shop/createShop";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateProduct extends Component {
  state = {
    name: "",
    description: "",
    unit_purchase_price: null,
    unit_selling_price: null,
    stock_quantity: 0,
    product_status: "active",
    mrp: null,
    sku: "",
    unit: "",
    tags: [],
    text: "",
    entity_id: "",
    entity_name: "",
    balance: null,
    loading: false,
    data: [],
    entities: [],
    redirectOrder: false,
    showUnit: false,
    showEntity: false,
    product_category_id: "",
    product_category_name: "",
    product_categories: [],
    is_parent_product: false,
    parent_product_id: "",
    parent_products: [],
    for_name: "product",
    imgFile: null,
    imgUrl: null,
    imgUrlThumbnail: null,
  };

  componentDidMount() {
    this.getUnitList();
    this.getEntityList();
    this.getProductCategory();
    this.getParentProduct();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getParentProduct = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT}?is_parent_product=true&limit=100&&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ parent_products: response.data.data });
          console.log("data from parent product list api", response);
        })
        .catch((error) => {
          console.log("error from parent product list api", error);
        });
    });
  };
  getProductCategory = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT_CATEGORY}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ product_categories: response.data.data });
          console.log("data from product category list api", response);
        })
        .catch((error) => {
          console.log("error from product category list api", error);
        });
    });
  };

  getEntityList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INVENTORY}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ entities: response.data.data });
          console.log("data from inventory list api", response);
        })
        .catch((error) => {
          console.log("error from inventory list api", error);
        });
    });
  };

  getUnitList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_UNIT}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ data: response.data.data });
          console.log("data from unit list api", response);
        })
        .catch((error) => {
          console.log("error from unit list api", error);
        });
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INVENTORY}?limit=100&entity_name=${value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ entities: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  }, 500);

  setProductCategory = (value) => {
    this.setState(
      { product_category_id: value.key, product_category_name: value.label },
      () => {
        console.log(this.state.product_category_name);
      }
    );
  };

  setEntity = (value) => {
    this.setState({ entity_id: value.key, entity_name: value.label }, () => {
      console.log(this.state.entity_id);
    });
  };
  setParentProduct = (value) => {
    this.setState({ parent_product_id: value }, () => {
      console.log(this.state.parent_product_id);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_PRODUCT}${this.state.parent_product_id}?access_token=${token}`;
        axios({
          method: "get",
          url: url,
        }).then((response) => {
          console.log("response fro parent product selection", response);
          this.setState({
            name: response.data?.data?.name ?? "",
            description: response.data?.data?.description ?? "",
            product_category_id: response.data?.data?.category_id ?? "",
            product_category_name: response.data?.data?.category_name ?? "",
            entity_id: response.data?.data?.inventory_id ?? "",
            product_status: response.data?.data?.product_status ?? "",
            sku: response.data?.data?.sku ?? "",
            unit: response.data?.data?.unit ?? "",
            unit_purchase_price: response.data?.data?.unit_purchase_price ?? 0,
            unit_selling_price: response.data?.data?.unit_selling_price ?? 0,
            stock_quantity: response.data?.data?.stock_quantity ?? 0,
            mrp: response.data?.data?.mrp ?? 0,
            tags: response.data?.data?.tags ?? [],
          });
        });
      });
    });
  };
  setIsParentProduct = (value) => {
    this.setState({ is_parent_product: CHECK_BOOL(value) }, () => {
      console.log(this.state.is_parent_product);
    });
  };
  setEntity = (value) => {
    this.setState({ entity_id: value.key, entity_name: value.label }, () => {
      console.log(this.state.entity_id);
    });
  };

  setText = (evt) => {
    evt.preventDefault();
    this.setState({ text: evt.target.value }, () => {
      console.log(this.state.text);
    });
  };

  setTags = () => {
    if (this.state.text === "") {
      return;
    } else {
      let array = [];
      array.push(this.state.text);
      this.setState({ tags: [...this.state.tags, ...array] }, () => {
        this.setState({ text: "" });
        console.log(this.state.tags);
      });
    }
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setDescription = (evt) => {
    evt.preventDefault();
    this.setState({ description: evt.target.value }, () => {
      console.log(this.state.description);
    });
  };
  setPurchasePrice = (evt) => {
    evt.preventDefault();
    this.setState({ unit_purchase_price: evt.target.value }, () => {
      console.log(this.state.unit_purchase_price);
    });
  };
  setSellingPrice = (evt) => {
    evt.preventDefault();
    this.setState({ unit_selling_price: evt.target.value }, () => {
      console.log(this.state.unit_selling_price);
    });
  };
  setMrp = (evt) => {
    evt.preventDefault();
    this.setState({ mrp: evt.target.value }, () => {
      console.log(this.state.mrp);
    });
  };
  setSku = (evt) => {
    evt.preventDefault();
    this.setState({ sku: evt.target.value }, () => {
      console.log(this.state.sku);
    });
  };
  setUnit = (value) => {
    this.setState({ unit: value}, () => {
      console.log(this.state.unit);
    });
  };
  setProductStatus = (value) => {
    this.setState({ product_status: value }, () => {
      console.log(this.state.product_status);
    });
  };
  setStockQuantity = (evt) => {
    evt.preventDefault();
    this.setState({ stock_quantity: evt.target.value }, () => {
      console.log(this.state.stock_quantity);
    });
  };

  uploadImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}${this.state.for_name}?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              imgUrl: `${response.data.data.upload_path}`,
              imgUrlThumbnail: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };

  createProduct = () => {
    if (this.state.unit === "" || this.state.price === "") {
      swal.fire("warning", "Please select a unit and price", "warning");
    } else if (!this.state.is_parent_product && this.state.entity_id === "") {
      swal.fire("warning", "Please select inventory", "warning");
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        is_parent_product: this.state.is_parent_product,
        parent_product_id: this.state.parent_product_id,
        inventory_id: this.state.entity_id,
        category_id: this.state.product_category_id,
        description: this.state.description,
        product_status: this.state.product_status,
        unit_purchase_price: Number(this.state.unit_purchase_price),
        unit_selling_price: Number(this.state.unit_selling_price),
        stock_quantity: Number(this.state.stock_quantity),
        mrp: Number(this.state.mrp),
        sku: this.state.sku,
        unit: this.state.unit,
        tags: this.state.tags,
        product_img: this.state.imgUrl,
        product_img_thumb: this.state.imgUrlThumbnail,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_PRODUCT}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            swal.fire("Info", response.data.message, "info");

            console.log("response from create product", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create product", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Create Product"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Parent Product</Text>
            <Select
              value={this.state.is_parent_product.toString()}
              onChange={this.setIsParentProduct}
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
          </Form.Item>
          {!this.state.is_parent_product ? (
            <Form.Item>
              <Text>Select Parent product</Text>
              <Select
                value={this.state.parent_product_id}
                onChange={this.setParentProduct}
              >
                {this.state.parent_products.map((r) => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>
          ) : null}
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Description</Text>
            <Input
              placeholder="Description"
              type="text"
              value={this.state.description}
              onChange={this.setDescription}
            />
          </Form.Item>
          <Form.Item>
            <Text>Upload Product Image (Image must be under 100 KB)</Text>

            <input
              type="file"
              onChange={(e) => {
                console.log("Image file inside", this.state.imgUrl);
                this.setState(
                  {
                    imgUrl: URL.createObjectURL(e.target.files[0]),
                    imgFile: e.target.files[0],
                  },
                  () => {
                    this.uploadImage();
                  }
                );
              }}
            />
          </Form.Item>
          <Form.Item>
            <Text>Select Product Category</Text>
            <Select
              labelInValue
              value={{
                key: this.state.product_category_id,
                label: this.state.product_category_name,
              }}
              onChange={this.setProductCategory}
            >
              {this.state.product_categories.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Search Inventory</Text>
            <Select
              disabled={this.state.is_parent_product ? true : false}
              showSearch={true}
              labelInValue
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              value={{
                key: this.state.entity_id,
                label: this.state.entity_name,
              }}
              onChange={this.setEntity}
              placeholder="Search Inventory"
            >
              {this.state.entities.map((r) => {
                return <Option key={r._id.$oid}>{r.entity_name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Unit Purchase Price</Text>
            <Input
              placeholder="Unit Purchase Price"
              type="number"
              value={this.state.unit_purchase_price}
              onChange={this.setPurchasePrice}
            />
          </Form.Item>
          <Form.Item>
            <Text>Unit Selling Price</Text>
            <Input
              placeholder="Unit Selling Price"
              type="number"
              value={this.state.unit_selling_price}
              onChange={this.setSellingPrice}
            />
          </Form.Item>

          <Form.Item>
            <Text>MRP</Text>
            <Input
              placeholder="MRP"
              type="number"
              value={this.state.mrp}
              onChange={this.setMrp}
            />
          </Form.Item>
          <Form.Item>
            <Text>Stock Quantity</Text>
            <Input
              disabled={this.state.is_parent_product ? true : false}
              placeholder="Stock Quantity"
              type="number"
              value={this.state.stock_quantity}
              onChange={this.setStockQuantity}
            />
          </Form.Item>
          <Form.Item>
            <Text>Product Status</Text>
            <Select
              value={this.state.product_status}
              onChange={this.setProductStatus}
            >
              <Option key="active">Active</Option>
              <Option key="inactive">Inactive</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>SKU</Text>
            <Input
              placeholder="SKU"
              type="number"
              value={this.state.sku}
              onChange={this.setSku}
            />
          </Form.Item>
          <Form.Item>
            <Text>
              Unit{" "}
              <Button
                type="link"
                onClick={() => this.setState({ showUnit: true })}
              >
                <Icon type="plus" style={{ fontSize: 20 }} />
              </Button>
            </Text>
            <Select
              value={this.state.unit}
              onChange={this.setUnit}
              placeholder="Select Unit"
            >
              {this.state.data.map((r) => {
                return <Option key={r._id.$oid}>{r.unit}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Tags</Text>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Input
                placeholder="Tags"
                type="text"
                value={this.state.text}
                onChange={this.setText}
              />
              <Button style={{ marginLeft: 5 }} onClick={this.setTags}>
                Add
              </Button>
            </div>
            <Text>Tag Names</Text>
            <Card style={{ marginTop: 5 }}>
              {this.state.tags.map((tag, index) => {
                return (
                  <Tag color="blue" key={index}>
                    {tag}
                  </Tag>
                );
              })}
            </Card>
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createProduct}
            >
              Create Product
            </Button>
          </Form.Item>
        </Form>
        <Modal
          title="Create New Unit"
          visible={this.state.showUnit}
          onOk={() => {
            this.setState({ showUnit: false });
            this.getUnitList();
          }}
          onCancel={() => {
            this.setState({ showUnit: false });
            this.getUnitList();
          }}
          width={window.innerWidth - 200}
          closable={true}
          destroyOnClose={true}
        >
          <CreateUnit />
        </Modal>
      </Card>
    );
  }
}
