import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_PLAN,
  EDIT_ORGANIZATION,
  // ORGANIZATION_PLAN,
  EXTEND_SUBSCRIPTION,
  REVERSE_GEO_COUNTRY,
  CHANGE_ORG_PLAN,
  ADD_ORG_PLAN
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";
import moment from "moment";

const { Text } = Typography;
const { Option } = Select;

export default class SubscribeOrganizationPlan extends Component {
  state = {
    organization_id: "",
    pid: "",
    loading: false,
    organization_name: "",
    planData: [],
    country_code: "",
    filtered_country: [],
    valid_till: "",
    existing_plans: false
  };

  componentDidMount() {
    this.getPlanList();
    this.getOrgInfo();
  }

  getCountry = () => {
    const success = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      axios.get(REVERSE_GEO_COUNTRY(latitude, longitude)).then(response => {
        console.log("response from reverse geo", response);
        this.setState(
          { country_code: response.data.address.country_code },
          () => {
            let filteredList = this.state.planData.filter(
              plan => plan.country.toLowerCase() === this.state.country_code
            );
            console.log("filtered list", filteredList);
            this.setState({ filtered_country: filteredList });
          }
        );
      });
    };
    navigator.geolocation.getCurrentPosition(success);
  };

  getOrgInfo = () => {
    this.setState({ organization_id: this.props.location.state.org_id });

    GET_ACCESS_TOKEN().then(token => {
      let org_id = this.props.location.state.org_id;
      let url = `${EDIT_ORGANIZATION}${org_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("subscribe page", response.data);
        this.setState({
          organization_name: response.data.data.name,
          valid_till: response?.data?.data?.plans[0]?.valid_till?.$date ?? "",
          pid: response?.data?.data?.plans[0]?._id?.$oid ?? "",
          existing_plans:
            response?.data?.data?.plans?.length > 0 ? true : false
        });
      });
    });
  };

  getPlanList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 500;
      const url = `${CREATE_PLAN}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from plan list api", response);
          this.setState(
            {
              planData: response.data.data
            },
            () => {
              this.getCountry();
            }
          );
        })
        .catch(error => {
          console.log("error from plan list api", error);
        });
    });
  };

  setPlan = value => {
    this.setState({ pid: value }, () => {
      console.log(this.state.pid);
    });
  };

  subscribeOrg = () => {
    swal
      .fire({
        title: "Are you sure you want to subscribe this organization?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      })
      .then(result => {
        if (result.value) {
          this.setState({ loading: true });
          const data = {
            organization_id: this.state.organization_id,
            subscription_date:
              Date.now() > this.state.valid_till
                ? Date.now()
                : moment(this.state.valid_till)
                    .add(1, "second")
                    .format("x")
          };
          console.log(data);
          GET_ACCESS_TOKEN().then(token => {
            let url = `${EXTEND_SUBSCRIPTION}${this.state.organization_id}?access_token=${token}`;
            axios({
              method: "put",
              url: url,
              data: data,
              headers: {
                "content-type": "application/x-www-form-urlencoded"
              }
            })
              .then(response => {
                this.setState({ loading: false });
                swal.fire("Success", response.data.message, "success");
                console.log("response from subscribe org", response);
                setTimeout(() => window.location.reload(), 1000);
              })
              .catch(error => {
                this.setState({ loading: false });
                swal.fire("Error", error.message, "error");
                console.log("error from subscribe org", error);
              });
          });
        }
      });
  };

  updatePlan=()=>{
    const data = {
      pid: this.state.pid
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${CHANGE_ORG_PLAN}${this.state.organization_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.getOrgInfo();
          swal.fire("Success", response.data.message, "success");
          console.log("response from change plan", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from change plan", error);
        });
    });
  }
  addPlan=()=>{
    const data = {
      pid: this.state.pid
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${ADD_ORG_PLAN}${this.state.organization_id}?access_token=${token}`;
      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          this.getOrgInfo();
          swal.fire("Success", response.data.message, "success");
          console.log("response from add plan", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from add plan", error);
        });
    });
  }
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          onBack={() => this.props.history.goBack()}
          title="Subscription and Plan Settings"
        />
        {moment(this.state.valid_till)
          .subtract(24, "hours")
          .format("x") <= Date.now() ? (
          <Card>
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column"
              }}
            >
              <Form.Item>
                <Text>Organization Name</Text>
                <Input
                  placeholder="Organization Name"
                  type="text"
                  value={this.state.organization_name}
                  disabled
                />
              </Form.Item>

              <Form.Item>
                <Button
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.subscribeOrg}
                >
                  Extend Subscription
                </Button>
              </Form.Item>
            </Form>
          </Card>
        ) : null}
        <Card>
          <Form
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column"
            }}
          >
            <Form.Item>
                <Text>Organization Name</Text>
                <Input
                  placeholder="Organization Name"
                  type="text"
                  value={this.state.organization_name}
                  disabled
                />
              </Form.Item>
            <Form.Item>
              <Text>Select Plan</Text>
              <Select
                value={this.state.pid}
                onChange={this.setPlan}
                placeholder="Select Plan"
              >
                {this.state.filtered_country.map(r => {
                  return <Option key={r._id.$oid}>{r.name}</Option>;
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={()=>{
                  this.state.existing_plans?this.updatePlan():this.addPlan()
                }}
              >
                {this.state.existing_plans?"Change Plan":"Add Plan"}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Card>
    );
  }
}
