import React, { Component } from "react";
import { Card, Typography, PageHeader, Tag } from "antd";
import axios from "axios";
import { EDIT_SUB_ORG } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;

export default class ViewSubOrg extends Component {
  state = {
    name: "",
    sub_org_type: "",
    sub_org_type_seq_no: 1,
    parent_sub_org: "",
    parent_sub_org_seq_no: 1,
    parent_sub_org_type: "",
    assigned_users: [],
    assigned_places: [],
    all_order_bill_amount: 0,
    all_order_due_amount: 0,
    all_order_number_by_places: 0,
    all_order_total_price: 0,
    all_order_total_received_amount: 0,
    assigned_client_number: 0,
    assigned_user_number: 0,
    no_of_child_sub_org: 0,
    child_sub_orgs: [],
    created_on: "",
    modified_on: "",
    representative_user:"",
    representative_place:""
  };

  componentDidMount() {
    this.setState({ sub_org_id: this.props.location.state.sub_org_id });
    this.getSubOrgDetails();
  }

  getSubOrgDetails = () => {
    GET_ACCESS_TOKEN().then(token => {
      let sub_org_id = this.props.location.state.sub_org_id;
      let url = `${EDIT_SUB_ORG}${sub_org_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro view suborg", response);
        this.setState({
          name: response.data?.data?.name ?? "",
          assigned_users: response.data?.data?.assigned_users ?? [],
          assigned_places: response.data?.data?.assigned_places ?? [],
          sub_org_type: response.data?.data?.sub_org_type_data?.name ?? "",
          sub_org_type_seq_no:
            response.data?.data?.sub_org_type_data?.seq_no ?? 1,
          parent_sub_org: response.data?.data?.parent_sub_org_data?.name ?? "",
          parent_sub_org_type:
            response.data?.data?.parent_sub_org_data?.sub_org_type_data?.name ??
            "",
          parent_sub_org_seq_no:
            response.data?.data?.parent_sub_org_data?.seq_no ?? 1,
          all_order_bill_amount:
            response.data?.data?.all_order_bill_amount ?? 0,
          all_order_due_amount: response.data?.data?.all_order_due_amount ?? 0,
          all_order_number_by_places:
            response.data?.data?.all_order_number_by_places ?? 0,
          all_order_total_price:
            response.data?.data?.all_order_total_price ?? 0,
          all_order_total_received_amount:
            response.data?.data?.all_order_total_received_amount ?? 0,
          assigned_client_number:
            response.data?.data?.assigned_client_number ?? 0,
          assigned_user_number: response.data?.data?.assigned_user_number ?? 0,
          no_of_child_sub_org: response.data?.data?.no_of_child_sub_org ?? 0,
          representative_user: `${response.data?.data?.representative_user?.name ?? ""}>${response.data?.data?.representative_user?.mobile ?? ""}`,
          representative_place: response.data?.data?.representative_place_as_inventory?.name ?? "",
          child_sub_orgs: response.data?.data?.child_sub_orgs ?? [],
          created_on: response.data?.data?.created_on?.$date ?? Date.now(),
          modified_on: response.data?.data?.modified_on?.$date ?? Date.now()
        });
      });
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          onBack={() => this.props.history.goBack()}
          title="View Sub Organization"
        />
        <Card
          style={{
            width: "100%"
          }}
        >
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Sub Organization Name</Text>:{" "}
            {this.state.name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Sub Organization Type</Text>:{" "}
            {this.state.sub_org_type}
          </p>
          {/* <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
          <Text style={{ fontWeight: "bold" }}>
            Sub Organization Type Sequence Number
          </Text>
          : {this.state.sub_org_type_seq_no}
        </p> */}
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>
              Parent Sub Organization Name & Type
            </Text>
            :{" "}
            {`${this.state.parent_sub_org}---${this.state.parent_sub_org_type}`}
          </p>
          {/* <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
          <Text style={{ fontWeight: "bold" }}>
            Parent Sub Organization Sequence Number
          </Text>
          : {this.state.parent_sub_org_seq_no}
        </p> */}
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>
              Number of Child Sub Organization
            </Text>
            : {this.state.no_of_child_sub_org}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Child Sub Organizations</Text>:{" "}
            {this.state.child_sub_orgs.length > 0 ? (
              <>
                {this.state.child_sub_orgs.map((cso, index) => (
                  <Tag
                    key={index}
                    color="blue"
                  >{`${cso.name}---${cso.sub_org_type_data.name}`}</Tag>
                ))}
              </>
            ) : null}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Representative User</Text>:{" "}
            {this.state.representative_user}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Representative Place</Text>:{" "}
            {this.state.representative_place}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Assigned Persons</Text>:{" "}
            {this.state.assigned_user_number}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Assigned Persons</Text>:{" "}
            {this.state.assigned_users.length > 0 ? (
              <>
                {this.state.assigned_users.map((user, index) => (
                  <Tag
                    key={index}
                    color="blue"
                  >{`${user.name}>>>${user.mobile}`}</Tag>
                ))}
              </>
            ) : null}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Clients</Text>:{" "}
            {this.state.assigned_client_number}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Clients</Text>:{" "}
            {this.state.assigned_places.length > 0 ? (
              <>
                {this.state.assigned_places.map((place, index) => (
                  <Tag key={index} color="green">{`Name: ${
                    place.name
                  }, Address: ${place?.address ??
                    ""}, Type: ${place?.enttiy_type ?? ""}`}</Tag>
                ))}
              </>
            ) : null}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Order Number</Text>:{" "}
            {this.state.all_order_number_by_places}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Sold Amount</Text>:{" "}
            {this.state.all_order_total_price}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Billed Amount</Text>:{" "}
            {this.state.all_order_bill_amount}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Received Amount</Text>:{" "}
            {this.state.all_order_total_received_amount}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Total Due Amount</Text>:{" "}
            {this.state.all_order_due_amount}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Created On</Text>:{" "}
            {timeConverter(this.state.created_on)}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Modified On</Text>:{" "}
            {timeConverter(this.state.modified_on)}
          </p>
        </Card>
      </Card>
    );
  }
}
