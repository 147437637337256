import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_ASSET, EDIT_CONTAINER } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class EditContainer extends Component {
  state = {
    name: "",
    loc_lat: 23.685,
    loc_lon: 90.3563,
    cl: null,
    cw: null,
    ch: null,
    unit_dim: null,
    unit_weight: null,
    max_weight: null,
    gross_weight: null,
    asset: "",
    assets: [],
    device_id: "",
    connect_key: "",
    container_id:"",
    loading: false
  };

  componentDidMount() {
    this.getContainerDetails();
    this.getAssetList();
  }

  getContainerDetails = () => {
    this.setState({ container_id: this.props.location.state.container_id });

    GET_ACCESS_TOKEN().then(token => {
      let container_id = this.props.location.state.container_id;
      let url = `${EDIT_CONTAINER}${container_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro container view", response.data.data);
        this.setState({
            name:response.data.data.name ,
            cl:response.data.data.cl ,
            cw:response.data.data.cw ,
            ch:response.data.data.ch ,
            unit_dim:response.data.data.unit_dim ,
            unit_weight:response.data.data.unit_weight ,
            max_weight:response.data.data.max_weight ,
            gross_weight:response.data.data.gross_weight ,
            asset:response.data.data.asset 
        });
      });
    });
  };

  getAssetList = () => {
    GET_ACCESS_TOKEN().then(token => {
      let limit = 1000;
      const url = `${CREATE_ASSET}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          this.setState({ assets: response.data.data });
          console.log("assets", response.data.data);
        })
        .catch(error => {
          console.log("error from assets list api", error);
        });
    });
  };

  setAsset = value => {
    this.setState({ asset: value }, () => {
      console.log(this.state.asset);
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setcl = evt => {
    evt.preventDefault();
    this.setState({ cl: evt.target.value }, () => {
      console.log(this.state.cl);
    });
  };
  setcw = evt => {
    evt.preventDefault();
    this.setState({ cw: evt.target.value }, () => {
      console.log(this.state.cw);
    });
  };
  setch = evt => {
    evt.preventDefault();
    this.setState({ ch: evt.target.value }, () => {
      console.log(this.state.ch);
    });
  };
  setDim = evt => {
    evt.preventDefault();
    this.setState({ unit_dim: evt.target.value }, () => {
      console.log(this.state.unit_dim);
    });
  };
  setWeight = evt => {
    evt.preventDefault();
    this.setState({ unit_weight: evt.target.value }, () => {
      console.log(this.state.unit_weight);
    });
  };
  setMaxweight = evt => {
    evt.preventDefault();
    this.setState({ max_weight: evt.target.value }, () => {
      console.log(this.state.max_weight);
    });
  };

  updateContainer = () => {
    if (
      this.state.name === "" ||
      this.state.cl === null ||
      this.state.ch === null ||
      this.state.cw === null ||
      this.state.unit_dim === null ||
      this.state.unit_weight === null ||
      this.state.max_weight === null
    ) {
      swal.fire(
        "warning",
        "Please fill up name,dimension,weight,maximum weight,length,width and height",
        "warning"
      );
    } else if (this.state.cw > 10 || this.state.cl > 40 || this.state.ch > 12) {
      swal.fire(
        "warning",
        "Container length,Height and Width Can not Exceed 40,12 and 10 Feet Respectively",
        "warning"
      );
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        loc: {
          coordinates: [this.state.loc_lon, this.state.loc_lat]
        },
        cl: Number(this.state.cl),
        cw: Number(this.state.cw),
        ch: Number(this.state.ch),
        unit_dim: Number(this.state.unit_dim),
        unit_weight: Number(this.state.unit_weight),
        max_weight: Number(this.state.max_weight),
        gross_weight: Number(this.state.gross_weight),
        asset: this.state.asset,
        device_id: this.state.device_id,
        connect_key: this.state.connect_key
      };
      console.log(data);
      GET_ACCESS_TOKEN().then(token => {
        let url = `${EDIT_CONTAINER}${this.state.container_id}?access_token=${token}`;
        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded"
          }
        })
          .then(response => {
            this.setState({ loading: false });
            swal.fire("Success", response.data.message, "success");

            console.log("response from edit container", response);
          })
          .catch(error => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from edit container", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          onBack={() => this.props.history.goBack()}
          title="Update Container"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Container length (In Feet)</Text>
            <Input
              placeholder="Container length"
              type="number"
              value={this.state.cl}
              onChange={this.setcl}
            />
          </Form.Item>
          <Form.Item>
            <Text>Container Width (In Feet)</Text>
            <Input
              placeholder="Container Width"
              type="number"
              value={this.state.cw}
              onChange={this.setcw}
            />
          </Form.Item>
          <Form.Item>
            <Text>Container Height (In Feet)</Text>
            <Input
              placeholder="Container Height"
              type="number"
              value={this.state.ch}
              onChange={this.setch}
            />
          </Form.Item>
          <Form.Item>
            <Text>Container Dimension</Text>
            <Input
              placeholder="Container Dimension"
              type="number"
              value={this.state.unit_dim}
              onChange={this.setDim}
            />
          </Form.Item>
          <Form.Item>
            <Text>Container Weight (In Kilogram)</Text>
            <Input
              placeholder="Container Weight"
              type="number"
              value={this.state.unit_weight}
              onChange={this.setWeight}
            />
          </Form.Item>
          <Form.Item>
            <Text>Container Maximum Weight (In Kilogram)</Text>
            <Input
              placeholder="Container Maximum Weight"
              type="number"
              value={this.state.max_weight}
              onChange={this.setMaxweight}
            />
          </Form.Item>
          <Form.Item>
            <Text>Select Asset</Text>
            <Select
              value={this.state.asset.toString()}
              onChange={this.setAsset}
              placeholder="Select Asset"
            >
              {this.state.assets.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.updateContainer}
            >
              Update Container
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
