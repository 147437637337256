import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Modal,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  CREATE_DRIVER,
  ASSET_SEARCH,
  ADVANCED_TASK_SEARCH,
  PROMOTION_ORDER_SEARCH,
  BILL_SEARCH,
  // CREATE_PARCEL,
  // CREATE_CONTAINER,
  CREATE_INCIDENT,
  INCIDENT_TYPE_CHOICES,
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import CreateBill from "../bill/createBill";
import ReactGA from "react-ga4";
import { debounce } from "lodash";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

export default class CreateIncident extends Component {
  state = {
    name: "",
    incident_type: "",
    reason: "",
    asset_id: "",
    asset_name: "",
    task_id: "",
    task_name: "",
    order_id: "",
    order_name: "",
    bill_id: "",
    bill_name: "",
    // parcel_name: "",
    // parcel_id: "",
    // container_id: "",
    // container_name: "",
    created_for_id: "",
    created_for_name: "",
    incidentData: [],
    taskData: [],
    assetData: [],
    userData: [],
    orderData: [],
    billData: [],
    showBillModal: false,
    incident_date:Date.now(),
    incident_date_view:moment(),
    // parcelData: [],
    // containerData: []
  };

  componentDidMount() {
    this.setState({
      asset_id: this.props?.location?.state?.asset_id ?? "",
      asset_name: this.props?.location?.state?.asset_name ?? "",
      task_id: this.props?.location?.state?.task_id ?? "",
      task_name: this.props?.location?.state?.task_name ?? "",
      order_id: this.props?.location?.state?.order_id ?? "",
      order_name: this.props?.location?.state?.order_name ?? "",
      bill_id: this.props?.location?.state?.bill_id ?? "",
      bill_name: this.props?.location?.state?.bill_name ?? "",
      created_for_id: this.props?.location?.state?.created_for_id ?? "",
      created_for_name: this.props?.location?.state?.created_for_name ?? "",
    });
    // this.getRunningTask();
    this.getincidentType();
    this.getAsset();
    this.getTask();
    this.getUser();
    this.getOrder();
    this.getBill();
    // this.getParcel();
    // this.getContainer();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;
  }

  getincidentType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${INCIDENT_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ incidentData: response.data.incident_type_choices });
          console.log("inc", response);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  getBill = debounce((value = "") => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${BILL_SEARCH}?bill_no=${value}&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("bill", response);
          this.setState({ billData: response.data.data });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, 500);
  getAsset = debounce((value = "") => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${ASSET_SEARCH}?name=${value}&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("asset", response);
          this.setState({ assetData: response.data.data });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, 500);
  getUser = debounce((value = "") => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${CREATE_DRIVER}?name=${value}&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("user", response);
          this.setState({ userData: response.data.data });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, 500);
  // getRunningTask = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     let limit = 10;
  //     const url = `${ADVANCED_TASK_SEARCH}?assigned=true&accepted=true&started=true&completed=false&assigned_to=${
  //       GET_USER_LOCAL()._id.$oid
  //     }&limit=${limit}&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         console.log("running task", response);
  //         if (response.data.data.length > 0) {
  //           this.setState({
  //             task_id: response.data?.data[0]?._id?.$oid ?? "",
  //             asset_id: response.data?.data[0]?.assigned_asset?._id?.$oid ?? ""
  //           });
  //         }
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //   });
  // };
  getTask = debounce((value = "") => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${ADVANCED_TASK_SEARCH}?name=${value}&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("task", response);
          this.setState({ taskData: response.data.data });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, 500);
  getOrder = debounce((value = "") => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${PROMOTION_ORDER_SEARCH}?name=${value}&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("order", response);
          this.setState({ orderData: response.data.data });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }, 500);
  // getParcel = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     let limit = 1000;
  //     const url = `${CREATE_PARCEL}?limit=${limit}&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         this.setState({ parcelData: response.data.data });
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //   });
  // };
  // getContainer = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     let limit = 1000;
  //     const url = `${CREATE_CONTAINER}?limit=${limit}&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         this.setState({ containerData: response.data.data });
  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //   });
  // };

  searchUser = (value) => {
    this.getUser(value);
  };
  setUser = (value) => {
    this.setState(
      { created_for_id: value.key, created_for_name: value.label },
      () => {
        console.log(this.state.created_for_name);
      }
    );
  };
  setIncidentType = (value) => {
    this.setState({ incident_type: value }, () => {
      console.log(this.state.incident_type);
    });
  };
  searchAsset = (value) => {
    this.getAsset(value);
  };
  setAsset = (value) => {
    this.setState({ asset_id: value.key, asset_name: value.label }, () => {
      console.log(this.state.asset_name);
    });
  };
  searchTask = (value) => {
    this.getTask(value);
  };
  setTask = (value) => {
    this.setState({ task_id: value.key, task_name: value.label }, () => {
      console.log(this.state.task_name);
    });
  };
  searchOrder = (value) => {
    this.getOrder(value);
  };
  setOrder = (value) => {
    this.setState({ order_id: value.key, order_name: value.label }, () => {
      console.log(this.state.order_name);
    });
  };
  searchBill = (value) => {
    this.getBill(value);
  };
  setBill = (value) => {
    this.setState({ bill_id: value.key, bill_name: value.label }, () => {
      console.log(this.state.bill_name);
    });
  };
  setParcel = (value) => {
    this.setState({ parcel_id: value.key, parcel_name: value.label }, () => {
      console.log(this.state.parcel_name);
    });
  };
  setContainer = (value) => {
    this.setState(
      { container_id: value.key, container_name: value.label },
      () => {
        console.log(this.state.container_name);
      }
    );
  };
  setText = (evt) => {
    evt.preventDefault();
    this.setState({ text: evt.target.value }, () => {
      console.log(this.state.text);
    });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setReason = (evt) => {
    evt.preventDefault();
    this.setState({ reason: evt.target.value }, () => {
      console.log(this.state.reason);
    });
  };

  createIncident = () => {
    if (
      this.state.name === "" ||
      this.state.incident_type === "" ||
      this.state.reason === ""
    ) {
      swal.fire(
        "Warning",
        "Please enter name,reason and incident type",
        "warning"
      );
    } else {
      const data = {
        name: this.state.name,
        incident_type: this.state.incident_type,
        reason: this.state.reason,
        bill_id: this.state.bill_id,
        incident_date: this.state.incident_date,
        asset: {
          id: this.state.asset_id,
          name: this.state.asset_name,
        },
        task: {
          id: this.state.task_id,
          name: this.state.task_name,
        },
        order: {
          id: this.state.order_id,
          name: this.state.order_name,
        },
        parcel: {
          id: "",
          name: "",
        },
        container: {
          id: "",
          name: "",
        },
        created_for: {
          id: this.state.created_for_id,
          name: this.state.created_for_name,
        },
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_INCIDENT}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");

            console.log("response from create incident", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from create incident", error);
          });
      });
    }
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="Create Incident"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          
          <Form.Item>
            <Text>Reason</Text>
            <Input
              placeholder="Reason"
              type="text"
              value={this.state.reason}
              onChange={this.setReason}
            />
          </Form.Item>

          {/* <Form.Item>
            <Text>Compensation</Text>
            <Input
              placeholder="Compensation"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item> */}
          <Form.Item>
            <Text>Incident Type</Text>
            <Select
              value={this.state.incident_type}
              onChange={this.setIncidentType}
              placeholder="Incident Type"
            >
              {this.state.incidentData.map((r) => {
                return <Option key={r}>{r}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Text>Incident For</Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              onSearch={this.searchUser}
              value={{
                key: this.state.created_for_id,
                label: this.state.created_for_name,
              }}
              onChange={this.setUser}
              placeholder="Incident For"
            >
              {this.state.userData.map((r) => {
                return (
                  <Option
                    key={r._id.$oid}
                  >{`${r.name}>${r.mobile} (Org Status - ${r.organization_status})`}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                marginBottom: 5,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Incident Date</Text>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  inputVariant="outlined"
                  value={this.state.incident_date_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      { incident_date: moment(value).format("x"), incident_date_view: value },
                      () => {
                        console.log("Selected Time: ", this.state.incident_date);
                        console.log("Selected value: ", this.state.incident_date_view);
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Form.Item>

          {/* <Form.Item>
            <Text>Remarks</Text>
            <Input
              placeholder="Remarks"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item> */}
          <Form.Item>
            <Text>Asset</Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              onSearch={this.searchAsset}
              value={{
                key: this.state.asset_id,
                label: this.state.asset_name,
              }}
              onChange={this.setAsset}
              placeholder="Asset"
            >
              {this.state.assetData.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Task</Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              onSearch={this.searchTask}
              value={{
                key: this.state.task_id,
                label: this.state.task_name,
              }}
              onChange={this.setTask}
              placeholder="Task"
            >
              {this.state.taskData.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Order</Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              onSearch={this.searchOrder}
              value={{
                key: this.state.order_id,
                label: this.state.order_name,
              }}
              onChange={this.setOrder}
              placeholder="Order"
            >
              {this.state.orderData.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>
              Bill No.{" "}
              <Button
                type="link"
                onClick={() => this.setState({ showBillModal: true })}
              >
                Create Bill
              </Button>
            </Text>
            <Select
              labelInValue
              showSearch={true}
              filterOption={false}
              showArrow={false}
              onSearch={this.searchBill}
              value={{
                key: this.state.bill_id,
                label: this.state.bill_name,
              }}
              onChange={this.setBill}
              placeholder="Bill"
            >
              {this.state.billData.map((r) => {
                return <Option key={r._id.$oid}>{r.bill_no}</Option>;
              })}
            </Select>
          </Form.Item>
          {/* <Form.Item>
            <Text>Parcel</Text>
            <Select
              labelInValue
              value={{
                key: this.state.parcel_id,
                label: this.state.parcel_name
              }}
              onChange={this.setParcel}
              placeholder="Parcel"
            >
              {this.state.parcelData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Container</Text>
            <Select
              labelInValue
              value={{
                key: this.state.container_id,
                label: this.state.container_name
              }}
              onChange={this.setContainer}
              placeholder="Container"
            >
              {this.state.containerData.map(r => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item> */}

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.createIncident}
            >
              Create Incident
            </Button>
          </Form.Item>
        </Form>
        <Modal
          visible={this.state.showBillModal}
          onOk={() => {
            this.setState({ showBillModal: false });
            this.getBill();
          }}
          onCancel={() => {
            this.setState({ showBillModal: false });
            this.getBill();
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <CreateBill />
        </Modal>
      </Card>
    );
  }
}
