import React, { Component } from "react";
import {
  Card,
  Typography,
  PageHeader,
  // Table
} from "antd";
import axios from "axios";
// import swal from "sweetalert2";
import {
  EDIT_PRODUCT,
  // PRODUCT_STOCK_HISTORY
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  // timeConverter
} from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;
// const { Column } = Table;

export default class ViewProduct extends Component {
  state = {
    name: "",
    product_id: "",
    description: "",
    unit_purchase_price: null,
    unit_selling_price: null,
    stock_quantity: 0,
    product_status: "active",
    mrp: null,
    sku: "",
    unit: "",
    unit_key: "",
    tags: [],
    text: "",
    stocks: [],
    product_category_name: "",
    available_stock: 0,
    ordered_quantity: 0,
    imgUrl: null,
    imgUrlThumbnail: null,
    product_barcode: null,
  };
  componentDidMount() {
    this.setState({ product_id: this.props.location.state.product_id });
    this.getProductDetails();
  }

  getProductDetails = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let product_id = this.props.location.state.product_id;
      let url = `${EDIT_PRODUCT}${product_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro view product", response);
        this.setState(
          {
            name: response.data?.data?.name ?? "",
            description: response.data?.data?.description ?? "",
            product_category_name: response.data?.data?.category_name ?? "",
            product_status: response.data?.data?.product_status ?? "",
            sku: response.data?.data?.sku ?? "",
            unit: response.data?.data?.unit ?? "",
            unit_purchase_price: response.data?.data?.unit_purchase_price ?? 0,
            unit_selling_price: response.data?.data?.unit_selling_price ?? 0,
            stock_quantity: response.data?.data?.stock_quantity ?? 0,
            ordered_quantity: response.data?.data?.ordered_quantity ?? 0,
            available_stock: response.data?.data?.available_stock ?? 0,
            imgUrl: response.data?.data?.product_img ?? null,
            imgUrlThumbnail: response.data?.data?.product_img_thumb ?? null,
            product_barcode: response.data?.data?.product_barcode ?? null,
            mrp: response.data?.data?.mrp ?? 0,
            tags: response.data?.data?.tags ?? [],
          },
          () => {
            // this.getStockHistory();
          }
        );
      });
    });
  };

  // getStockHistory = () => {

  //   GET_ACCESS_TOKEN().then(token => {
  //     let limit = 100;
  //     const url = `${PRODUCT_STOCK_HISTORY}?product_id=${this.state.product_number}&limit=${limit}&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         this.setState({ stocks: response.data.data });
  //         console.log("data from product stock history list api", response);
  //       })
  //       .catch(error => {
  //         console.log("error from product stock history list api", error);
  //       });
  //   });
  // };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
          }}
          title="View Product"
          onBack={() => this.props.history.goBack()}
        />
        <Card
          style={{
            width: "100%",
          }}
        >
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Product Name</Text>:{" "}
            {this.state.name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Description</Text>:{" "}
            {this.state.description}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Category Name</Text>:{" "}
            {this.state.product_category_name}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Unit Purchase Price</Text>:{" "}
            {this.state.unit_purchase_price}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Unit Selling Price</Text>:{" "}
            {this.state.unit_selling_price}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Stock Quantity</Text>:{" "}
            {this.state.stock_quantity}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Ordered Quantity</Text>:{" "}
            {this.state.ordered_quantity}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Available Stock</Text>:{" "}
            {this.state.available_stock}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Product Status</Text>:{" "}
            {this.state.product_status}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>MRP</Text>: {this.state.mrp}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>SKU</Text>: {this.state.sku}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Product Unit</Text>:{" "}
            {this.state.unit}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Product Image</Text>:{" "}
            {this.state.imgUrl ? (
              <img
                src={`${process.env.REACT_APP_BASE_PATH}${this.state.imgUrl}`}
                alt="avatar"
                style={{ width: 300}}
              />
            ) : null}
          </p>
          <p style={{ fontFamily: "AirbnbCerealApp-Book" }}>
            <Text style={{ fontWeight: "bold" }}>Product Bar Code</Text>:{" "}
            {this.state.product_barcode ? (
              <img
                src={`${process.env.REACT_APP_BASE_PATH}${this.state.product_barcode}`}
                alt="avatar"
                style={{ width: 300}}
              />
            ) : null}
          </p>
        </Card>
        {/* <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Stock history"
        />
        <Card
          style={{
            width: "100%"
          }}
        >
        <Table
          dataSource={this.state.stocks}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title="Available Stock"
            dataIndex="available_stock"
            key="available_stock"
          />
          <Column
            title="Added Stock"
            dataIndex="added_stock"
            key="added_stock"
          />
          <Column
            title="Subtracted Stock"
            dataIndex="subtracted_stock"
            key="subtracted_stock"
          />
          <Column
            title="Purchase Price"
            dataIndex="cost_amount"
            key="cost_amount"
          />
          <Column
            title="Unit Purchase Price"
            dataIndex="unit_price"
            key="unit_price"
          />
          <Column
            title="Remarks"
            dataIndex="remarks"
            key="remarks"
          />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
         
        </Table>
        </Card> */}
      </Card>
    );
  }
}
