import React, { Component } from "react";
import { Card, Form, Input, Button, Typography, PageHeader } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { EDIT_ROLE } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
// import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;

export default class EditRole extends Component {
  state = {
    name: "",
    description: "",
    permissions: {},
    role_id: ""
  };
  componentDidMount() {
    this.setState({ role_id: this.props.location.state.role_id });
    this.getRole();
  }

  getRole = () => {
    GET_ACCESS_TOKEN().then(token => {
      let role_id = this.props.location.state.role_id;
      let url = `${EDIT_ROLE}${role_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      }).then(response => {
        console.log("response fro edit role", response);
        this.setState({
          name: response.data.data.name,
          description: response.data.data.description,
          permissions: response.data.data.permissions
        });
      });
    });
  };

  setName = evt => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setDescription = evt => {
    evt.preventDefault();
    this.setState({ description: evt.target.value }, () => {
      console.log(this.state.description);
    });
  };

  editRole = () => {
    const data = {
      name: this.state.name,
      description: this.state.description,
      permissions: this.state.permissions
    };
    console.log(data);
    GET_ACCESS_TOKEN().then(token => {
      let url = `${EDIT_ROLE}${this.state.role_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit unit", response);
        })
        .catch(error => {
          swal.fire("Error", error.message, "error");
          console.log("error from edit role", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)"
          }}
          title="Edit Role"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column"
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>
          <Form.Item>
            <Text>Description</Text>
            <Input
              placeholder="Description"
              type="text"
              value={this.state.description}
              onChange={this.setDescription}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editRole}
            >
              Update Role
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
